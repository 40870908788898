import React,{useEffect, useMemo,useRef, useState} from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { apiBaseUrl, http, notify } from "../../utitility";
import { confirmAlert } from 'react-confirm-alert'; 
import { useLocation,Link } from 'react-router-dom';

const AddMailingList = () =>{
    const childRef = useRef();
    const buttonRef = useRef({});
	const  location = useLocation();
    const [contactId,setContactId] = useState(new URLSearchParams(location.search).get('contact_id'))
	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}
    const getActionField = (contact_id,mailListId) => {
        if(contact_id){
            return( 
                <div>
                    <span onClick={()=>{ConfirmAlert(contact_id,mailListId)}}  className="mr-2 table_font_size btn-link link-pointer">Remove</span>
                </div>
            )
        }
        else{
            return( 
                <div>
                    <button onClick={()=>{handleAddMailingList(mailListId)}} ref={el=>buttonRef.current[mailListId] = el} className="mr-2 btn btn-link">Add</button>
                </div>
            )
        }
	}
    const handleAddMailingList = (mailListId) =>{
		buttonRef.current[mailListId].setAttribute("disabled", "disabled");
        const params = new URLSearchParams(location.search);
		let contactID = params.get('contact_id');
        let data = {contactID : contactID}
        http.post(apiBaseUrl(`/api/addMailingList?mailListing_id=${mailListId}`),data)
        .then((res)=>{
            notify.info('Added successfully')
            reloadTableData()
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    const ConfirmAlert = (contact_id,mailListId) => {
		confirmAlert({
			message: 'Do you really want to remove this mailing list?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => removeMailingList(contact_id,mailListId)
				},
				{
					label: 'No',
					onClick: () => {}
				}
			]
		});
	}

	const removeMailingList = (contact_id,mailListId) => {
		http.delete(apiBaseUrl(`/api/removeContact?contact_id=${contact_id}&mailListId=${mailListId}`))
		.then(() => {
			reloadTableData();
			notify.success("Remove successfully");
		}).catch((error)=>{
			let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
											error.response.data.message :
											'whoops...something went wrong';
			notify.error(errorMsg);
		});
	}
    const columns = useMemo(
		() => [
			{
				Header: "List",
				accessor: "mailinglist_name",
				width: 300,
				sort:true,
			},
			{
				Header: "On List",
				accessor: "contact_id",
				width: 130,
				sort:true,
                Cell: data => {return (data.row.original.contact_id ? 'Yes': 'No')}
			},
			{
				Header: "Action",
				accessor: "act",
				Cell: data => (getActionField(data.row.original.contact_id,data.row.original.maillist_id)),
				width: 130
			}
		],
		[]
	)
    return(
        <React.Fragment>
					<div>
						{ contactId && <ReactGenericTable ref={childRef} pagination={true} url={`getMailingListByContactId?contact_id=${contactId}&`} defaultSortIndex="maillist_id" defaultSortOrder="DESC" columns={columns} />}
					</div>
				</React.Fragment>
    )
}
export default AddMailingList