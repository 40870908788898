import React, { useMemo,useRef, useState,useEffect }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { http, apiBaseUrl, notify,getCurrentDate} from '../../utitility';
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; 
import { CSVLink } from "react-csv";
const MailListing = ({user}) => {
	const childRef = useRef();
	const csvLink = React.createRef();
	const [data,setData] = useState([])
	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}
	const getExportData = async(maillist_id) =>{
		await http.get(apiBaseUrl(`/api/getContactListByMailingId?mailingList_id=${maillist_id}`))
		.then((res) => {	
			const value = res.data.data;
			setData(value);
		}).catch((error=>{
			console.log(error,'error')
		}))
	}
	useEffect(() => {
		if (data.length > 0) {
			csvLink.current.link.click();
		}
	  }, [data])
	const getActionField = (maillist_id) => {
		return( 
			<div>
				<Link to={`/contacts/mail_listing_info?mailingList_id=${maillist_id}`}className="mr-2 table_font_size btn-link">View</Link>
				<span onClick={()=>ConfirmAlert(maillist_id)} className="mr-2 table_font_size btn-link link-pointer">Delete</span>
				<span onClick={()=>getExportData(maillist_id)} className="mr-2 table_font_size btn-link link-pointer">Export</span>
			</div>
		)
	}
	const ConfirmAlert = (value) => {
		confirmAlert({
			message: 'Do you really want to delete this mailing list?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteMailingList(value)
				},
				{
					label: 'No',
					onClick: () => {}
				}
			]
		});
	}

	const deleteMailingList = (maillist_id) => {
		http.delete(apiBaseUrl(`/api/deleteMailingList?mailingList_id=${maillist_id}`))
		.then(() => {
			reloadTableData();
			notify.success("Deleted successfully");
		}).catch((error)=>{
			let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
											error.response.data.message :
											'whoops...something went wrong';
			notify.error(errorMsg);
		});
	}
	
	const columns = useMemo(
		() => [
			{
				Header: "List",
				accessor: "mailinglist_name",
				width: 300,
				sort:true,
				Cell: data => (<Link to={`/contacts/edit_mail_listing?mailingList_id=${data.row.original.maillist_id}`} className="mr-2 table_font_size btn-link">{data.row.original.mailinglist_name}</Link>),
			},
			{
				Header: "Contacts",
				accessor: "contactcnt",
				width: 130,
				sort:true
			},
			{
				Header: "Action",
				accessor: "act",
				Cell: data => (getActionField(data.row.original.maillist_id)),
				width: 150
			}
		],
		[]
	)

  return (
				<React.Fragment>
					<div className="px-2 mb-3">
						<u><Link  className="px-2 text-primary" to={'/contacts/mylisting'}>My Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_contact'}>Create Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_contacts'}>Search Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_organizations'}>Create Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/organizations'}>Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_organizations'}>Search Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/mailing_lists'}>Mailing lists</Link></u>
					</div>
					<div className="px-3 mb-3">
						<h5 className="font-weight-bold form-text">Mailing List</h5>
						<u><Link className="px-2 text-primary" to={'/contacts/create_mail_listing'}>Create New Mailing List</Link></u>
					</div>
					<div>
						<ReactGenericTable ref={childRef} pagination={true} url={`getMailListing?`} defaultSortIndex="maillist_id" defaultSortOrder="DESC" columns={columns} />
					</div>
				{data && <CSVLink data={data} filename={"Mail list exported "+getCurrentDate()+".csv"}ref={csvLink}target="_blank"/>}
				</React.Fragment>
  );
};
export default MailListing;