import { Formik, Field, ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import { ConnectedFocusError } from "focus-formik-error";
import Radio from "../Fields/Radio";
import Select from "react-select";
import classnames from "classnames";
import { notify, http, apiBaseUrl, formatDate,getCurrentDate } from "../../utitility";
import OrganizatioinSchema from "../validation/OrganizationSchema";
import {useLocation } from "react-router-dom";
import AddTynaxContact from "../Contacts/AddTynaxContact";
import ConfirmationDialogComponent from '../ConfirmationDialog/ConfirmationDialog';
import closeIcon from '../../assets/images/closeIcon.png';
const CreateOrganization = ({
  org_id,
  initialState,
  setInitialState,
  history,
  user,
}) => {
  const location = useLocation();
  const [countryOptions, setcountryOptions] = useState([]);
  const [selectedCountry, setselectedCountry] = useState({
    value: "",
    label: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedPatentSellingPolicy, setSelectedPatentSellingPolicy] =
    useState({ value: 0, label: "Unknown" });
  const [selectedPatentSellingTarget, setSelectedPatentSellingTarget] =
    useState({ value: 0, label: "Any Buyer" });
  const [selectedTechTransferActivity, setSelectedTechTransferActivity] =
    useState({ value: "Not Known", label: "Not Known" });
  const [organzationType, setOrganizationType] = useState(null);
  const [showAddTynaxContact,setShowAddTynaxContact] = useState(false);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const [tynaxPeople,setTynaxPeople] = useState([]);
  const [tynaxContactData,setTynaxContactData] = useState([]);
  const [removingId,setRemovingId] = useState(null);


  const patentSellingPolicyOptions = [
    { value: 0, label: "Unknown" },
    { value: 1, label: "Will Sell" },
    { value: 2, label: "Perhaps Sell" },
    { value: 3, label: "Will Not Sell" },
  ];
  const patentSellingTargetOptions = [
    { value: 0, label: "Any Buyer" },
    { value: 1, label: "No NPE" },
    { value: 2, label: "No Competitors" },
    { value: 3, label: "No NPE & Competitors" },
  ];
  const techTransferActivityOptions = [
    { value: "Not Known", label: "Not Known" },
    { value: "Verry Active", label: "Verry Active" },
    { value: "Active", label: "Active" },
    { value: "Some Activity", label: "Some Activity" },
    { value: "No Activity", label: "No Activity" },
  ];
  const getOrganizationsTypeList = () => {
    http
      .get(apiBaseUrl(`/api/getOrganizationsTypeList`))
      .then((res) => {
        setOrganizationType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (countryOptions.length === 0) {
      http
        .get(apiBaseUrl(`/api/getCountry`))
        .then((res) => {
          setcountryOptions(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getOrganizationsTypeList();
  }, []);
  const handleCountryChange = (option, setFieldValue) => {
    setFieldValue("organizationDetails.Country", option.value);
    setselectedCountry(option);
  };
  const handlePatentSellingPolicyChange = (option, setFieldValue) => {
    setFieldValue("organizationDetails.PatentSellingPolicy", option.value);
    setSelectedPatentSellingPolicy(option);
  };
  const handlePatentSellingTargetChange = (option, setFieldValue) => {
    setFieldValue("organizationDetails.PatentSellingTarget", option.value);
    setSelectedPatentSellingTarget(option);
  };
  const handleTechTransferActivityChange = (option, setFieldValue) => {
    setFieldValue("organizationDetails.TechTransferActivity", option.value);
    setSelectedTechTransferActivity(option);
  };
  const changeType = (e, setFieldValue, field) => {
    setFieldValue(field, e.target.value);
  };
  const clearFormFields = (resetForm) => {
    resetForm({});
  };
  const redirectToListingPage = () => {
    history.goBack();
  };
  useEffect(() => {
    if (org_id) {
      http
        .get(apiBaseUrl(`/api/getOrganizationInfo?org_id=${org_id}`))
        .then((response) => {
          const orgDetails = response.data.organizationDetails[0];
          setTynaxPeople(response.data.tynax_people);
          response.data.org_tynax_link.map(val => {
            const matchedObj = response.data.tynax_people.find(data => data.PersonID === val.TynaxContactId);
            if(matchedObj){
              tynaxContactData.push({contactId: val.TynaxContactId, tynaxContactType: val.TynaxContactType, label: `${matchedObj.Firstname} ${matchedObj.Lastname}`})
            }})
          http.get(apiBaseUrl(`/api/getOrganizationsTypeList?org_id=${org_id}`))
          .then((res)=>{
            let initialStateNew = {organizationDetails: orgDetails};
            let orgObject = {}
            res.data.map(val=>{
              orgObject[val.TypeId] = val.Flag
              })
              initialStateNew.organizationDetails['organizationInfo'] = orgObject
              let selectedPatentSell =  patentSellingPolicyOptions.filter((data)=>(data.value === orgDetails.PatentSellingPolicy));
              setSelectedPatentSellingPolicy(selectedPatentSell);
              let selectedPatentTarget = patentSellingTargetOptions.filter((val)=>(val.value === orgDetails.PatentSellingTarget));
              setSelectedPatentSellingTarget(selectedPatentTarget);
              let selectedTechRTransfer = techTransferActivityOptions.filter((opt)=>(opt.value === orgDetails.TechTransferActivity));
              setSelectedTechTransferActivity(selectedTechRTransfer);
              setInitialState(initialStateNew)
          })
          if (response.data.organizationDetails[0].Country != "") {
            http
              .get(apiBaseUrl(`/api/getCountry`))
              .then((ress) => {
                ress.data.data.filter(function (count) {
                  if (response.data.organizationDetails[0].Country == count.value) {
                    setselectedCountry({
                      value: count.value,
                      label: count.label,
                    });
                  }
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    }
  }, [org_id]);
  const handleAddTynaxContact = (selectedContactValue,tynaxContactType) =>{
    var tynaxArray =[]
    tynaxContactData.map((ele)=>{
      tynaxArray.push(ele.contactId)
})
const even = (element) => element == selectedContactValue.value;
if(!tynaxArray.some(even)){
  setTynaxContactData(prevState=>[...prevState,{contactId: selectedContactValue.value, tynaxContactType: tynaxContactType, label: selectedContactValue.label}]);
  setShowAddTynaxContact(false);
}else{
  notify.info('Already exist')
}
  }
  const handleRemovingTynaxContact = () =>{
      const remainingData = tynaxContactData.filter((data)=> (data[data.contactId ? 'contactId' : 'TynaxContactId'] && data[data.contactId ? 'contactId' : 'TynaxContactId'] != removingId)    )
      setTynaxContactData(remainingData)
    setShowConfirmation(false);
  }
  return (
    <>
      <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={OrganizatioinSchema}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
        setSubmitting(true);
        setIsDisabled(true);
        let data = { ...values,tynaxContactData };
        data.organizationInfo = Object.entries(
          values.organizationDetails.organizationInfo
        ).map(([key, value]) => ({ TypeId: parseInt(key), value }));
        data.organizationDetails.CreateDate = formatDate(getCurrentDate());
        if (!org_id) {
          http
            .post(apiBaseUrl(`/api/createOrganization`), data)
            .then((res) => {
              setSubmitting(false);
              setIsDisabled(false);
              clearFormFields(resetForm, setFieldValue);
              const redirect = new URLSearchParams(location.search).get("redirect");
              if (redirect && redirect == "contact") {
                const values = new URLSearchParams(location.search).get("value");
                const orgUrl = JSON.parse(new URLSearchParams(location.search).get("organization"));
                orgUrl.push(res.data)
                notify.success("Successfully created an organization.");
                history.push(`/contacts/create_contact?redirect=contact&value=${values}&organization=${JSON.stringify(orgUrl)}`);
              } else {
                notify.success("Organization created successfully");
                history.push("/contacts/organizations");
              }
            })
            .catch((error) => {
              setSubmitting(false);
              setIsDisabled(false);
              notify.error(error);
            });
        } else {
        data.organizationDetails.UpdateDate = formatDate(getCurrentDate());
          http
            .post(apiBaseUrl(`/api/createOrganization?org_id=${org_id}`), data)
            .then(() => {
              setSubmitting(false);
              setIsDisabled(false);
              if(window.location.pathname === '/contacts/org_details/edit_organizations'){
                history.push(`/contacts/org_info?org_id=${org_id}`)
              }
              else if(window.location.pathname === '/contacts/edit_organizations'){
                history.push('/contacts/organizations')
              }
              else{
                history.push('/contacts/search_organizations');
              }
              notify.success("Organization updated successfully");
            })
            .catch((error) => {
              setSubmitting(false);
              setIsDisabled(false);
              notify.error(error);
            });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="OrganizationName"
            >
              Organizatioin name:
            </label>
            <Field
              type="text"
              name="organizationDetails.OrgName"
              className="form-control"
            />
            {errors.OrgName && touched.OrgName}
            <ErrorMessage
              name="organizationDetails.OrgName"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="ParentCompany"
            >
              Parent company:
            </label>
            <Field
              type="text"
              name="organizationDetails.ParentCompany"
              className="form-control"
            />
            {errors.ParentCompany && touched.ParentCompany}
            <ErrorMessage
              name="organizationDetails.ParentCompany"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="NickName">
              Nick name:
            </label>
            <Field
              type="text"
              name="organizationDetails.CompanyNickname"
              className="form-control"
            />
            {errors.CompanyNickname && touched.CompanyNickname}
            <ErrorMessage
              name="organizationDetails.CompanyNickname"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="Address">
              Address:
            </label>
            <Field
              type="text"
              name="organizationDetails.Address"
              className="form-control"
            />
            {errors.Address && touched.Address}
            <ErrorMessage
              name="organizationDetails.Address"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="City">
              City:
            </label>
            <Field
              type="text"
              name="organizationDetails.City"
              className="form-control"
            />
            {errors.City && touched.City}
            <ErrorMessage
              name="organizationDetails.City"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="State">
              State:
            </label>
            <Field
              type="text"
              name="organizationDetails.State"
              className="form-control"
            />
            {errors.State && touched.State}
            <ErrorMessage
              name="organizationDetails.State"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="Zipcode">
              Zip code:
            </label>
            <Field
              type="text"
              name="organizationDetails.ZipCode"
              className="form-control"
            />
            {errors.ZipCode && touched.ZipCode}
            <ErrorMessage
              name="organizationDetails.ZipCode"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Country:
            </label>
            <Select
              value={selectedCountry}
              name="organizationDetails.Country"
              options={countryOptions}
              onChange={(e) => handleCountryChange(e, setFieldValue)}
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Website:
            </label>
            <Field
              type="text"
              name="organizationDetails.Website"
              className="form-control"
            />
            {errors.Website && touched.Website}
            <ErrorMessage
              name="organizationDetails.Website"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              IP Catalog URL:
            </label>
            <Field
              type="text"
              name="organizationDetails.IPCatalogURL"
              className="form-control"
            />
            {errors.IPCatalogURL && touched.IPCatalogURL}
            <ErrorMessage
              name="organizationDetails.IPCatalogURL"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Email:
            </label>
            <Field
              type="text"
              name="organizationDetails.Email"
              className="form-control"
            />
            {errors.Email && touched.Email}
            <ErrorMessage
              name="organizationDetails.Email"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Phone:
            </label>
            <Field
              type="text"
              name="organizationDetails.Phone"
              className="form-control"
            />
            {errors.Phone && touched.Phone}
            <ErrorMessage
              name="organizationDetails.Phone"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Fax:
            </label>
            <Field
              type="text"
              name="organizationDetails.Fax"
              className="form-control"
            />
            {errors.Fax && touched.Fax}
            <ErrorMessage
              name="organizationDetails.Fax"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Referrer:
            </label>
            <Field
              type="text"
              name="organizationDetails.Referrer"
              className="form-control"
            />
            {errors.Referrer && touched.Referrer}
            <ErrorMessage
              name="organizationDetails.Referrer"
              component="div"
              className="text-danger"
            />
            </div>
            <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              {org_id ? `Created by: ${initialState.organizationDetails.CreatorName}` : ''}
            </label>
          </div>
          <div className="form-group row pl-3" hidden={!org_id}>
            <label className="font-weight-bold form-text pr-2" htmlFor="exampleInputEmail1">
            Preferred Tynax Contact:
            </label>
            <span className="btn btn-primary btn-sm" onClick={()=>{setShowAddTynaxContact(val=>!val)}} >Add Tynax Contact</span>
          </div>
          <div className="row pl-3">
          {tynaxContactData&&tynaxContactData.map(val =>(
          <div class="border-card-tynax-contact pl-3 mb-3"hidden={!org_id}>
								<div class="d-flex justify-content-between">
										<h6 class="text-dark my-2">{val.label} {val.tynaxContactType ? '(Preferred)' : '(Exclusive)'} </h6>
                    <img src={closeIcon}height="25px"width="25px"onClick={()=>{setShowConfirmation(true);setRemovingId(val.contactId ? val.contactId : val.TynaxContactId)}}/>
						</div>
            </div>
                  ))}
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="OrganizationName"
            >
              Status:
            </label>
            <Radio
              id="Status_yes"
              name="organizationDetails.Status"
              value={1}
              custom="false"
              label="Active"
              onClick={(e) => {
                changeType(e, setFieldValue, values);
              }}
            />
            <Radio
              id="Status_no"
              name="organizationDetails.Status"
              value={0}
              custom="false"
              label="Inactive"
              onClick={(e) => {
                changeType(e, setFieldValue, values);
              }}
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="OrganizationName"
            >
              Tynax RelationShip:
            </label>
            <Radio
              id="TynaxRelationship_yes"
              name="organizationDetails.TynaxRelationship"
              value={1}
              custom="false"
              label="Yes"
              onClick={(e) => {
                changeType(e, setFieldValue, values);
              }}
            />
            <Radio
              id="TynaxRelationship_no"
              name="organizationDetails.TynaxRelationship"
              value={0}
              custom="false"
              label="No"
              onClick={(e) => {
                changeType(e, setFieldValue, values);
              }}
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="OrganizationName"
            >
              Active Patent Buyer?
            </label>
            <Radio
              id="ActivePatentBuyer_yes"
              name="organizationDetails.ActivePatentBuyer"
              value={1}
              custom="false"
              label="Yes"
              onClick={(e) => {
                changeType(e, setFieldValue, values);
              }}
            />
            <Radio
              id="ActivePatentBuyer_no"
              name="organizationDetails.ActivePatentBuyer"
              value={0}
              custom="false"
              label="No"
              onClick={(e) => {
                changeType(e, setFieldValue, values);
              }}
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="OrganizationName"
            >
              Pays Tynax Buy-Side Commission?
            </label>
            <Radio
              id="TynaxBuySideComission_yes"
              name="organizationDetails.TynaxBuySideComission"
              value={"1"}
              custom="false"
              label="Yes"
              onClick={(e) => {
                changeType(e, setFieldValue, values);
              }}
            />
            <Radio
              id="TynaxBuySideComission_no"
              name="organizationDetails.TynaxBuySideComission"
              value={"0"}
              custom="false"
              label="No"
              onClick={(e) => {
                changeType(e, setFieldValue, values);
              }}
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="OrganizationName"
            >
              Under NDA:
            </label>
            <Radio
              id="Under_NDA_yes"
              name="organizationDetails.Under_NDA"
              value={1}
              custom="false"
              label="Yes"
              onClick={(e) => {
                changeType(e, setFieldValue, values);
              }}
            />
            <Radio
              id="Under_NDA_no"
              name="organizationDetails.Under_NDA"
              value={0}
              custom="false"
              label="No"
              onClick={(e) => {
                changeType(e, setFieldValue, values);
              }}
            />
            <Radio
              id="Under_NDA_unknown"
              name="organizationDetails.Under_NDA"
              value={2}
              custom="false"
              label="Unknown"
              onClick={(e) => {
                changeType(e, setFieldValue, values);
              }}
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Description:
            </label>
            <Field
              type="text"
              name="organizationDetails.Description"
              component="textarea"
              className="form-control"
            />
            {errors.Description && touched.Description}
            <ErrorMessage
              name="organizationDetails.Description"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Keywords:
            </label>
            <Field
              type="text"
              name="organizationDetails.Keyword"
              component="textarea"
              className="form-control"
            />
            {errors.Keyword && touched.Keyword}
            <ErrorMessage
              name="organizationDetails.Keyword"
              component="div"
              className="text-danger"
            />
          </div>
          {organzationType &&
            organzationType.map((val) => (
              <div className="form-group">
                <label
                  className="font-weight-bold form-text"
                  htmlFor="exampleInputEmail1"
                >
                  {val.TypeLabel}
                </label>
                <Radio
                  id={val.TypeId + "yes"}
                  value={1}
                  name={`organizationDetails.organizationInfo.${val.TypeId}`}
                  label="Yes"
                  onClick={(e) => {
                    setFieldValue(
                      `organizationDetails.organizationInfo.${val.TypeId}`,
                      1
                    );
                  }}
                />
                <Radio
                  id={val.TypeId + "no"}
                  value={0}
                  name={`organizationDetails.organizationInfo.${val.TypeId}`}
                  label="No"
                  onClick={(e) => {
                    setFieldValue(
                      `organizationDetails.organizationInfo.${val.TypeId}`,
                      0
                    );
                  }}
                />
              </div>
            ))}
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Patent Selling Policy:
            </label>
            <Select
              value={selectedPatentSellingPolicy}
              name="organizationDetails.PatentSellingPolicy"
              options={patentSellingPolicyOptions}
              onChange={(e) =>
                handlePatentSellingPolicyChange(e, setFieldValue)
              }
            />
            {errors.PatentSellingPolicy &&
              touched.PatentSellingPolicy}
            <ErrorMessage
              name="organizationDetails.PatentSellingPolicy"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Patent Selling to Who:
            </label>
            <Select
              value={selectedPatentSellingTarget}
              name="organizationDetails.PatentSellingTarget"
              options={patentSellingTargetOptions}
              onChange={(e) =>
                handlePatentSellingTargetChange(e, setFieldValue)
              }
            />
             {errors.PatentSellingTarget &&
              touched.PatentSellingTarget}
            <ErrorMessage
              name="organizationDetails.PatentSellingTarget"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Describe Patent Selling Policy:
            </label>
            <Field
              type="text"
              name="organizationDetails.PatentSellingPolicyDescription"
              component="textarea"
              className="form-control"
            />
            {errors.PatentSellingPolicyDescription &&
              touched.PatentSellingPolicyDescription}
            <ErrorMessage
              name="organizationDetails.PatentSellingPolicyDescription"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Tech Transfer Activity:
            </label>
            <Select
              value={selectedTechTransferActivity}
              name="organizationDetails.TechTransferActivity"
              options={techTransferActivityOptions}
              onChange={(e) =>
                handleTechTransferActivityChange(e, setFieldValue)
              }
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Industry:
            </label>
            <Field
              type="text"
              name="organizationDetails.Industry"
              className="form-control"
            />
            {errors.Industry && touched.Industry}
            <ErrorMessage
              name="organizationDetails.Industry"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Size:
            </label>
            <Field
              type="text"
              name="organizationDetails.Size"
              className="form-control"
            />
            {errors.Size && touched.Size}
            <ErrorMessage
              name="organizationDetails.Size"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Market Cap:
            </label>
            <Field
              type="text"
              name="organizationDetails.MarketCap"
              className="form-control"
            />
            {errors.MarketCap && touched.MarketCap}
            <ErrorMessage
              name="organizationDetails.MarketCap"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Ticker/ID:
            </label>
            <Field
              type="text"
              name="organizationDetails.Ticker"
              className="form-control"
            />
            {errors.Ticker && touched.Ticker}
            <ErrorMessage
              name="organizationDetails.Ticker"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Number of Patents:
            </label>
            <Field
              type="text"
              name="organizationDetails.NumberOfPatentsHeld"
              className="form-control"
            />
            {errors.NumberOfPatentsHeld && touched.NumberOfPatentsHeld}
            <ErrorMessage
              name="organizationDetails.NumberOfPatentsHeld"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              University Patent Ranking:
            </label>
            <Field
              type="text"
              name="organizationDetails.UniversityPatentRanking"
              className="form-control"
            />
            {errors.UniversityPatentRanking && touched.UniversityPatentRanking}
            <ErrorMessage
              name="organizationDetails.UniversityPatentRanking"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              OT Ranking:
            </label>
            <Field
              type="text"
              name="organizationDetails.OTRanking"
              className="form-control"
            />
            {errors.OTRanking && touched.OTRanking}
            <ErrorMessage
              name="organizationDetails.OTRanking"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="text-center py-2">
            <button
              disabled={isDisabled}
              type="submit"
              className={classnames({
                "buttonload btn btn-primary btn-sm": isDisabled === true,
                "btn btn-primary btn-sm": isDisabled === false,
              })}
            >
              Submit
            </button>
            <button
              type="button"
              onClick={() => {
                redirectToListingPage();
              }}
              className="ml-2 btn btn-secondary"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </Formik>
    {showAddTynaxContact && <AddTynaxContact show={showAddTynaxContact} handleClose={()=>setShowAddTynaxContact(false)} handleAddTynaxContact={handleAddTynaxContact} tynaxPeople={tynaxPeople} />}
    {showConfirmation && <ConfirmationDialogComponent show={showConfirmation} close={()=>{setShowConfirmation(false)}}confirm={handleRemovingTynaxContact}/>}
    </>
  );
};
export default CreateOrganization;
