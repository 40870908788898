import Cookies from "js-cookie";
import axios from "axios";
import { apiBaseUrl } from "./url";

const setCsrfToken = (token) => {
  document.head
    .querySelector('meta[name="csrf-token"]')
    .setAttribute("content", token);
  Cookies.set("x-token", token);
};

const getCsrfToken = () => {
  const token = Cookies.get("x-token");

  if (!token) {
    return document.head.querySelector('meta[name="csrf-token"]').content || "";
  }

  return token;
};

const setAccessToken = (token) => Cookies.set("x-access-token", token);
const getAccessToken = () => Cookies.get("x-access-token");

const refreshToken = () => axios.get(apiBaseUrl("token/refresh"));

export {
  setCsrfToken,
  getCsrfToken,
  setAccessToken,
  getAccessToken,
  refreshToken,
};
