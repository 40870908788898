import React, {useEffect} from "react";
import { Row, Col } from "reactstrap";
import Select from 'react-select'
import { useState } from "react";
import { filter , isEmpty} from "lodash";
import { http, apiBaseUrl, notify } from '../../utitility'

const ChangeFollowUp = ({ CurrentStatusDetail, reloadTableData, listing_id, closeAllModal }) => {

	useEffect(() => {
		let data = getFollowupStatus(CurrentStatusDetail.FollowUp);
    if(data && data[0]){
			setSelectedOption(data[0])
		}
	},[CurrentStatusDetail]);

	const [selectedOption,setSelectedOption] = useState({ value: 0, label: 'No Status' });
	const options = [
		{ value: 0, label: 'No Status' },
		{ value: 1, label: 'Evaluation' },
		{ value: 2, label: 'Passed' },
		{ value: 3, label: 'Under Offer' },
		{ value: 4, label: 'Pre-Evaluation' },
		{ value: 5, label: 'Closed' }
	]

	const handleChange=(value)=>{
		setSelectedOption(value);
	}
	
	const changeStatus=()=>{
		let data ={};
		data.listingid = listing_id;
		data.objID = CurrentStatusDetail.objID;
		data.FollowUp = selectedOption.value;

		http.post(apiBaseUrl(`/api/update_follow_up`),data)
		.then((res) => {
			reloadTableData();
			closeAllModal();
			notify.success("Status changed successfully");
		}).catch((error)=>{
		//	notify.error("Error while uploading");
		});
	}

  const getFollowupStatus=(status)=> {
		if (!isEmpty(options)) {
		return filter(options, function(o) {
				if (o.value === status) return o;
		});
		} else {
			return {};
		}
	}

  return (
    <div>
			<Row className="justify-content-center">
				<Col className="mb-3 col-4">
					<Select value={selectedOption} options={options} onChange={handleChange} />
				</Col>
			</Row>
			<div className="text-center">
				 <button onClick={()=>changeStatus()} className="btn btn-secondary">Save</button>
			</div>
		</div>
  );
};

export default ChangeFollowUp;
