import React,{useContext, useState, useEffect} from "react";
import {  CreateDetailedListingForm3 } from "../Components/Forms";
import { hasKeys, getStandardDateForm } from '../utitility'
import { UserContext } from "../Context/UserContext";
import { useLocation } from 'react-router-dom';

const CreateDetailedListingpage3 = ({history}) => {
	
	const {user} = useContext(UserContext);
	const [listingId,setListingId] = useState(0);

	const getAvailableStatus=()=>{
		if(hasKeys(user)){
			if(user.UserType==40){
				return 1;
		  }
		  else{
			return 0;
		  }
		}
	}

	const [listing] =useState({
		ListingType:1,
		ListingName:"",
		Brokercode:user.BrokerID,
		OwnerID:user.UserID,
		ListingStatus:"",
		AvailableStatus:getAvailableStatus(),
		PrivateFlag:"",
		AccessControl:"",
		DealStructure:2,
		ListingDescription:"",
		DealStructureNotes:"",
		Keywords:"",
		StartDate:"",
		EndDate:"",
		CreateDate:getStandardDateForm(),
	});
	const [listing_details] = useState({
		PatentBackLicense:0,
		PatentIPOwnership:0,
		PatentLicensingStatus:0,
		PatentLicensingArrangement:"",
		PatentPotentialInfringers:'',
		PatentNotes:'',
		PatentLitigationNotes:'',
		PatentLitigation:0,
		TechnologyPrimaryApplication:'',
		PatentInfringement:0,
		TechnologyOverview:"",
		CreateDate:getStandardDateForm()
	});
	const [salesInfo] =useState({
		Seller:"",
		TargetPrice:"",
		MinimumAcceptablePrice:"",
		PricingNotes:"",
		ReasonsForListing:"",
		DecisionMakingProcess:"",
		MarketingMaterials:"",
		PriorPromotionalEfforts:"",
		OtherSalesNotes:"",
		CreateDate:getStandardDateForm(),
		UpdateDate:getStandardDateForm()
	})
	const  location = useLocation();
  
	const [initialState,setInitialState] = useState({
		user_id:user.UserID,
		listing:listing,
		listing_details:listing_details,
		salesInfo:salesInfo
	});

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		let listingId = params.get('listing_id');
		setListingId(listingId);
	},[location.search]);

  return (
		<React.Fragment>
			{hasKeys(user) &&
					<div className="container-fluid bg-white">
						<div className="border-bg">
							<section className="py-3 mb-3 ml-2 mr-2 row">
								<div className="col-12">
									<h4 className="mb-3">
										<b className="text-primary">CREATE TECHNOLOGY AVAILABLE LISTING</b>
									</h4>
									<h4>
										<b className="text-primary">Listing {listingId} ListingName</b>
									</h4>
								</div>
							</section>
							<section className=" mb-5 ml-5 row">
								<div className="col-9">
									<CreateDetailedListingForm3 user={user} history={history} listingId={listingId} listing={listing} initialState={initialState} setInitialState={setInitialState}/>
								</div>
							</section>
						</div>
					</div>
				}
		</React.Fragment>
  );
}
export default CreateDetailedListingpage3;
