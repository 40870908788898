import React, { useEffect, useState,useContext,useRef } from "react";
import { ContactDetails } from "../Components";
import { http, apiBaseUrl } from "../utitility";
import { useLocation } from 'react-router-dom';
import addIcon from '../assets/images/addMailingList.png';
import AddMailingList from '../Components/Contacts/AddMailingList';
import CreateNoteForm from "../Components/Forms/CreateNoteForm";
import { UserContext } from "../Context/UserContext";
import ListingContactLink from "../Components/Contacts/ListingContactLink";
const ContactDetailsPage = () => {
	const  location = useLocation();
  const [contactDetails,setContactDetails] = useState({});
	const [tynaxPeople,setTynaxPeople] = useState({});
  const [contact_link,setContactLink] = useState([]);
	const [userSummary,setUserSummary] = useState({});
	const [organization_link,setOrganizationLink] = useState([]);
	const [responsible,setResponsible] = useState();
	const [showAddMailingList,setShowAddMailingList] = useState(false);
	const [contact_id,setContact_id] = useState(null);
	const [showNotes,setShowNotes] = useState(false);
	const [showListing,setShowListing] = useState(false);
	const [editValue,setEditValue] = useState({});
	const {user} = useContext(UserContext);
	const childRef = useRef();
	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}
	const EditNotes = (value) => {
		setEditValue(value);
   }
  useEffect(() => {
		const params = new URLSearchParams(location.search);
		let contactID = params.get('contact_id');
		setContact_id(contactID);
		http.get(apiBaseUrl(`/api/getContactInfo?contact_id=${contactID}`))
		.then((res) => {	
			setContactDetails(res.data.contact_details[0]);
			setTynaxPeople(res.data.tynax_people);
			setContactLink(res.data.contact_link);
			setUserSummary(res.data.user_summary);
			setOrganizationLink(res.data.organization_link);
			setResponsible(JSON.parse(res.data.contact_details[0].Responsible));
		}).catch((error=>{
			console.log(error,'error')
		}));
  },[]);
  return (
		<div className="container-fluid bg-white">
			<div className="justify-content-center py-1">
				<ContactDetails contactDetails={contactDetails} tynaxPeople={tynaxPeople} organization_link={organization_link} contact_link={contact_link} userSummary={userSummary} responsible={responsible?responsible:[]}/>
			</div>
			<div className="container-fluid bg-white row">
				<img src={addIcon} height="20px"width="35px" onClick={()=>{showAddMailingList?setShowAddMailingList(false):setShowAddMailingList(true)}} className="mt-1"/>
				<label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
				Mailing lists
            </label>
			<div hidden={!showAddMailingList}>
				<AddMailingList contactId={contact_id} />
			</div>
			</div>
			<div className="container-fluid bg-white row">
				<img src={addIcon} height="20px"width="35px" onClick={()=>{showNotes?setShowNotes(false):setShowNotes(true)}} className="mt-1"/>
				<label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
				Notes
            </label>
			<div hidden={!showNotes} className="mt-5">
				<CreateNoteForm contactId={contact_id} reloadTableData={()=>reloadTableData()} editValue={editValue} />
			</div>
			</div>
			<div className="container-fluid bg-white row">
				<img src={addIcon} height="20px"width="35px" onClick={()=>{showListing?setShowListing(false):setShowListing(true)}} className="mt-1"/>
				<label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
				Listings
            </label>
			<div hidden={!showListing}>
				<ListingContactLink />
			</div>
			</div>
		</div>
  );
}

export default ContactDetailsPage;
