import React, { useState,useEffect,useRef,useMemo } from "react";
import { useLocation,Link } from 'react-router-dom';
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { http, apiBaseUrl, notify, mergeString} from '../../utitility';
import { confirmAlert } from 'react-confirm-alert'; 
const ContactListByOrgId = () =>{
    const [orgId, setOrgId] = useState(null);
	const  location = useLocation();
    useEffect(() => {
		const params = new URLSearchParams(location.search);
        setOrgId(params.get('org_id'));
  },[]);
  const childRef = useRef();
	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}
    const getActionField = (contact_id) => {
		return( 
			<div>
				<Link to={`/contacts/contact_edit?contact_id=${contact_id}`} className="mr-2 table_font_size btn-link">Edit</Link>
				<span onClick={()=>ConfirmAlert(contact_id)} className="mr-2 table_font_size btn-link link-pointer">Delete</span>
			</div>
		)
	}

	const ConfirmAlert = (value) => {
		confirmAlert({
			message: 'Do you really want to delete this contact?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteContact(value)
				},
				{
					label: 'No',
					onClick: () => {}
				}
			]
		});
	}

	const deleteContact = (contact_id) => {
		http.delete(apiBaseUrl(`/api/deleteContact?contact_id=${contact_id}`))
		.then(() => {
			reloadTableData();
			notify.success("Deleted successfully");
		}).catch((error)=>{
			let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
											error.response.data.message :
											'whoops...something went wrong';
			notify.error(errorMsg);
		});
	}
    const columns = useMemo(
		() => [
			{
				Header: "Name",
				accessor: "Firstname",
				width: 130,
				sort:true,
				Cell: data => {return(<Link to={`/contacts/contact_info?contact_id=${data.row.original.ContactId}`}>{mergeString(data.row.original.Firstname,data.row.original.Lastname)}</Link>)},
			},
			{
				Header: "Organization",
				accessor: "OrgList",
				width: 130,
				sort:true,
				Cell: data => {return (<div dangerouslySetInnerHTML={{ __html: data.row.original.OrgList}}></div>)}
			},
			{
				Header: "Job Title",
				accessor: "Title",
				width: 130,
				sort:true
			},
			{
				Header: "Work Phone",
				accessor: "WorkPhone",
				width: 130,
				sort:true
			},
			{
				Header: "Mobile Phone",
				accessor: "MobilePhone",
				width: 130,
				sort:true
			},
			{
				Header: "Email",
				accessor: "PrimaryEmail",
				width: 300,
				sort:true,
				Cell: data => {return (<a href={`mailto:${data.row.original.PrimaryEmail}`}>{data.row.original.PrimaryEmail}</a>)}
			},
			{
				Header: "Action",
				accessor: "act",
				Cell: data => (getActionField(data.row.original.ContactId)),
				width: 130
			}
		],
		[]
	)
    return (
        <React.Fragment>
            <div className="container-fluid bg-white">
                <div className="justify-content-center py-1">
				<div className="px-2">
						<u><Link  className="px-2 text-primary" to={'/contacts/mylisting'}>My Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_contact'}>Create Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_contacts'}>Search Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_organizations'}>Create Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/organizations'}>Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_organizations'}>Search Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/mailing_lists'}>Mailing lists</Link></u>
					</div>
                    <ReactGenericTable  pagination={true} url={`getContactListByOrg?org_id=${orgId}&`} ref={childRef} defaultSortIndex="contactid" defaultSortOrder="DESC" columns={columns} />
                </div>
            </div>
        </React.Fragment>
);
}
export default ContactListByOrgId;