import React,{ useContext, useState, useEffect } from "react";
import {  PublishListingForm } from "../Components";
import { hasKeys, http, apiBaseUrl } from '../utitility'
import { UserContext } from "../Context/UserContext";
import { useLocation } from 'react-router-dom';

const PublishListingPage = ({history}) => {

	const { user } = useContext(UserContext);
	const [listingId,setListingId] = useState(0);
  const [listing,setListing] = useState({});

	const  location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		let listingId = params.get('listing_id');
		setListingId(listingId);
		if(listingId){
      http.get(apiBaseUrl(`/api/getListingById?listing_id=${listingId}`))
      .then((res) => {
         setListing(res.data.data);
      }).catch((err)=>{
        console.log(err);
      })
    }

	},[location.search]);

  return (
		<React.Fragment>
			{hasKeys(listing) &&
						<div className="container-fluid bg-white">
							<div className="border-bg">
								<section className="py-3 mb-3 ml-2 mr-2 row">
									<div className="col-12">
										<h4 className="mb-3">
											<b className="text-primary">Submit Listing</b>
										</h4>
										<ul className="list-unstyled">
											<li>Listing ID:<span className="ml-2">{listing.ListingID}</span></li>
											<li>Listing Name:<span className="ml-2">{listing.ListingName}</span></li>
										</ul>
										{listing.AvailableStatus==0 &&<p className="mb-3">
										</p>}
									</div>
								</section>
								<section className="mb-5 ml-2 mr-2 row">
									<div className="col-6">
									{listing.AvailableStatus==0 ?
										<PublishListingForm history={history} user={user} listingId={listingId} listing={listing}/>:
										<b className="text-primary">Listing has been send for review</b>
									}
									</div>
								</section>
							</div>
						</div>
				}
		</React.Fragment>
  );
}
export default PublishListingPage;
