import React, { useMemo, useEffect, useRef }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { formatDate, hasKeys } from '../../utitility';
import { useState } from "react";
import ActionField from './ActionField';
import ChangeListingStatus from "../ChangeListingStatus";
import { Link } from 'react-router-dom';

const MyListing = ({user}) => {

  const [url,setUrl] = useState("");
  const [hiddenColumns,setHiddenColumns] = useState([]);

	const childRef = useRef();

	useEffect(() => {
		if(user.UserType==15){
			setHiddenColumns('Tag');
		}
		initialLoad();
  },[user]);

	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

  const initialLoad = () =>{
		if(hasKeys(user)){
			if(user.UserType == 1){
			 setUrl(`listing_ta?ownerID=${user ? user.UserID: null}&`);
			}else if(parseInt(user.UserType) == 15){
			 setUrl(`listing_ta?ownerID=${user ? user.UserID: null}&AvailableStatus=1,-1&`);
			}else{
			 setUrl(`listing_ta?ownerID=${user ? user.UserID: null}&`);
			}
		}
	}
  
	const getStatus = (status,listing) => {
			return (<ChangeListingStatus status={status}  user={user} reloadTableData={() => reloadTableData()} listing={listing}/>);
	}

	const getActionField = (value) =>{
		return <ActionField user={user} listing={value} reloadTableData={()=>reloadTableData()}/>
	}

	const getViewLink = (value) => {
		if(user.UserType == 1){
     return <span>{value}</span>
		}
		return <Link className="nav-link" to={`/listing_full_details?listing_id=${value}`}>{value}</Link>
	}

  const column = [
		{
			Header: "ID",
			accessor: "ListingID",
			width: 70,
			sort:true,
			Cell: data => {return getViewLink(data.cell.value)},
		},{
			Header: "Source",
			accessor: "Brokercode",
			width: 70
		},{
			Header: "Listing Name",
			accessor: "ListingName",
			width: 300,
			sort:true
		},{
			Header: "Status",
			accessor: "AvailableStatus",
			Cell: row => (getStatus(row.cell.value,row.row.original)),
			width: 100,
			sort:true
		},{
			Header: "Tag",
			accessor: "Tag",
			width: 100
		},{
			Header: "Date",
			accessor: "CreateDate",
			Cell: row => (formatDate(row.cell.value)),
			width: 100,
			sort:true
		},{
			Header: "Action",
			accessor: "Action",
			width: 400,
			Cell: data => (getActionField(data.row.original))
		}
	]
	const columns = useMemo(
		() => column,
		[]
	)

  return (
		<ReactGenericTable ref={childRef} hiddenColumns={hiddenColumns} pagination={true} url={url} defaultSortIndex="ListingID" defaultSortOrder="DESC" columns={columns} />
	);
	
};

export default MyListing;
