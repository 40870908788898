import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  LoginPage,
  RegisterPage,
  RegistrationVerificationPage,
  ForgotPasswordPage,
  ListingDetailsPage,
  MainPage,
  PatentDetailsPage,
  CreateListingpage,
  PublishListingPage,
  ContactDetailsPage,
  CreateUserPage,
  CreateBrokerPage,
  BrokerListingPage,
  SearchUser,
  CreateDetailedListingpage,
  CreateDetailedListingpage1,
  CreateDetailedListingpage2,
  CreateDetailedListingpage3,
  CreateDetailedListingpage4,
  CreateDetailedListingpage5,
  UploadAttachmentPage,
  CreateContactpage,
  OrganizationDetailsPage,
  ContactEditPage,
  CreateOrganizationPage,
  CreateMailingListPage,
  MailingListDetailsPage,
} from "./Pages";
import {UserContext} from './Context/UserContext'
import ContactListByOrgId from "./Components/Contacts/ContactListByOrgId";
import { Footer, Header } from "./Components";
import { getUser } from "./utitility";
import { CreateUserForm, ShowListingForm } from "./Components/Forms";
const isAuthenticated = () => {
  let user = JSON.parse(window.localStorage.getItem('user'));
  if(user){
    if(user.UserID){
      return true;
    }else{
      return false;
    }
  }else{
    return false;
  }
};

function App() {

  const [user, setUser] = useState(getUser());

  useEffect(() => {
    window.localStorage.setItem('user', JSON.stringify(user));
  },[user]);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        isAuthenticated()
        ? <Component {...props} history={props.history}/>
        : <login/>
    )} />
  )

  return (
    <BrowserRouter basename="/">
      <div className="base-container">
        <UserContext.Provider value={{user,setUser}}>
          <Route  path="/" render={(props) => <Header history={props.history} />} />
          <Route exact path="/" render={(props) => <LoginPage history={props.history} />} />
          <Route exact path="/login" render={(props) => <LoginPage  history={props.history}/>}  />
          <Route exact path="/register" render={(props) => <RegisterPage history={props.history} />} />
          <Route
            exact
            path="/email_verification"
            render={(props) => <RegistrationVerificationPage history={props.history} />}
          />

          <PrivateRoute exact path="/myaccount" component={MainPage} />
          <PrivateRoute exact path="/myaccount/my_listing" component={MainPage} />
          <PrivateRoute exact path="/myaccount/myaccount_ta_all" component={MainPage} />
          <PrivateRoute exact path="/myaccount/browse_listing" component={MainPage} />
          <PrivateRoute exact path="/myaccount/bookmarked_listing" component={MainPage} />

          <PrivateRoute exact path="/listing_full_details" component={ListingDetailsPage} />
          <PrivateRoute exact path="/profile" component={MainPage} />
          <PrivateRoute exact path="/myaccount/search" component={MainPage} />
          <PrivateRoute exact path="/admin/users" component={MainPage} />
          <PrivateRoute exact path="/admin/profile" component={MainPage} />
          <PrivateRoute exact path="/admin/newuser" component={CreateUserPage} />
          <PrivateRoute exact path="/admin/newbroker" component={CreateBrokerPage} />
          <PrivateRoute exact path="/admin/brokerlist" component={MainPage} />
          <PrivateRoute exact path="/admin/searchuser" component={SearchUser} />
          
          <PrivateRoute exact path="/myaccount/patent" component={MainPage} />
          <PrivateRoute exact path="/patent/browse" component={MainPage} />
          <PrivateRoute exact path="/pursue/listings" component={MainPage} />
          <PrivateRoute exact path="/eou/listings" component={MainPage} />

          <PrivateRoute exact path="/pursue/patents" component={MainPage} />

          <PrivateRoute exact path="/patent/search" component={MainPage} />
          <PrivateRoute exact path="/patent/create" component={MainPage} />
          <PrivateRoute exact path="/wanted/listing" component={MainPage} />
          <PrivateRoute exact path="/wanted/team_listing" component={MainPage} />
          <PrivateRoute exact path="/wanted/all_listing" component={MainPage} />
          <PrivateRoute exact path="/analysis/listings" component={MainPage} />
          <PrivateRoute exact path="/contacts/mylisting" component={MainPage} />
          <PrivateRoute exact path="/contacts/all_contacts" component={MainPage} />
          <PrivateRoute exact path="/contacts/organizations" component={MainPage} />
          <PrivateRoute exact path="/contacts/create_mail_listing" component={CreateMailingListPage} />
          <PrivateRoute exact path="/contacts/edit_mail_listing" component={CreateMailingListPage} />
          <PrivateRoute exact path="/contacts/mail_listing_info" component={MailingListDetailsPage} />
          <PrivateRoute exact path="/contacts/search_contacts" component={MainPage} />
          <PrivateRoute exact path="/contacts/search_organizations" component={MainPage} />
          <PrivateRoute exact path="/contacts/mailing_lists" component={MainPage} />
          <PrivateRoute exact path="/myaccount/team_listing" component={MainPage} />
          <PrivateRoute exact path="/patent_details" component={PatentDetailsPage} />
          <PrivateRoute exact path="/listings" component={CreateListingpage} />
          <PrivateRoute exact path="/publish_listing" component={PublishListingPage} />
          <PrivateRoute exact path="/contacts/contact_info" component={ContactDetailsPage} />
          <PrivateRoute exact path="/contacts/org_info" component={OrganizationDetailsPage} />
          <PrivateRoute exact path="/contacts/org_details/org_info" component={OrganizationDetailsPage} />
          <PrivateRoute exact path="/contacts/create_contact" component={ContactEditPage} />
          <PrivateRoute exact path="/contacts/contact_edit" component={ContactEditPage} />
          <PrivateRoute exact path="/mycontacts/contact_edit" component={ContactEditPage} />
          <PrivateRoute exact path="/serachcontacts/contact_edit" component={ContactEditPage} />
          <PrivateRoute exact path="/contacts/create_organizations" component={CreateOrganizationPage} />
          <PrivateRoute exact path="/contacts/edit_organizations" component={CreateOrganizationPage} />
          <PrivateRoute exact path="/contacts/org_details/edit_organizations" component={CreateOrganizationPage} />
          <PrivateRoute exact path="/contacts/org_details/contact_listing" component={ContactListByOrgId} />
          <PrivateRoute exact path="/contacts/search_org/edit_organizations" component={CreateOrganizationPage} />
          <PrivateRoute exact path="/contact_edit" component={ContactEditPage} />
          <PrivateRoute exact path="/detailed_listing" component={CreateDetailedListingpage} />
          <PrivateRoute exact path="/detailed_listing1" component={CreateDetailedListingpage1} />
          <PrivateRoute exact path="/detailed_listing2" component={CreateDetailedListingpage2} />
          <PrivateRoute exact path="/detailed_listing3" component={CreateDetailedListingpage3} />
          <PrivateRoute exact path="/detailed_listing4" component={CreateDetailedListingpage4} />
          <PrivateRoute exact path="/detailed_listing5" component={CreateDetailedListingpage5} />
          <PrivateRoute exact path="/listings/UploadAttachment" component={UploadAttachmentPage} />
          <PrivateRoute exact path="/contacts/contact_details" component={CreateContactpage} />
          <PrivateRoute exact path="/myaccount/unreadEmail" component={MainPage} />
          <Route 
          exact 
          path="/forgot_password" 
          render={(props) => <ForgotPasswordPage history={props.history} />}
          />
        </UserContext.Provider>
        <div
          style={{ clear: "both", textAlign: "center"}}
          >
          <Footer />
         </div>
      </div>
      
      <div style={{ textAlign: "center"}}>
        <ToastContainer style={{width:'450px'}} transition={Slide} />
      </div> 
    </BrowserRouter>
  );
}

export default App;
