import React, { useState, useContext } from "react";

import { Formik } from "formik";
import classnames from "classnames";
import { notify, http, apiBaseUrl,hasKeys } from "../../utitility";
import { UserContext } from "../../Context/UserContext";
import CustomButton from './CustomButton';

const Login = (props) => {
  const [userNameAvailable] = useState(false);

  const {user,setUser} = useContext(UserContext);


  if(user !== null) {
    http.get(apiBaseUrl(`/api/authToken`))
    .then((res) => {
      if(res.data.errors == null)
      {
        if(hasKeys(user) && (user.UserType == 15 || user.UserType == 30 || user.UserType == 40)){
          props.history.push('/myaccount/myaccount_ta_all');
        }else{
          props.history.push('/myaccount/my_listing');
        }
      } else {
        setUser(null);
        localStorage.clear();
        props.history.push('/login');
      }
    }).catch((error=>{
      setUser(null);
      localStorage.clear();
      props.history.push('/login');
		}));
  }
  
  return (
    <Formik
      initialValues={{
        Username: "",
        Password: "",
      }}
      validate={(values) => {
        const errors = {};
        if (!values.Username) {
          errors.Username = "Required";
        }
        if (!values.Password) {
          errors.Password = "Required";
        }
        return errors;
      }}
      onSubmit={(values,{ setSubmitting,resetForm }) => {
        setSubmitting(true);
        http.post(apiBaseUrl(`/api/login`),values)
        .then((res) => {
          resetForm({})
          setUser(res.data.data);
          window.localStorage.setItem('user', JSON.stringify(res.data.data));
          setSubmitting(false);
          let userData = res.data.data;
          if(hasKeys(userData) && (userData.UserType == 15 || userData.UserType == 30 || userData.UserType == 40)){
            props.history.push('/myaccount/myaccount_ta_all');
          }else{
            props.history.push('/myaccount/my_listing');
          }
          notify.success("success");
        }).catch((error)=>{
          let errorMessage = (error.response && error.response.data && error.response.data.message) ?
                          error.response.data.message:error.message;
          notify.info(errorMessage);
          setSubmitting(false);
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              User Name
            </label>
            <input
              type="text"
              name="Username"
              className="form-control"
              onChange={handleChange}
              value={values.Username}
            />
            <span
              className={classnames({
                "text-success": userNameAvailable === "available",
                "text-danger": userNameAvailable === "not_available",
              })}
            >
              {userNameAvailable === "available" && "available"}
              {userNameAvailable === "not_available" && "not available"}
            </span>
            <span className="text-danger">
              {errors.Username && touched.Username && errors.Username}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputPassword1"
            >
              Password
            </label>
            <input
              type="password"
              name="Password"
              className="form-control"
              onChange={handleChange}
              value={values.Password}
            />
            <span className="text-danger">
              {errors.Password && touched.Password && errors.Password}
            </span>
          </div>
          <div className="text-center py-2">
            <CustomButton isSubmitting={isSubmitting} btnText='Sign In' />
          </div>
        </form>
      )}
    </Formik>
  );
};
export default Login;
