import React, { useEffect, useState, useRef } from "react";
import { notify, http, apiBaseUrl } from "../../utitility";
import { Formik, Field, ErrorMessage } from "formik";
import CustomButton from './CustomButton';
import Radio from "../Fields/Radio";
import CheckBox from "../Fields/CheckBox";
import ListingSchema from "../validation/ListingSchema";
import classnames from "classnames";
import Select from 'react-select'
import Button from 'react-bootstrap/Button';

const CreateDetailedListingForm = ({ user, listingId,initialState, setInitialState, history }) => {
	const [isDisabled,setIsDisabled] = useState(false);
  const [selectedShare_type,setselectedShare_type] = useState({ value: 'default', label: 'Default' });

  const refDetails = useRef();
  const refPatents = useRef();
  const refTechnology = useRef();

  const options = [
		{ value: 'default', label: 'Default' },
		{ value: 'custom', label: 'Custom' },
		{ value: 'none', label: 'None' }
  ]

  const handleSelectChange=(option, setFieldValue)=>{
    setselectedShare_type(option);
    setFieldValue('listing.Share_type',option.value)
  }
  
  const clearFormFields=(resetForm)=>{
    resetForm({});
  }

  const redirectToListingPage=()=>{
    history.goBack();
  }

  const redirectToNextPage=()=>{
    history.push('detailed_listing1');
  }
  
  const changeType = (e, setFieldValue, field) => {
    setFieldValue(field, e.target.value);
  };

  const changeListingType = (e, setFieldValue, field) => {
    setFieldValue(field, e.target.value);
    if (e.target.name==="listing.ListingType" && e.target.value==1)
    {
      refDetails.current.hidden=false;
      refPatents.current.hidden=false;
      refTechnology.current.hidden=true;
    }
    else if (e.target.name==="listing.ListingType" && e.target.value==0)
    {
      refDetails.current.hidden=false;
      refTechnology.current.hidden=false;
      refPatents.current.hidden=true;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      // validationSchema={ListingSchema}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          // setSubmitting(true);
          setIsDisabled(true);
          let data = {...values};
          // http.post(apiBaseUrl(`/api/createListing`),data)
          // .then(() => {
          //   // setSubmitting(false);
          //   setIsDisabled(false);
          //   notify.success("Listing created successfully");
          //   clearFormFields(resetForm,setFieldValue);
          //   if(user.UserType==40){
          //     history.push('/myaccount/myaccount_ta_all');
          //   }
          //   else{
          //     history.push('/myaccount/my_listing');
          //   }
          // }).catch((error)=>{
          //   // setSubmitting(false);
          //   setIsDisabled(false);
          //   notify.error(error);
          //   });
          // history.push('detailed_listing1');
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Is this technology available listing covered by a patent(s)?
            </label>
            <Radio
                id="ListingType_yes"
                name="listing.ListingType"
                value={1}
                custom="false"
                label="Yes"
                onClick = { e => {changeListingType(e,setFieldValue,'ListingType')}}
            />
            <Radio
              id="ListingType_no"
              name="listing.ListingType"
              value={0}
              custom="false"
              label="No"
              onClick = { e => {changeListingType(e,setFieldValue,'ListingType')}}
            />
            <ErrorMessage name="listing.ListingType" component="div" className="text-danger" />
          </div>
          <div className="form-group" ref={refDetails} hidden="true">
            <div className="form-group">
              <label className="font-weight-bold form-text">
              What are you looking to sell with this	listing?
              </label>
            <div ref={refPatents} hidden="true">
                <input className="form-check-input" id="dealstructure" value={2} 
                        type="checkbox" onChange={handleChange} checked={values.dealstructure} />
                <label>Patent(s) - sell the patent(s) outright.</label>
            </div>
            <div ref={refTechnology} hidden="true">
                <input className="form-check-input ml-8" id="dealstructure" value={2} 
                        type="checkbox" onChange={handleChange} checked={values.dealstructure} />
                <label>Technology - sell the technology outright.</label>
            </div>
          <label>License(s) - Choose the type of license(s) you would like to sell.</label>
            <div>
            <input className="form-check-input" id="dealstructure" value={3} 
                          type="checkbox" onChange={handleChange}  checked={values.dealstructure}  />
                    <label>Exclusive License with Right to Enforce Patents</label>
            </div>
            <div>
            <input className="form-check-input" id="dealstructure" value={4} 
                          type="checkbox" onChange={handleChange}  checked={values.dealstructure}  />
                    <label>Exclusive License</label>
            </div>
            <div>
            <input className="form-check-input" id="dealstructure" value={5} 
                          type="checkbox" onChange={handleChange}  checked={values.dealstructure}  />
                    <label>Non-exclusive license</label>
            </div>
            <div>
            <input className="form-check-input" id="dealstructure" value={6} 
                          type="checkbox" onChange={handleChange}  checked={values.dealstructure}  />
                    <label>Region/country license</label>
            </div>
            <div>
            <input className="form-check-input" id="dealstructure" value={7} 
                          type="checkbox" onChange={handleChange}  checked={values.dealstructure}  />
                    <label>Field-of-use license</label>
            </div>
            <div>
            <input className="form-check-input" id="dealstructure" value={1} 
                          type="checkbox" onChange={handleChange}  checked={values.dealstructure}  />
                    <label>Business Unit/Product Line - sell product lines, business units or other technology assets.</label>
            </div>
            <label><b className="text-primary mr-2">Do you want to restrict the listing information published 
                on the Tynax web site?</b></label>
            <Radio
                id="PrivateFlag_yes"
                name="listing.PrivateFlag"
                value={1}
                custom="false"
                label="No - publish the listing descriptions on the Tynax web site and circulate to corporate buyers & the Tynax broker network."
                onClick = { e => {changeType(e,setFieldValue,'PrivateFlag')}}
              />
            <Radio
              id="PrivateFlag_no"
              name="listing.PrivateFlag"
              value={0}
              custom="false"
              label="Yes - restrict the information published in the listing to show only the topic and class categories. Only select this option when you are extremely concerned about retaining anonymity."
              onClick = { e => {changeType(e,setFieldValue,'PrivateFlag')}}
            />
            <div className="form-group">
              <label className="font-weight-bold form-text">
                Share with:
              </label>
              <Select value={selectedShare_type} name="listing.Share_type" options={options}  onChange={(e)=>handleSelectChange(e,setFieldValue)} />
            </div>
            <div className="text-center py-2">
              <button type="button" onClick={(e) => redirectToNextPage()} className="ml-2 btn btn-primary">Next</button>
              <button type="button" onClick={(e) => redirectToListingPage()} className="ml-2 btn btn-primary">Cancel</button>
            </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default CreateDetailedListingForm;
