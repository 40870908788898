import React, { useState } from "react";
import { Formik } from "formik";
import classnames from "classnames";
import CustomButton from './CustomButton';

import { notify, http, apiBaseUrl } from "../../utitility";

const ForgotPassword = () => {
  const [userNameAvailable] = useState(false);
  return (
    <Formik
      initialValues={{
        Username: "",
        Email: "",
      }}
      validate={(values) => {
        const errors = {};
        if (!values.Username) {
          errors.Username = "Required";
        }
        if (!values.Email) {
          errors.Email = "Required";
        }else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
        ) {
          errors.Email = "Invalid email address";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting,resetForm }) => {
        setSubmitting(true);
        http.post(apiBaseUrl(`/api/send/password`),values)
        .then((res) => {
          resetForm({})
          setSubmitting(false);
          notify.success(res.data.message);
        }).catch((error)=>{
          let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
                          error.response.data.message :error.message;
          notify.error(errorMsg);
          setSubmitting(false);
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              User Name
            </label>
            <input
              type="text"
              name="Username"
              className="form-control"
              onChange={handleChange}
              value={values.Username}
            />
            <span
              className={classnames({
                "text-success": userNameAvailable === "available",
                "text-danger": userNameAvailable === "not_available",
              })}
            >
              {userNameAvailable === "available" && "available"}
              {userNameAvailable === "not_available" && "not available"}
            </span>
            <span className="text-danger">
              {errors.Username && touched.Username && errors.Username}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputPassword1"
            >
              Email
            </label>
            <input
              type="text"
              name="Email"
              className="form-control"
              onChange={handleChange}
              value={values.Email}
            />
            <span className="text-danger">
              {errors.Email && touched.Email && errors.Email}
            </span>
          </div>
          <div className="text-center py-2">
            <CustomButton isSubmitting={isSubmitting} btnText='Email Password' />
          </div>
        </form>
      )}
    </Formik>
  );
};
export default ForgotPassword;
