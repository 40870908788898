import React, { useMemo, useState, useRef }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { formatDate, getStar } from '../../utitility';
import { Link } from 'react-router-dom';
import CustomModal from "../Modal/CustomModal";
import ChangeDecision from '../ChangeDecision'

const BrowsePatent = ({user}) => {

	const [IsOpenDecisionModal,openDecisionModal] = useState(false);
	const [selectedOption,setselectedOption] = useState({});

	const toggle = () => {
		openDecisionModal(!IsOpenDecisionModal);
	}

	const childRef = useRef();

	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

	const getStarRating = (rating)=>{
		let star = 0;
		if(rating<=30){
		star = 1;
		}else if(rating>30 && rating<50){
		star = 2;
		}else if(rating>=50 && rating<70){
		star = 3;
		}else if(rating>=70 && rating<80){
		star = 4;
		}else if(rating>=80){
		star = 5;
		}
		let result = [];
		for(let i=0;i<star;i++){
			result.push(<i className="fa fa-star" aria-hidden="true"></i>);
		}
		return result;
	}

	const getViewLink = (patentId,patentNumber,country) => {
     return <Link className="nav-link" to={`/patent_details?patentid=${patentId}&plist=pid&pat_num=${patentNumber}&pcont=${country}`}>{patentNumber}</Link>
	 }
	
	const columns = useMemo(
		() => [
			{
				Header: "Patent Number",
				accessor: "PatentNumber",
				width: 90,
				sort:true,
				Cell: data => {return getViewLink(data.row.original.PatentID,data.row.original.PatentNumber,data.row.original.Country)},
			},{
				Header: "Country",
				accessor: "Country",
				width: 50
			},{
				Header: "Listing",
				accessor: "ListingId",
				width: 188,
				sort:true
			},{
				Header: "Rating",
				accessor: "rating",
				width: 100,
				Cell: data => {return getStar(data.row.values.rating)},
				sort:true
			},{
				Header: "Status",
				accessor: "PatentStatus",
				width: 70,
				sort:true
			},{
				Header: "Title",
				accessor: "Title",
				width: 350,
				sort:true
			},{
				Header: "Company",
				accessor: "CompanyName",
				width: 150,
			},{
				Header: "Assignee",
				accessor: "Assignee",
				width: 203,
			},{
				Header: "Inventor",
				accessor: "Inventor",
				width: 203,
			},{
				Header: "Created",
				accessor: "CreateDate",
				width: 110,
				Cell: row => (formatDate(row.cell.value)),
			}
		],
		[]
	)


  return (
				<React.Fragment>
					<div className="px-2">
						{/* <u><Link className="px-2 text-primary" to={`/patent/create`}>Create Patent</Link></u> */}
						<u><Link className="px-2 text-primary" to={'/patent/search'}>Search Patent</Link></u>
					</div>
					<div>
						<ReactGenericTable wordWrap={true} ref={childRef} pagination={true} url={`patents?BrokerCode=${user.BrokerID}&`} defaultSortIndex="ListingID" defaultSortOrder="DESC" columns={columns} />
					</div>
				</React.Fragment>
  );
};
export default BrowsePatent;