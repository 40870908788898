import React,{useContext, useState, useEffect} from "react";
import {  CreateBrokerForm } from "../Components/Forms";
import { hasKeys, getStandardDateForm } from '../utitility'
import { UserContext } from "../Context/UserContext";

const CreateBrokerPage = ({history}) => {
	
  	const {user,setUser} = useContext(UserContext);
	return (
		<React.Fragment>
			{hasKeys(user) &&
						<div className="container-fluid bg-white">
										<div className="border-bg">
											<h3
                  								className="font-weight-bold text mb-3 mt-3 ml-3" 
                  								style={{ color: "#2d78a0" }}
                							>
											Create Broker
											</h3>
											<section className=" mb-5 ml-5 row">
												<div className="col-6">
													<CreateBrokerForm history={history} />
												</div>
											</section>
										</div>
									</div>
				}
		</React.Fragment>
  	);
}
export default CreateBrokerPage;
