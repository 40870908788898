import React, { useMemo, useRef, useEffect }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { getStandardDateUnix} from '../../utitility';
import { useState } from "react";
import { SearchListingForm } from "../Forms";
import { Link } from 'react-router-dom';
import ActionField from './ActionField';
import ChangeListingStatus from "../ChangeListingStatus";

const SearchListing = ({user}) => {
	
	const [url,setUrl] = useState("");
	const [buyerListingStatusMessage,setbuyerListingStatusMessage] = useState("");
	const childRef = useRef();
	const [hiddenColumns,setHiddenColumns] = useState([]);

	useEffect(() => {
		if(user.UserType == 15 && hiddenColumns.length){
			setHiddenColumns(['tag']);
		}
  },[hiddenColumns]);
  	
	const getStatus = (status,listing) => {
		return (<ChangeListingStatus status={status}  user={user} reloadTableData={() => reloadTableData()} listing={listing}/>)
	}

	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}
  
	const getViewLink = (value) => {
		return <Link className="nav-link" to={`/listing_full_details?listing_id=${value}`}>{value}</Link>
	}

	const getActionField = (value) =>{
		return <ActionField sphinx={true} user={user} listing={value} reloadTableData={() => reloadTableData()}/>
	}
  
	const column =	[
		{
			Header: "ID",
			accessor: "ListingID",
			width: 70,
			sort:true,
			Cell: data => {return getViewLink(data.cell.value)},
		},{
			Header: "Source",
			accessor: "Brokercode",
			width: 70
		},{
			Header: "Listing Name",
			accessor: "ListingName",
			width: 200,
			sort:true
		},{
			Header: "Status",
			accessor: "AvailableStatus",
			Cell: row => (getStatus(row.cell.value,row.row.original)),
			width: 100,
			sort:true
		},{
			Header: "Tag",
			accessor: "Tag",
			hide:true,
			width: 100,
		},{
			Header: "Date",
			accessor: "CreateDate",
			Cell: row => (getStandardDateUnix(row.cell.value)),
			width: 110,
			sort:true
		},{
			Header: "Action",
			accessor: "Action",
			width: 400,
			Cell: data => (getActionField(data.row.original))
		}
	]
	
	const columns = useMemo(
		() => column,
		[]
	)

  return (
				<React.Fragment>
					<section>
						<SearchListingForm user={user} reloadTableData={() => reloadTableData()}  setUrl={setUrl} setbuyerListingStatusMessage={setbuyerListingStatusMessage}/>
					</section>
					<div className="text-danger" style={{marginLeft: '1.0rem'}}>
						{buyerListingStatusMessage ? buyerListingStatusMessage : ''}
					</div>
					<div>
						<ReactGenericTable ref={childRef} hiddenColumns={hiddenColumns} pagination={true} url={url} defaultSortIndex="ListingID" defaultSortOrder="DESC" columns={columns} />
					</div>
				</React.Fragment>
  );
};
export default SearchListing;
