import React, { useEffect, useState } from "react";
import { notify, http, apiBaseUrl } from "../../utitility";
import { hasKeys } from '../../utitility'

const UnReadEmail = ({user, history}) => {
	const [unreadEmail,setUnreadEmail] = useState(true);
	useEffect(() => {
		if(unreadEmail){
		  http.get(apiBaseUrl(`/api/getUnreadEmailMessage`))
		  .then((res) => { 
			  console.log(res);
			  notify.success("Unread message seen successfully");
			  setUnreadEmail(false);
		  }).catch((err)=>{
			console.log(err);
			notify.error("Unread message seen failed");
		  })
		}
	});
	
	return (
		<React.Fragment>
			{hasKeys(user) &&
				<section className=" mb-5 ml-5 row">
					<div className="col-12">
						<h3 className="font-weight-bold" style={{ color: "#2d78a0" }}>
							Un Read Email Test
						</h3>
					</div>
				</section>
			}
		</React.Fragment>
  	);
}
export default UnReadEmail;
