import React, { useState,useContext,useEffect } from "react";
import { CreateOrganization } from "../Components";
import { Link,useLocation } from "react-router-dom";
import { UserContext } from "../Context/UserContext"
import {http,apiBaseUrl} from '../utitility';
const CreateOrganizationPage = ({history}) =>{
    const {user} = useContext(UserContext);
	const  location = useLocation();
    const [orgId,setOrgId] = useState();
    const [organizationDetails,setOrganizationDetails] = useState({
        OrgName:"",
		CompanyNickname:"",
		ParentCompany:"",
		Status:1,
		Phone:"",
		Fax:"",
		Email:"",
		IPCatalogURL:"",
		TynaxRelationship:0,
		ActivePatentBuyer:0,
		TynaxBuySideComission:"0",
		Under_NDA:2,
		PatentSellingPolicy:"",
        PatentSellingTarget:"",
		Website:"",
		PatentSellingPolicyDescription:"",
		TechTransferActivity:"",
		Address:"",
		City:"",
		State:"",
		ZipCode:"",
		Country:"",
		Industry:"",
		Description:"",
		Keyword:"",
		Size:"",
		CreateDate:"",
		UpdateDate:"",
		MarketCap:"",
		Ticker:"",
        NumberOfPatentsHeld:"",
        UniversityPatentRanking:"",
        OTRanking:"",
        organizationInfo:{},
		CreatorId: user.UserID,
		Referrer: ""
    });
    const [initialState,setInitialState] = useState({
		organizationDetails:organizationDetails,
	});
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let org_id = params.get('org_id');
        setOrgId(org_id);
        getOrganizationsTypeList()
},[]);
const getOrganizationsTypeList = () =>{
    http.get(apiBaseUrl(`/api/getOrganizationsTypeList`))
    .then((res)=>{
        let initialValues = {organizationDetails}
        let organizationInfo = {}
        if ( res.data ){
            res.data.map((val)=>{
                organizationInfo[val.TypeId] = val.Flag
            })
            initialValues.organizationDetails.organizationInfo = organizationInfo;
            setInitialState(initialValues);
        }
    })
    .catch((err)=>{
        console.log(err);
    })
  }
    return(
        <div className="container-fluid bg-white">
			<div className="px-2 mb-3">
						<u><Link  className="px-2 text-primary" to={'/contacts/mylisting'}>My Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_contact'}>Create Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_contacts'}>Search Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_organizations'}>Create Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/organizations'}>Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_organizations'}>Search Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/mailing_lists'}>Mailing lists</Link></u>
					</div>
			<div className="border-bg">
				<section className="mb-5 ml-5 row">
					<div className="col-9">
						<CreateOrganization user={user}  history={history} org_id={orgId}initialState={initialState} setInitialState={setInitialState}  />
					</div>
				</section>
			</div>
		</div>
    )
}
export default CreateOrganizationPage;