
import React, { useMemo, useState, useRef }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { formatDate } from '../../utitility';
import Switch from '../Fields/Switch';
import 'react-confirm-alert/src/react-confirm-alert.css';

const PatentListings = ({patentDetails}) => {
	
	const [count,setCount] = useState(0);
	const title = `LISTINGS - ${count}`;
	const childRef = useRef();

	const columns = useMemo(
		() => [
			{
				Header: "Listing Id",
				accessor: "ListingID",
				width: 150,
				sort:true
			},
			{
				Header: "Listing Name",
				accessor: "tbl_listing.ListingName",
				width: 600,
				sort:true		
			},{
				Header: "Patent Status",
				accessor: "PatentStatus",
				width: 150
			},{
				Header: "Country",
				accessor: "Country",
				width: 150,
				sort:true
			},{
				Header: "Date",
				accessor: "CreateDate",
				width: 150,
				sort:true,
				Cell: data => {return formatDate(data.cell.value)}
			}
		],
		[]
	)


	const isActive = (count > 0 ? true : false);
	return (
	<Switch
		classNames="icon icon-uploadAgreement-icon"
		title={title}
		className="grouped-body"
		id="ptn_listings"
		collapsible={true}
		activeStatus={isActive}
	>
		<section className="mb-3">
			<React.Fragment>
				{patentDetails && patentDetails.PatentID	&&
					<ReactGenericTable  
						ref={childRef}
						setCount={setCount} 
						url={`getListingByPatentNumber?PatentNumber=${patentDetails.PatentNumber}&`} 
						defaultSortIndex="PatentNumber" 
						defaultSortOrder="DESC" 
						columns={columns}
					/>
				}	
			</React.Fragment>
		</section>
	</Switch>
  );
};
export default PatentListings;