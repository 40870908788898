import React, { Component } from "react";
import tynax_anim from "../assets/images/tynax-anim.gif";
import Tynax_buyers_300 from "../assets/images/Tynax-buyers-300.jpg";
import { PublicLeftMenu } from "../Components";

class ServicesInfringedPortfolio extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div id="content">
        <PublicLeftMenu />
        <div className="box-main">
          <h1>Looking to Monetize a Heavily Infringed Patent Portfolio?</h1>
          <p>
            If you have a group of patents covering inventions that are being
            widely practiced in the marketplace, you're going to need to gather
            evidence of infringement and essentially prepare a business plan
            designed to generate maximum revenue.
          </p>
          <h3>Experience &amp; Necessary Skills</h3>
          <img
            src={Tynax_buyers_300}
            style={{
              float: "right",
              marginTop: "8px",
              marginLeft: "8px",
              marginBottom: "8px",
            }}
          />
          Tynax has the experience and capability you need to gather the
          evidence you need, prepare sales/licensing materials and Tynax has the
          connections you need in order to run the patent sale/licensing
          campaign, including contingent patent litigators, investors, licensing
          companies, technical experts and researchers.
          <p></p>
          <p></p>
          <h3>
            Online Questionnaire/Listing Gathers the Key Information We Need
          </h3>
          You can contact us by clicking the 'Contact' link at the bottom of
          this page, or alternatively, if you would like to create a (free)
          account on our site, you can create a listing, essentially a
          questionnaire asking all the information we need to know in order to
          help you. If you select to create a 'detailed' listing, you will
          receive a series of forms asking you for information on the patents,
          the infringement, licensing history and other information that we're
          going to need. Don't worry, this information is not published on our
          website and we understand how this confidential information can be
          highly sensitive. As a first step, the information on infringement and
          licensing history will simply be used by Tynax staff to assess the
          opportunity and figure out the best route to monetizing your patent
          assets.
          <p></p>
          <ul className="smallgoldbulletimage">
            <li>
              <a href="transactions_patent_sale_guide.php">
                More information on the patent sale process
              </a>
              .
            </li>
            <li>
              <a href="daggers.php">Book on the patent marketplace.</a>
            </li>
            <li>
              <a href="eou.php">Tynax EOU services.</a>
            </li>
            <li>
              <a href="daggers.php#19-EOU">EOU section of patent book</a>.
            </li>

            <li>
              <a href="eou-search-guide.php">
                Book on evidence of use and claims charts
              </a>
              .
            </li>
          </ul>
          <h3>Proven Track Record</h3>
          Tynax has a track record in the patent brokering business. Check out{" "}
          <a href="closedcases.php">
            examples of transactions brokered by Tynax
          </a>
          <div style={{ margin: "15px 4px" }}>
            <a
              className="ovalbutton"
              href="contact.php"
              style={{ marginLeft: "6px" }}
            >
              <span>Tell Me More</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default ServicesInfringedPortfolio;
