import React, { useMemo, useRef } from "react";
import ReactGenericTable from '../Tables/ReactGenericTable'
import { getStar, getAge, hasKeys, notify, apiBaseUrl, http, formatDate } from '../../utitility';
import { useState } from "react";
import confidential_img from "../../assets/images/confidentialstamp-small.png";
import Switch from '../Fields/Switch';
import { Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';
import CreatePatentListingForm from "../Forms/CreatePatentListing";

const PatentInfo = ({ listings, salesDetails, user}) => {
	
	const [hidePatentColumns,setHidePatentColumns] = useState(user.UserType === 40 || user.UserType === 30 || listings.OwnerID === user.UserID ? false : true);

	const [patentCount,setPatentCount] = useState(0);

	const childRef = useRef();
	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

	const setCount = (count) =>{
		setPatentCount(count);
	}
	const getPatentLink = (row,value) =>{
		let patentDetailUrl = `patent_details?patentid=${row.PatentID}&plist=pid&pat_num=${row.PatentNumber}&pcont=${row.Country}`;
		return <Link target="_blank" to={patentDetailUrl}>{value}</Link> 
	}

	const getTitleLink = (row,value) => {
		let patentDetailUrl = `https://patents.google.com/patent/US${row.PatentNumber}`;
		return <a target="_blank" href={patentDetailUrl}>{value}</a>;
	}

	const deleteListingPatent = (data) =>{
		if(data.PatentID){
			http.delete(apiBaseUrl(`/api/deleteListingPatent?PatentID=`+data.PatentID))
			.then((res) => {
						reloadTableData();
						notify.success("Patent deleted successfully");
			}).catch((error)=>{
			  let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
							  error.response.data.message :
							  'whoops...something went wrong';
			  notify.error(errorMsg);
			});
		}
	}

	const getActionField = (data) => {
		return( 
			<div>
				<button  onClick={e => deleteListingPatent(data)} className="btn btn-secondary btn-sm">
					<i className="fa fa-trash" />
				</button>
			</div>
		)
	}

	const columns = useMemo(
		() => [
			{
				Header: "Country",
				accessor: "Country",
				width: 60,
				sort:true
			},{
				Header: "Number",
				accessor: "PatentNumber",
				width: 70,
				Cell: data => (getPatentLink(data.row.original,data.cell.value)),
			},{
				Header: "Title",
				accessor: "Title",
				width: 220,
				sort:true,
				Cell: data => (getTitleLink(data.row.original,data.cell.value)),
			},{
				Header: "Assignee/Owner on Record",
				accessor: "Assignee",
				width: 100,
				sort:true
			},{
				Header: "Status",
				accessor: "PatentStatus",
				width: 60
			},{
				Header: "Rating",
				accessor: "rating",
				Cell: row => (getStar(row.cell.value)),
				width: 100,
				sort:true
			},{
				Header: "Filed",
				accessor: "FiledDate",
				width: 100
			},{
				Header: "Age",
				accessor: "PublicationDate",
				Cell:row =>(
					row.cell.row.values.FiledDate === null ? 0 :
					getAge(row.cell.row.values.FiledDate)),
				width: 40
			},
			{
				Header: "Action",
				accessor: "act",
				Cell: data => {return getActionField(data.row.original)},
				width: 200,
				sort:false
			}
		],
		[]
	)
	const title = `PATENTS - ${patentCount}`;
	const isActive = (patentCount > 0 ? true : false);
    return (
		<Switch
			classNames="icon icon-uploadAgreement-icon"
			title={title}
			className="grouped-body"
			id="patent_info"
			collapsible={true}
			activeStatus={isActive}
		>
			<section className="mb-3">
				<Row>
					<Col>
						{user.UserType >= 30 &&
						<ul className="pl-0" style={{listStyleType:'none'}}>
							<li>
								<b className="text-primary mr-2">
									Seller Name:
								</b>
								{salesDetails.Seller}
							</li>
							<li>
								<b className="text-primary mr-2">
									Listing Create Date:
								</b>
								{formatDate(listings.createdate)}
							</li>
						</ul>}
					</Col>
					<Col>
					  <img className="" src={confidential_img} style={{ width: "150px" }} /> 
					</Col>
				</Row>
				{ hidePatentColumns ? '' :
				<CreatePatentListingForm 
					reloadTableData={() => reloadTableData()} 
					listings={listings} user={user}
				/> }
				<h5>PATENT INFO</h5>
				{hasKeys(listings) &&	
					<ReactGenericTable  ref={childRef} url={`patent_details?listing_id=${listings.ListingID}&`} setCount={setCount} defaultSortIndex="ListingID" defaultSortOrder="DESC" columns={columns} hiddenColumns={ hidePatentColumns ? 'act' : '' } />
				}	
			</section>
		</Switch>	
    );
  }

export default PatentInfo;
