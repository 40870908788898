import React from "react";
import { Row, Col } from "reactstrap";
import { hasKeys,mergeString } from "../../utitility";
import Switch from '../Fields/Switch';
import { Link } from 'react-router-dom';

const ListingContact = ({listings,listingcontact}) => {
	const title = `LINKED CONTACTS - ${listingcontact ? listingcontact.length: 0}`;
	const isActive = (listingcontact.length > 0 ? true : false);
  return (
		<Switch
			classNames="icon icon-uploadAgreement-icon"
			title={title}
			className="grouped-body"
			id="listing_contact"
			collapsible={true}
			activeStatus={isActive}
		>
		<section className="mb-3">
			<Row>
				<Col className="mb-3 ml-5 col-6 col-md-8">
					<ul className="pl-0" style={{listStyleType:'none'}}>
						<li><b className="text-primary mr-2">Listing Id:</b>{listings ? listings.ListingID: ""}</li>
						<li><b className="text-primary mr-2">Linked To:</b>
								<ol>
									{hasKeys(listingcontact) && 
										listingcontact.map((contact,index) => (
											<li key={index}>
												<Link to={`/contacts/contact_info?contact_id=${contact.ContactId}`}>{mergeString(contact.Firstname,contact.Lastname)}</Link>
											</li>
										))
									}
								</ol>
						</li>
					</ul>
				</Col>
			</Row>
		</section>
		</Switch>
    );
  }

export default ListingContact;
