import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactGenericTable from '../Tables/ReactGenericTable'
import InvitationForm from "../Forms/InvitationForm";
import CustomModal from "../Modal/CustomModal";
import DisplayMessage from "./DisplayMessage";
import ChangeFollowUp from "./ChangeFollowUp";
import { Row, Col } from "reactstrap";
import { formatDate, mergeString } from "../../utitility";
import Switch from '../Fields/Switch';

const Invitation = ({listings, salesDetails, user, forceReload}) => {
	
	const childRef = useRef();

	const [isOpen,setIsOpen] = useState(false);
	const [isChangeStatusOpen,setIsChangeStatusOpen] = useState(false);
	const [isModalOpen,setIsModalOpen] = useState(false);
	const [CurrentStatusDetail,setCurrentStatusDetail] = useState({});
  const [count,setCount] = useState(0);
	const [message,setMessage] = useState("");

	const toggle = () => {
		setIsModalOpen(!isModalOpen);
	}
  const reloadTableData = () => {
		childRef.current.reloadTableData()
	}
	const closeAllModal=()=>{
		setIsChangeStatusOpen(false)
		setIsModalOpen(false)
		setCurrentStatusDetail(false)
	}

	const isSet=(patent)=>{
		if(patent){
			return <i className="fa fa-check" aria-hidden="true"></i>
		}else{
			return "";
		}
	}

	const getViewButton=(message)=>{
			return <button onClick={()=>showMsgViewModal(message)} className="btn btn-secondary btn-sm">View</button>
	}

	const changeStatusModal=(value)=>{
		setCurrentStatusDetail(value);
		setIsChangeStatusOpen(true);
		setIsModalOpen(true);
		setIsOpen(false);
	}

	const showMsgViewModal=(message)=>{
		setIsChangeStatusOpen(false);
		setIsModalOpen(true);
		setIsOpen(true);
		setMessage(message);
	}

	const getFollowUpButton=(value)=>{
		let status = ['No Status','Evaluation','Passed','Under Offer','Pre-evaluation','Closed'];
		return <button style={{'width':"156px"}} onClick={()=>changeStatusModal(value)} className="btn btn-secondary btn-sm">{status[value.FollowUp]}</button>
	}
	
	const columns_attachment = useMemo(
		() => [
			{
				Header: "First Name",
				accessor: "FirstName",
				Cell: data => {return mergeString(data.row.original.FirstName,data.row.original.LastName)},
				width: 100,
				sort:true
			},
			{
				Header: "Email",
				accessor: "EmailAddress",
				width: 100,
				sort:true
			},
			{
				Header: "Patent",
				accessor: "Patent",
				Cell: data => {return isSet(data.value)},
				width: 100,
				sort:true
			},
			{
				Header: "Attachments",
				accessor: "Attachments",
				Cell: data => {return isSet(data.value)},
				width: 100,
				sort:true
			},
			{
				Header: "Sent by",
				accessor: "tbl_user.Firstname",
				Cell: data => {return mergeString(data.row.original.tbl_user.Firstname,data.row.original.tbl_user.Lastname)},
				width: 100,
				sort:false
			},
			{
				Header: "Sent Date",
				accessor: "CreateDate",
				Cell: data => {return formatDate(data.cell.value)},
				width: 100,
				sort:true
			},
			{
				Header: "View",
				accessor: "Message",
				Cell: data => {return getViewButton(data.value)},
				width: 100
			},
			{
				Header: "Follow up",
				accessor: "FollowUp",
				Cell: data => {return getFollowUpButton(data.row.original)},
				width: 100
			}
		]
	)
  const title = `INVITATIONS - ${count}`;
  const isActive = (count > 0 ? true : false);
  return (
		<Switch
			classNames="icon icon-uploadAgreement-icon"
			title={title}
			className="grouped-body"
			id="invitation"
			collapsible={true}
			activeStatus={isActive}
		>
		<section className="mb-3">
				<p>
					Invite people to view this listing, manage and track who you have sent invites to. 
				</p>
				<ul className="pl-0" style={{listStyleType:'none'}}>
					<li><b className="text-primary mr-2">Listing Id:</b>{listings.ListingID}</li>
					<li><b className="text-primary mr-2">Listing Name:</b>{listings.ListingName}</li>
					<li><b className="text-primary mr-2">Seller Name : </b>{salesDetails.Seller}</li>
					<li><b className="text-primary mr-2">Create Date:</b>{formatDate(listings.createdate)}</li>
				</ul>
        <Row>
					<Col className="col-sm-12 col-md-12">
						<InvitationForm 
						  forceReload={forceReload}
							reloadTableData={() => reloadTableData()}  
							user={user} 
							listings_id={listings.ListingID} 
							listingName={listings.ListingName}
						/>
					</Col>
				</Row>

				{listings.ListingID &&
					<ReactGenericTable  
							ref={childRef} 
							setCount={setCount} 
							pagination={false} 
							url={`getInvitation?listing_id=${listings.ListingID}&`} 
							defaultSortIndex="FirstName" 
							defaultSortOrder="DESC" 
							columns={columns_attachment} />
					}
					{isModalOpen &&
						<CustomModal notice={true} modal={isModalOpen}  toggle={toggle}  closeAllModal={closeAllModal} heading={isOpen?'message':"change followup"}  render={() => (
							<React.Fragment>
								{isOpen && <DisplayMessage  message={message} link="/dashboard" btnText="GO TO DASHBOARD"/>}
								{isChangeStatusOpen && <ChangeFollowUp  closeAllModal={closeAllModal} listing_id={listings.ListingID} reloadTableData={() => reloadTableData()} CurrentStatusDetail={CurrentStatusDetail} message={message} link="/dashboard" btnText="GO TO DASHBOARD"/>}
							</React.Fragment>
							
						)}/>
					}
		</section>
		</Switch>
    );
  }

export default Invitation;
