import queryString from "query-string";
import get from "lodash.get";
import config from "./config";

const appUrl = (path) => `${config("app.url.base")}${path}`;

const apiUrl = (path) =>
  `${config("api.url.base")}${config("api.url.path")}${path}`;

const apiBaseUrl = (path) => `${config("api.url.base")}${path}`;

const localUrl = (path) => `${config("local.url.base")}${path}`;

const query = (key, defaultValue = "") =>
  get(queryString.parse(window.location.search), key, defaultValue);

export { appUrl, apiUrl, apiBaseUrl, localUrl, query };
