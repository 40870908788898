import React, {useEffect} from "react";
import { Row, Col } from "reactstrap";
import Select from 'react-select'
import { useState } from "react";
import { filter , isEmpty} from "lodash";
import { http, apiBaseUrl,notify, getStandardDateForm, hasKeys } from "../utitility"
import { listingStatus } from "../utitility/constants"
import classnames from "classnames";
import CustomModal from "./Modal/CustomModal";

const ChangeListingStatus = ({ listing, reloadTableData,status, user }) => {

	useEffect(() => {
    if(hasKeys(listing)){
			let listing_status = getListingStatus(listing.AvailableStatus);
			if(listing_status.length){
				setSelectedOption(listing_status[0])
			}
		}
	},[listing]);

	const [IsOpenStatusModal,OpenStatusModal] = useState(false);

	const [selectedOption,setSelectedOption] = useState({ value: 1, label: 'Active' });
	const [isSubmitting,setIsSubmitting] = useState(false);
	const [selectedListingId,setSelectedListingId]= useState(null)

	const handleChange=(value)=>{
		setSelectedOption(value);
	}  
	
	const onClickStatus = (listing) => {
		OpenStatusModal(true)
		let ListingID = listing.ListingID;
		let statuslabel = getListingStatusLabel(listing.AvailableStatus);
		let selectedValue = { value: listing.AvailableStatus, label: statuslabel};
		setSelectedOption(selectedValue);
		setSelectedListingId(ListingID);
	}

	const getListingStatusLabel=(status)=> {
		if (!isEmpty(listingStatus)) {
			let data =  filter(listingStatus, function(o) {
					if (parseInt(o.value) === parseInt(status)) return o;
			});
			if(data.length){
				return data[0].label
			}else{
				return ""
			}
		} else {
			return ""
		}
	}
	
	const getStatus = () => {
	   if(status===0 || status=== -1){
			if(user.UserType == '40'){
			return <span className="btn-link link-pointer" onClick={()=>onClickStatus(listing)}>Pending
				{status=== -1 &&
						<i className="ml-1 fa fa-hand-paper-o" aria-hidden="true"></i>
				}
			</span>
			}else{
				return <span>Pending
								{status=== -1 &&
										<i className="ml-1 fa fa-hand-paper-o" aria-hidden="true"></i>
								}
							</span>
			}
		 }else if(status===-2){
			if(user.UserType == '40'){
				return <span  onClick={()=>onClickStatus(listing)} className="btn-link">Declined</span>;
			}else{
				return <span>Declined</span>;
			}
		 }else{
			if(user.UserType == '40'){
				return <span onClick={()=>onClickStatus(listing)} className="btn-link link-pointer">{getListingStatusLabel(status)}</span>;
			}else{
				return <span>{getListingStatusLabel(status)}</span>;
			}
		 }
	}

  const changeListingStatus=(selectedOption)=>{
		let data ={};
		setIsSubmitting(true);
		data.listing_id = selectedListingId;
		data.AvailableStatus = selectedOption.value;
		data.UpdateDate = getStandardDateForm();
		http.post(apiBaseUrl(`/api/changeListingStatus`),data)
		.then(() => {
			try{
				reloadTableData();
				toggleListingModal();
				setIsSubmitting(false);
				notify.success("Listing Status changed successfully");
			}catch(error){
				setIsSubmitting(false);
				console.log(error.response)
			}
			
		}).catch((error)=>{
			setIsSubmitting(false);
			notify.error(error);
		});
	}

	const getListingStatus=(status)=> {
		if (!isEmpty(listingStatus)) {
		return filter(listingStatus, function(o) {
				if (o.value === status) return o;
		});
		} else {
			return {};
		}
	}

	const toggleListingModal = () => {
		OpenStatusModal(!IsOpenStatusModal);
	}

	
  return (
    <div>
      {getStatus()}
			{IsOpenStatusModal &&
				<CustomModal notice={true} modal={IsOpenStatusModal}  toggle={toggleListingModal} closeAllModal={toggleListingModal} heading={"Change Listing Status"}  render={() => (  
					<React.Fragment>
						<Row className="justify-content-center">
							<Col className="mb-3 col-6">
								<Select value={selectedOption} options={listingStatus} onChange={handleChange} />
							</Col>
						</Row>
						<div className="text-center">
							<button 
							disabled={isSubmitting} 
							type="button"
							className={classnames({
							"buttonload btn btn-primary btn-sm": isSubmitting === true,
							"btn btn-primary btn-sm": isSubmitting === false,
							})}
							onClick={()=>changeListingStatus(selectedOption)}
							>
								{isSubmitting &&
								<i className="fa fa-spinner fa-spin"></i>
								}
								Save
							</button>
						</div>
					</React.Fragment>
				)}/>
			}
		</div>
  );
};

export default ChangeListingStatus;
