import React, { Component } from "react";
import { PublicLeftMenu } from '../Components';

class HowToSellYourOwnPatent extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div id="content">
        <PublicLeftMenu />
        <div className="box-main">
          <h1>How to Sell your Own Patent</h1>
          <p>
            If you're looking to sell a patent that is not being heavily
            infringed already, and you're looking to find a company that's going
            to build a new product out of the invention, you'll likely find that
            patent broker isn't in a good position to help you, as the broker
            likely doesn't have contacts with the companies that would be
            building new products. You know your invention better than any
            broker, and you're in a better position to identify potential buyers
            because you have insights into the invention, the product, the
            marketplace and what it will take to build and market a new product.
          </p>
          <p>
            <iframe
              width="250"
              height="125"
              src="https://www.youtube.com/embed/J-b8Qik0tXE"
              style={{ float: "right", padding: "10px" }}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
            ></iframe>
            All patents, by their very nature, are unique, and there are
            patented inventions covering all types of products. Patent brokers
            like Tynax, generally operate only in fields where there are repeat
            buyers--companies repeatedly acquiring patents, usually for
            licensing or cross-licensing purposes. As a result, patent brokers
            are only in position to help a small percentage of patent
            sellers--usually infringed patent portfolios in telecommunications,
            consumer electronics and other large markets where products consist
            of thousands of patented inventions.
          </p>
          <p>
            This article explains how you can go about selling your own patent
            when brokers aren't in position to help you.
          </p>
          <h3>Post your Patent on the Tynax Exchange</h3>
          <p>
            If you're looking to sell a patent that is not being heavily
            infringed already, and you're looking to find a company that's going
            to build a new product out of the invention, you'll likely find that
            patent broker isn't in a good position to help you, as the broker
            likely doesn't have contacts with the companies that would be
            building new products.
          </p>
          <p>
            {" "}
            <img
              src="images/working250.png"
              style={{
                float: "right",
                marginTop: "8px",
                marginLeft: "8px",
                marginBottom: "8px",
              }}
            />
            When you post your patent on our online exchange, the patent number
            is not disclosed to the public. The listing describes your patent
            without actually disclosing the patent number or any other
            identifiable information, so that you can post the patent for sale
            and connect with potential buyers without letting the world know
            about it--this is because our clients usually want to keep their
            patent selling activities under wraps. If you're looking to sell a
            patent that is not being heavily infringed already, and you're
            looking to find a company that's going to build a new product out of
            the invention, you'll likely find that patent broker isn't in a good
            position to help you, as the broker likely doesn't have contacts
            with the companies that would be building new products.
          </p>
          <p>
            When your listing is posted, it is reviewed by Tynax staff to make
            sure it's looking good, then it's matched with buyers who have
            indicated an interest in that particular technology field and is
            posted on the Tynax website, in the appropriate category. Beyond the
            Tynax listing, there are other things you can do to actively market
            your patent. If you sell your patent yourself, you don't pay any
            brokering fees to Tynax or anyone else, so this can be a more
            profitable approach for you. It involves identifying buyers,
            approaching them, pitching them, negotiating a deal, finalizing the
            paperwork and closing the transaction. The various key steps are
            outlined below: If you're looking to sell a patent that is not being
            heavily infringed already, and you're looking to find a company
            that's going to build a new product out of the invention, you'll
            likely find that patent broker isn't in a good position to help you,
            as the broker likely doesn't have contacts with the companies that
            would be building new products.
          </p>
          <h3>Familiarize Yourself with the Patent Market</h3>
          <img
            src="images/working2-250.png"
            style={{
              float: "right",
              marginTop: "8px",
              marginLeft: "8px",
              marginBottom: "8px",
            }}
          />
          <p>
            On this Tynax website, you will find free books and resources
            explaining{" "}
            <a href="https://www.tynax.com/daggers.php">
              how the patent market works
            </a>
            ,
            <a href="https://www.tynax.com/dollarvalue.php">
              how patents are valued
            </a>
            , patent purchase agreements are structured and a range of
            information that will be helpful to you as you start the process of{" "}
            <a href="transactions_patent_sale_guide.php">selling your patent</a>
            . Further information and educational resources are available from
            our sister company{" "}
            <a href="https://svbs.co/">Silicon Valley Business School</a>.
          </p>
          <h3>Draw up a List of Potential Buyers</h3>
          <p>
            Potential buyers could be companies making and selling similar
            products. Essentially, you're looking for companies with product
            design, manufacturing and marketing capabilities, and with
            distribution channels to reach your target customer. You can
            research the market online, identifying potential buyers by looking
            at companies that are marketing and selling products to your target
            customers.
          </p>
          <h3>Prepare your Pitch</h3>
          <p>
            You're going to need to compile all the information a potential
            buyer might like to see, and this includes a summary of the
            opportunity, market size, projected sales of the new patented
            invention. Essentially, you're going to need all the elements of a
            business plan for commercializing your invention(s). A short video
            presenting the opportunity would be ideal. If you're product needs
            to be manufactured, you're going to need to know the associated
            design, tooling and production costs.
          </p>
          <h3>Identify Decision-Makers</h3>
          <p>
            For each of the companies on your target list, you're going to have
            to identify the decision makers and find their contact details. All
            patent matters are generally dealt with by the legal department,
            under the general counsel (top lawyer). This is unfortunate when
            you're trying to develop a new product and the lawyers who are
            tasked with dealing with patents are generally looking at the risks
            of litigation. Don't be surprised if the lawyers you deal with are
            focused on whether you plan to sue them for patent infringement. The
            view of lawyers is often restricted to minimizing the risks
            associated with legal threats, and they're not always the best
            people to discuss new product development.
          </p>
          <p>
            There are product development teams in large corporations and you
            might want to try talking to them. Be aware here though of a
            not-invented-here syndrome and find that product developers are not
            very open minded to new ideas coming from outside.
          </p>
          <p>
            To find the contact names, email addresses, phone numbers of the
            decision makers, you might want to subscribe to a service like
            Lead411, which has contact details for individuals in most
            organizations in the US and in some other countries as well.
            Alternatively, you can usually get the general counsel's name from
            the company's SEC filings, and often you can get a phone number and
            email address from there as well.
          </p>
          <h3>Approach Potential Buyers</h3>
          <p>
            When you reach out to the general counsel or the product development
            team, you'll likely use a combination of email and phone call. You
            only get one chance to make a first impression, so make sure your
            pitch is concise and compelling. When you get someone on a call,
            respect their time and avoid telling them your life story. If you
            have a video, refer them to that and other information, and arrange
            a follow-up call.
          </p>
          <h3>Protecting your Intellectual Property</h3>
          <img
            src="images/shhh300.png"
            style={{
              width: "250px",
              float: "right",
              marginTop: "8px",
              marginLeft: "8px",
              marginBottom: "8px",
            }}
          />
          <p>
            Your patent is publicly disclosed, and provides you with the right
            to sue an infringer, so it's already protected, but it's likely that
            the buyer will want to enter into a non-disclosure agreement with
            you, and it's likely that you might want to protect some trade
            secrets and other intellectual property that you disclose to the
            potential buyer. This is something of a legal minefield, and it's
            highly recommended that you engage with an attorney, and as always,
            make sure you engage an attorney with lots of experience and
            expertise in the field--in this case you want someone with
            experience of patent sale and licensing transactions, and ideally
            someone with experience of patent litigation.
          </p>
          <h3>Negotiate Price and Deal Terms</h3>
          <p>
            When it comes to pricing, the spread between the price anticipated
            by the seller and the price offered by the buyer is often
            surprisingly large. This is usually because sellers are unfamiliar
            with the market, and they've been misled into thinking that patents
            are all worth millions of dollars. In reality, the vast majority of
            patents sell for 5-figures (less than $100,000 each), and only small
            percentage sell for 6-figures. It's very rare, and becoming
            increasingly rare, for a patent to sell in the 7-figures. Review
            these resources very carefully before entering into price
            discussions:
          </p>
          <ul className="smallgoldbulletimage">
            <li>
              <a href="https://www.tynax.com/transactions_patent_sale_guide.php#9-Why-Asking-Price">
                Tynax Guide: Why set an asking price.
              </a>
            </li>
            <li>
              <a href="https://www.tynax.com/transactions_patent_sale_guide.php#10-How-Asking-Price">
                Tynax Guide: How to set an asking price.
              </a>
            </li>
            <li>
              <a href="https://www.tynax.com/dollarvalue.php#10-Patents">
                Patent Valuation Section in Dollar Value Book
              </a>
            </li>
            <li>
              <a href="https://www.tynax.com/dollarvalue.php">
                Dollar Value--Book on The Valuation of Patents, Startups,
                Software and Other Intellectual Property Assets
              </a>
            </li>
            <li>
              <a href="https://www.tynax.com/transactions_patent_sale_guide.php#27-Increasing-Value">
                Tynax Guide: Increasing the value in a patent sale transaction.
              </a>
            </li>
          </ul>
          <p></p>
          When you have a buyer seriously interested in buying your patent(s),
          we might be able to help you here at Tynax. We are familiar with the
          patent sale process, the market pricing rates, and we can usually help
          clients get the best terms possible.
          <p></p>
          <h3>Negotiate a Patent Purchase Agreement &amp; Other Documents</h3>
          <p>
            There are various documents required in the patent sale transaction.
            Again, it's a good idea to have a lawyer represent you, and again,
            make sure it's a lawyer that's familiar with patent sale
            transactions. More information on the patent purchase agreements,
            other documents, and the closing process are available here:
          </p>
          <ul className="smallgoldbulletimage">
            <li>
              <a href="https://youtu.be/u6AQpz7cFJ4">
                Tynax Video: patent purchase agreements.
              </a>
            </li>
            <li>
              <a href="https://www.tynax.com/daggers.php#25-Transaction">
                Patents, Cloaks and Daggers Book: transaction section.
              </a>
            </li>
            <li>
              <a href="https://www.tynax.com/transactions_patent_sale_guide.php#15-PPA">
                Tynax Guide: transaction section.
              </a>
            </li>
          </ul>
          <h3>Close out the Transaction</h3>
          <p>
            The closing involves the seller providing the buyer with the
            executed assignment of title, and the buyer providing the seller
            with the purchase funds.
          </p>
          <p>
            There are sometimes liens that need to be removed, and other
            complications that require an escrowed closing process to protect
            the interests of all the parties and
            <img
              src="images/deal2-300.png"
              style={{
                width: "250px",
                float: "right",
                marginTop: "8px",
                marginLeft: "8px",
                marginBottom: "8px",
              }}
            />
            overcome the catch-22 situation that the buyer won't release the
            assignment until funds are received from the seller and the seller
            won't release the purchase funds until the seller has received the
            assignment from the buyer. The process is laid out in the Patent's
            Cloaks &amp; Daggers book here:
          </p>
          <ul className="smallgoldbulletimage">
            <li>
              <a href="https://www.tynax.com/daggers.php#25-Transaction">
                Patents, Cloaks and Daggers Book: transaction section.
              </a>
            </li>
          </ul>
          <p>
            Selling a patent can be a profitable experience, but buyers are
            increasingly picky and the truth is that many patents simply aren't
            sellable at any price--however hard you work, there's no guarantee
            that you will be successful in selling your patent. Sometimes it's a
            matter of timing. An invention that isn't yet infringed might be
            impossible to sell today, but when the invention is adopted by the
            market, and starts to be infringed, the patent could suddenly become
            sellable and valuable. For many patent holders it's a waiting
            game--waiting for your invention to get built into large numbers of
            products and become heavily infringed. With infringement, and strong
            evidence of infringement, you have a much better chance of selling
            your patent, but if you can find a company wanting to buy the patent
            to practice the invention, building and commercializing products,
            then with luck you might be able to sell your patent based on it's
            potential for future commercialization.
          </p>
          <div style={{ margin: "15px 4px" }}>
            <a
              className="ovalbutton"
              href="contact.php"
              style={{ marginLeft: "6px" }}
            >
              <span>Tell Me More</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default HowToSellYourOwnPatent;
