
import React, { useMemo, useState, useRef }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { formatDate, http, apiBaseUrl, notify } from '../../utitility';
import Switch from '../Fields/Switch';
import { Row, Col } from "reactstrap";
import { PatentNotesForm } from "../Forms";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

const PatentNotes = ({user,patentDetails}) => {
	
	const [editValue,setEditValue] = useState({});
	const [count,setCount] = useState(0);
	const title = `NOTES - ${count}`;
	const EditNotes = (value) => {
		 setEditValue(value);
	}
	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

	const DeleteNotes = (value) => {
		let notesId= value.NotesId;
		let userId = user.UserID;
		let patentId = value.PatentID;
		http.delete(apiBaseUrl(`/api/deletePatentNotes?notesId=${notesId}&userId=${userId}&patentId=${patentId}`))
			 .then((res) => {
				 notify.success("success");
			 	reloadTableData();
			 }).catch((error)=>{
				 let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
												 error.response.data.message :
												 'whoops...something went wrong';
				 notify.error(errorMsg);
			 });
	}
  const formatHtml = (message) => {
		return <div
						dangerouslySetInnerHTML={{
							__html: message
						}}>

						</div>
	}
	const ConfirmAlert = (value) => {
			confirmAlert({
				//title: 'Confirm to submit',
				message: 'Do you really want to delete this note?',
				buttons: [
					{
						label: 'Yes',
						onClick: () => DeleteNotes(value)
					},
					{
						label: 'No',
						onClick: () => {}
					}
				]
			});
		
	}

	const childRef = useRef();

	const getActionField = (value) => {
			return <div>
             <button onClick={()=>EditNotes(value)} className="btn btn-sm btn-secondary mr-2">Edit</button>
						 <button onClick={()=>ConfirmAlert(value)} className="btn btn-sm btn-danger">Delete</button>
			       </div>
	}

	const columns = useMemo(
		() => [
			{
				Header: "Date",
				accessor: "CreateDate",
				width: 150,
				sort:true,
				Cell: data => {return formatDate(data.cell.value)}
			},{
				Header: "Tag",
				accessor: "Tag",
				width: 150
			},{
				Header: "Message",
				accessor: "ConvertedMessage",
				width: 300,
				sort:true,
				Cell: data => {return formatHtml(data.cell.value)}
			},{
				Header: "User",
				accessor: "Username",
				width: 150
			},{
				Header: "Action",
				accessor: "ListingId",
				Cell: data => {return getActionField(data.cell.row.original)},
				width: 220,
				sort:true
			}
		],
		[]
	)


	const isActive = (count > 0 ? true : false);
	return (
	<Switch
		classNames="icon icon-uploadAgreement-icon"
		title={title}
		className="grouped-body"
		id="notes_info"
		collapsible={true}
		activeStatus={isActive}
	>
		<section className="mb-3">
			<React.Fragment>
			{patentDetails &&
				<ul className="pl-0" style={{listStyleType:'none'}}>
					<li><b className="text-primary mr-2">Patent Id:</b>{patentDetails.PatentID}</li>
					<li><b className="text-primary mr-2">Patent Name:</b>{patentDetails.PatentName}</li>
				</ul>
				}
				<Row>
					<Col className="col-sm-12 col-md-8">
						<PatentNotesForm 
							reloadTableData={() => reloadTableData()} 
							patentDetails={patentDetails} 
							editValue={editValue}
							user={user}
						/>
					</Col>
				</Row>
				{patentDetails && patentDetails.PatentID	&&
					<ReactGenericTable  
						ref={childRef}
						setCount={setCount} 
						url={`getPatentNotes?patent_id=${patentDetails.PatentID}&`} 
						defaultSortIndex="a.ObjID" 
						defaultSortOrder="DESC" 
						columns={columns}
					/>
				}	
			</React.Fragment>
		</section>
	</Switch>
  );
};
export default PatentNotes;