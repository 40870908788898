import React, { useState }  from "react";
import CustomModal from "./Modal/CustomModal";
import { hasKeys, apiBaseUrl,http, notify, getStandardDateForm } from "../utitility";

import classnames from "classnames";

const EditTag = ({listing,classNameValue}) => {

	const [isModalOpen,setIsModalOpen] = useState(false);
	const [isSubmitting,setIsSubmitting] = useState(false);
  	const [tag,setTag] = useState(listing.Tag);
	const [existingTag,setExistingTag] = useState(listing.Tag);

	const openLinkContact = () => {
		setIsModalOpen(true);
	}

	const handleChange=(e)=>{
		setTag(e.target.value);
	}
  
	const editTag = () => {
		let data = {};
		data.listing_id = listing.ListingID;
		data.Tag = tag;
		data.UpdateDate = getStandardDateForm();
		http.post(apiBaseUrl(`/api/updateTag`),data)
		.then((res) => {
			if(res.data.message){
			 notify.success(res.data.message);
			}else{
			 notify.success("Tag updated successfully");
			}
			toggle();
			setTag(tag);
			setExistingTag(tag);
			setIsSubmitting(false);
		}).catch((error)=>{
			notify.error(error);
			setIsSubmitting(false);
		});
	}

	const toggle = () => {
		setTag(existingTag);
		setIsModalOpen(!isModalOpen);
	}


  return (
		<React.Fragment>
				{hasKeys(listing)&&
				<span key={listing.ListingID}>
						<span onClick={()=>(openLinkContact())} className={classNameValue? classNameValue :"btn-link link-pointer mr-2 table_font_size"}>Tag</span>
						{isModalOpen &&
							<CustomModal notice={true} modal={isModalOpen}  toggle={toggle} closeAllModal={toggle} heading={"Edit Tag"}  render={() => (  
								<React.Fragment>
									<div className="form-group">
										<label
											className="font-weight-bold form-text"
											htmlFor="exampleInputEmail1"
										>
											Tag
										</label>
										<input
											type="text"
											name="tag"
											className="form-control"
											onChange={handleChange}
											value={tag}
										/>
										<span className="text-danger">
											{/* {errors.current_password && touched.current_password && errors.current_password} */}
										</span>
									</div>
									<div className="text-center">
										<button 
											className={classnames({
												"buttonload mt-2 btn btn-primary btn-sm": isSubmitting === true,
												"btn mt-2 btn-primary btn-sm": isSubmitting === false,
											})}
											disabled={isSubmitting} 
											onClick={(value)=>editTag(value)}
										>
											Save
										</button>
										<button 
											className="ml-3 btn mt-2 btn-secondary btn-sm"
											onClick={()=>toggle()}
										>
											Close
										</button>
									</div>
								</React.Fragment>
							)}/>
      			}
				</span>}
			</React.Fragment>
  );
};
export default EditTag;
