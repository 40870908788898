import React, { useMemo, useRef, useEffect }  from "react";
import  ReactGenericTable from '../Components/Tables/ReactGenericTable';
import { formatDate, mergeString, getUserType } from '../utitility';
import { useState } from "react";
import { SearchUserForm } from "../Components/Forms";
import { Link } from 'react-router-dom';

const SearchUser = ({user}) => {
	
	const [url,setUrl] = useState("");
	const childRef = useRef();

	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

	const getViewLink = (value) => {
		return <Link className="nav-link py-0" to={`/admin/profile?user_id=${value}`}>{value}</Link>
	}

	// const columns = useMemo(
	// 	() => [
	const column =	[
		{
				Header: "ID",
				accessor: "UserID",
				width: 70,
				sort:true,
				Cell: data => {return getViewLink(data.cell.value)}
			},{
				Header: "Type",
				accessor: "UserType",
				width: 100,
				Cell: data => {return getUserType(data.cell.value)}
			},{
				Header: "User Name",
				accessor: "Username",
				width: 160,
				sort:true
			},{
				Header: "Full Name",
				accessor: "Firstname",
				width: 200,
				Cell: data => {return mergeString(data.row.original.Firstname,data.row.original.Lastname)},
				sort:true
			},{
				Header: "Broker Id",
				accessor: "BrokerID",
				width: 80
			},{
				Header: "Email",
				accessor: "Email",
				width: 240
			},{
				Header: "Phone",
				accessor: "Phone",
				width: 110,
			},{
				Header: "Company",
				accessor: "Company",
				width: 150,
			},
			// {
			// 	Header: "Role",
			// 	accessor: "UserRole",
			// 	Cell: data => {return getUserType(data.cell.value)},
			// 	width: 100,
			// },
			{
				Header: "Date",
				accessor: "CreateDate",
				width: 106,
				Cell: data => {return formatDate(data.cell.value)}

			}
		]
	// 	[]
	// ) 
	const columns = useMemo(
		() => column,
		[]
	)

  return (
				<React.Fragment>
					<div className="container-fluid bg-white">
							<div className="border-bg">
								<section>
									<SearchUserForm user={user} reloadTableData={() => reloadTableData()}  setUrl={setUrl}/>
								</section>
								<div>
									<ReactGenericTable ref={childRef} pagination={true} url={url} defaultSortIndex="UserID" defaultSortOrder="DESC" columns={columns} />
								</div>
							</div>
					</div>
				</React.Fragment>
  );
};
export default SearchUser;
