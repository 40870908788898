import React,{useEffect, useState} from "react";
import { useLocation,Link } from 'react-router-dom';
import MailingListDetails from '../Components/Contacts/MailingListDetails';
import { notify, http, apiBaseUrl, formatDate,getCurrentDate } from "../utitility";
const MailingListDetailsPage = () =>{
	const  location = useLocation();
    const [mailingListId,setMailingListId] = useState(null);
    const [mailListingDetails,setMailListingDetails] = useState(null);
    useEffect(() => {
		const params = new URLSearchParams(location.search);
        let mailListing_id = params.get('mailingList_id');
        setMailingListId(mailListing_id);
		http.get(apiBaseUrl(`/api/getMailListingInfo?mailingList_id=${mailListing_id}`))
		.then((res) => {	
            setMailListingDetails(res.data);
		}).catch((error=>{
			console.log(error,'error')
		}))
  },[]);
  return(
    <div className="container-fluid bg-white">
        <div className="px-2 mb-3">
						<u><Link  className="px-2 text-primary" to={'/contacts/mylisting'}>My Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_contact'}>Create Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_contacts'}>Search Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_organizations'}>Create Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/organizations'}>Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_organizations'}>Search Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/mailing_lists'}>Mailing lists</Link></u>
					</div>
                        {mailListingDetails && mailListingDetails.map(val => 
                    <div className="px-3 mb-3">
						<h5 className="font-weight-bold form-text mb-3">Mailing List</h5>
                            <h6 className="text-primary mx-2">Mailing Name:<span class="text-muted ml-3">{val.mailinglist_name ? val.mailinglist_name:"N/A"}</span></h6>
                            <h6 className="text-primary mx-2">Mailing Notes:<span class="text-muted ml-3">{val.mailinglist_notes ? val.mailinglist_notes:"N/A"}</span></h6>
					</div>
                            )}
			<div className="justify-content-center py-1">
				<MailingListDetails mailListing_id={mailingListId} />
			</div>
		</div>
  )
}
export default MailingListDetailsPage;