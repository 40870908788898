import React, { useState } from "react";
import { notify, http, apiBaseUrl, getStandardDateForm } from "../../utitility";
import { Formik } from "formik";
import classnames from "classnames";
import CustomButton from './CustomButton';

const Register = () => {

  const [userNameAvailable, setUserNameAvailable] = useState(false);

  const checkUserNameAvailability = (username) => {
      http.post(apiBaseUrl(`/api/username/availability`),{ username: username })
      .then((res) => {
        if (res.data && res.data.data) {
          setUserNameAvailable("not_available");
        } else {
          setUserNameAvailable("available");
        }
      });
  };

  return (
    <Formik
      initialValues={{
        Username: "",
        Confirm_Password: "",
        Password: "",
        Email: "",
        Firstname: "",
        Lastname: "",
        Salutation: "",
        BrokerID: "TTX",
        UserRole: 1,
        UserType: 1,
        Phone: "",
        Fax: "",
        Company: "",
        CreatorID: "",
        CreateDate: getStandardDateForm(),
        LastUpdate: getStandardDateForm(),
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        Zipcode: "",
        Country: "",
        ServiceOffer: "",
        GeoCoverage: "",
        ProReference: "",
        AdditionalInfo: "",
        SupportID: 0,
        SalesID: 0,
        RefererID: 0,
        agreed: false,
      }}
      validate={(values) => {
        const errors = {};
        // var phoneno = /^\d{10}$/;
        if (!values.Email) {
          errors.Email = "Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
        ) {
          errors.Email = "Invalid email address";
        }
        if (!values.Password && !values.Password.trim()) {
          errors.Password = "Required";
        }else if(values.Password.length < 6){
          errors.Password = "Password must be at least 6 characters";
        }
        // if(!(values.Phone && (values.Phone.match(phoneno))))
        // {
        //   errors.Phone = "Enter valid phone number";
        // }
        if (typeof values.Phone !== "undefined" && values.Phone.length !== 0) {
          // var pattern = new RegExp(/^[0-9\b]+$/);
          var pattern = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);
          if (!pattern.test(values.Phone)) {
            errors.Phone = "Please enter valid phone number.";
          }else if(values.Phone.length < 8){
            errors.Phone = "Please enter valid phone number.";
          }
        }
        if (!values.Confirm_Password && !values.Confirm_Password.trim()) {
          errors.Confirm_Password = "Required";
        }
        if (values.Password !== values.Confirm_Password) {
          errors.Confirm_Password = "Password didnt match";
        }
        if (!values.Username) {
          errors.Username = "Required";
        } else if (values.Username.length < 3) {
          errors.Username = "User name should have at least 2 characters";
        }
        if (!values.Firstname) {
          errors.Firstname = "Required";
        }
        if (!values.Lastname) {
          errors.Lastname = "Required";
        }
        if (!values.agreed) {
          errors.agreed = "Required";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        http.post(apiBaseUrl(`/api/register`),values)
        .then((res) => {
          resetForm({});
          setSubmitting(false);
          notify.success("You have created a account successfully,Please verify your mail account ");
        }).catch((error)=>{
          setSubmitting(false);
          notify.error("user name / email id  is already attached to an account.");
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              User Name
            </label>
            <div className="input-group mb-3">
              <input type="text" name="Username" className="form-control" onChange={handleChange} value={values.Username} aria-describedby="button-addon2"/>
              <div className="input-group-append">
                <button disabled={!values.Username} onClick={() => checkUserNameAvailability(values.Username)} className="btn-ad btn btn-primary" type="button" id="button-addon2">check availability</button>
              </div>
            </div>
            <span
              className={classnames({
                "text-success": userNameAvailable === "available",
                "text-danger": userNameAvailable === "not_available",
              })}
            >
              {userNameAvailable === "available" && "available"}
              {userNameAvailable === "not_available" && "not available"}
            </span>
            <span className="text-danger">
              {errors.Username && touched.Username && errors.Username}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputPassword1"
            >
              Password
            </label>
            <input
              type="password"
              name="Password"
              className="form-control"
              onChange={handleChange}
              value={values.Password}
            />
            <span className="text-danger">
              {errors.Password && touched.Password && errors.Password}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Retype Password
            </label>
            <input
              type="password"
              name="Confirm_Password"
              className="form-control"
              onChange={handleChange}
              value={values.Confirm_Password}
            />
            <span className="text-danger">
              {errors.Confirm_Password &&
                touched.Confirm_Password &&
                errors.Confirm_Password}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Email Address
            </label>
            <input
              type="text"
              name="Email"
              onChange={handleChange}
              className="form-control"
              value={values.Email}
            />
            <span className="text-danger">
              {errors.Email && touched.Email && errors.Email}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              First Name
            </label>
            <input
              type="text"
              onChange={handleChange}
              name="Firstname"
              maxLength="50"
              className="form-control"
              value={values.Firstname}
            />
            <span className="text-danger">
              {errors.Firstname && touched.Firstname && errors.Firstname}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Last Name
            </label>
            <input
              type="text"
              name="Lastname"
              maxLength="50"
              onChange={handleChange}
              className="form-control"
              value={values.Lastname}
            />
            <span className="text-danger">
              {errors.Lastname && touched.Lastname && errors.Lastname}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Phone
            </label>
            <input
              type="text"
              name="Phone"
              onChange={handleChange}
              className="form-control"
              value={values.Phone}
            />
            <span className="text-danger">
              {errors.Phone && touched.Phone && errors.Phone}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Company
            </label>
            <input
              type="text"
              name="Company"
              maxLength="70"
              onChange={handleChange}
              className="form-control"
              value={values.Company}
            />
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              value={values.agreed}
              type="checkbox"
              onChange={handleChange}
              id="agreed"
            />
            <label className="form-check-label pt-4 pl-3" for="inlineCheckbox1">
              I have read and agree to the Tynax Terms of Use and Tynax Privacy
              Policy, and to receive important communications from Tynax
              electronically.
            </label>
          </div>
          <span className="text-danger">
              {errors.agreed && touched.agreed && errors.agreed}
            </span>
          <div className="text-center py-2">
            <CustomButton isSubmitting={isSubmitting} btnText='Sign Up' /> 
          </div>
        </form>
      )}
    </Formik>
  );
};
export default Register;
