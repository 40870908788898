import config from "./config";
import notify from "./notify";
import { appUrl, apiUrl, apiBaseUrl, query, localUrl } from "./url";
import http from './http'
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import React from "react";
import { mainMenuTab } from './constants';
import { filter } from "lodash";

export {
  config,
  notify,
  http,
  appUrl,
  apiUrl,
  apiBaseUrl,
  localUrl,
  query,
};
export function hasKeys(obj) {
  if (!isEmpty(obj)) {
    return true;
  } else {
    return false;
  }
}
export function getErrorMessage(errorObj) {
  let firstKey = [];
  let errorMsg = "whoops.. something went wrong";
  if (
    hasKeys(errorObj) &&
    hasKeys(errorObj.response) &&
    hasKeys(errorObj.response.data) &&
    errorObj.response.data.errors
  ) {
    firstKey = Object.keys(errorObj.response.data.errors)[0];
    errorMsg = errorObj.response.data.errors[firstKey][0];
  }
  if (errorObj.message) {
    errorMsg = errorObj.message;
  }
  return errorMsg;
}
export function formatDate(date) {
  if (date === '0000-00-00' || date === undefined || date === null || date === "") {
    return "";
  } else {
    return moment(date).format("YYYY-MM-DD");
  }
}

export function formatTime(time) {
  if (time === undefined || time === null || time === "") {
    return "";
  } else {
    return moment(time).format("h:mm A");
  }
}

export function QueryStringToJSON(query) {
  if (!query) {
    return {};
  }
  const queryStrings = query.split("&");
  const queryJSON = {};
  queryStrings.forEach((queryString) => {
    queryString = queryString.split("=");
    queryJSON[queryString[0]] = decodeURIComponent(queryString[1] || "");
  });
  return JSON.parse(JSON.stringify(queryJSON));
}

export function mergeDateAndTime(date, time) {
  if (time === undefined || time === null || time === "") {
    return "";
  } else {
    return moment(time).format("h:mm A");
  }
}

export function getUtcFormat(value) {
  let date = value.format("YYYY-MM-DD");
  let time = value.format("HH:mm:ssZ");
  return date + "T" + time;
}

export function parseHtml(html) {
  return (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: html }} />{" "}
    </React.Fragment>
  );
}

export function getStandardDateForm() {
  let date = new Date();
  return moment(date).format();
}

export function  getStandardDateUnix(value){
  let date = moment.unix(value);
  date = new Date(date);
  return moment(date).format("YYYY-MM-DD");
}

export function dateFormat() {
  let date = new Date();  
  return moment(date).format("YYYY-MM-DD hh:mm:sss");
}

export function timeFormat(time, format = "h:mm A") {
  return moment(time).format(format);
}

export function getSubMenu(tab,userType) {
  userType = userType ? userType.toString() : 0;
  if (!isEmpty(mainMenuTab)) {
    let mainMenu = filter(mainMenuTab, function (obj) {
      return obj.mainTab === tab;
    });
    if(mainMenu.length>0 && mainMenu[0]){
      let subMenu ={...mainMenu[0].subMenus};
      let menu = filter(subMenu, function (sub) {
        return sub.allowedUserIds.includes(userType) === true;
      });
      return menu;
    }else{
      return {};
    }
  } else {
    return {};
  }
}

export function getMainMenuByUser(userType) {
  if (!isEmpty(mainMenuTab)) {
    let mainMenu = filter(mainMenuTab, function (obj) {
      return obj.allowedUserIds.includes(userType) === true ;
    });
    return mainMenu;
  } else {
    return {};
  }
}

export function getAccessToken() {
  let user = JSON.parse(window.localStorage.getItem('user'));
  let accessToken = user ? user.access_token :null;
  return accessToken;
}

export function getUser() {
  let user = localStorage.getItem('user');
  user = user ? JSON.parse(user) : null;
  return user;
}

export function getUserType(type) {
  if(type=== 1){
    return "Seller"
  }else if(type === 40){
    return "Admin"
  }else if(type === 15){
    return "Buyer"
  }else if(type === 30){
    return "Tynax Broker"
  }else{
    return "Not available"
  }
}

export function getListingType(type){
  if(type=== 0){
    return "Technology For"
  }else if(type === 1){
    return "Patent For"
  }else if(type === 2){
    return "Patent Portfolio For"
  }
}

export function getDealStructureString(dealStructure){
  if(dealStructure){
    if(dealStructure.indexOf("Sale")!== -1){
      return "Sale"
    }else if(dealStructure.indexOf("license")!== -1){
      return "License"
    }else if(dealStructure.indexOf("right to enforce")!== -1){
      return "License with Right to Enforce"
    }else{
      return "Others";
    }
  }else{
    return "Sale"
  }
  
}


export function getStarRating(rating){
  let star = 0;
  if(rating<=30){
  star = 1;
  }else if(rating>30 && rating<50){
  star = 2;
  }else if(rating>=50 && rating<70){
  star = 3;
  }else if(rating>=70 && rating<80){
  star = 4;
  }else if(rating>=80){
  star = 5;
  }
  let result = [];
  for(let i=0;i<star;i++){
    result.push(<i className="fa fa-star" aria-hidden="true"></i>);
  }
  return result;
}

export function getStar(rating){
  let result = [];
  for(let i=0;i<rating;i++){
    result.push(<i className="fa fa-star" aria-hidden="true"></i>);
  }
  return result;
}

export function getAge(dateString) {
  if(dateString==="0000-00-00"){
    return "";
  }
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  return age;
}

export function mergeString(str1="",str2=""){
  let MergedString = str1 ? `${str1}  ` : ' ';
  MergedString += str2 ? str2 : '';
  return MergedString;
}


export function getDecisionStatus(status){
  status = parseInt(status);
  if(status === 3){
    return "Pass"
  }else if(status === 4){
    return "Pursue"
  }else if(status === 5){
    return "Postpone"
  }else {
    return "Undecided"
  }
}

export function getStatus(status){
  status = parseInt(status);
  if(status === 1){
    return "Active"
  }else{
    return "Inactive"
  }
}

export function getUserRole(type) {
  if (type === 0){
    return "Guest"
  } else if (type === 1){
    return "User"
  } else {
    return "Not available"
  }
}

export function getCurrentDate(separator='-'){

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  
  return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
}

// export function getQueryParam(string) {
//   let path = location.pathname;
//   return path;
// }


// if ($intDealStructure == 1) {
//   $strDealStructure = "Sale";
//   } elseif ($intDealStructure == 2) {
//   $strDealStructure = "LicensestrDealStructure";
//   } elseif ($intDealStructure == 3) {
//   $strDealStructure = "License with Right to Enforce";
//   } else {
//   $strDealStructure = "Other";
//   }