import React, { useState }  from "react";

const Pagination = ({getTableContent, currentPage, lastPage, currentLimit}) => {

	const handleChange=(event)=>{
		getTableContent(1,event.target.value);
		setLimit(event.target.value);
	}
	const [limit, setLimit] = useState(currentLimit);

  return (
				<div className="py-3 text-center">
					<button disabled={currentPage ===  1 ? true : false} className="btn btn-sm btn-pagination mx-1 btn-secondary" onClick={()=>{getTableContent(1,limit)}}>
						<i className="fa fa-step-backward" aria-hidden="true"></i>
					</button>
					<button disabled={currentPage ===  1 ? true : false} className="btn btn-sm btn-pagination mx-1 btn-secondary" onClick={()=>{getTableContent(currentPage-1,limit)}}>
						<i className="fa fa-backward" aria-hidden="true"></i>
					</button>
					<span>Page {currentPage}</span><span> of {lastPage}</span>
					<button disabled={currentPage ===  lastPage ? true : false} className="btn btn-sm btn-pagination mx-1 btn-secondary" onClick={()=>{getTableContent(currentPage+1,limit)}}>
						<i className="fa fa-forward" aria-hidden="true"></i>
					</button>
					<button disabled={currentPage ===  lastPage ? true : false} className="btn btn-sm btn-pagination mx-1 btn-secondary" onClick={()=>{getTableContent(lastPage,limit)}}>
						<i className="fa fa-step-forward" aria-hidden="true"></i>
					</button>
					<select className='form-control form-control-sm w-auto mx-1 d-inline-block' value={limit} onChange={(e)=>{handleChange(e)}}>
						<option value="10">10</option>
						<option value="20">20</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</select>
				</div>
  );
};
export default Pagination;