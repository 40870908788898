import React, { useEffect, useState } from "react";
import { OrganizationDetails } from "../Components";
import { http, apiBaseUrl } from "../utitility";
import { useLocation } from 'react-router-dom';
import TableContact from "../Components/Contacts/TableContacts";
const OrganizationDetailsPage = () => {

	const  location = useLocation();
	const [organizationDetails,setOrganizationDetails] = useState({});
	const [organizatoinType,setOrganizationType] = useState([]);
	const [contactData,setContactData] = useState([]);
	const [orgTynaxLink,setOrgTynaxLink] = useState([]);
	const [tynaxPeople,setTynaxPeople] = useState([]);
	useEffect(() => {
			const params = new URLSearchParams(location.search);
			let org_id = params.get('org_id');
			http.get(apiBaseUrl(`/api/getOrganizationInfo?org_id=${org_id}`))
			.then((res) => {
				setOrganizationDetails(res.data.organizationDetails[0]);
				setContactData(res.data.contact_link);
				setOrgTynaxLink(res.data.org_tynax_link);
				setTynaxPeople(res.data.tynax_people);
			}).catch((error=>{
				console.log(error,'error')
			}));
			http.get(apiBaseUrl(`/api/getOrganizationsTypeList?org_id=${org_id}`))
			.then((res)=>{
				setOrganizationType(res.data)
			})
			.catch((error)=>{
				console.log(error)
			})
	},[]);

	return (
			<div className="container-fluid-center bg-white row">
				<div className="justify-content-center py-1 col-md-6">
					<OrganizationDetails organizationDetails={organizationDetails} organizatoinTypeDetails={organizatoinType} orgTynaxLink={orgTynaxLink} tynaxPeople={tynaxPeople}/>
				</div>
				<div className="justify-content-center py-1 col-md-6">
					<TableContact contactDetails={contactData}/>
				</div>
			</div>
	);
}

export default OrganizationDetailsPage;
