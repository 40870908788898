import React, {useEffect} from "react";
import { Row, Col } from "reactstrap";
import { useState } from "react";
import { http, apiBaseUrl,notify, hasKeys, getStandardDateForm  } from "../utitility"
import classnames from "classnames";
import { Link } from 'react-router-dom';
import { listingStatus } from '../utitility/constants';
import RecommendListing from "./RecommendListing"
import { confirmAlert } from 'react-confirm-alert'; 
import LinkContactButton from './LinkContactButton';
import EditTag from './EditTag';
import LoadingOverlay from "react-loading-overlay";
import CustomModal from "./Modal/CustomModal";
import ChangeDecisionAsAdmin from "./ChangeDecisionAsAdmin";


const ActionBox = ({ user, history, listings,getContent, getListingContact }) => {

	const [decisionStatus,setDecisionStatus] = useState(0);
	const [prev,setPreviousId] = useState(null);
	const [next,setNextId] = useState(null);
  const [isLoadOverLay,setLoadOverLay] = useState(false);
	const [isBrokerCodeOpen,setBrokerCodeOpen] = useState(false);

	useEffect(() => {
		loadDecisionStatus();
		getPreviousAndNextListing();
	},[listings]);

	const options = [
		{ value: '3', label: 'Pass' },
		{ value: '4', label: 'Pursue' },
		{ value: '5', label: 'Postpone' }
	]
	
	const toggle = () =>{
		setBrokerCodeOpen(!isBrokerCodeOpen);
	}
	
  const loadDecisionStatus =  () => {
		http.get(apiBaseUrl(`/api/listing_status?ListingID=${listings.ListingID}&BrokerCode=${user.BrokerID}`))
		.then((res) => {
			if(hasKeys(res.data) && hasKeys(res.data.data)){
				setDecisionStatus(res.data.data.DecisionStatus);
			}else{
				setDecisionStatus(0);
			}
		}).catch((error=>{
			console.log(error,'error')
		}))
	}

	const getPreviousAndNextListing = () => {
		http.get(apiBaseUrl(`/api/getPreviousAndNextListing?listingId=${listings.ListingID}&BrokerCode=${user.BrokerID}`))
		.then((res) => {
			setNextId(res.data.next);
			setPreviousId(res.data.prev);
		}).catch((error)=>{
			console.log(error);
		});
	}

	const ConfirmAlert = (value) => {
		confirmAlert({
			message: 'Do you really want to delete this note?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteListing(value)
				},
				{
					label: 'No',
					onClick: () => {}
				}
			]
		});
	}

	const deleteListing = (listing_id) => {
		setLoadOverLay(true);
		http.delete(apiBaseUrl(`/api/deleteListing?listing_id=${listing_id}`))
		.then(() => {
			setLoadOverLay(false);
			notify.success("Deleted successfully");
			if(hasKeys(user) && (user.UserType == 15 || user.UserType == 30)){
				history.push('/myaccount/myaccount_ta_all');
			}else{
				history.push('/myaccount/my_listing');
			}
		}).catch((error)=>{
			setLoadOverLay(false);
			let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
											error.response.data.message :
											'whoops...something went wrong';
			notify.error(errorMsg);
		});
	}


  const changeListingStatus=(value)=>{
		setLoadOverLay(true);
		let data ={};
		data.listing_id = listings.ListingID;
		data.AvailableStatus = value;
		data.UpdateDate = getStandardDateForm();
		http.post(apiBaseUrl(`/api/changeListingStatus`),data)
		.then(() => {
			setLoadOverLay(false);
			try{
				getContent(listings.ListingID);
				notify.success("Listing Status changed successfully");
				history.push('/listing_full_details?listing_id='+ listings.ListingID);
				window.location.reload();
			}catch(error){
				console.log(error.response)
			}
		}).catch((error)=>{
			setLoadOverLay(false);
			notify.error(error);
		});
	}

	const ChangeDecisionStatus=(value)=>{
		let data ={};
		setLoadOverLay(true);
		data.ListingID = listings.ListingID;
		data.ListingName = listings.ListingName;
		data.BrokerCode = user.BrokerID;
		data.OwnerID= listings.OwnerID
		data.DecisionStatus = value;
		data.DecisionMadeByID = user.UserID;
		data.LastUpdate = getStandardDateForm();
		http.post(apiBaseUrl(`/api/decision`),data)
		.then(() => {
			setLoadOverLay(false);
			loadDecisionStatus();
			notify.success("Decision Status changed successfully");
		}).catch((error)=>{
			setLoadOverLay(false);
			notify.error(error);
		});
  }

  return (
    <LoadingOverlay
          active={isLoadOverLay}
          // spinner={<BounceLoader />}
          spinner={isLoadOverLay}
        >
			<Row className="align-items-center">
				{user.UserType == '15' || user.UserType == '30'}
					<Col className="mb-3">
					<div className="span-console">
						{hasKeys(options) && 
								options.map((option,index) => (
									<div key={index} className="py-2">
										<button 
											className={classnames({
													// "btn btn-primary btn-block": decisionStatus == option.value,
													// "btn btn-secondary btn-block": decisionStatus != option.value
													"btn btn-passed btn-block": (option.value == 3 && decisionStatus == 3),
													"btn btn-pursued btn-block": (option.value == 4 && decisionStatus == 4),
													"btn btn-postponed btn-block": (option.value == 5 && decisionStatus == 5),
													"btn btn-pass btn-block": option.value == 3,
													"btn btn-pursue btn-block": option.value == 4,
													"btn btn-postpone btn-block": option.value == 5												
												})}
											onClick={()=>ChangeDecisionStatus(option.value)}
										>
											{option.label}
										</button>
									</div>
								))
							}
					</div>
					</Col>
			</Row>
			<Row className="align-items-center cols-3 py-2">
				<Col>
					<Link 
					className={classnames({
						"btn btn-secondary btn-block disabled": prev === null,
						"btn btn-primary btn-block": prev != null
					})}
					to={`/listing_full_details?listing_id=${prev}`}>
						<i class="fa fa-arrow-left" aria-hidden="true"></i>
					</Link>
					<center>NEXT</center>
					<Link 
					className={classnames({
						"btn btn-secondary btn-block disabled": next === null,
						"btn btn-primary btn-block": next != null
					})}
					to={`/listing_full_details?listing_id=${next}`}>
						<i class="fa fa-arrow-right" aria-hidden="true"></i>
					</Link>
				</Col>
			</Row>
			<Row className="align-items-center cols-3 px-4">
			{(user.UserType == '40') && 
				<div className="span-console px-3">
					<Col className="mb-3">
					<b className="text-primary"><u>Change Status</u></b>
						{(hasKeys(listingStatus) && user.UserType == '40') && 
								listingStatus.map((option,index) => (
									<div key={index}>
										<Link 
											className={classnames({
													"lnk-primary": listings.AvailableStatus == option.value,
													"": listings.AvailableStatus !== option.value
											})}
											onClick={()=>changeListingStatus(option.value)}
										>
											{option.label}
										</Link>
									</div>
								))
						}
					</Col>
					<Col className="mb-3">
						{(user.UserType == '40') && 
									<div className="py-2">
											<b className="text-primary"><u>Admin Actions</u></b><br/>
											<RecommendListing className="lnk-primary" listing={listings} listing_id={listings.ListingID} reloadTableData ={getContent}/><br/>
											<Link to={`/listings?listing_id=${listings.ListingID}`}>Edit</Link><br/>
											<EditTag listing={listings}/><br/>
											<Link onClick={()=>ConfirmAlert(listings.ListingID)}>
												{isLoadOverLay &&
													<i className="fa fa-spinner fa-spin"></i>
												}
												Delete
											</Link><br/>
											<LinkContactButton className="lnk-primary"  listing={listings} getListingContact={getListingContact}/>
									</div>
						}
					</Col>
				</div>
			}
			</Row>
			<Row>
				{isBrokerCodeOpen &&
					<CustomModal notice={true} modal={isBrokerCodeOpen}  toggle={toggle} closeAllModal={toggle} heading={"Change Decision Status"}  render={() => (  
						<React.Fragment>
							<ChangeDecisionAsAdmin asAdmin={true} listing={true} listings={listings} closeAllModal={toggle}  user={user} user_id={user.UserID}/>
						</React.Fragment>
					)}/>
				}
			</Row>
		</LoadingOverlay>
  );
};

export default ActionBox;
