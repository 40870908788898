import * as Yup from 'yup'
const charactersRegExp = /^[a-zA-Z ]*$/
const ContactSchema = Yup.object().shape({
    contactDetails: Yup.object().shape({
        Firstname: Yup.string().max(30,'Please enter first name within 30 characters').matches(charactersRegExp,'Plaese enter characters only').required('First name is required'),
        Lastname: Yup.string().max(30,'Please enter last name within 30 characters').matches(charactersRegExp,'Plaese enter characters only').required('Last name is required'),
        Nickname: Yup.string().max(30,'Please enter nick name within 30 characters').matches(charactersRegExp,'Plaese enter characters only'),
        Title: Yup.string().max(50,'Please enter job tiltle within 50 characters'),
        PrimaryEmail: Yup.string().email(),
        SecondaryEmail: Yup.string().email(),
        City: Yup.string().max(20,'Please enter city within 20 characters').matches(charactersRegExp, 'Invalid city'),
        State: Yup.string().max(20,'Please enter state within 20 characters').matches(charactersRegExp, 'Invalid state'),
    }),
   

});
export default ContactSchema;