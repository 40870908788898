import React, { useMemo, useRef, useEffect }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { formatDate, hasKeys, getDecisionStatus } from '../../utitility';
import { Link } from 'react-router-dom';
import CustomModal from "../Modal/CustomModal";
import ChangeDecision from '../ChangeDecision'
import { useState } from "react";
import ActionField from './ActionField';
import ChangeListingStatus from "../ChangeListingStatus";
import IsRecommended from "../IsRecommended";

const TechAvailableListing = ({user}) => {
		const [url,setUrl] = useState("");
		const [IsOpenDecisionModal,openDecisionModal] = useState(false);
		const childRef = useRef();
		const [selectedOption] = useState({});
    const [hiddenColumns,setHiddenColumns] = useState([]);

		useEffect(() => {
			if(hasKeys(user)){
				if(user.UserType == 40){
				 setHiddenColumns(['recommendedByTynax']);
				 setUrl(`myaccount_ta_all?BrokerCode=&`);
				}else if(user.UserType == 30){
					setHiddenColumns(['recommendedByTynax']);
					setUrl(`myaccount_ta_all?BrokerCode=&`);
				}else if(parseInt(user.UserType) == 15){
				 setHiddenColumns(['rating','Brokercode',"ListingType","tbl_user.BrokerID","tbl_user.Username",'CreateDate','StartDate'])
				 setUrl(`myaccount_ta_all?BrokerCode=${user.BrokerID}&AvailableStatus=1&`);
				}else{
					setHiddenColumns(['recommendedByTynax']);
				  setUrl();
				}
			}
	 },[user]);

	const getStarRating = (rating)=>{
			let star = 0;
			if(rating<=30){
			star = 1;
			}else if(rating>30 && rating<50){
			star = 2;
			}else if(rating>=50 && rating<70){
			star = 3;
			}else if(rating>=70 && rating<80){
			star = 4;
			}else if(rating>=80){
			star = 5;
			}
			let result = [];
			for(let i=0;i<star;i++){
				result.push(<i className="fa fa-star" aria-hidden="true"></i>);
			}
			return result;
	}

	const getDecisionStatusValue = (value) => {
		let status=[];
		if(hasKeys(value.tbl_listing_decision_statuses)){
			// let status = getDecisionStatus(value.tbl_listing_decision_statuses[0].DecisionStatus);
			value.tbl_listing_decision_statuses.map((option) => (
				status.push(<p>{option.BrokerCode + "-" + getDecisionStatus(option.DecisionStatus) }</p>)
			))
			return status;
		}
		else
		{
			return "";
		}
	}

	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

	const getActionField = (value) =>{
		return <ActionField user={user} listing={value} reloadTableData={reloadTableData}/>
	}

	const getStatus = (status,listing) => {
		return (<ChangeListingStatus status={status}  user={user} reloadTableData={() => reloadTableData()} listing={listing}/>)
	}

	const getTechnologyType = (value) =>{
     if(value === 0){
			 return 'Technology';
		 }else if(value === 1){
			return 'Patent';
		 }else if(value === 2){
			 return 'Patent PortFolio';
		 }else{
			 return "";
		 }
	}

	const toggleDecisionModal = () => {
		openDecisionModal(!IsOpenDecisionModal);
	}

	const getViewLink = (value) => {
     return <Link className="nav-link" to={`/listing_full_details?listing_id=${value}`}>{value}</Link>
	}
  

	const columns = useMemo(
		() => [
			{
				Header: "ID",
				accessor: "ListingID",
				width: 70,
				sort:true,
				Cell: data => {return getViewLink(data.cell.value)},
			},{
				Header: "Rating",
				accessor: "rating",
				sort:true,
				width: 60
			},,{
				Header: "Decision Status",
				accessor: "decision",
				Cell: data => {return getDecisionStatusValue(data.row.original)},
				width: 100,
				sort:true
			},{
				Header: "Star rating",
				accessor: "star_rating",
				width: 100,
				Cell: data => {return getStarRating(data.row.values.rating)},
				sort:true
			},
			{
				Header: "Recommended",
				accessor: "recommendedByTynax",
				width: 100,
				Cell: data => {return <IsRecommended user={user} value={data.cell.value} />},
				sort:true
			},
			{
				Header: "Source",
				accessor: "Brokercode",
				width: 70,
				sort:true
			},{
				Header: "Type",
				accessor: "ListingType",
				width: 70,
				Cell: data => {return getTechnologyType(data.cell.value)}
			},{
				Header: "Listing Name",
				accessor: "ListingName",
				width: 200,
				sort:true
			},{
				Header: "Broker ID",
				accessor: "tbl_user.BrokerID",
				width: 70
			},
			,{
				Header: "Owner",
				accessor: "tbl_user.Username",
				width: 70
			},{
				Header: "Status",
				accessor: "AvailableStatus",
				width: 106,
				Cell: data => {return getStatus(data.cell.value,data.row.original)}
			},{
				Header: "Create",
				accessor: "CreateDate",
				width: 110,
				Cell: row => (formatDate(row.cell.value)),
			},{
				Header: "Start",
				accessor: "StartDate",
				width: 110,
				Cell: row => (formatDate(row.cell.value)),
			},
			{
				Header: "Action",
				accessor: "act",
				Cell: data => (getActionField(data.row.original)),
				width: 400
			},
		],
		[]
	)

  return (
				<React.Fragment>
					<div>
						{hasKeys(user)&&
							<ReactGenericTable hiddenColumns={hiddenColumns} pagination={true} ref={childRef} url={url} defaultSortIndex="ListingID" defaultSortOrder="DESC" columns={columns} />
						}
						{IsOpenDecisionModal &&
							<CustomModal notice={true} modal={IsOpenDecisionModal}  toggle={toggleDecisionModal} closeAllModal={toggleDecisionModal} heading={"Change Decision Status"}  render={() => (  
								<React.Fragment>
									<ChangeDecision listing={true} closeAllModal={toggleDecisionModal} reloadTableData={() => reloadTableData()} CurrentDetail={selectedOption} user={user} />
								</React.Fragment>
							)}/>
						}
					</div>
				</React.Fragment>
	);
	
};
export default TechAvailableListing;