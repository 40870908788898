import React, { Component } from "react";
import { ForgotPassword,LoginUsingGoogle } from "../Components";

const ForgotPasswordPage = ({history}) => {
    return (
      <section className="">
        <div className="container-fluid py-3 bg-white">
          <div className="row justify-content-center py-5">
            <div className="col-xs-12 col-sm-6 col-lg-6">
              <div className="border-card p-3">
                <h3
                  className="font-weight-bold text-center mb-5"
                  style={{ color: "#2d78a0" }}
                >
                  Forgot Your Password?
                </h3>
                <ForgotPassword />
                <h6 className="text-muted text-center">Or</h6>
                <LoginUsingGoogle history={history}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
export default ForgotPasswordPage;
