
import React, { useMemo, useState, useRef }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { formatDate } from '../../utitility';
import Switch from '../Fields/Switch';
import 'react-confirm-alert/src/react-confirm-alert.css';

const PatentAnalysis = ({patentId,user}) => {
	
	const [count,setCount] = useState(0);
	const title = `ANALYSIS - ${count}`;

	const childRef = useRef();

	const columns = useMemo(
		() => [
			{
				Header: "Patent",
				accessor: "Patent",
				width: 100,
				sort:true
			},
			{
				Header: "Listing Id",
				accessor: "List",
				width: 100,
				sort:true		
			},{
				Header: "Country",
				accessor: "Country",
				width: 100
			},{
				Header: "QPS",
				accessor: "Qps",
				width: 100,
				sort:true
			},{
				Header: "Analysis Level Performed",
				accessor: "AnalysisLevelPerformed",
				width: 300,
				sort:true
			},{
				Header: "Recommentation",
				accessor: "Recommendation",
				width: 200,
				sort:true
			},{
				Header: "Analyst",
				accessor: "Analyst",
				width: 100,
				sort:true
			},{
				Header: "Reason",
				accessor: "Reason",
				width: 100,
				sort:true
			},{
				Header: "Created",
				accessor: "CreateDate",
				width: 100,
				sort:true,
				Cell: data => {return formatDate(data.cell.value)}
			}
		],
		[]
	)


	const isActive = (count > 0 ? true : false);
	return (
	<Switch
		classNames="icon icon-uploadAgreement-icon"
		title={title}
		className="grouped-body"
		id="ptn_analysis"
		collapsible={true}
		activeStatus={isActive}
	>
		<section className="mb-3">
			<React.Fragment>
				{patentId && 
					<ReactGenericTable  
						ref={childRef}
						setCount={setCount} 
						url={`getAnalysisByPatentId?Patent=${patentId}&user_id=${user.UserID}`} 
						defaultSortIndex="Patent" 
						defaultSortOrder="DESC" 
						columns={columns}
					/>
				}	
			</React.Fragment>
		</section>
	</Switch>
  );
};
export default PatentAnalysis;