import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
const AddTynaxContact = (props) => {
  const [contacts, setContact] = useState(null);
  const [selectedContact, setSelectedContact] = useState();
  const [selectedContactValue, setSelectedContactValue] = useState(null);
  const [tynaxContactType, setTynaxContactType] = useState(0);
  useEffect(() => {
    setContact(props.tynaxPeople.map((val)=>({
              value: val.PersonID,
              label: `${val.Firstname} ${val.Lastname} ${val.Active ? '' : '(InActive)'}`
    })))
  }, []);
  return (
    <Modal show={props.show} centered>
      <Modal.Header>
        <h5 className="font-weight-bold form-text">Add Tynax Contact</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label
            className="font-weight-bold form-text"
            htmlFor="exampleInputEmail1"
          >
            Tynax Contact:
          </label>
          <Select
            value={selectedContact}
            name="tynaxContact"
            options={contacts}
            onChange={(e) => setSelectedContactValue(e)}
          />
          <div className="mt-3 pl-2">
            <input
              type="radio"
              value={1}
              onChange={() => setTynaxContactType(0)}
              checked={!tynaxContactType}
            />
            <label className="pr-3 pl-1">Exclusive</label>
            <input
              type="radio"
              value={0}
              onChange={() => setTynaxContactType(1)}
              checked={tynaxContactType}
            />
            <label className="pl-1">Preferred</label>
          </div>
        </div>
        <div className="mt-5 text-right">
          <button
            disabled={!selectedContactValue}
            type="button"
            className="btn btn-primary btn-sm"
            onClick={()=>{props.handleAddTynaxContact(selectedContactValue,tynaxContactType)}}
          >
            Add
          </button>
          <button
            type="button"
            onClick={props.handleClose}
            className="ml-2 btn btn-secondary"
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddTynaxContact;
