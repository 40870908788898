import React,{useState,useEffect} from "react";
import { Profile, UserListing } from "../Components";
import { useLocation, Link } from 'react-router-dom';
import { hasKeys, getSubMenu, http, apiBaseUrl } from '../utitility';
import { BrokerListingPage } from ".";

const AdminPage = ({user}) => {
  const  location = useLocation();
  const pathName = location.pathname;
  const [subMenu,setSubMenu] = useState();
  const [userData,setUserData] = useState({});
  const [brokerData,setBrokerData] = useState({});

  useEffect(() => {
    let path = location.pathname;
    let splittedPath = path.split("/");
		let subMenu = getSubMenu(splittedPath[1],user.UserType);
    setSubMenu(subMenu);
    if(pathName=== '/admin/profile'){
      getUserDetails();
      getBrokerDetails();
    }
  },[]);

  function getBrokerDetails(){
    http.get(apiBaseUrl(`/api/broker`))
      .then((res) => {
         setBrokerData(res.data.data);
      })
      .catch((error) => {
      });
  }
  ///api/broker
  function getUserDetails(){
      const params = new URLSearchParams(location.search);
      let user_id = params.get('user_id');
      http.get(apiBaseUrl(`/api/user?userId=${user_id}`))
      .then((res) => {
         setUserData(res.data.data);
      })
      .catch((error) => {
      });
  }
  return (
    <div className="tab-content" id="myTabContent">
      <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        {pathName=== '/admin/users' && <UserListing user={user}/>}
        {pathName=== '/admin/brokerlist' && <BrokerListingPage user={user} brokerData={brokerData}/>}
        <section className="py-3 mb-5 ml-5 row">
          <div className="col-6">
            {pathName=== '/admin/profile' && <Profile asAdmin={true} brokerData={brokerData} user={userData}/>}
          </div>
        </section>
      </div>
    </div>
  );
}
export default AdminPage;
