import React,{useContext,useState,useEffect} from "react";
import CreateMailingList from "../Components/Contacts/CreateMailingList";
import { UserContext } from "../Context/UserContext"
import { useLocation } from "react-router-dom";
const CreateMailingListPage = ({history}) =>{
    const {user} = useContext(UserContext);
	const  location = useLocation();
    const [mailingListDetails, setMaiingListDetails] = useState({
        mailinglist_name: "",
        mailinglist_notes: "",
        active: 1,
        created_by: user.Firstname + ' ' + user.Lastname,
        modified_by: user.Firstname + ' ' + user.Lastname
    });
    const [initialState, setInitialState] = useState({mailingListDetails: mailingListDetails});
    const [mailListingId, setMailListingId] = useState(null);
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let mailListing_id = params.get('mailingList_id');
        setMailListingId(mailListing_id);
},[]);
    return(
        <div className="container-fluid bg-white">
			<div className="border-bg">
                <div className="px-2 mb-4 font-weight-bold form-text mt-3">
                    <h4 className="mb-3">Create New Mailing List</h4>
                    <h5>TYNAX CONTACTS CAN BE ADDED TO YOUR NEW MAILING LIST</h5>
                </div>
				<section className="ml-5 row">
					<div className="col-9">
						<CreateMailingList user={user} initialState={initialState} setInitialState={setInitialState} mailListing_id={mailListingId} history={history}  />
					</div>
				</section>
			</div>
		</div>
    )
}
export default CreateMailingListPage