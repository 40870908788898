import { Formik,Field } from "formik";
import React, { useEffect } from "react";
import { notify, http, apiBaseUrl, formatDate,getCurrentDate } from "../../utitility";
const CreateMailingList = ({user,initialState,setInitialState,history,mailListing_id}) =>{
    const redirectToListingPage = () => {
        history.goBack();
      };
    const clearFormFields = (resetForm) => {
        resetForm({});
    };
    useEffect(()=>{
        if(mailListing_id){
           http.get(apiBaseUrl(`/api/getMailListingInfo?mailingList_id=${mailListing_id}`))
           .then((res) =>{
            let initialStateNew = {...initialState};
            initialStateNew.mailingListDetails = res.data[0];
            setInitialState(initialStateNew);
           })
        }
    }, [mailListing_id])
    return(
        <Formik
            enableReinitialize
            initialValues={initialState}
            onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
                setSubmitting(true);
                let data = { ...values };
                if (!mailListing_id) {
                    http
                      .post(apiBaseUrl(`/api/CreateMailingList`), data)
                      .then((res) => {
                        setSubmitting(false);
                        notify.success("Mailing list created successfully");
                        clearFormFields(resetForm, setFieldValue);
                        history.push('/contacts/mailing_lists')
                      })
                      .catch((error) => {
                        setSubmitting(false);
                        notify.error(error);
                      });
                  } else {
                    http
                      .post(apiBaseUrl(`/api/CreateMailingList?mailingList_id=${mailListing_id}`), data)
                      .then(() => {
                        setSubmitting(false);
                        clearFormFields(resetForm, setFieldValue);
                        notify.success("Mailing list updated successfully");
                        history.push('/contacts/mailing_lists')
                      })
                      .catch((error) => {
                        setSubmitting(false);
                        notify.error(error);
                      });
                  }
            }}
        >
              {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm,
      }) => (
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
                        Name (of Mailing List):
                    </label>
                    <Field type="text" name="mailingListDetails.mailinglist_name" className="form-control"  />
                </div>
                <div className="form-group">
                    <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
                    Notes:
                    </label>
                    <Field type="text" name="mailingListDetails.mailinglist_notes" component="textarea" className="form-control"  />
                </div>
                <div className="text-center py-2">
            <button
              disabled={!values.mailingListDetails.mailinglist_name || !values.mailingListDetails.mailinglist_notes}
              type="submit"
              className="buttonload btn btn-primary btn-sm"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={(e) => 
                redirectToListingPage()
              }
              className="ml-2 btn btn-secondary"
            >
              Cancel
            </button>
          </div>
            </form>
      )}
        </Formik>
    )
}
export default CreateMailingList