import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import queryString from 'query-string';

const RegistrationVerificationPage = () => {
    const  location = useLocation();
    let params = queryString.parse(location.search)
    const [verificationStatus, setVerificationStatus] = useState(-2);
    const [userName,setUserName] = useState("");
    useEffect(() => {
      if(params && params.verify){
      //http.post(apiBaseUrl(`/api/email_verification`,{ token: params.verify }))
      axios
      .post(`/api/email_verification`, { token: params.verify })
      .then((res) => {
        if (res.data) {
          setVerificationStatus(res.data.status);
          setUserName(res.data.UserName);
        } else {
          setVerificationStatus(res.data.status);
          setUserName(res.data.UserName);
        }
       }).catch((error)=>{
         console.log(error)
       });
      }      
    },[]);
    return (
      <section className="">
        <div className="container-fluid py-3 bg-white">
          <div className="row justify-content-center py-5">
            <div className="col-xs-12 col-sm-6 col-lg-6">
              <div className="border-card p-3">
                <h3
                  className="font-weight-bold text-center mb-4"
                  style={{ color: "#2d78a0" }}
                >
                 {( verificationStatus==1 || verificationStatus==2 ) && "Congratulations!"}
                 {( verificationStatus==0 ) && "Failed"}
                </h3>
                {verificationStatus===0 && 
                <React.Fragment>
                 Failed!
                    Email verification is failed for Tynax.com.
                </React.Fragment>
                }
                {(verificationStatus===1 || verificationStatus===2)&& 
                  <div  className="ml-5" style={{ textAlign: "justify" }}>
                    <p className="ml-5">
                      <h5>Dear {userName},<br/></h5>
                      Welcome to the Tynax Patent Exchange.<br/>

                      Thank you for verifying email for Tynax.com.<br/>

                      Please make a note of your Tynax account login information; you will need this to login to your account.<br/>

                      <b>Web URL</b>: http://www.tynax.com/<br/>

                      <b>User name</b>: {userName}<br/>
                      Enjoy.
                    </p>
                    
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
export default RegistrationVerificationPage;
