import React, { useMemo, useState, useRef, useEffect }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { formatDate, getDecisionStatus, hasKeys} from '../../utitility';
import { Link } from 'react-router-dom';
import ActionField from '../Listing/ActionField';
import ChangeListingStatus from "../ChangeListingStatus";
import IsRecommended from '../IsRecommended'

const PursueListings = ({user}) => {

		const childRef = useRef();
		const [url,setUrl] = useState("");
    const [hiddenColumns,setHiddenColumn] = useState([]);
		useEffect(() => {
			if(hasKeys(user)){
				if(user.UserType==40){
					setHiddenColumn(['recommendedByTynax'])
					setUrl(`pursue_request?AvailableStatus=1&`)	;
				}else{
					if(user.UserType!=15){
						setHiddenColumn(['recommendedByTynax'])
					}
					setUrl(`pursue_request?AvailableStatus=1&BrokerCode=${user.BrokerID}&`)	;
				}
			}
		},[user]);

		// const getDecisionStatusValue = (value) => {
		// 	if(hasKeys(value.tbl_listing_decision_statuses)){
		// 		let status = getDecisionStatus(value.tbl_listing_decision_statuses[0].DecisionStatus);
		// 		return status;
		// 	}else{
		// 		return  "";
		// 	}
		// }

		const getDecisionStatusValue = (value) => {
			if(hasKeys(value)){
				let status = getDecisionStatus(value);
				return status;
			}else{
				return  "";
			}
		}

		const getStarRating = (rating)=>{
			let star = 0;
			if(rating<=30){
			star = 1;
			}else if(rating>30 && rating<50){
			star = 2;
			}else if(rating>=50 && rating<70){
			star = 3;
			}else if(rating>=70 && rating<80){
			star = 4;
			}else if(rating>=80){
			star = 5;
			}
			let result = [];
			for(let i=0;i<star;i++){
				result.push(<i className="fa fa-star" aria-hidden="true"></i>);
			}
			return result;
	}

	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

	const getActionField = (value) =>{
		return <ActionField user={user} listing={value} reloadTableData={()=>reloadTableData()}/>
	}

	const getViewLink = (value) => {
     return <Link className="nav-link" to={`/listing_full_details?listing_id=${value}`}>{value}</Link>
	}

	const getStatus = (status,listing) => {
		return (<ChangeListingStatus status={status}  user={user} reloadTableData={() => reloadTableData()} listing={listing}/>)
	}

	const columns = useMemo(
		() => [
			{
				Header: "ID",
				accessor: "ListingID",
				width: 100,
				sort:true,
				Cell: data => {return getViewLink(data.cell.value)},
			},{
				Header: "Buyer Request",
				accessor: "decisionMadeByUser",
				width: 150
			},{
				Header: "Rating",
				accessor: "rating",
				width: 70
			},{
				Header: "Star rating",
				accessor: "star_rating",
				width: 100,
				Cell: data => {return getStarRating(data.row.values.rating)},
				sort:true
			},{
				Header: "Recommended",
				accessor: "recommendedByTynax",
				width: 150,
				Cell: data => {return <IsRecommended user={user} value={data.cell.value} />},
				sort:true
			},{
				Header: "Listing Name",
				accessor: "ListingName",
				width: 300,
				sort:true
			},{
				Header: "Decision Status",
				accessor: "DecisionStatus",
				Cell: data => {return getDecisionStatusValue(data.cell.value)},
				width: 150
			},{
				Header: "Status",
				accessor: "AvailableStatus",
				width: 106,
				Cell: row => (getStatus(row.cell.value,row.row.original)),
			},{
				Header: "Create",
				accessor: "CreateDate",
				width: 110,
				Cell: row => (formatDate(row.cell.value)),
			},{
				Header: "Action",
				accessor: "act",
				width: 400,
				Cell: data => (getActionField(data.row.original)),
			}
		],
		[]
	)


  return (
				<React.Fragment>
					<div>
						{hasKeys(user)&&url&&
						<ReactGenericTable  hiddenColumns={hiddenColumns} ref={childRef} pagination={true} url= {url} defaultSortIndex="ListingID" defaultSortOrder="DESC" columns={columns} />}
					</div>
				</React.Fragment>
  );
};
export default PursueListings;