import React, {useEffect} from "react";
import { Row, Col } from "reactstrap";
import Select from 'react-select'
import { useState } from "react";
import { filter , isEmpty} from "lodash";
import { http, apiBaseUrl,notify, getStandardDateForm } from "../utitility"
import classnames from "classnames";

const ChangeDecision = ({ user, patent, listing,listings, CurrentDetail, reloadTableData, closeAllModal }) => {

	useEffect(() => {
    if(listing){
			if(CurrentDetail.tbl_listing_decision_statuses && CurrentDetail.tbl_listing_decision_statuses.length >0){
				let Decision = CurrentDetail.tbl_listing_decision_statuses[0];
				let data = getDecisionStatus(Decision.DecisionStatus);
				if(data && data[0]){
					setSelectedOption(data[0])
				}
			}
		}else if(patent){

		}
	},[CurrentDetail]);

	const [selectedOption,setSelectedOption] = useState({ value: 3, label: 'Pass' });
	const [isSubmitting,setIsSubmitting] = useState(false);
	const options = [
	//	{ value: '0', label: 'Undecided' },
		{ value: '3', label: 'Pass' },
		{ value: '4', label: 'Pursue' },
		{ value: '5', label: 'Postpone' }
	]

	const handleChange=(value)=>{
		setSelectedOption(value);
	}
	
	const changePatentDecision=()=>{
		let data ={};

		setIsSubmitting(true);
		data.PatentNumber = CurrentDetail.PatentNumber;
		data.BrokerCode = user.BrokerID;
		data.DecisionStatus = selectedOption.value;
    data.UserID =user.UserID;
		http.post(apiBaseUrl(`/api/setPatentDecision`),data)
		.then(() => {
			reloadTableData();
			closeAllModal();
			setIsSubmitting(false);
			notify.success("Decision Status changed successfully");
		}).catch((error)=>{
			setIsSubmitting(false);
			notify.error(error);
		});
		
	}

  const changeListingDecision=(value)=>{
		let data ={};
		setIsSubmitting(true);
		data.ListingID = listings.ListingID;
		data.ListingName = listings.ListingName;
		data.BrokerCode = user.BrokerID;
		data.OwnerID= listings.OwnerID
		data.DecisionStatus = value;
		data.DecisionMadeByID = user.UserID;
		data.LastUpdate = getStandardDateForm();
		http.post(apiBaseUrl(`/api/decision`),data)
		.then(() => {
			reloadTableData();
			closeAllModal();
			setIsSubmitting(false);
			notify.success("Decision Status changed successfully");
		}).catch((error)=>{
			setIsSubmitting(false);
			notify.error(error);
		});
	}

	const changeDecision=(selectedOption)=>{
		if(patent){
			changePatentDecision();
		}else if(listing){
			changeListingDecision(selectedOption.value);
		}
	}

  const getDecisionStatus=(status)=> {
		if (!isEmpty(options)) {
		return filter(options, function(o) {
				if (o.value === status) return o;
		});
		} else {
			return {};
		}
	}

  return (
    <div>
			<Row className="justify-content-center">
				<Col className="mb-3 col-6">
					<Select value={selectedOption} options={options} onChange={handleChange} />
				</Col>
			</Row>
			<div className="text-center">
				<button 
					disabled={isSubmitting} 
					type="button"
					className={classnames({
						"buttonload btn btn-primary btn-sm": isSubmitting === true,
						"btn btn-primary btn-sm": isSubmitting === false,
					})}
					onClick={()=>changeDecision(selectedOption)}
				>
				{isSubmitting &&
					<i className="fa fa-spinner fa-spin"></i>
				}
				Save
				</button>
			</div>
		</div>
  );
};

export default ChangeDecision;
