import React, { useEffect, useState } from "react";
import { notify, http, apiBaseUrl } from "../../utitility";
import { Formik } from "formik";
import classnames from "classnames";
import Select from 'react-select'
import { Link } from 'react-router-dom';

const CreatePatentForm = ({ user, history}) => {
	const [isDisabled,setIsDisabled] = useState(false);
  const [selectedCountry,setselectedCountry] = useState({ value: 'US', label: 'United States' });
  const [selectedStatus,setselectedStatus] = useState({ value: 'Issued', label: 'Issued' });
  const [countryOptions,setcountryOptions] = useState([]);

  const options = [
		{ value: 'Issued', label: 'Issued' },
		{ value: 'Application', label: 'Application' }
  ]
  
  useEffect(() => {
    if(countryOptions.length === 0){
      http.get(apiBaseUrl(`/api/getCountry`))
      .then((res) => {
        setcountryOptions(res.data.data);
      }).catch((err)=>{
        console.log(err);
      })
    }
	});

  const handleStatusChange=(option, setFieldValue)=>{
    console.log(setFieldValue);
    setselectedStatus(option);
    // setFieldValue('Status',option.value)
  }

  const handleCountryChange=(option, setFieldValue)=>{
    console.log(option);
    setselectedCountry(option);
    // setFieldValue('Country',option.value)
  }

  return (
    <Formik
      initialValues={{
        PatentNumber: "",
        Country:'US',
        PatentStatus:'Issued'
      }}
      validate={(values) => {
        const errors = {};
        if (!values.PatentNumber) {
          errors.PatentNumber="Patent Number is Required";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          setIsDisabled(true);
          let data = {...values};
          console.log('Values',data);
          http.post(apiBaseUrl(`/api/createPatent`),data)
          .then((res) => {
            console.log(res);
            setIsDisabled(false);
            notify.success(res.data.message);
            history.push(`/myaccount/patent`);
          }).catch((error)=>{
            setIsDisabled(false);
            notify.error(error);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="py-3 px-6 mb-6 ml-6 mr-2">
            <div className="form-group">
              <label className="font-weight-bold form-text">
                Patent Number
              </label>
              <input
                type="text"
                name="PatentNumber"
                className="form-control"
                onChange={handleChange}
                value={values.PatentNumber}
              />
              <span className="text-danger">
                {errors.PatentNumber && touched.PatentNumber && errors.PatentNumber}
              </span>
            </div>
            <div className="form-group">
              <label className="font-weight-bold form-text">
                Country
              </label>
              <Select value={selectedCountry} name="Country" options={countryOptions} onChange={(e)=>handleCountryChange(e,setFieldValue)} />
            </div>
            <div className="form-group">
              <label className="font-weight-bold form-text">
              Status
              </label>
              <Select value={selectedStatus} name="PatentStatus" options={options} onChange={(e)=>handleStatusChange(e,setFieldValue)} />
            </div>
            <div className="text-center py-2">
              <button 
                    disabled={isDisabled} 
                    type="submit" 
                    className={classnames({
                      "buttonload btn btn-primary btn-sm": isDisabled === true,
                      "btn btn-primary btn-sm": isDisabled === false,
                    })}>
                      Submit
              </button>
              <Link to='/myaccount/patent' className="ml-2 btn btn-secondary">Cancel</Link>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default CreatePatentForm;
