import React from "react";

const arrTechAvailableFieldList = [
	{id:1,field:"TechnologyOverview",label:"Overview"},
	{id:2,field:"ListingImg",label:""},
	{id:3,field:"TechnologyProblemSolvedbyTechnology",label:"The Problem Solved by the Technology"},
	{id:4,field:"TechnologyHowtheTechnologySolvestheProblem",label:"How the Technology Solves the Problem"},
	{id:5,field:"TechnologyPrimaryApplication",label:"Primary Application of the Technology"},
	{id:6,field:"TechnologyOtherApplications",label:"Other Potential Applications"},
	{id:7,field:"TechnologyCompetitiveAdvantage",label:"Competitive Advantage"},
	{id:8,field:"TechnologyDeployment",label:"Deployment"},
	{id:9,field:"TechnologyDevelopmentPlatform",label:"Development Platform"},
	{id:10,field:"TechnologyDevelopmentStatusNotes",label:"Notes on Development Status"},
	{id:11,field:"TechnologyPlatformsSupported",label:"Platforms Supported"},
	{id:12,field:"BizSalesSalesStatusNotes",label:"Notes on Sales Status"},
	{id:13,field:"DealStructureNotes",label:"Comments on Deal Structure, Potential Terms and Restrictions"},
	{id:14,field:"FAQ",label:"Frequently Asked Questions"},
	{id:15,field:"TechnologyAdditionalInfo",label:"Additional Information"}
]
const arrPatentFieldList = [
	{id:1,field:"TechnologyOverview",label:"Overview"},
	{id:2,field:"ListingImg",label:""},
	{id:3,field:"TechnologyPrimaryApplication",label:"Primary Application of the Technology"},
	{id:4,field:"TechnologyOtherApplications",label:"Other Potential Applications"},
	{id:5,field:"TechnologyProblemSolvedbyTechnology",label:"The Problem Solved by the Technology"},
	{id:6,field:"TechnologyHowtheTechnologySolvestheProblem",label:"How the Technology Solves the Problem"},
	{id:7,field:"TechnologyCompetitiveAdvantage",label:'Competitive Advantage'},
	{id:8,field:"DealStructureNotes",label:"Comments on Deal Structure"},
	{id:9,field:"PatentBackLicense",label:"Back License"},
	{id:10,field:"PatentSellIndividualPatent",label:"Sell Individual Patent"},
	{id:11,field:"FAQ",label:"Frequently Asked Questions"},
	{id:12,field:"TechnologyAdditionalInfo",label:"Additional Information"},
	{id:13,field:"TechnologyPatentSummary",label:"Patent Summary"}
]
const arrYesNoUnknown = ['UnKnown','No','Yes'];
const getYesNoStatus=(status)=>{
	  status = status ? status : 0;
	  return arrYesNoUnknown[status];
  }
const ListingDetail = ({listings, confidential_details}) => {
  return (
				<div className="">
					{/* <h3 className="text-primary mb-2">{getListingType(listings.ListingType)}  {getDealStructureString(arrDealStructurePatentObj[listings.DealStructure])}:</h3> */}
					<h5 className="text-primary mb-2">{listings.ListingName}</h5>
						<ul className="navbar-nav">
							<li>
							{listings.ListingDescription}
							</li>
							{listings.ListingType == 0 &&  arrTechAvailableFieldList && confidential_details ?
								arrPatentFieldList.map((patent, index) => (
									<li className="nav-item mr-2" key={index}>
										{confidential_details[patent['field']] ?
										<div>
											<br></br>
											<h4>
												{patent['label']}
											</h4>
											{confidential_details[patent['field']]}
										</div>:""
										}
									</li>
								)):""}
							{listings.ListingType != 0 && arrPatentFieldList && confidential_details ?
							 arrPatentFieldList.map((patent, index) => (
								<li className="nav-item mr-2" key={index}>
									{confidential_details[patent['field']] ?
									<div>
											<br></br>
										{patent['field'] == "PatentBackLicense" || patent['field'] == "PatentSellIndividualPatent"
											? ""
											:
											<h4>
												{patent['label']}
											</h4>
										}
										{patent['field'] == "PatentBackLicense" || patent['field'] == "PatentSellIndividualPatent"
      										 	? ""
												: confidential_details[patent['field']]}
									</div>:""
									}
								</li>
							)):""}
						</ul>
				</div>
    );
}

export default ListingDetail;
