import React, { useState,useEffect } from "react";
import { hasKeys, formatDate } from '../../utitility';

const OrganizationInfo = ({organization_link}) => {
  console.log(organization_link,'organization_link')
  const getSellingPolicy = (policy) => {
		if(policy == 0 ){
			return "Unknown"
		}else if(policy == 1){
			return "Will Not Sell"
		}else if(policy == 2){
      return "Perhaps Sell"
		}else if(policy == 3){
			return "Will Sell"
		}
	}

	const getPatentSellingTarget = (target) =>{
			if(target == 0 ){
				return "Any Buyer"
			}else if(target == 1){
				return "No NPE"
			}else if(target == 2){
				return "No Competitors"
			}else if(target == 3){
				return "No NPE & Competitors"
			}
	}

	const getUnderNDA = (nda) =>{
		if(nda == 2){
      return "Unknown" ;
		}else if (nda == 1) {
			return "Yes" ;
		}else {
     return "No" ;
		}
	}

	return (
		<div class="col-12 col-lg-6">
			<h5 class="text-primary mt-4 mb-2">Organization:</h5>
		 {hasKeys(organization_link) ?
			organization_link.map((Organization,index)=>{
				return(
					<React.Fragment>
						<div class="border-card p-3 mb-3">
								<div class="d-flex justify-content-between">
										<h6 class="text-dark my-2">{Organization.OrgName}</h6>
										<div class="d-flex ">
												<a href={`/contacts/org_info?org_id=${Organization.OrgId}`} class="text-primary mr-2">Detail</a>
												{/* <a href="#" class="text-primary">Edit</a> */}
										</div>
								</div>
								<a href="www.deminor.com" class="text-primary mb-3">{Organization.Website}</a>
								<h6 class="text-primary">Patent Selling Policy: <span class="text-muted ml-3">{getSellingPolicy(Organization.PatentSellingPolicy)} | {getPatentSellingTarget(Organization.PatentSellingTarget)}</span></h6>
								<h6 class="text-primary">Active Patent Buyer:<span class="text-muted ml-3">{Organization.ActivePatentBuyer ? Organization.ActivePatentBuyer : "N/A" }</span></h6>
								<h6 class="text-primary">Under NDA:<span class="text-muted ml-3">{getUnderNDA(Organization.Under_NDA)}</span></h6>
								<h6 class="text-primary">Tynax Relationship:<span class="text-muted ml-3">{Organization.TynaxRelationship ? 'Yes' : 'No'}</span></h6>
						</div>
				</React.Fragment>	
				)
			}):
				<div class="border-card p-3 mb-3">
					<div class="d-flex justify-content-between">
						No Data Found
					</div>
				</div>
			}
		</div>
    );
}

export default OrganizationInfo;
