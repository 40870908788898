import React,{useMemo,useRef} from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { Link,useLocation } from "react-router-dom";
import { http, apiBaseUrl, notify, mergeString} from '../../utitility';
import { confirmAlert } from 'react-confirm-alert'; 
const MailingListDetails = ({mailListing_id}) =>{
    const childRef = useRef();
	const  location = useLocation();

	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}
    const getViewLink = (contact_id) => {
    	return(<Link to={`/contacts/contact_info?contact_id=${contact_id}`}>{contact_id}</Link>);
	}
    const getActionField = (contact_id) => {
		return( 
			<div>
				<span onClick={()=>ConfirmAlert(contact_id)} className="mr-2 table_font_size btn-link link-pointer">Remove</span>
			</div>
		)
	}

	const ConfirmAlert = (value) => {
		confirmAlert({
			message: 'Do you really want to remove this contact?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => removeContact(value)
				},
				{
					label: 'No',
					onClick: () => {}
				}
			]
		});
	}

	const removeContact = (contact_id) => {
        const params = new URLSearchParams(location.search);
        let mailList_id = params.get('mailingList_id');
		http.delete(apiBaseUrl(`/api/removeContact?contact_id=${contact_id}&mailListId=${mailList_id}`))
		.then(() => {
			reloadTableData();
			notify.success("Remove successfully");
		}).catch((error)=>{
			let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
											error.response.data.message :
											'whoops...something went wrong';
			notify.error(errorMsg);
		});
	}
    const columns = useMemo(
		() => [
			{
				Header: "Name",
				accessor: "Firstname",
				width: 300,
				sort:true,
                Cell: data => {return (<Link to={`/contacts/contact_info?contact_id=${data.row.original.ContactId}`}>{mergeString(data.row.original.Firstname,data.row.original.Lastname)}</Link>)}
			},
			{
				Header: "Email",
				accessor: "PrimaryEmail",
				width: 130,
				sort:true,
				Cell: data => {return (<a href={`mailto:${data.row.original.PrimaryEmail}`}>{data.row.original.PrimaryEmail}</a>)}
			},
			{
				Header: " Email verified status",
				accessor: "EmailVerifyStatus",
				width: 130,
				sort:true
			},
            {
				Header: "Job title",
				accessor: "Title",
				width: 130,
				sort:true
			},
            {
				Header: "Organization",
				accessor: "OrgList",
				width: 130,
				sort:true,
                Cell: data => {return (<div dangerouslySetInnerHTML={{ __html: data.row.original.OrgList}}></div>)}
			},
            {
				Header: "Send Patents",
				accessor: "OkSendPatentNo",
				width: 130,
				sort:true,
                Cell: data => {return (data.row.original.OkSendPatentNo?'Yes':'No')}
			},
            {
				Header: "Notes",
				accessor: "mailing_notes",
				width: 130,
				sort:true
			},
			{
				Header: "Action",
				accessor: "act",
				Cell: data => (getActionField(data.row.original.ContactId)),
				width: 130
			}
		],
		[]
	)
    return(
       <React.Fragment>
					<div>
						<ReactGenericTable ref={childRef}  pagination={true} url={`getContactListByMailingId?mailingList_id=${mailListing_id}&`} defaultSortIndex="ContactId" defaultSortOrder="DESC" columns={columns} />
					</div>
       </React.Fragment>
    )
}
export default MailingListDetails;