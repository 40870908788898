import React, { useEffect, useState } from "react";
import { notify, http, apiBaseUrl } from "../../utitility";
import { Formik } from "formik";
import CustomButton from './CustomButton';
import Select from 'react-select'
import { isEmpty} from "lodash";


const CreatePatentListingForm = ({ user, listings, reloadTableData, history }) => {
  const [selectedCountry,setselectedCountry] = useState({ value: 'US', label: 'United States' });
  const [selectedStatus,setselectedStatus] = useState({ value: 'Issued', label: 'Issued' });
  const [countryOptions,setcountryOptions] = useState([]);

  const options = [
		{ value: 'Issued', label: 'Issued' },
		{ value: 'Application', label: 'Application' }
  ]
  
  useEffect(() => {
    if(countryOptions.length === 0){
      http.get(apiBaseUrl(`/api/getCountry`))
      .then((res) => {
        setcountryOptions(res.data.data);
      }).catch((err)=>{
        console.log(err);
      })
    }
	});

		
  const clearFormFields=(resetForm)=>{
    resetForm({});
  }

  const redirectToListingPage=()=>{
    history.goBack();
  }
  

  const handleStatusChange=(option, setFieldValue)=>{
    setselectedStatus(option);
  }

  const handleCountryChange=(option, setFieldValue)=>{
    setselectedCountry(option);
  }

  return (
    <Formik
    enableReinitialize
    initialValues={{
      PatentNumber:''
    }}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
        if (values.PatentNumber) {
          let listing_Patent =[];
          let listingPatents = {
            patentNumber: values.PatentNumber,
            patentCountry: selectedCountry.value,
            patentStatus : selectedStatus.value,
          };
          listing_Patent.push(listingPatents);
          let data = {listingPatent:listing_Patent};
            http.post(apiBaseUrl(`/api/createListingPatent?listing_id=${listings.ListingID}`),data)
            .then((res) => {
              setSubmitting(false); 
              clearFormFields(resetForm,setFieldValue);     
              reloadTableData();
              if(!isEmpty(res.data.data)) {
                notify.success("Patent added successfully");
              } else {
                notify.error("Patent number already added to this listing");
              }
                
            }).catch((error)=>{
              notify.error(error);
            });
        }
        else {
          setSubmitting(false);
          notify.error("Patent Number is Required");
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Add Patent
            </label>
          </div>
          <div className="form-group" style={{ border: "1px solid #ced4da", borderRadius: "5px"}}>
            <div className="form-group col-sm-12 col-md-4 col" style={{ float: "left"}}>
              <label className="font-weight-bold form-text" >
                Patent Number
              </label>
              <input
                type="text"
                name="PatentNumber"
                className="form-control"
                onChange={handleChange}
                value={values.PatentNumber}
                placeholder="Patent Number"
              />
            </div>
            <div className="form-group col-sm-12 col-md-4 col" style={{ float: "left"}}>
              <label className="font-weight-bold form-text">
                Country
              </label>
              <Select value={selectedCountry} name="Country" options={countryOptions} onChange={(e)=>handleCountryChange(e,setFieldValue)} />
            </div>
            <div className="form-group col-sm-12 col-md-4 col" style={{ float: "left"}}>
              <label className="font-weight-bold form-text">
              Status
              </label>
              <Select value={selectedStatus} name="PatentStatus" options={options} onChange={(e)=>handleStatusChange(e,setFieldValue)} />
            </div>
            <div className="text-center py-2">
              <CustomButton isSubmitting={isSubmitting} btnText='Add Patent' /> 
              {/* <span className="btn btn-primary btn-sm" onClick={()=>onAddPatent(values)}> Add Patent </span>
              <Link to='/myaccount/patent' className="ml-2 btn btn-secondary">Cancel</Link> */}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default CreatePatentListingForm;
