import React from "react";
import { PursueListings } from "../Components";

const MyAccountPage = ({user}) => {

  return (
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <PursueListings user={user}/>
              {/* {pathName==="/pursue/patents"&&<PursuePatents user={user}/>} */}
            </div>
          </div>
  );
}
export default MyAccountPage;
