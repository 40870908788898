import React, { useMemo, useRef }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import ActionField from '../Listing/ActionField';
import { formatDate, mergeString, getUserType, getUserRole } from '../../utitility';
import { Link } from 'react-router-dom';
import { CreateUserForm } from "../Forms";

const UserListing = ({user}) => {

	const childRef = useRef();

	const getViewLink = (value) => {
    	return <Link className="nav-link py-0" to={`/admin/profile?user_id=${value}&user_type=${user.UserType}`}>{value}</Link>
	}

	const getActionField = (value) =>{
		return <ActionField user={user} listing={value} reloadTableData={()=>reloadTableData()}/>
	}

	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

	const columns = useMemo(
		() => [
			{
				Header: "ID",
				accessor: "UserID",
				width: 70,
				sort:true,
				Cell: data => {return getViewLink(data.cell.value)},
			},{
				Header: "Type",
				accessor: "UserType",
				width: 100,
				Cell: data => {return getUserType(data.cell.value)},
			},{
				Header: "User Name",
				accessor: "Username",
				width: 160,
				sort:true
			},{
				Header: "Full Name",
				accessor: "Firstname",
				width: 200,
				Cell: data => {return mergeString(data.row.original.Firstname,data.row.original.Lastname)},
				sort:true
			},{
				Header: "Broker Id",
				accessor: "BrokerID",
				width: 80,
			},{
				Header: "Email",
				accessor: "Email",
				width: 240,
			},{
				Header: "Phone",
				accessor: "Phone",
				width: 110,
			},{
				Header: "Company",
				accessor: "Company",
				width: 150,
			},{
				Header: "Role",
				accessor: "UserRole",
				Cell: data => {return getUserRole(data.cell.value)},
				width: 100,
			},{
				Header: "Date",
				accessor: "CreateDate",
				width: 106,
				Cell: data => {return formatDate(data.cell.value)}

			}
		],
		[]
	)

	
  return (
				<React.Fragment>
					<div className="px-2">
						<u><Link className="px-2 text-primary" to={`/admin/newuser`}>Create New User</Link></u>
						<u><Link className="px-2 text-primary" to={'/admin/newbroker'}>Create New Broker Code</Link></u>
						<u><Link className="px-2 text-primary" to={'/admin/brokerlist'}>View Broker Codes</Link></u>
						<u><Link className="px-2 text-primary" to={'/admin/SearchUser'}>Search User</Link></u>
					</div>
					<div>
						<ReactGenericTable ref={childRef} pagination={true} url="users?" defaultSortIndex="UserID" defaultSortOrder="DESC" columns={columns} />
					</div>
				</React.Fragment>
  );
};
export default UserListing;