import React, {useEffect,useContext} from "react";
import { notify, http, apiBaseUrl, hasKeys, getStandardDateForm} from "../../utitility";
import { Formik } from "formik";
import CustomButton from './CustomButton';
import { useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import NotesListByContactId from "../Contacts/NotesListByContactId";
import { UserContext } from "../../Context/UserContext";
const CreateNoteForm = ({reloadTableData,editValue,contactId}) => {
	const {user} = useContext(UserContext);
  const [messageData,setMessageData] = useState('');
  const [load,setLoad] = useState(false);
  const [initialState,setInitialState] = useState({
    Tag:"",
    Message:"",
    UserID:user.UserID,
    ListingID:"",
    Share_type:0
  });

  useEffect(() => {
    if(hasKeys(editValue)){
      setMessageData(editValue.ConvertedMessage)
      setInitialState(editValue)
    }
  },[editValue]);
  
  const clearFormFields=(resetForm,setFieldValue)=>{
    resetForm({});
    setFieldValue('Message','')
    setMessageData('')
    setInitialState({
      Tag:"",
      Message:"",
      UserID:user.UserID,
      ListingID:"",
      Share_type:""
    })
  }
  const getNotesData = (note_id) =>{
    http.get(apiBaseUrl(`/api/getNotes?notes_id=${note_id}`))
    .then((res)=>{
      const buffer = Buffer.from(res.data.data[0].Message);
      const decodedString = buffer.toString('utf-8');
      const initialStateNew = {};
      initialStateNew.NotesId = res.data.data[0].NotesId;
      initialStateNew.Tag = res.data.data[0].Tag;
      initialStateNew.UserID = user.UserID;
      initialStateNew.ListingID = res.data.data[0].ListingID;
      initialStateNew.Share = res.data.data[0].Share ? true : false;
      setInitialState(initialStateNew)
      setMessageData(decodedString)
    })
    .catch((error) =>{
      console.log(error);
    })
  }
  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
        if(!values.NotesId){
          setSubmitting(true);
          let data = {...values};
          data.Message = messageData;
          data.CreateDate = getStandardDateForm();
          data.Share_type = data.Share ? 1 : 0;
          http.post(apiBaseUrl(`/api/ListingNotes?listing_id=${data.ListingID}&contact_id=${contactId}`),data)
          .then(() => {
            setSubmitting(false);
            setLoad(true);
            notify.success("Notes added successfully");
            reloadTableData();
            clearFormFields(resetForm,setFieldValue);
          }).catch((error)=>{
            setSubmitting(false);
            clearFormFields(resetForm,setFieldValue);
            notify.error(error);
          }).finally(()=>{
            setLoad(false);
          });
        }else{
          setSubmitting(true);
          let data = {...values};
          data.Message = messageData;
          data.UpdateDate = getStandardDateForm();
          http.post(apiBaseUrl(`/api/ListingNotes?listing_id=${data.ListingID}&notes_id=${values.NotesId}`),data)
          .then(() => {
            setSubmitting(false);
            setLoad(true);
            notify.success("Notes updated successfully");
            clearFormFields(resetForm,setFieldValue);
            resetForm({});
            reloadTableData();
          }).catch((error)=>{
            setSubmitting(false);
            resetForm({});
            notify.error(error);
          }).finally(()=>{
            setLoad(false);
          });
        }
			
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <CKEditor
								editor={ ClassicEditor }
								data={messageData}
								onReady={ editor => {
									const data = editor.getData();
									setFieldValue('Message',data)
									setMessageData(data);
								} }
								onChange={ ( event, editor ) => {
										const data = editor.getData();
                    setFieldValue('Message',data)
                    setMessageData(data);
								} }
						/>
            <span className="text-danger">
              {errors.notes &&
                touched.notes &&
                errors.notes}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Tag
            </label>
            <input
              type="text"
              name="Tag"
              className="form-control"
              onChange={handleChange}
              value={values.Tag}
            />
            <span className="text-danger">
              {errors.Tag &&
                touched.Tag &&
                errors.Tag}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Listing number
            </label>
            <input
              type="text"
              name="ListingID"
              className="form-control"
              onChange={handleChange}
              value={values.ListingID}
            />
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Share Note
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              type="checkbox"
              name="Share"
              onChange={handleChange}
              value={values.Share}
              checked={values.Share}
              className="form-check-input"
            />
            <label className="form-check-label pl-3" htmlFor="inlineCheckbox1">
            Share this note with the Tynax team.
          </label>
          </div>
          <div className="text-center py-2">
            <CustomButton isSubmitting={isSubmitting} btnText='Save' /> 
            <button type="button" onClick={(e) => clearFormFields(resetForm,setFieldValue)} className="ml-2 btn btn-secondary">Cancel</button>
          </div>
				<NotesListByContactId getNotesData={getNotesData} load={load} />
        </form>
      )}
    </Formik>
  );
};
export default CreateNoteForm;
