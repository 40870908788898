import axios from 'axios'
import { loadProgressBar } from 'axios-progress-bar'
import notify from './notify'
import { apiUrl, apiBaseUrl } from './url'
import {  setAccessToken } from './token'
import { getAccessToken } from './index'
const http = axios.create()

const logout = () =>{
  http.get(apiBaseUrl(`/api/logout`))
  .then((res) => {
    if(res){
      localStorage.clear();
      window.location.href = "/login";
    }
  });
}

http.defaults.baseURL = apiUrl()
http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

http.interceptors.request.use(
  (config) => {
    config.headers.common['Authorization'] = `Bearer ${getAccessToken()}`    
    return config
  }, 
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => {
    if (response.headers['x-access-token']) {
      setAccessToken(response.headers['x-access-token'])
    }
    if(response.data === 'invalid token' || response.data ==='jwt expired' || response.data === 'token mismatch'){
     logout();
    }
    return response
  },
  (error) => {

    if (error.response.status === 401) {
      window.location.href = "/login"
    } else {
      if (error.response) {
        return Promise.reject(error.response.data)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      } else {
        notify.error('Network error occured. Please try later')
      }
      
     
    }
    // Do something with response error
    return Promise.reject(error)
  }
);

loadProgressBar({
  minimum: 0.1,
  easing: 'ease', 
  speed: 500,
  showSpinner: true,
  trickleRate: 0.02, 
  trickleSpeed: 800
}, http)

export default http