import React from "react";
import { Login,LoginUsingGoogle } from "../Components";
import { Link } from 'react-router-dom';

const LoginPage = ({history}) => {
    return (
      <section className="">
        <div className="container-fluid py-3 bg-white">
          <div className="row justify-content-center py-5">
            <div className="col-xs-12 col-sm-6 col-lg-6">
              <div className="border-card p-3">
                <h3
                  className="font-weight-bold text-center mb-5"
                  style={{ color: "#2d78a0" }}
                >
                  Existing User
                </h3>
                <Login history={history}/>
                <Link style={{'display': 'block'}} className=" text-center" to="/forgot_password">
                  Forgot Password
                </Link>
                <h6 className="text-muted text-center">Or</h6>
                <LoginUsingGoogle history={history}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
export default LoginPage;
