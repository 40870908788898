import React, {useState} from "react";
import {hasKeys} from "../../utitility";

const ListingStatus = ({listings, arrDealStructurePatentObj, listingStatusConstant, category, patentDetail, confidential_details}) => {
  const [dealStructureArray,setDealStructureArray] = useState(listings.DealStructure ? listings.DealStructure.split(','):[]);

	return (
		<React.Fragment>
			{listings && listings.ListingID &&
				<div className="card mt-3">
				<div className="card-title text-center p-2">
					Listing Status
				</div>
				<div className="card-list">
					<div className="card-item">
						<div className="">
							<span className="card-text mr-2">
								Listing Number:
							</span>
							{listings.ListingID}
						</div>
					</div>
					<div className="card-item">
							<div className="">
								<span className="card-text mr-2">
								Status:
								</span>
								{listings.AvailableStatus <= 0 ? "Pending" :
									listingStatusConstant[listings.AvailableStatus-1]['label']}
								{/* {listingStatusConstant[listings.ListingStatus]['description']} */}
							</div>
					</div>
					<div className="card-item">
						<div className="card-text mb-2">
							Transaction Type Sought:
						</div>
						<ul className="pl-0" style={{listStyleType:'none'}}>
						{dealStructureArray &&
							dealStructureArray.map((struct, index) =>
							<li key={index}>{arrDealStructurePatentObj[struct]}</li>
							)}
						</ul>
					</div>
					<div className="card-item">
						<div className="card-text mb-2">
							Technology Topics:
						</div>
						<ul className="list unstyled sidebar-topics">
						{category &&
							category.map((tab, index) =>
							<li key={index}><a href="#">{tab.CategoryName}</a></li>
						)}
						</ul>
					</div>
					{hasKeys(listings) && listings.ListingType > 0 && 
					<div className="card-item">
						<div className="card-text mb-2">
							Intellectual Property:
						</div>
						{patentDetail &&
							patentDetail.map((tab, index) =>
								<div key={index}>
									<span className="mr-1">{tab.count}</span>
									<span className="mr-1">
										{tab.patentStatus === "Issued" && "Issued Patent"}
										{(tab.patentStatus === "application" || tab.patentStatus === "Application") && "Patent Application"}
									</span>
									<span>
										{tab.Country}
									</span>
								</div>
						)}
					</div>
				  }
				</div>
			</div>
      } 
		</React.Fragment>	
    );
}

export default ListingStatus;
