import React, { useEffect, useState } from "react";
import { Collapse, CustomInput } from "reactstrap";
const Switch = props => {
  const [collapse, setcollapse] = useState(false);
  const toggleCollapse = target => {
    if (target.checked) {
      setcollapse(true);
    } else {
      setcollapse(false);
    }
  };
  useEffect(() => {
	setcollapse(props.activeStatus);
  },
  [props.activeStatus]
  );
  return (
		<React.Fragment>
			<div
				className="d-flex mb-3"
				id={props.id}
			>
				<React.Fragment>
					<CustomInput
						type="switch"
						checked={collapse}
						id={`customSwitch${props.id}`}
						name="customSwitch"
						onChange={e => toggleCollapse(e.target)}
					/>
					<h6 className="text-primary mb-2">{props.title}</h6>
					
				</React.Fragment>
			</div>
			<Collapse className="grouped-body" isOpen={collapse}>
				{props.children}
			</Collapse>
		</React.Fragment>
  );
};
export default Switch;
