import React from "react";
import { Row, Col } from "reactstrap";

const DisplayMessage = ({ message }) => {
  return (
    <div>
			<Row className="justify-content-center">
				<Col className="mb-3 col-12 col-md-8">
					{message}
				</Col>
			</Row>
		</div>
  );
};

export default DisplayMessage;
