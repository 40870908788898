import React, { Component } from "react";

const Footer = () =>{
  return (
    <div className="container-fluid bg-white" id="footer">
      <span>Copyright © {new Date().getFullYear()} All Rights Reserved</span> |
      <a href="https://tynax.com/privacypolicy" target="_blank">Privacy Policy</a> |{" "}
      <a href="https://tynax.com/termsofuse" target="_blank">Terms Of Use</a> |
      <a href="https://tynax.com/contact" target="_blank">Contact</a>
    </div>
  );
}

export default Footer;
