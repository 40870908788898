import React from "react";
import { Link } from "react-router-dom";
const TableContact = ({contactDetails}) =>{
    return(
        <section class="contact-section">
            <div class="container-fluid">
                <div class="row justify-content-center pt-3 my-5">
                    <div class="col-12">
                         <div class="d-flex flex-wrap align-items-center justify-content-between">
							<h4 class="text-primary mb-4 text-center">Contacts:</h4>
						</div>
                        <div>
                            <table className="table-contact">
                                <tr>
                                    <th className="table-contact-row">Name</th>
                                    <th className="table-contact-row">Title</th>
                                </tr>
                                {contactDetails.map(val =>(
                                    <tr>
                                        <td className="table-contact-row"><Link to={`/contacts/contact_info?contact_id=${val.ContactId}`}>{`${val.Firstname} ${val.Lastname}`}</Link></td>
                                        <td className="table-contact-row">{val.Title}</td>
                                    </tr>
                                    ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TableContact;