import React, { useState } from "react";
import { Formik } from "formik";
import CustomButton from './CustomButton';

import { notify, http, apiBaseUrl } from "../../utitility";

const ChangePassword = ({user_id}) => {
  return (
    <Formik
      initialValues={{
				user_id:user_id,
        current_password: "",
				new_password: "",
				confirm_password: ""
      }}
      validate={(values) => {
				const errors = {};
				if (!values.current_password && !values.current_password.trim()) {
          errors.current_password = "Required";
        }else if(values.current_password.length < 6){
          errors.current_password = "Password must be at least 6 characters";
				}
				if (!values.new_password && !values.new_password.trim()) {
          errors.new_password = "Required";
        }else if(values.new_password.length < 6){
          errors.new_password = "Password must be at least 6 characters";
        }else if(values.new_password === values.current_password){
          errors.new_password = "Current password and new password should not be same";
        }
				if (!values.confirm_password && !values.confirm_password.trim()) {
          errors.confirm_password = "Required";
        }
        if (values.confirm_password !== values.new_password) {
          errors.confirm_password = "Password didnt match";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting,resetForm }) => {
        setSubmitting(true);
        http.post(apiBaseUrl(`/api/change/password`),values)
        .then((res) => {
          resetForm({})
          setSubmitting(false);
          notify.success("success");
        }).catch((error)=>{
          console.log(error)
          if(error.message){
            notify.error(error.message);
          }else{
            let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
            error.response.data.message :
            'whoops...something went wrong';
            notify.error(errorMsg);
          }     
          setSubmitting(false);
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Current Password
            </label>
            <input
              type="password"
              name="current_password"
              className="form-control"
              onChange={handleChange}
              value={values.current_password}
            />
            <span className="text-danger">
              {errors.current_password && touched.current_password && errors.current_password}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputPassword1"
            >
              New Password
            </label>
            <input
              type="password"
              name="new_password"
              className="form-control"
              onChange={handleChange}
              value={values.new_password}
            />
            <span className="text-danger">
              {errors.new_password && touched.new_password && errors.new_password}
            </span>
          </div>
					<div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputPassword1"
            >
              Retype New Password
            </label>
            <input
              type="password"
              name="confirm_password"
              className="form-control"
              onChange={handleChange}
              value={values.confirm_password}
            />
            <span className="text-danger">
              {errors.confirm_password && touched.confirm_password && errors.confirm_password}
            </span>
          </div>
          <div className="text-center py-2">
            <CustomButton isSubmitting={isSubmitting} btnText='Change Password' />
          </div>
        </form>
      )}
    </Formik>
  );
};
export default ChangePassword;
