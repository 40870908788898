import React, { useEffect, useState, useRef } from "react";
import { notify, http, apiBaseUrl } from "../../utitility";
import { Formik, Field, ErrorMessage } from "formik";
import CustomButton from './CustomButton';
import Radio from "../Fields/Radio";
import ListingSchema from "../validation/ListingSchema";
import classnames from "classnames";

const CreateDetailedListingForm3 = ({ user, listingId,initialState, setInitialState, history }) => {
	const [isDisabled,setIsDisabled] = useState(false);
  
  useEffect(() => {
    if(listingId){
      http.get(apiBaseUrl(`/api/getListingDetailById?listing_id=${listingId}`))
      .then((res) => {
        let initialStateNew = {...initialState};
        initialStateNew.listing = res.data.listing;
        initialStateNew.listing_details = res.data.listing_details;
        initialStateNew.salesInfo =res.data.salesInfo;
        setInitialState(initialStateNew);
      }).catch((err)=>{
        console.log(err);
      })
    }
	},[listingId]);

  const refPatentIPOwnership_Notes = useRef();
  const refPatentLicensingStatus_Notes = useRef();
  const refPatentInfringement_Notes = useRef();
  const refPatentLitigation_Notes = useRef();

  const clearFormFields=(resetForm)=>{
    resetForm({});
  }

  const redirectToPreviousPage=()=>{
    history.goBack();
  }
  
  const redirectToNextPage=()=>{
    history.push('detailed_listing4');
  }

  const PreviewListing=()=>{
    // history.push('detailed_listing2');
  }

  const changeType = (e, setFieldValue, field) => {
    setFieldValue(field, e.target.value);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={ListingSchema}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          // // setSubmitting(true);
          // setIsDisabled(true);
          // let data = {...values};
          // if(!listingId){
          //   http.post(apiBaseUrl(`/api/createListing`),data)
          //   .then(() => {
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     notify.success("Listing created successfully");
          //     clearFormFields(resetForm,setFieldValue);
          //     if(user.UserType==40){
          //       history.push('/myaccount/myaccount_ta_all');
          //     }
          //     else{
          //       history.push('/myaccount/my_listing');
          //     }
          //   }).catch((error)=>{
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     notify.error(error);
          //   });
          // }else{
          //   http.post(apiBaseUrl(`/api/editListing?listing_id=${listingId}`),data)
          //   .then(() => {
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     if(user.UserType==40){
          //       history.push('/myaccount/myaccount_ta_all');
          //     }
          //     else{
          //       history.push('/myaccount/my_listing');
          //     }
          //     notify.success("Listing updated successfully");
          //   }).catch((error)=>{
          //     // setSubmitting(false);  
          //     setIsDisabled(false);
          //     notify.error(error);
          //   });
          // }
          if(user.UserType==40){
            history.push('/myaccount/myaccount_ta_all');
          }
          else{
            history.push('/myaccount/my_listing');
          }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <h5 className="mb-3">
              <b className="text-primary">3.  Patent Information</b>
            </h5>
            <span>
              Please complete the questions in this section, patent data is NOT made public on the Tynax web site.
            </span>
            <label className="font-weight-bold form-text">
              Patent Seller: 
            </label>
            <Field type="text" name="listing.ListingName" className="form-control"  />
            <ErrorMessage
              name="listing.ListingDescription"
              component="div"
              className="text-danger"
            />
            <Field type="hidden" name="listing.AvailableStatus" className="form-control" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Listing Create Date: 
            </label>
            <span>{}</span>
          </div>
          <div className="form-group">
            <u><a className="text-secondary" target="_blank" href="">Import Patent</a></u>
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Patent Notes
            </label>
            <Field type="text" name="listing_details.Overview" component="textarea" className="form-control"  />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Topic List
            </label>
            <span>
              Select the topic in which you would like the listing to be featured. If a topic has been suggested you can do nothing 
              and keep it, or if it's not a good fit delete it. If no topic has been suggested for this listing, then please select 
              at least one Tynax topic from the drop-down menu below:
            </span>
            <Field type="text" name="salesInfo.Keywords" component="textarea" className="form-control"  />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
                Back License:
            </label>
            <span>
              When selling this patent(s) would you like to retain a license back for your own ongoing use?
            </span>
            <Radio
              id="listing_details_no"
              name="listing_details.PatentBackLicense"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,'listing_details.PatentBackLicense')}}
            />
            <Radio
              id="listing_details_yes"
              name="listing_details.PatentBackLicense"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,'listing_details.PatentBackLicense')}}
            />
            <Radio
              id="listing_details_unknown"
              name="listing_details.PatentBackLicense"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,'listing_details.PatentBackLicense')}}
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Intellectual Property Ownership & Encumbrances:
            </label>
            <span>
              Are there any liens, secured debts or other claims encumbering the patent rights that would restrict the buyer from gaining clean title?
            </span>
            <Radio
              id="PatentIPOwnership_no"
              name="listing_details.PatentIPOwnership"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,'listing_details.PatentIPOwnership')}}
            />
            <Radio
              id="PatentIPOwnership__yes"
              name="listing_details.PatentIPOwnership"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,'listing_details.PatentIPOwnership')}}
            />
            <Radio
              id="PatentIPOwnership_unknown"
              name="listing_details.PatentIPOwnership"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,'listing_details.PatentIPOwnership')}}
            />
          </div>
          <div className="form-group" ref={refPatentIPOwnership_Notes} hidden="true">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              If yes, please describe these encumbrances:
            </label>
            <Field type="text" name="listing_details.TechnologyOverview" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing_details.TechnologyOverview"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
             Licensing Status
            </label>
            <span>
            Are there any existing licensees?
            </span>
            <Radio
              id="PatentLicensingStatus_no"
              name="listing_details.PatentLicensingStatus"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLicensingStatus")}}
            />
            <Radio
              id="PatentLicensingStatus_yes"
              name="listing_details.PatentLicensingStatus"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLicensingStatus")}}
            />
            <Radio
              id="PatentLicensingStatus_unknown"
              name="listing_details.PatentLicensingStatus"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLicensingStatus")}}
            />
          </div>
          <div className="form-group" ref={refPatentLicensingStatus_Notes} hidden="true">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              If yes, without naming licensees, please describe existing licensing arrangements:
            </label>
            <Field type="text" name="listing_details.PatentLicensingArrangement" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing_details.PatentLicensingArrangement"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Infringement:
            </label>
            <span>
            Do you suspect anyone of practicing the patented inventions without a license?
            </span>
            <Radio
              id="PatentInfringement_no"
              name="listing_details.PatentInfringement"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentInfringement")}}
            />
            <Radio
              id="PatentInfringement_yes"
              name="listing_details.PatentInfringement"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentInfringement")}}
            />
            <Radio
              id="PatentInfringement_unknown"
              name="listing_details.PatentInfringement"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentInfringement")}}
            />
          </div>
          <div className="form-group" ref={refPatentInfringement_Notes} hidden="true">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            If yes, please provide information on potential infringers:
            </label>
            <Field type="text" name="listing_details.PatentPotentialInfringers" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing_details.PatentPotentialInfringers"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Litigation
            </label>
            <span>
            Has this listing been subject to infringement or other forms of litigation?
            </span>
            <Radio
              id="PatentLitigation_no"
              name="listing_details.PatentLitigation"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <Radio
              id="PatentLitigation_yes"
              name="listing_details.PatentLitigation"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />            
            <Radio
              id="PatentLitigation_unknown"
              name="listing_details.PatentLitigation"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />  
          </div>
          <div className="form-group" ref={refPatentLitigation_Notes} hidden="true">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            If yes, please describe the cases and the outcome:
            </label>
            <Field type="text" name="listing_details.PatentLitigationNotes" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing_details.PatentLitigationNotes"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Patent portfolio
            </label>
            <span>
              Would you be prepared to sell the patents individually?
            </span>
            <Radio
              id="PatentPortfolio_no"
              name="listing_details.PatentPortfolio"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentPortfolio")}}
            />
            <Radio
              id="PatentPortfolio_yes"
              name="listing_details.PatentPortfolio"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentPortfolio")}}
            />            
          </div>
          <div className="text-center py-2">
            <button type="button" onClick={(e) => redirectToPreviousPage()} className="ml-2 btn btn-primary">Back</button>
            <button 
                  disabled={isDisabled} 
                  type="submit" 
                  className={classnames({
                    "ml-2 buttonload btn btn-primary btn-sm": isDisabled === true,
                    "ml-2 btn btn-primary btn-sm": isDisabled === false,
                  })}>
                    Save & Return Later
            </button>
            <button type="button" onClick={(e) => PreviewListing()} className="ml-2 btn btn-primary">Preview Listing</button>
            <button type="button" onClick={(e) => redirectToNextPage()} className="ml-2 btn btn-primary">Next</button>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default CreateDetailedListingForm3;
