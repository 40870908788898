
import React from "react";
import { Field } from "formik";
import { CustomInput } from "reactstrap";

const Radio = props => {

return (
  <React.Fragment>
  {props.custom === "true" ?
  <Field name={props.name}>
  {({ field, form }) => (
        <input
          type="radio"
          {...props}
          checked={field.value === props.value}
          onChange={() => {
            form.setFieldValue(props.name, props.value);
          }}
        />
      )}
    </Field>:
     <Field name={props.name}>
    {({ field, form }) => (
      <CustomInput
        type="radio"
        {...props}
        checked={field.value === props.value}
        onChange={() => {
          form.setFieldValue(props.name, props.value);
        }}
      />
    )}
  </Field>}
  </React.Fragment>
)};

export default Radio;
