import React, { useMemo, useRef }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { mergeString,http, apiBaseUrl, notify} from '../../utitility';
import { Link } from 'react-router-dom';
import { useState } from "react";
import { SearchContactForm } from "../Forms";
import { confirmAlert } from 'react-confirm-alert'; 

const SearchContact = ({user}) => {

	const [url,setUrl] = useState("");
	const childRef = useRef();

	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

	const getViewLink = (contact_id) => {
    return(<Link to={`/contacts/contact_info?contact_id=${contact_id}`}>{contact_id}</Link>);
	}
	const getActionField = (contact_id) => {
		return( 
			<div>
				<Link to={`/searchcontacts/contact_edit?contact_id=${contact_id}`} className="mr-2 table_font_size btn-link">Edit</Link>
				<span onClick={()=>ConfirmAlert(contact_id)} className="mr-2 table_font_size btn-link link-pointer">Delete</span>
			</div>
		)
	}

	const ConfirmAlert = (value) => {
		confirmAlert({
			message: 'Do you really want to delete this contact?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteContact(value)
				},
				{
					label: 'No',
					onClick: () => {}
				}
			]
		});
	}

	const deleteContact = (contact_id) => {
		http.delete(apiBaseUrl(`/api/deleteContact?contact_id=${contact_id}`))
		.then(() => {
			reloadTableData();
			notify.success("Deleted successfully");
		}).catch((error)=>{
			let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
											error.response.data.message :
											'whoops...something went wrong';
			notify.error(errorMsg);
		});
	}
	const columns = useMemo(
		() => [
			{
				Header: "Name",
				accessor: "Firstname",
				width: 130,
				sort:true,
				Cell: data => {return (<Link to={`/contacts/contact_info?contact_id=${data.row.original.ContactId}`}>{mergeString(data.row.original.Firstname,data.row.original.Lastname)}</Link>)},
			},
			{
				Header: "Organization",
				accessor: "OrgList",
				width: 130,
				sort:true
			},
			{
				Header: "Job Title",
				accessor: "Title",
				width: 130,
				sort:true
			},
			{
				Header: "Work Phone",
				accessor: "WorkPhone",
				width: 130,
				sort:true
			},
			{
				Header: "Mobile Phone",
				accessor: "MobilePhone",
				width: 130,
				sort:true
			},
			{
				Header: "Email",
				accessor: "PrimaryEmail",
				width: 300,
				sort:true,
				Cell: data => {return (<a href={`mailto:${data.row.original.PrimaryEmail}`}>{data.row.original.PrimaryEmail}</a>)}
			},
			{
				Header: "Action",
				accessor: "act",
				Cell: data => (getActionField(data.row.original.ContactId)),
				width: 130
			}
		],
		[]
	)

  return (
				<React.Fragment>
					<div className="px-2">
						<u><Link  className="px-2 text-primary" to={'/contacts/mylisting'}>My Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_contact'}>Create Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_contacts'}>Search Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_organizations'}>Create Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/organizations'}>Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_organizations'}>Search Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/mailing_lists'}>Mailing lists</Link></u>
					</div>
					<section>
						<SearchContactForm user={user} reloadTableData={() => reloadTableData()}  setUrl={setUrl}/>
					</section>
					<div>
						<ReactGenericTable  pagination={true} url={url} defaultSortIndex="contactid" defaultSortOrder="DESC" columns={columns} />
					</div>
				</React.Fragment>
  );
};
export default SearchContact;