import React,{useContext,useState,useEffect} from "react";
import { UserContext } from "../../Context/UserContext";
const OrganizationDetail = ({organizationDetails,organizatoinTypeDetails,orgTynaxLink,tynaxPeople}) => {

	const {user} = useContext(UserContext);
	const [tynaxPeopleData,setTynaxPeopleData] = useState([]);
  useEffect(() => {
	if(orgTynaxLink){
		orgTynaxLink.map(val => {
			const matchedObj = tynaxPeople.find(data => data.PersonID === val.TynaxContactId);
			if(matchedObj){
				setTynaxPeopleData(prevState=>[...prevState,{contactId: val.TynaxContactId, tynaxContactType: val.TynaxContactType, label: `${matchedObj.Firstname} ${matchedObj.Lastname}`}])
			}
		})
	}
  },[orgTynaxLink,tynaxPeople]);
	const getUnderNDA = (nda) =>{
		if(nda == 2){
      return "Unknown" ;
		}else if (nda == 1) {
			return "Yes" ;
		}else {
     return "No" ;
		}
	}
	const getSellingPolicy = (policy) => {
		if(policy == 0 ){
			return "Unknown"
		}else if(policy == 1){
			return "Will Not Sell"
		}else if(policy == 2){
      return "Perhaps Sell"
		}else if(policy == 3){
			return "Will Sell"
		}
	}
	const getPatentSellingTarget = (target) =>{
		if(target == 0 ){
			return "Any Buyer"
		}else if(target == 1){
			return "No NPE"
		}else if(target == 2){
			return "No Competitors"
		}else if(target == 3){
			return "No NPE & Competitors"
		}
}
	return (
		<section class="contact-section">
			<div class="container-fluid">
				<div class="row justify-content-center pt-3 my-5">
					<div class="col-12">
						<div class="d-flex flex-wrap align-items-center justify-content-between">
							<h4 class="text-primary mb-4 text-center">Organization Information</h4>
						</div>
						<div className="text-right">
							<a href={`/contacts/org_details/edit_organizations?org_id=${organizationDetails.OrgId}`} className="btn btn-primary btn-sm">Edit</a>
						</div>
						<div class="grey-bg p-3">
							<div class="my-3">
								<h6 class="text-primary mx-2">ID:
									<span class="text-muted ml-3">{organizationDetails.OrgId}</span>
								</h6>
								<h6 class="text-primary mx-2">Name:
									<span class="text-muted ml-3 mr-1">{organizationDetails.OrgName}</span>
								</h6>
								<h6 class="text-primary mx-2">Parent Company:
									<span class="text-muted ml-3 mr-1">{organizationDetails.ParentCompany}</span>
								</h6>
								<h6 class="text-primary mx-2">Nickname:
									<span class="text-muted ml-3 mr-1">{organizationDetails.CompanyNickname}</span>
								</h6>
								<h6 class="text-primary mx-2">Address:
									<span class="text-muted ml-3 mr-1">{organizationDetails.Address}</span>
								</h6>
								<h6 class="text-primary mx-2">City:
									<span class="text-muted ml-3 mr-1">{organizationDetails.City}</span>
								</h6>
								<h6 class="text-primary mx-2">State:
									<span class="text-muted ml-3 mr-1">{organizationDetails.State}</span>
								</h6>
								<h6 class="text-primary mx-2">Zip code:
									<span class="text-muted ml-3 mr-1">{organizationDetails.ZipCode}</span>
								</h6>
								<h6 class="text-primary mx-2">Website:
									<span class="text-muted ml-3 mr-1">{organizationDetails.Website}</span>
								</h6>
								<h6 class="text-primary mx-2">IP Catalog URL:
									<span class="text-muted ml-3 mr-1">{organizationDetails.IPCatalogURL}</span>
								</h6>
								<h6 class="text-primary mx-2">Country:
									<span class="text-muted ml-3 mr-1">{organizationDetails.Country}</span>
								</h6>
								<h6 class="text-primary mx-2">Phone:
									<span class="text-muted ml-3 mr-1">{organizationDetails.Phone}</span>
								</h6>
								<h6 class="text-primary mx-2">Fax:
									<span class="text-muted ml-3 mr-1">{organizationDetails.Fax}</span>
								</h6>
								<h6 class="text-primary mx-2">Status:
									<span class="text-muted ml-3 mr-1">{organizationDetails.Status ? 'Active' : 'Inactive'}</span>
								</h6>
								<h6 class="text-primary mx-2">Email:
									<span class="text-muted ml-3 mr-1">{organizationDetails.Email}</span>
								</h6>
								<h6 class="text-primary mx-2">Patent Selling Policy:
									<span class="text-muted ml-3 mr-1">{getSellingPolicy(organizationDetails.PatentSellingPolicy)}</span>
								</h6>
								<h6 class="text-primary mx-2">Patent Selling to Who:
									<span class="text-muted ml-3 mr-1">{getPatentSellingTarget(organizationDetails.PatentSellingTarget)}</span>
								</h6>
								<h6 class="text-primary mx-2">Describe Patent Selling Policy:
									<span class="text-muted ml-3 mr-1">{organizationDetails.PatentSellingPolicyDescription}</span>
								</h6>
								<h6 class="text-primary mx-2">Tech Transfer Activity:
									<span class="text-muted ml-3 mr-1">{organizationDetails.TechTransferActivity}</span>
								</h6>
								<h6 class="text-primary mx-2">Industry:
									<span class="text-muted ml-3 mr-1">{organizationDetails.Industry}</span>
								</h6>
								<h6 class="text-primary mx-2">Size:
									<span class="text-muted ml-3 mr-1">{organizationDetails.Size}</span>
								</h6>
								<h6 class="text-primary mx-2">Market Cap:
									<span class="text-muted ml-3 mr-1">{organizationDetails.MarketCap}</span>
								</h6>
								<h6 class="text-primary mx-2">Ticker/ID:
									<span class="text-muted ml-3 mr-1">{organizationDetails.Ticker}</span>
								</h6>
								<h6 class="text-primary mx-2">Number of Patents:
									<span class="text-muted ml-3 mr-1">{organizationDetails.NumberOfPatentsHeld}</span>
								</h6>
								<h6 class="text-primary mx-2">University Patent Ranking:
									<span class="text-muted ml-3 mr-1">{organizationDetails.UniversityPatentRanking}</span>
								</h6>
								<h6 class="text-primary mx-2">OT Ranking:
									<span class="text-muted ml-3 mr-1">{organizationDetails.OTRanking}</span>
								</h6>
								<h6 class="text-primary mx-2">Tynax Relationship:
									<span class="text-muted ml-3 mr-1">{organizationDetails.TynaxRelationship ? 'Yes' : 'No'}</span>
								</h6>
								<h6 class="text-primary mx-2">Active Patent Buyer?:
									<span class="text-muted ml-3 mr-1">{organizationDetails.ActivePatentBuyer ? 'Yes' : 'No'}</span>
								</h6>
								<h6 class="text-primary mx-2">Pays Tynax Buy-Side Commission?:
									<span class="text-muted ml-3 mr-1">{organizationDetails.TynaxBuySideComission === "1" ? 'Yes' : 'No'}</span>
								</h6>
								<h6 class="text-primary mx-2">Under NDA:
									<span class="text-muted ml-3 mr-1">{getUnderNDA(organizationDetails.Under_NDA)}</span>
								</h6>
								<h6 class="text-primary mx-2">Description:
									<span class="text-muted ml-3 mr-1 text-muted-break">{organizationDetails.Description}</span>
								</h6>
								<h6 class="text-primary mx-2">Keywords:
									<span class="text-muted ml-3 mr-1">{organizationDetails.Keyword}</span>
								</h6>
								{organizatoinTypeDetails && organizatoinTypeDetails.map(val =>
									<h6 class="text-primary mx-2">{val.TypeLabel}:
									<span class="text-muted ml-3 mr-1">{val.Flag ? 'Yes' : 'No'}</span>
								</h6>
									)}
								<h6 class="text-primary mx-2">Created By:
									<span class="text-muted ml-3 mr-1">{organizationDetails.CreatorName}</span>
								</h6>
								<h6 class="text-primary mx-2">Tynax Person:</h6>
							{ tynaxPeopleData && tynaxPeopleData.map(val =>
							<div class="grey-bg">
								<span class="text-muted ml-3">{val.label} {val.tynaxContactType ? '(Preferred)' : '(Exclusive)'}</span>
							</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    );
}

export default OrganizationDetail;
