import React, { useState,useEffect } from "react";
import { Formik } from "formik";
import CustomButton from './CustomButton';
import { Row, Col } from "reactstrap";
import { hasKeys,http, apiBaseUrl } from "../../utitility";

const SearchOrganizationsForm = ({setUrl,reloadTableData,user}) => {
  const [tynaxPeople,setTynaxPeople] = useState([]);
  useEffect(() => {
		http.get(apiBaseUrl(`/api/getOrganizationInfo`))
		.then((res) => {	
      setTynaxPeople(res.data.tynax_people);
		}).catch((error=>{
			console.log(error,'error')
		}))
  },[]);
		const  cleanObj = (obj) => {
			for (var propName in obj) { 
				if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
					delete obj[propName];
				}
			}
			return obj;
		}

    const clean =(array)=>{
      return array.filter(function (el) {
        return el != null;
      });
    }
		
    const buildQuery=(params)=>{
      var esc = encodeURIComponent;
      var query = Object.keys(params)
                  .map(function(k) {
                    if(esc(params[k])){
                      return esc(k) + '=' + esc(params[k].trim())
                    }
                  });
      if(query){
        query= clean(query).join('&')
      }
      return query;
    }

    return (
      <Formik
      initialValues={{
        // orgId: "",
        orgName: "",
        Intermediary: "",
        Buyer: "",
        Seller: "",
        NPE: "",
        ContingentLawyers: "",
        TynaxRelationship: "",
        TynaxContact: "",
      }}
      validate={(values) => {
				const errors = {};
				if(!hasKeys(cleanObj(values))){
					errors.username = 'please enter at least one value to search';
				}
        return errors;
      }}
      onSubmit={(values,{ setSubmitting }) => {
        setSubmitting(false)
        let query = buildQuery(values);
        query = `searchOrganization?${query}&`;
				setUrl(query);
				reloadTableData();
      }}
    >
      {({
        values,
        handleChange,
				handleBlur,
				errors,
        touched,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
					<Row className="ml-2">
						{/* <Col className="form-group col-md-2 mt-3">
							<input
								type="text"
								name="orgId"
								className="form-control"
								onChange={handleChange}
								value={values.username}
								placeholder="Organization Id"
							/>
							<span className="text-danger">
              	{errors.contactId && touched.contactId && errors.contactId}
              </span>
						</Col> */}
						<Col className="form-group col-md-2 mt-3">
							<input
								type="text"
								name="orgName"
								className="form-control"
								onChange={handleChange}
								value={values.firstname}
								placeholder="Organizations Name"
							/>
							<span className="text-danger">
							{errors.organization && touched.organization && errors.organization}
              </span>
						</Col>
            <Col className="form-group col-md-2 mt-3">
            <select
                name="Intermediary"
                value={values.Intermediary}
                onChange={handleChange}
                aria-label="Default select example"
                className="form-control"
              >
                <option value="">Select Intermediary</option>
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </select>
						</Col>
            <Col className="form-group col-md-2 mt-3">
            <select
                name="Buyer"
                value={values.Buyer}
                onChange={handleChange}
                aria-label="Default select example"
                className="form-control"
              >
                <option value="">Select Buyer</option>
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </select>
						</Col>
            <Col className="form-group col-md-2 mt-3">
            <select
                name="Seller"
                value={values.Seller}
                onChange={handleChange}
                aria-label="Default select example"
                className="form-control"
              >
                <option value="">Select Seller</option>
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </select>
						</Col>
            <Col className="form-group col-md-2 mt-3">
            <select
                name="NPE"
                value={values.NPE}
                onChange={handleChange}
                aria-label="Default select example"
                className="form-control"
              >
                <option value="">Select NPE</option>
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </select>
						</Col>
            <Col className="form-group col-md-2 mt-3">
            <select
                name="ContingentLawyers"
                value={values.ContingentLawyers}
                onChange={handleChange}
                aria-label="Default select example"
                className="form-control"
              >
                <option value="">Select ContingentLawyers</option>
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </select>
						</Col>
            <Col className="form-group col-md-2 mt-3">
            <select
                name="TynaxRelationship"
                value={values.TynaxRelationship}
                onChange={handleChange}
                aria-label="Default select example"
                className="form-control"
              >
                <option value="">Select Tynax Relationship</option>
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </select>
						</Col>
            <Col className="form-group col-md-2 mt-3">
              <select
                  name="TynaxContact"
                  value={values.TynaxContact}
                  onChange={handleChange}
                  aria-label="Default select example"
                  className="form-control"
                >
                  <option value="">Select Tynax Contact</option>
                  {
                tynaxPeople.map(val => 
                  <option value={val.PersonID}>{`${val.Firstname} ${val.Lastname}`}</option>
                  )
              }
                </select>
						</Col>
						<Col className="form-group col-md-2 mt-3">
							<CustomButton isSubmitting={isSubmitting} btnText='Search' />
						</Col>
						
			</Row>
        </form>
      )}
    </Formik>
    );
}

export default SearchOrganizationsForm;
