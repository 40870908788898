import React, { useState, useEffect, useImperativeHandle, forwardRef }  from "react";
import styled from 'styled-components'
import { useTable, useBlockLayout, useResizeColumns } from 'react-table'
import {  hasKeys, http, apiBaseUrl, getCurrentDate } from "../../utitility";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/core";
import Pagination from '../Tables/Pagination';
import { Col } from "reactstrap";
import { CSVLink } from "react-csv";

const Styles = styled.div`
  padding: 1rem;

  .table {
    display: inline-block;
    border-spacing: 0;
    border: 1px solid grey;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

		.table-head {
      width:100%
		}

    .th,
    .td {
      background: #fff;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid grey;
      border-right: 1px solid grey;
      position: relative;
      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        touch-action:none;
      }
    }
  }
`

const ReactGenericTable = forwardRef(({ columns, defaultSortIndex, defaultSortOrder, url, pagination, setCount, hiddenColumns = [], filter, exportCSV },ref) => {

	const [data, setTableData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentLimit, setCurrentLimit]= useState(20);
	const [lastPage, setLastPage]= useState(0);
	const [totalPages,setTotalPages] = useState(0);
	const [loading,setLoading] = useState(false);
	const [sortOrder,setSortOrder] = useState(defaultSortOrder);
  const [sortIndex,setSortIndex] = useState(defaultSortIndex);
  const [starFilter,setStarFilter] = useState('');

  useEffect(() => {
    if(hiddenColumns.length){
      setHiddenColumns(hiddenColumns);
    }
  },[hiddenColumns]);

  const defaultColumn = React.useMemo(
		() => ({
			width: 150,
		}),
		[]
	)
  useImperativeHandle(ref, () => ({
    reloadTableData() {
      getTableContent(currentPage);
    }
  }));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: hiddenColumns
      }
    },
    useBlockLayout,
    useResizeColumns
  )
  const onSort=(data,type)=>{
		if(data.sort){
			setSortIndex(data.id);
			setSortOrder(type);
			getTableContent('','',data.id,type)
		}
	}
  const override = css`
                    position: absolute;
                    display: block;
                    margin: auto;
                    border-color: red;
                    position: absolute;
                    top: 0px;
                    bottom: 0;
                    right: 0;
                    left: 0;
                  `;

	useEffect(() => {
		getTableContent();
	},[url]);


	const getTableContent = (page,limit,index=sortIndex,order=sortOrder) =>{ 
		page = page ? page :currentPage;
		limit = limit ? limit : currentLimit;
    // console.log('page',page);
		setCurrentPage(page);
		setCurrentLimit(limit);
		if(url){
			setLoading(true);
			let table_load_url= `/api/${url}page=${page}&limit=${limit}&sid=${index}&sod=${order}`;
			if(!pagination){
				table_load_url= `/api/${url}sid=${index}&sod=${order}`;
			} // console.log(table_load_url);
			http.get(apiBaseUrl(table_load_url))
			.then((res) => {
				if (res.data) {
					if(res.data.type === 'sphinx'){
						setTableData(res.data.data[0]);
						setLoading(false);
						setLastPage(getpageCount(res.data.data[1][0].Value,limit));
						setTotalPages(getpageCount(res.data.data[1][0].Value,limit));
					}else{
						setCount && setCount(res.data.data.length);
						setTableData(res.data.data);
						setLoading(false);
						setLastPage(res.data.pages);
						setTotalPages(res.data.pages);
					}
				} else {
					setTableData([]);
					setLoading(false);
				}	 
			})
			.catch((error) => {
				setLoading(false);
        setTableData([]);
			});
		}
	}
  
	const getpageCount=(totalRecord,limit)=>
	{   
		if(totalRecord){
			return Math.ceil(totalRecord/limit)
		}else{
			return 0;
		}
	}

  const filterStar =(page,limit,index=sortIndex,order=sortOrder,filter) =>
  {
    if(data.length > 0)
    {
      page = page ? page :currentPage;
      limit = limit ? limit : currentLimit;
      if(filter)
      {
        setStarFilter(filter);
      }
      // console.log(filter,' ---- ',starFilter);
      setLoading(true);
      setCurrentPage(page);
      setCurrentLimit(limit);
      let table_load_url= `/api/${url}page=${page}&limit=${limit}&sid=${index}&sod=${order}&filter=${filter ? filter : starFilter}`;
      if(!pagination){
        table_load_url= `/api/${url}sid=${index}&sod=${order}&filter=${filter ? filter : starFilter}`;
      } // console.log(table_load_url);
      http.get(apiBaseUrl(table_load_url))
      .then((res) => {
        setTableData(res.data.data);
        setLoading(false);
        // console.log('Data  > ',res);
      })
      .catch((error) => {
        setLoading(false);
        setTableData([]);
      });
    }
  }

  let starFilterUI = [];
  for (let i = 1; i <= 5; i++) 
  {
    starFilterUI.push(
      <i className="fa fa-star" style={{paddingLeft: "4px"}} aria-hidden="true" onClick={()=>filterStar('','','','',i)}></i>
    );
  }

  return (
    <Styles>
      <React.Fragment>
        {filter &&
          <Col className="form-group col-md-2 mt-3" style={{"marginLeft": "80%", "top": "-80px"}}> 
            <span style={{ "fontWeight": "500"}}>Filter by :</span>
            {starFilterUI}
					</Col>
				}
        <div className="table-responsive">
        <table {...getTableProps()} className="table_gen w-auto table-sm table table-striped table-layout-fixed" 
        style={{"tableLayout": "fixed"}}>
          <thead>
          {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="th table-head">
                    {column.render('Header')}
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                    {column.sort && 
                      <span>
                        {sortIndex===column.id ? 
                          <span>{sortOrder === 'DESC' ?
                            <i onClick={()=>onSort(column,'ASC')} className="fa fa-sort-desc" aria-hidden="true"></i>:
                            <i onClick={()=>onSort(column,'DESC')} className="fa fa-sort-asc" aria-hidden="true"></i>
                          }
                          </span>:
                        <span>
                          <i onClick={()=>onSort(column,'ASC')} className="fa fa-sort" aria-hidden="true"></i>
                        </span>	 
                        }
                      </span>
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className="tr">
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className="td">
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {!hasKeys(rows) &&
          <h5 className="text-center">No data found</h5>
        }
        <BounceLoader
          css={override}
          size={50}
          color={"#123abc"}
          loading={loading}
        />
      </div>
      {exportCSV &&
        <Col>
          <CSVLink data={data} filename={"patents exported "+getCurrentDate()+".csv"} className="btn btn-primary btn-sm" style={{"marginLeft": "90%", "marginTop": "10px"}}>Export</CSVLink>
        </Col>
      }
      {pagination &&
        <Pagination  
          // getTableContent={getTableContent} 
          getTableContent={starFilter != '' ? filterStar : getTableContent} 
          currentLimit={currentLimit} 
          currentPage={currentPage}
          lastPage={lastPage}
          totalPages={totalPages}
        />
        }
    </React.Fragment>
    </Styles>
  )
})

export default ReactGenericTable
