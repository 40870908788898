import React, { useEffect, useState, useRef } from "react";
import { notify, http, apiBaseUrl, getCurrentDate,formatDate } from "../../utitility";
import { Formik, Field, ErrorMessage,FieldArray } from "formik";
import CustomButton from './CustomButton';
import Radio from "../Fields/Radio";
import ListingSchema from "../validation/ListingSchema";
import classnames from "classnames";
import { ConnectedFocusError } from 'focus-formik-error';
import Select from 'react-select';
import CheckBox from "../Fields/CheckBox";
import ContactSchema from '../validation/ContactSchema';
import AddTynaxContact from "../Contacts/AddTynaxContact";
import closeIcon from '../../assets/images/closeIcon.png';
import ConfirmationDialogComponent from '../ConfirmationDialog/ConfirmationDialog';
import {useLocation } from "react-router-dom";
const CreateContactForm = ({ contactId, initialState, setInitialState, history, user }) => {
  const location = useLocation();
	const [isDisabled,setIsDisabled] = useState(false);
  const [forceReload,setForceReload] = useState(false);
  const [countryOptions,setcountryOptions] = useState([]);
  const [selectedCountry,setselectedCountry] = useState({ value: '', label: '' });
  const [selectedEmailVerified,setselectedEmailVerified] = useState({ value: '', label: 'Change Status' });
  const [selectedVerifiedStatus,setselectedVerifiedStatus] = useState({ value: '', label: 'Change Status' });
  const [newsletterStatus,setNewsletterStatus] = useState(0);
  const [organization,setOrganization] = useState();
  const [selectedOrganization,setSelectedOrganization] = useState([]);
  const [showAddTynaxContact,setShowAddTynaxContact] = useState(false);
  const [tynaxContactData,setTynaxContactData] = useState([]);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const [removingId,setRemovingId] = useState(null);
  const [tynaxPeople,setTynaxPeople] = useState([]);
  const options = [
		{ value: 1, label: 'Yes' },
		{ value: 0, label: 'No' }
  ]
  const orgOption = [
		{ value: 0, label: 'Search for organization' }
  ]
  const statusOption = [
    { value: "Ok", label: 'Ok' },
		{ value: "Bad", label: 'Bad' },
    { value: "Unknown", label: 'Unknown' },
    { value: "N/A", label: 'N/A' },
  ]
  const responsibleOptions = [
    { value: 1, label: 'Buying Patent' },
    { value: 2, label: 'Selling Patent' },
    { value: 3, label: 'M & A' }
  ]
  useEffect(() => {
    if(countryOptions.length === 0){
      http.get(apiBaseUrl(`/api/getCountry`))
      .then((res) => {
        setcountryOptions(res.data.data);
      }).catch((err)=>{
        console.log(err);
      })
    }

    if(contactId){
      http.get(apiBaseUrl(`/api/getContactInfo?contact_id=${contactId}`))
      .then((res) => {
        let initialStateNew = {...initialState};
        initialStateNew.contactDetails = res.data.contact_details[0];
        initialStateNew.contactDetails.Responsible = JSON.parse(initialStateNew.contactDetails.Responsible);
        setInitialState(initialStateNew);
        setTynaxPeople(res.data.tynax_people);
        res.data.contact_link.map(val => {
              const matchedObj = res.data.tynax_people.find(data => data.PersonID === val.TynaxContactId);
              if(matchedObj){
                tynaxContactData.push({contactId: val.TynaxContactId, tynaxContactType: val.TynaxContactType, label: `${matchedObj.Firstname} ${matchedObj.Lastname}`})
              }})
        var status = res.data.contact_details[0].EmailVerifyStatus
        setselectedEmailVerified({value : res.data.contact_details[0].EmailVerify , label : res.data.contact_details[0].EmailVerify == 1 ? 'Yes' : 'No' });
        setselectedVerifiedStatus({value : status, label : status});
        if(res.data.contact_details[0].Country != '') {
          http.get(apiBaseUrl(`/api/getCountry`))
          .then((ress) => {
            ress.data.data.filter(function (count) {
              if(res.data.contact_details[0].Country == count.value)
              {
                setselectedCountry({ value: count.value, label: count.label });
              }
            });
          }).catch((err)=>{
            console.log(err);
          })
        }
        setNewsletterStatus(res.data.contact_details[0].Newsletter)
      }).catch((err)=>{
        console.log(err);
      })
    }
    if(contactId){
      http.get(apiBaseUrl(`/api/getContactInfo?contact_id=${contactId}`))
			.then((res) => {
      setSelectedOrganization(res.data.organization_link.map((val)=>({value: val.OrgId, label: val.OrgName} )))
			}).catch((error=>{
				console.log(error,'error')
			}))
    }
	},[contactId]);

  const clearFormFields=(resetForm)=>{
    resetForm({});
  }

  const redirectToListingPage=()=>{
    history.goBack();
  }

  const handleCountryChange=(option, setFieldValue)=>{
    setselectedCountry(option);
  }

  const handleEmailVerified=(option, setFieldValue)=>{
    setselectedEmailVerified(option);
  }
  const handleOrganizationChange=(text)=>{
    http.get(apiBaseUrl(`/api/searchOrganizationName?orgName=${text}&page=1`))
    .then((res)=>{
      const organizationOption = res.data.data.map((val)=>({value: val.OrgId, label: val.OrgName}))
      setOrganization(organizationOption);
    })
    .catch((error)=>{
      console.log(error)
    })
  }
  const handleOrganizationSelected = (option,setFieldValue) =>{
        if(option != null){
      if(option[0].label != 'Search for organization'){
        setSelectedOrganization(option);
      }
    }else{
      setSelectedOrganization([])
    }
  }
  const handleEmailVerifyStatus=(option, setFieldValue)=>{
    setselectedVerifiedStatus(option);
  }

  const handleCheckBoxChange=(e,setFieldValue,value)=>{
    if(value == 'Newsletter')
    {
      setNewsletterStatus(!newsletterStatus);
    }
  }
  const changeType = (e, setFieldValue, field) => {
    setFieldValue(field, e.target.value);
  };
  const handleResponsible = (e,tag,setFieldValue,currentArray=[]) =>{
    if (e.target.checked) {
      const values = [...currentArray,tag.value]
      setFieldValue('contactDetails.Responsible', values);
        } 
        else {
          const values = currentArray.filter((arr)=>arr!=tag.value)
          setFieldValue('contactDetails.Responsible', values);
        }
  }
  const handleAddTynaxContact = (selectedContactValue,tynaxContactType) =>{
    var tynaxArray =[]
    tynaxContactData.map((ele)=>{
      tynaxArray.push(ele.contactId)
})
const even = (element) => element == selectedContactValue.value;
if(!tynaxArray.some(even)){
  setTynaxContactData(prevState=>[...prevState,{contactId: selectedContactValue.value, tynaxContactType: tynaxContactType, label: selectedContactValue.label}]);
  setShowAddTynaxContact(false);
}else{
  notify.info('Already exist')
}
  }
  const handleRemovingTynaxContact = () =>{
      const remainingData = tynaxContactData.filter((data)=> (data[data.contactId ? 'contactId' : 'TynaxContactId'] && data[data.contactId ? 'contactId' : 'TynaxContactId'] != removingId)    )
      setTynaxContactData(remainingData)
    setShowConfirmation(false);
  }
  const handlingCreateOrganization = (values) =>{
    const url = `/contacts/create_organizations?redirect=contact&value=${JSON.stringify(values)}&organization=${JSON.stringify(selectedOrganization)}`;
    history.push(url);
  }
  useEffect(()=>{
    const redirect = new URLSearchParams(location.search).get("redirect");
  if (redirect && redirect == "contact"){
    let data = new URLSearchParams(location.search).get("value");
    setInitialState(JSON.parse(data));
    let orgDetail = new URLSearchParams(location.search).get("organization");
    setSelectedOrganization(JSON.parse(orgDetail));
  }
  }, [])
  return (
    <>
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={ContactSchema}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          setSubmitting(true);
          setIsDisabled(true);
          let data = {...values,selectedOrganization,tynaxContactData};
          data.contactDetails.EmailVerify=selectedEmailVerified.value;
          data.contactDetails.EmailVerifyStatus=selectedVerifiedStatus.value;
          data.contactDetails.EmailVerifyDate=selectedEmailVerified.value == 1 ? getCurrentDate() : 'N/A'; 
          data.contactDetails.CreatorId=user.UserID;
          data.contactDetails.Country=selectedCountry.value;
          data.contactDetails.Newsletter=newsletterStatus;
          data.contactDetails.Responsible = JSON.stringify(data.contactDetails.Responsible);
          data.contactDetails.CreateDate = data.contactDetails.CreateDate ? data.contactDetails.CreateDate : formatDate(getCurrentDate());
          data.contactDetails.UpdateDate = contactId ? formatDate(getCurrentDate()):'';

          if(!contactId){
            data.contactDetails.Verified=1;
            http.post(apiBaseUrl(`/api/upsertContactInfo`),data)
            .then((res) => {
              setSubmitting(false);
              setIsDisabled(false);
              notify.success("Contact created successfully");
              clearFormFields(resetForm,setFieldValue);
              history.push(`/contacts/all_contacts`); 
            }).catch((error)=>{
              setSubmitting(false);
              setIsDisabled(false);
              notify.error(error);
            });
          }else{
            http.post(apiBaseUrl(`/api/upsertContactInfo?contact_id=${contactId}`),data)
            .then(() => {
              setSubmitting(false);
              setIsDisabled(false);
              if(window.location.pathname === '/mycontacts/contact_edit'){
                history.push('/contacts/mylisting');
              }
              if(window.location.pathname === '/serachcontacts/contact_edit'){
                history.push('/contacts/search_contacts');
              }
              if(window.location.pathname === '/contact_edit'){
                http.get(apiBaseUrl(`/api/getContactInfo?contact_id=${contactId}`))
                history.push(`contacts/contact_info?contact_id=${contactId}`);
              }
              if(window.location.pathname === '/contacts/contact_edit'){
                history.push('/contacts/all_contacts');
              }
              notify.success("Contact updated successfully");
            }).catch((error)=>{
              setSubmitting(false);  
              setIsDisabled(false);
              notify.error(error);
            });
          }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              First Name:
            </label>
            <Field type="text" name="contactDetails.Firstname" className="form-control"  />
            {errors.Firstname && touched.Firstname}
            <ErrorMessage name="contactDetails.Firstname" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Last Name:
            </label>
            <Field type="text" name="contactDetails.Lastname" className="form-control"  />
            {errors.Lastname && touched.Lastname}
            <ErrorMessage name="contactDetails.Lastname" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Nickname:
            </label>    
            <Field type="text" name="contactDetails.Nickname" className="form-control"  />
            {errors.Nickname && touched.Nickname}
            <ErrorMessage name="contactDetails.Nickname" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Job Title:
            </label>
            <Field type="text" name="contactDetails.Title" className="form-control"  />
            {errors.Title && touched.Title}
            <ErrorMessage name="contactDetails.Title" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Organization:
            </label>
            <div className="row pl-3">
            <Select value={selectedOrganization} name="InitialOrganization" onChange={(e)=>{handleOrganizationSelected(e,setFieldValue)}} options={organization ? organization : orgOption} isMulti onInputChange={handleOrganizationChange} className="css-2b097c-container-organizatoin" /> 
            <div className="pl-2">
            <a className="btn btn-primary btn-sm pl-3" onClick={()=>handlingCreateOrganization(values)} role="button">Create organization</a>
            </div>
            </div>
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Phone: Work:
            </label>    
            <Field type="text" name="contactDetails.WorkPhone" className="form-control"  />
            {errors.WorkPhone && touched.WorkPhone}
            <ErrorMessage name="contactDetails.WorkPhone" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Mobile:
            </label>            
            <Field type="text" name="contactDetails.MobilePhone" className="form-control"  />
            {errors.MobilePhone && touched.MobilePhone}
            <ErrorMessage name="contactDetails.MobilePhone" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Fax:
            </label>            
            <Field type="text" name="contactDetails.OtherPhone" className="form-control"  />
            {errors.OtherPhone && touched.OtherPhone}
            <ErrorMessage name="contactDetails.OtherPhone" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Primary Email:
            </label>            
            <Field type="text" name="contactDetails.PrimaryEmail" className="form-control"  />
            {errors.PrimaryEmail && touched.PrimaryEmail}
            <ErrorMessage name="contactDetails.PrimaryEmail" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Email Verified:
            </label> 
            <Select value={selectedEmailVerified} name="EmailVerify" options={options} onChange={(e)=>handleEmailVerified(e,setFieldValue)} />           
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Verified Status:
            </label> 
            <Select key={new Date()} value={selectedVerifiedStatus} name="EmailVerifyStatus" options={statusOption} onChange={(e)=>handleEmailVerifyStatus(e,setFieldValue)} >  
            </Select>     
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Verified Date: {selectedEmailVerified.value ? formatDate(getCurrentDate()) : 'N/A'}
            </label>
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Secondary Email:
            </label>           
            <Field type="text" name="contactDetails.SecondaryEmail" className="form-control"  />
            {errors.SecondaryEmail && touched.SecondaryEmail}
            <ErrorMessage name="contactDetails.SecondaryEmail" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Dealsmiths Website Code:
            </label>            
            <Field type="text" name="contactDetails.DealsmithWebsiteCode" className="form-control"  />
            {errors.DealsmithWebsiteCode && touched.DealsmithWebsiteCode}
            <ErrorMessage name="contactDetails.DealsmithWebsiteCode" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Source Website:
            </label>            
            <Field type="text" name="contactDetails.SourceWebsite" className="form-control"  />
            {errors.SourceWebsite && touched.SourceWebsite}
            <ErrorMessage name="contactDetails.SourceWebsite" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Website:
            </label>            
            <Field type="text" name="contactDetails.Website" className="form-control"  />
            {errors.Website && touched.Website}
            <ErrorMessage name="contactDetails.Website" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Skype:
            </label>            
            <Field type="text" name="contactDetails.Skype" className="form-control"  />
            {errors.Skype && touched.Skype}
            <ErrorMessage name="contactDetails.Skype" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Linkedin:
            </label>            
            <Field type="text" name="contactDetails.Linkedin" className="form-control"  />
            {errors.Linkedin && touched.Linkedin}
            <ErrorMessage name="contactDetails.Linkedin" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Address:
            </label>           
            <Field type="text" name="contactDetails.Address" className="form-control"  />
            {errors.Address && touched.Address}
            <ErrorMessage name="contactDetails.Address" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              City:
            </label>           
            <Field type="text" name="contactDetails.City" className="form-control"  />
            {errors.City && touched.City}
            <ErrorMessage name="contactDetails.City" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              State:
            </label>           
            <Field type="text" name="contactDetails.State" className="form-control"  />
            {errors.State && touched.State}
            <ErrorMessage name="contactDetails.State" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Zip code:
            </label>           
            <Field type="text" name="contactDetails.ZipCode" className="form-control"  />
            {errors.ZipCode && touched.ZipCode}
            <ErrorMessage name="contactDetails.ZipCode" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Country:
            </label>
            <Select value={selectedCountry} name="Country" options={countryOptions} onChange={(e)=>handleCountryChange(e,setFieldValue)} />        
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              {contactId ? `Created by: ${initialState.contactDetails.CreatorName}` : ''}
            </label>
          </div>
          <div className="form-group row pl-3" hidden={!contactId}>
            <label className="font-weight-bold form-text pr-2" htmlFor="exampleInputEmail1">
            Preferred Tynax Contact:
            </label>
            <span className="btn btn-primary btn-sm" onClick={()=>{setShowAddTynaxContact(val=>!val)}} >Add Tynax Contact</span>
          </div>
          <div className="row pl-3">
          {tynaxContactData&&tynaxContactData.map(val =>(
          <div class="border-card-tynax-contact pl-3 mb-3"hidden={!contactId}>
								<div class="d-flex justify-content-between">
										<h6 class="text-dark my-2">{val.label} {val.tynaxContactType ? '(Preferred)' : '(Exclusive)'} </h6>
                    <img src={closeIcon}height="25px"width="25px"onClick={()=>{setShowConfirmation(true);setRemovingId(val.contactId ? val.contactId : val.TynaxContactId)}}/>
						</div>
            </div>
                  ))}
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Description:
            </label>
            <Field type="text" name="contactDetails.Description" component="textarea" className="form-control"  />
            {errors.Description && touched.Description}
            <ErrorMessage name="contactDetails.Description" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Keywords:
            </label>
            <Field type="text" name="contactDetails.Keywords" component="textarea" className="form-control"  />
            {errors.Keywords && touched.Keywords}
            <ErrorMessage name="contactDetails.Keywords" component="div" className="text-danger" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Mailing Notes:
            </label>
            <Field type="text" name="contactDetails.mailing_notes" component="textarea" className="form-control"  />
            {errors.mailing_notes && touched.mailing_notes}
            <ErrorMessage name="contactDetails.mailing_notes" component="div" className="text-danger" />
          </div>
          <div className="form-check form-check-inline">
            <label className="font-weight-bold form-text pr-3" htmlFor="exampleInputEmail1">
              Newsletter :
            </label>
            <input
              className="form-check-input"
              value={values.Newsletter}
              type="checkbox"
              onChange={e => {handleCheckBoxChange(e,setFieldValue,'Newsletter')}}
              id="Newsletter"
              checked={newsletterStatus}
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text pr-3" htmlFor="exampleInputEmail1" >
              US Patent Attorney:
            </label>
            <Radio
              id="US_Patent_Attorney_Yes"
              name="contactDetails.USPatentAttorney"
              value={1}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,'US_Patent_Attorney_No')}}
            />
            <Radio
              id="US_Patent_Attorney_No"
              name="contactDetails.USPatentAttorney"
              value={0}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,'US_Patent_Attorney_No')}}
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text pr-3" htmlFor="exampleInputEmail1" >
              Member Of AUTM:
            </label>
            <Radio
              id="Mem_Autm_Yes"
              name="contactDetails.MemberOfAUTM"
              value={1}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,'Mem_Autm')}}
            />
            <Radio
              id="Mem_Autm_No"
              name="contactDetails.MemberOfAUTM"
              value={0}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,'Mem_Autm')}}
            />
          </div>
          <div className="form-check form-check-inline row">
            <div className="col-12">
              <label className="font-weight-bold form-text pr-3" htmlFor="exampleInputEmail1">
                Responsible for:
              </label>
            </div>
            <FieldArray
    name="contactDetails.Responsible"
    render={arrayHelpers => (
        <div>
            {responsibleOptions.map(tag => (
              <div className="pl-3">
                    <input
                        name="contactDetails.Responsible"
                        type="checkbox"
                        value={tag.value}
                        checked={values.contactDetails.Responsible ? values.contactDetails.Responsible.includes(tag.value) : null}
                        onChange= {e=>handleResponsible(e,tag,setFieldValue,values.contactDetails.Responsible)}
                    />
                    <label className="pl-2">
                      {tag.label}
                    </label>
                    </div>
            ))}
        </div>
    )}
/>
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text pr-3" htmlFor="exampleInputEmail1" >
              OK to Send Patent Numbers:
            </label>
            <Radio
              id="OkSendPatentNo_Yes"
              name="contactDetails.OkSendPatentNo"
              value={1}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
            <Radio
              id="OkSendPatentNo_No"
              name="contactDetails.OkSendPatentNo"
              value={0}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
            <Radio
              id="OkSendPatentNo_Un"
              name="contactDetails.OkSendPatentNo"
              value={2}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              {contactId ? `Create Date: ${formatDate(initialState.contactDetails.CreateDate)}` :''}
            </label>
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              {contactId ? `Update Date: ${formatDate(getCurrentDate())}` : ''}
            </label>
          </div>
          <div className="text-center py-2">
            <button 
                  disabled={isDisabled} 
                  type="submit" 
                  className={classnames({
                    "buttonload btn btn-primary btn-sm": isDisabled === true,
                    "btn btn-primary btn-sm": isDisabled === false,
                  })}>
                    Submit
            </button>
            <button type="button" onClick={(e) => redirectToListingPage()} className="ml-2 btn btn-secondary">Cancel</button>
          </div>
        </form>
      )}
    </Formik>
    {showAddTynaxContact && <AddTynaxContact show={showAddTynaxContact} handleClose={()=>setShowAddTynaxContact(false)} handleAddTynaxContact={handleAddTynaxContact} tynaxPeople={tynaxPeople} />}
    {showConfirmation && <ConfirmationDialogComponent show={showConfirmation} close={()=>{setShowConfirmation(false)}}confirm={handleRemovingTynaxContact}/>}
    </>
  );
};
export default CreateContactForm;
