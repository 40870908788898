import React, { Component } from "react";
import MoveTynaxBlue250_6 from '../assets/images/MoveTynaxBlue250-6.png';
import { PublicLeftMenu } from "../Components";
class ServicesPatentProtectionPage extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div id="content">
        <PublicLeftMenu/>
        <div className="box-main">
          <h1>Monetizing a Large Patent Portfolio</h1>

          <p>
            If you're looking to monetize a large patent portfolio, look no
            further than Tynax. Tynax can analyze your patents, finding the most
            valuable assets, then organize a monetization program that involves
            patent sale and/or licensing transactions. Sometimes a combination
            of patent selling and licensing is the most effective approach.
            Please contact us here at Tynax and we will be happy to take a look
            at your portfolio and give you an assessment of what we think might
            be realistically possible.
            <img
              src={MoveTynaxBlue250_6}
              style={{
                float: "right",
                marginTop: "8px",
                marginLeft: "8px",
                marginBottom: "8px",
              }}
            />
          </p>
          <h3>
            Unique Patent Analysis Tools Help Identify Your Most Marketable
            Assets
          </h3>
          <p></p>
          <p>
            {" "}
            Tynax has unique tools to analyze a large patent portfolio, rate all
            the patents, identify patents that are most likely infringed, then
            Tynax has capabilities to gather evidence of use and prepare
            portfolios of patents for sale and licensing. This process can help
            you manage your costs and reduce your prosecution and maintenance
            fees by evaluating the market potential for each of your patents.
            This provides you with the information you need to determine where
            you might want to invest in future. There will be some patents and
            applications that are worth pursuing, but there are also sure to be
            some inventions that are going nowhere and are best discontinued.
            Substantial savings can be achieved by dropping dead-end patents,
            cutting the cost of prosecution and maintenance fees.
          </p>
          <h3>Help You Cut Costs as Well as Increase Return on Investment</h3>
          <p></p>
          <p>
            It's hardly surprising that the patents that are most likely
            sellable, and most suitable for generating licensing revenues are
            the most heavily infringed assets and are the most valuable.
            Companies sometimes try to sell off the weak patents instead of
            simply abandoning them, but the idea of selling off the weakest
            patents is simply not practical. It would be a waste of time to try
            to sell off all the weaker patents as buyers are only interested in
            heavily infringed patents that can stand up to the rigors of
            litigation.
          </p>
          <h3>Innovative Routes to Profitable Patent Monetization</h3>
          <p></p>
          <p>
            If you're looking to extract revenues from a large patent portfolio,
            through patent sale and licensing, please get in touch. The market
            is very tough, but we have some unique capabilities and innovative
            routes to profitable patent monetization.
          </p>

          <div style={{ margin: "15px 4px" }}>
            <a
              className="ovalbutton"
              href="contact.php"
              style={{ marginLeft: "6px" }}
            >
              <span>Tell Me More</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default ServicesPatentProtectionPage;
