import React from 'react'
import { Field } from 'formik'

const CheckBox = (props) => 
  <Field name={props.name} key={props.index}>
    {({ field, form }) => (
      <React.Fragment>
        <div className="form-check">
        <input 
          key={props.index}
          {...props}
          type="checkbox" 
          checked={field.value.includes(props.value)}
          className="form-check-input"
          onChange={() => {
            if (field.value.includes(props.value)) {
              const nextValue = field.value.filter(
                value => value !== props.value
              );
              form.setFieldValue(props.name, nextValue);
            } else {
              const nextValue = field.value.concat(props.value);
              form.setFieldValue(props.name, nextValue);
            }
          }}
        />
        <label className="form-check-label">{props.label}</label>
        </div>
      </React.Fragment>
      
    )}
  </Field>

export default CheckBox