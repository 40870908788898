import React,{useContext} from "react";
import { MyAccountDetailSection } from "../Components";
import EOUPage from "./EOUPage"
import MyAccountpage from "./MyAccountPage"
import WantedPage from "./WantedPage"
import AdminPage from "./AdminPage";
import ProfilePage from "./ProfilePage"
import PursuePage from "./PursuePage"
import AnalysisPage from "./AnalysisPage"
import ContactPage from "./ContactPage"
import {hasKeys} from '../utitility';
import { UserContext } from "../Context/UserContext";
import { useLocation } from 'react-router-dom';

const MainPage = () => {
  const {user,setUser} = useContext(UserContext);
  const  location = useLocation();
  const pathName = location.pathname;
  let splittedPath = pathName.split("/");

    return (
      <React.Fragment>
        {hasKeys(user)&&
        <div>
          <section>
              <div className="container-fluid bg-white">
                  <div className="row">
                      <div className="col-12">
                        <div className="border-bg">
													{splittedPath[1] === 'myaccount' &&
                          	<MyAccountpage user={user} />
													}
													{splittedPath[1] === 'profile' &&
														<ProfilePage user={user} setUser={setUser} asAdmin={false}/>
													}
                          {splittedPath[1] === 'admin' && 
                             <AdminPage user={user} />
                          }
                          {splittedPath[1] === 'pursue' &&
                          	<PursuePage user={user} />
													}
                          {splittedPath[1] === 'patent' &&
                          	<MyAccountpage user={user} />
													}
                         
                          {/* {splittedPath[1] === 'wanted' &&
                          	<WantedPage user={user} />
													}
                          {splittedPath[1] === 'eou' &&
                          	<EOUPage user={user} />
													}
                          {splittedPath[1] === 'analysis' &&
                          	<AnalysisPage user={user} />
													} */}
                          {splittedPath[1] === 'contacts' &&
                          	<ContactPage user={user} />
													}
                        </div>
                      </div>
                  </div>
            </div>
            </section>
          </div>}
      </React.Fragment>
    );
}
export default MainPage;
