
import React, { useMemo, useState, useRef }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { formatDate } from '../../utitility';
import Switch from '../Fields/Switch';
import 'react-confirm-alert/src/react-confirm-alert.css';

const PatentEOU = ({patentId}) => {
	
	const [count,setCount] = useState(0);
	const title = `EOU - ${count}`;
	const childRef = useRef();

	const columns = useMemo(
		() => [
			{
				Header: "Patent ID",
				accessor: "Patent",
				width: 100,
				sort:true
			},
			{
				Header: "Count",
				accessor: "Cited_count",
				width: 70,
				sort:true
			},
			{
				Header: "Listing ID",
				accessor: "List",
				width: 100,
				sort:true
			}
			,{
				Header: "Target Company",
				accessor: "TargetCompany",
				width: 115
			},{
				Header: "Target Product",
				accessor: "TargetProduct",
				width: 115,
				sort:true
			},{
				Header: "EOU Type",
				accessor: "EOUType",
				width: 100,
				sort:true
			},{
				Header: "Probability",
				accessor: "Probability",
				width: 100,
				sort:true
			},
			{
				Header: "EOURating",
				accessor: "EOURating",
				width: 100,
				sort:true
			},
			{
				Header: "EOUAnalysisLevel",
				accessor: "EOUAnalysisLevel",
				width: 100
			},{
				Header: "Analyst",
				accessor: "Analyst",
				width: 100,
				sort:true
			},{
				Header: "Rating",
				accessor: "Qps",
				width: 50,
				sort:true
			},{
				Header: "Created",
				accessor: "CreateDate",
				width: 90,
				sort:true,
				Cell: data => {return formatDate(data.cell.value)}
			},{
				Header: "Action",
				accessor: "Action",
				width: 60
			}
		],
		[]
	)

  const isActive = (count > 0 ? true : false);
  return (
	<Switch
		classNames="icon icon-uploadAgreement-icon"
		title={title}
		className="grouped-body"
		id="ptn_eou"
		collapsible={true}
		activeStatus={isActive}
	>
		<section className="mb-3">
			<React.Fragment>
				{patentId	&&
					<ReactGenericTable  
						ref={childRef}
						setCount={setCount} 
						url={`getEouByPatentNumber?Patent=${patentId}&`} 
						defaultSortIndex="Patent" 
						defaultSortOrder="DESC" 
						columns={columns}
					/>
				}	
			</React.Fragment>
		</section>
	</Switch>
  );
};
export default PatentEOU;