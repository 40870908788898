import React,{useContext, useState, useEffect} from "react";
import {  CreateListingForm } from "../Components";
import { hasKeys, getStandardDateForm } from '../utitility'
import { UserContext } from "../Context/UserContext";
import { useLocation } from 'react-router-dom';

const CreateListingpage = ({history}) => {
	
	const {user} = useContext(UserContext);
	const [listingId,setListingId] = useState(0);

	const getAvailableStatus=()=>{
		if(hasKeys(user)){
			return 1;
		// 	if(user.UserType==40){
		// 		return 1;
		//   }
		//   else{
		// 	return 0;
		//   }
		}
	}

	const getStartDate=()=>{
		if(hasKeys(user)){
			if(user.UserType==40){
				return getStandardDateForm();
		  	}
			else{
				return "";
			}
		}
	}

	const [listing] =useState({
		ListingType:1,
		ListingName:"",
		Brokercode:user.BrokerID,
		OwnerID:user.UserID,
		ListingStatus:"",
		AvailableStatus:getAvailableStatus(),
		PrivateFlag:"",
		AccessControl:"",
		DealStructure:2,
		ListingDescription:"",
		DealStructureNotes:"",
		Keywords:"",
		StartDate:getStartDate(),
		EndDate:"",
		CreateDate:getStandardDateForm(),
	});
	const [listing_details] = useState({
		PatentBackLicense:0,
		PatentIPOwnership:0,
		PatentLicensingStatus:0,
		PatentLicensingArrangement:"",
		PatentPotentialInfringers:'',
		PatentNotes:'',
		PatentLitigationNotes:'',
		PatentLitigation:0,
		TechnologyPrimaryApplication:'',
		PatentInfringement:0,
		TechnologyOverview:"",
		CreateDate:getStandardDateForm()
	});
	const [salesInfo] =useState({
		Seller:"",
		TargetPrice:"",
		MinimumAcceptablePrice:"",
		PricingNotes:"",
		ReasonsForListing:"",
		DecisionMakingProcess:"",
		MarketingMaterials:"",
		PriorPromotionalEfforts:"",
		OtherSalesNotes:"",
		CreateDate:getStandardDateForm(),
		UpdateDate:getStandardDateForm()
	})
	const  location = useLocation();
  
	const [initialState,setInitialState] = useState({
		user_id:user.UserID,
		listing:listing,
		listing_details:listing_details,
		salesInfo:salesInfo
	});

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		let listingId = params.get('listing_id');
		setListingId(listingId);
	},[location.search]);

  return (
		<React.Fragment>
			{hasKeys(user) &&
						<div className="container-fluid bg-white">
										<div className="border-bg">
											<section className="py-3 mb-3 ml-2 mr-2 row">
												<div className="col-12">
													<h4 className="mb-3">
														<b className="text-primary">Let Us Know About The Patents You’re Looking to Monetize</b>
													</h4>
													<p className="mb-3">
														If you have patents that you're looking to monetize, please go ahead and let us know by completing and submitting this form. 
														This information will be shared with a small number of Tynax clients—attorneys and patent monetization specialists seeking 
														patents and new cases. You will receive updates as your patents are reviewed and we will be in touch when our clients have 
														offers or proposals for you.  
													</p>
													<h5 className="mb-3">
														<b className="text-primary">Free Service—No Obligation—No Fees</b>
													</h5>
													<p className="mb-2">
														This is a free service for patent holders, and you are not obliged to accept any offers or pay any fees. Tynax is 
														compensated by its buying clients.
													</p>
													<h5 className="mb-3">
														<b className="text-primary">Confidentiality</b>
													</h5>
													<p className="mb-2">
														The information you submit on this form will be treated as confidential and will be shared with Tynax clients under the 
														terms of a confidentiality agreement. If you wish to enter into a confidentiality agreement with Tynax, please complete this &nbsp;
														<u><a className="text-secondary" target="_blank" href="https://tynax.com/wp-content/uploads/sites/38/2021/06/Tynax-Mutual-NDA-PDF-1.pdf">Mutual Non-Disclosure Agreement</a></u>&nbsp;
														and attach the completed agreement to your listing on the next page. After you submit this page, you will be invited to attach claims charts or any other pertinent documents. Please attach your signed confidentiality agreement at that time.
													</p>
												</div>
											</section>
											<section className=" mb-5 ml-5 row">
												<div className="col-9">
													<CreateListingForm user={user} history={history} listingId={listingId} listing={listing} initialState={initialState} setInitialState={setInitialState}/>
												</div>
											</section>
										</div>
									</div>
				}
		</React.Fragment>
  );
}
export default CreateListingpage;
