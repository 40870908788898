import React, {useRef} from "react";
import { Formik } from "formik";
import CustomButton from './CustomButton';
import { Row, Col } from "reactstrap";
import { hasKeys } from "../../utitility";

const SearchPatentForm = ({setUrl,reloadTableData,user}) => {

		const  cleanObj = (obj) => {
			for (var propName in obj) { 
				if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
					delete obj[propName];
				}
			}
			return obj;
		}

    const clean =(array)=>{
      return array.filter(function (el) {
        return el != null;
      });
    }
    const buildQuery=(params)=>{
      var esc = encodeURIComponent;
      var query = Object.keys(params)
                  .map(function(k) {
                    if(esc(params[k])){
                      return esc(k) + '=' + esc(params[k].trim())
                    }
                  });
      if(query){
        query= clean(query).join('&')
      }
      return query;
    }

    return (
      <Formik
      initialValues={{
        PatentNumber: "",
        Inventor: "",
        Assignee: "",
		keywords: ""
      }}
      validate={(values) => {
				const errors = {};
				if(!hasKeys(cleanObj(values))){
					errors.listing_number = 'please enter at least one value to search';
				}
        return errors;
      }}
      onSubmit={(values,{ setSubmitting }) => {
        setSubmitting(false)
        let query = buildQuery(values);
        query = `searchPatent?${query}&userType=${user.UserType}&userID=${user.UserID}&`;
				setUrl(query);
				reloadTableData();
      }}
    >
      {({
        values,
        handleChange,
				handleBlur,
				errors,
        touched,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
					<Row className="ml-2">
						<Col className="form-group col-md-2 mt-3">
							<input
								type="text"
								name="PatentNumber"
								className="form-control"
								onChange={handleChange}
								value={values.PatentNumber}
								placeholder="Patent Number"
							/>
							<span className="text-danger">
              	{errors.PatentNumber && touched.PatentNumber && errors.PatentNumber}
              </span>
						</Col>
						<Col className="form-group col-md-2 mt-3">
						<input
								type="text"
								name="Assignee"
								className="form-control"
								onChange={handleChange}
								value={values.Assignee}
								placeholder="Assignee"
							/>
						</Col>
            <Col className="form-group col-md-2 mt-3">
							<input
								type="text"
								name="Inventor"
								className="form-control"
								onChange={handleChange}
								value={values.Inventor}
								placeholder="Inventor"
							/>
						</Col>
            <Col className="form-group col-md-2 mt-3">
							<input
								type="text"
								name="Keywords"
								className="form-control"
								onChange={handleChange}
								value={values.with_all}
								placeholder="Keywords"
							/>
						</Col>
						{/* <Col className="form-group col-md-2 mt-3">
							<input
								type="text"
								name="with_atleast_one"
								className="form-control"
								onChange={handleChange}
								value={values.with_atleast_one}
								placeholder="at least one of these words"
							/>
						</Col>
						<Col className="form-group col-md-2 mt-3">
							<input
								type="text"
								name="PatentClass"
								className="form-control"
								onChange={handleChange}
								value={values.PatentClass}
								placeholder="Patent Class"
							/>
						</Col> */}
						<Col className="form-group col-md-2 mt-3">
							<CustomButton isSubmitting={isSubmitting} btnText='Search' />
						</Col>
					</Row>
        </form>
      )}
    </Formik>
    );
}

export default SearchPatentForm;
