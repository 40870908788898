import React,{useContext} from "react";
import { Profile } from "../Components";

const ProfilePage = ({user,setUser, history}) => {

  return (
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
							<section className="py-3 mb-5 ml-5 row">
                <div className="col-6">
								  <Profile  user={user} setUser={setUser} history={history} />
                </div>
							</section>
            </div>
          </div>
  );
}
export default ProfilePage;
