import React, { useMemo,useRef }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { formatDate, getStatus, http, apiBaseUrl, notify } from '../../utitility';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; 

const Organizations = ({user}) => {
	const childRef = useRef();
	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}
	const getActionField = (OrgId) => {
		return( 
			<div>
				<Link to={`/contacts/org_details/contact_listing?org_id=${OrgId}`} className="mr-2 table_font_size btn-link">Contacts</Link>
				<Link to={`/contacts/edit_organizations?org_id=${OrgId}`} className="mr-2 table_font_size btn-link">Edit</Link>
				<span onClick={()=>ConfirmAlert(OrgId)} className="mr-2 table_font_size btn-link link-pointer">Delete</span>
			</div>
		)
	}
	const ConfirmAlert = (value) => {
		confirmAlert({
			message: 'Do you really want to delete this organization?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteOrganization(value)
				},
				{
					label: 'No',
					onClick: () => {}
				}
			]
		});
	}
	const deleteOrganization = (orgId) => {
		http.delete(apiBaseUrl(`/api/deleteOrganization?org_id=${orgId}`))
		.then(() => {
			reloadTableData();
			notify.success("Deleted successfully");
		}).catch((error)=>{
			console.log('error',error)
			let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
											error.response.data.message :
											'whoops...something went wrong';
			notify.error(errorMsg);
		});
	}
	const columns = useMemo(
		() => [
			{
				Header: "Name",
				accessor: "OrgName",
				width: 200,
				sort:true,
				Cell: data => {return (<Link to={`/contacts/org_info?org_id=${data.row.original.OrgId}`}>{data.row.original.OrgName}</Link>)},

			},
			{
				Header: "Contacts",
				accessor: "noOfContacts",
				width: 130,
				sort:true
			},
			{
				Header: "Created Date",
				accessor: "CreateDate",
				width: 130,
				sort:true,
				Cell: data => {return formatDate(data.cell.value)}
			},
			{
				Header: "Updated Date",
				accessor: "UpdateDate",
				width: 130,
				sort:true,
				Cell: data => {return formatDate(data.cell.value)}
			},
			{
				Header: "Status",
				accessor: "Status",
				width: 130,
				Cell: data => {return getStatus(data.cell.value)},
				sort:true
			},
			{
				Header: "Action",
				accessor: "act",
				Cell: data => (getActionField(data.row.original.OrgId)),
				width: 200
			}
		],
		[]
	)

  return (
				<React.Fragment>
					<div className="px-2">
						<u><Link  className="px-2 text-primary" to={'/contacts/mylisting'}>My Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_contact'}>Create Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_contacts'}>Search Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_organizations'}>Create Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/organizations'}>Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_organizations'}>Search Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/mailing_lists'}>Mailing lists</Link></u>
					</div>
					<div>
						<ReactGenericTable ref={childRef}  pagination={true} url={`getOrganizations?`} defaultSortIndex="OrgId" defaultSortOrder="DESC" columns={columns} />
					</div>
				</React.Fragment>
  );
};
export default Organizations;