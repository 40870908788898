import React, { useEffect, useState, useRef } from "react";
import CreateAttachment from '../ListingDetails/CreateAttachment';
import { notify, http, apiBaseUrl } from "../../utitility";
import classnames from "classnames";
import AttachmentForm from "./AttachmentForm";

const UploadAttachmentForm = ({ user, listingId,initialState, setInitialState, history }) => {
	const [forceReload,setForceReload] = useState(false);

  useEffect(() => {
    if(listingId){
      http.get(apiBaseUrl(`/api/getListingDetailById?listing_id=${listingId}`))
      .then((res) => {
        let initialStateNew = {...initialState};
        initialStateNew.listing = res.data.listing;
        initialStateNew.listing_details = res.data.listing_details;
        initialStateNew.salesInfo =res.data.salesInfo;
        setInitialState(initialStateNew);
      }).catch((err)=>{
        console.log(err);
      })
    }
	},[listingId]);
  
  return (
				<React.Fragment>
          <div>
            <CreateAttachment forceReload={forceReload} setForceReload={setForceReload} listings={initialState.listing} user={user} history={history} />
          </div>
        </React.Fragment>
  );
};
export default UploadAttachmentForm;
