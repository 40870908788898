import React,{useContext, useState, useEffect} from "react";
import {  CreateContactForm } from "../Components";
import { hasKeys, getStandardDateForm } from '../utitility'
import { UserContext } from "../Context/UserContext";
import { useLocation } from 'react-router-dom';

const CreateContactpage = ({history}) => {
	
	const {user} = useContext(UserContext);
	const [contactId,setContactId] = useState(0);

	const getAvailableStatus=()=>{
		if(hasKeys(user)){
			if(user.UserType==40){
				return 1;
		  }
		  else{
			return 0;
		  }
		}
	}

	const [contact] =useState({
		FirstName:"",
		LastName:"",
		Brokercode:user.BrokerID,
		OwnerID:user.UserID,
		ListingStatus:"",
		AvailableStatus:getAvailableStatus(),
		PrivateFlag:"",
		AccessControl:"",
		DealStructure:2,
		ListingDescription:"",
		DealStructureNotes:"",
		Keywords:"",
		StartDate:"",
		EndDate:"",
		CreateDate:getStandardDateForm(),
	});
	
	const  location = useLocation();
  
	const [initialState,setInitialState] = useState({
		user_id:user.UserID,
		contact:contact,
	});

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		let contactId = params.get('contact_id');
		setContactId(contactId);
	},[location.search]);

  return (
		<React.Fragment>
			{hasKeys(user) &&
						<div className="container-fluid bg-white">
										<div className="border-bg">
											<section className=" mb-5 ml-5 row">
												<div className="col-9">
													<CreateContactForm user={user} history={history} contactId={contactId} contact={contact} initialState={initialState} setInitialState={setInitialState}/>
												</div>
											</section>
										</div>
									</div>
				}
		</React.Fragment>
  );
}
export default CreateContactpage;
