import React,{useRef,useMemo, useState,useEffect} from "react";
import { useLocation,Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; 
import { http, apiBaseUrl, notify} from '../../utitility';
import  ReactGenericTable from '../Tables/ReactGenericTable';
const NotesListByContactId = (props) =>{
    const childRef = useRef();
	const  location = useLocation();
    const [contactId,setContactId] = useState(new URLSearchParams(location.search).get('contact_id'))
    const reloadTableData = () => {
		childRef.current.reloadTableData()
	}
    const getMessage = (row) =>{
        const message = row.Message;
        const buffer = Buffer.from(message.data);
        const decodedString = buffer.toString('utf-8');
        return (<div dangerouslySetInnerHTML={{ __html: decodedString}}></div>)
    }
    const getActionField = (note_id) => {
		return( 
			<div>
				<span onClick={()=>{props.getNotesData(note_id)}} className="mr-2 table_font_size btn-link link-pointer">Edit</span>
				<span onClick={()=>ConfirmAlert(note_id)} className="mr-2 table_font_size btn-link link-pointer">Delete</span>
			</div>
		)
	}
    const ConfirmAlert = (value) => {
		confirmAlert({
			message: 'Do you really want to delete this note?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteMailingList(value)
				},
				{
					label: 'No',
					onClick: () => {}
				}
			]
		});
	}

	const deleteMailingList = (note_id) => {
		http.delete(apiBaseUrl(`/api/deleteNote?note_id=${note_id}`))
		.then(() => {
			reloadTableData();
			notify.success("Deleted successfully");
		}).catch((error)=>{
			let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
											error.response.data.message :
											'whoops...something went wrong';
			notify.error(errorMsg);
		});
	}
    const columns = useMemo(
		() => [
			{
				Header: "Tag",
				accessor: "Tag",
				width: 130,
				sort:true
			},
            {
				Header: "Listing Id",
				accessor: "ListingID",
				width: 130,
				sort:true
			},
            {
                	Header: "Message",
                	accessor: "Message",
                	width: 130,
                	sort:true,
				    Cell: data => (getMessage(data.row.original)),
                },
			{
				Header: "Action",
				accessor: "act",
				Cell: data => (getActionField(data.row.original.NotesId)),
				width: 130
			}
		],
		[]
	)
    useEffect(() => {
       reloadTableData()
      },[props.load]);
    return(
        <React.Fragment>
            <div className="container-fluid bg-white">
                <div className="justify-content-center py-1">
                   {contactId && <ReactGenericTable 
                    pagination={true} 
                    url={`getNotesByContactId?contact_id=${contactId}&`}
                     ref={childRef} defaultSortIndex="NotesId"
                     defaultSortOrder="DESC" 
                     columns={columns} />}
                </div>
            </div>
        </React.Fragment>
    )
}
export default NotesListByContactId;