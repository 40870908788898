import React, { useState, useRef, useMemo } from "react";
import ReactGenericTable from '../Tables/ReactGenericTable'
import { notify,  http, apiBaseUrl, hasKeys, formatDate,getStandardDateForm, getAge} from "../../utitility";
import { Formik } from "formik";
import CustomButton from './CustomButton';
import { useEffect } from "react";
import CheckBox from "../Fields/CheckBox";
import Radio from "../Fields/Radio";

const InvitationForm = ({listings_id,listingName,user,reloadTableData,forceReload}) => {
  
  const [attachments,setAttachment] = useState([]);

  const getAttachment = () => {
    if(listings_id){
      http.get(apiBaseUrl(`/api/listing_attachment?listing_id=${listings_id}`))
      .then((res) => {
        setAttachment(res.data.data);
      }).catch((error)=>{
        notify.error(error);
      });
    }
  }

  const clearFormFields = (resetForm) =>{
    resetForm({});
  }

  useEffect(() => {
    getAttachment();
  },[listings_id]);

	useEffect(() => {
    getAttachment();
  },[forceReload]);

  const refPatentInfo = useRef();

  const changeType = (e, setFieldValue, values) => {
      setFieldValue("patent", e.target.value);
      if (e.target.value == 1) {
        refPatentInfo.current.hidden=false;
      }  
      else {
        refPatentInfo.current.hidden=true;
      }
  };

  const columns = useMemo(
		() => [
			{
				Header: "Country",
				accessor: "Country",
				width: 60,
				sort:true
			},{
				Header: "Number",
				accessor: "PatentNumber",
				width: 70,
			},{
				Header: "Title",
				accessor: "Title",
				width: 220,
				sort:true,
			},{
				Header: "Assignee/Owner on Record",
				accessor: "Assignee",
				width: 100,
				sort:true
			},{
				Header: "Status",
				accessor: "PatentStatus",
				width: 60
			},{
				Header: "Filed",
				accessor: "FiledDate",
				width: 100
			},{
				Header: "Age",
				accessor: "PublicationDate",
				Cell:row =>(
					row.cell.row.values.FiledDate === null ? 0 :
					getAge(row.cell.row.values.FiledDate)),
				width: 40
			}
		],
		[]
	)

	return (
    <Formik
      enableReinitialize
      initialValues={{
        ListingID:listings_id,
        ListingName:listingName,
        SenderID:user.UserID,
        subject:"",
        FirstName:"",
        LastName:"",
        EmailAddress:"",
        InviteeID:0,
        cc:"",
        Message:"",
        Patent:0,
        Attachments:[],
        DeleteFlag:0,
        CreateDate:getStandardDateForm(),
        SenderEmail:user.Email
      }}
      validate={(values) => {
        const errors = {};
        if (!values.EmailAddress) {
          errors.EmailAddress = "Please select a file to upload";
        }else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.EmailAddress)
        ) {
          errors.EmailAddress = "Invalid email address";
        }
        if(!values.Message){
          errors.Message = "Message can't be empty";
        }
        if(!values.FirstName){
          errors.FirstName = "First Name can't be empty";
        }
        if(values.cc){
          let cc = values.cc;
          let splittedcc = cc.split(",");
          for(let i=0;i<splittedcc.length;i++){
            if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(splittedcc[i])){
              errors.cc = "Invalid email address";
            } 
            if(values.EmailAddress === splittedcc[i]) {
              errors.cc = "Contains same email in Email Address and Copy To. Please enter different email address";
            }
          }
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
				setSubmitting(true);
        http.post(apiBaseUrl(`/api/add_invitation`),values)
        .then((res) => {
          resetForm({});
          reloadTableData();
          notify.success('Invitation has been sent successfully');
					setSubmitting(false);
          refPatentInfo.current.hidden=true;
        }).catch((error)=>{
          setSubmitting(false);
          notify.error(error.message);
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        resetForm,
        handleSubmit,
				isSubmitting,
				setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group col-8">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Email Address
            </label>
            <input
              type="text"
              name="EmailAddress"
              className="form-control"
              onChange={handleChange}
              value={values.EmailAddress}
            />
            <span className="text-danger">
              {errors.EmailAddress &&
                touched.EmailAddress &&
                errors.EmailAddress}
            </span>
          </div>
					<div className="form-group col-8">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              First name
            </label>
            <input
              type="text"
              name="FirstName"
              className="form-control"
              onChange={handleChange}
              value={values.FirstName}
            />
            <span className="text-danger">
              {errors.FirstName &&
                touched.FirstName &&
                errors.FirstName}
            </span>
          </div>
					<div className="form-group col-8">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Last name
            </label>
            <input
              type="text"
              name="LastName"
              className="form-control"
              onChange={handleChange}
              value={values.LastName}
            />
            <span className="text-danger">
              {errors.LastName &&
                touched.LastName &&
                errors.LastName}
            </span>
          </div>
					<div className="form-group col-8">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Subject
            </label>
            <input
              type="text"
              name="subject"
              className="form-control"
              onChange={handleChange}
              value={values.subject}
            />
            <span className="text-danger">
              {errors.subject &&
                touched.subject &&
                errors.subject}
            </span>
          </div>
					<div className="form-group col-8">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Message
            </label>
            <textarea
              type="text"
              name="Message"
              className="form-control"
              onChange={handleChange}
              value={values.Message}
            ></textarea>
            <span className="text-danger">
              {errors.Message &&
                touched.Message &&
                errors.Message}
            </span>
          </div>
					<div className="form-group col-8">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Copy To
            </label>
            <input
              type="text"
              name="cc"
              className="form-control"
              onChange={handleChange}
              value={values.cc}
            />
            <span className="text-danger">
              {errors.cc &&
                touched.cc &&
                errors.cc}
            </span>
          </div>
					<div className="form-group col-8">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
               Include Attachment Links
            </label>
            {hasKeys(attachments) && 
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">File Name</th>
                      <th scope="col">User</th>
                      <th scope="col">Date</th>
                      <th scope="col">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attachments.map((attachment,index) => (
                    <tr key={index} >
                      <th scope="row">
                      {attachment.Internal?
                        <span><i class="fa fa-lock"></i>{attachment.FileName}</span>:
                        <CheckBox key={index} name="Attachments" label={attachment.FileName} value={attachment.DocID} />
                      }
                      </th>
                      {/* <th scope="row"><CheckBox key={index} name="Attachments" label={attachment.FileName} value={attachment.DocID} /></th> */}
                      {hasKeys(attachment.tbl_user)&&<td>{attachment.tbl_user.Firstname} {attachment.tbl_user.Lastname}</td>}
                      <td>{formatDate(attachment.CreateDate)}</td>
                      <td>{attachment.Description}</td>
                    </tr>))
                    }
                  </tbody>
                </table>
									</div>
							}
          </div>
					<div className="form-group col-8">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
               Include Patent Info
            </label>
            <Radio
              id="no"
              name="Patent"
              value={0}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
            <Radio
              id="yes"
              name="Patent"
              value={1}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
          </div>
          <div className="form-group" ref={refPatentInfo} hidden="true">
            <ReactGenericTable  url={`patent_details?listing_id=${listings_id}&`} defaultSortIndex="ListingID" defaultSortOrder="DESC" columns={columns} />
          </div>
          <div className="text-center py-2">
            <CustomButton isSubmitting={isSubmitting} btnText='Invite' />
            <button type="button" onClick={(e) => clearFormFields(resetForm)} className="ml-2 btn btn-secondary">Clear</button>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default InvitationForm;
