import React, { useState } from "react";
import { notify, http, apiBaseUrl, getStandardDateForm } from "../../utitility";
import { Formik } from "formik";
import classnames from "classnames";
import CustomButton from './CustomButton';
import Select from 'react-select'

const CreateBrokerForm = ({history}) => {

  const [selectedStatus,setselectedStatus] = useState({ value: '1', label: 'Active' });

	const options = [
		{ value: 1, label: 'Active' },
		{ value: 0, label: 'Inactive' }
  ]
  
  const handleSelectChange=(option, setFieldValue)=>{
    setselectedStatus(option);
    setFieldValue('Status',option.value)
  }

  return (
    <Formik
      initialValues={{
        BrokerID: "",
        BrokerName: "",
        Folder: 0,
        ContactName: "",
        ContactPhone: "",
        ContactEmail: "",
        CustomerServiceEmail: "",
        SalesEmail: "",
        TellMeMoreEmail: "",
        Status: 1,
        Website: "",
        CreateDate: getStandardDateForm(),
        LastUpdate: getStandardDateForm(),
        PatentintakeURL: "",
        OutgoingEmail: "",
        SubmissionReceived: "",
        UnderReview: "",
        PassDecision: "",
        PursueDecision: "",
        PostponeDecision: "",
        NewSubmission: "",
        AssignedAnalyst: "",
        RecommendationEmaill: "",
        DecisionEmail: ""
      }}
      validate={(values) => {
        const errors = {};
        if (!values.ContactEmail) {
          errors.ContactEmail = "Contact Email is Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.ContactEmail)
        ) {
          errors.ContactEmail = "Invalid email address";
        }
        if (values.ContactPhone) {
          // var pattern = new RegExp(/^[0-9\b]+$/);
          var pattern = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);
          if (!pattern.test(values.ContactPhone)) {
            errors.ContactPhone = "Please enter valid phone number.";
          }else if(values.ContactPhone.length < 8){
            errors.ContactPhone = "Please enter valid phone number.";
          }
        }
        if (!values.BrokerID) {
          errors.BrokerID="Broker ID is Required";
        }
        if (!values.BrokerName) {
          errors.BrokerName = "Broker Name is Required";
        } else if (values.BrokerName.length < 3) {
          errors.BrokerName = "Broker Name should have at least 2 characters";
        }
        if (!values.ContactName) {
          errors.ContactName = "Contact Name is Required";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        values.OutgoingEmail=values.ContactEmail;
        http.post(apiBaseUrl('/api/createbroker'),values)
        .then((res) => {
          resetForm({});
          setSubmitting(false);
          notify.success("Broker created successfully.");
          history.push('/admin/users');
        }).catch((error)=>{
          setSubmitting(false);
          notify.error("Creating Broker failed.");
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
					<div className="py-3 px-6 mb-6 ml-6 mr-2">
            <div className="form-group">
              <label className="font-weight-bold form-text">
                Broker ID
              </label>
              <input
                type="text"
                name="BrokerID"
                className="form-control"
                onChange={handleChange}
                value={values.BrokerID}
              />
              <span className="text-danger">
                {errors.BrokerID && touched.BrokerID && errors.BrokerID}
              </span>
            </div>
            <div className="form-group">
              <label className="font-weight-bold form-text">
                Broker Name
              </label>
              <input
                type="text"
                name="BrokerName"
                className="form-control"
                onChange={handleChange}
                value={values.BrokerName}
              />
              <span className="text-danger">
                {errors.BrokerName && touched.BrokerName && errors.BrokerName}
              </span>
            </div>
            <div className="form-group">
              <label className="font-weight-bold form-text">
              Status
              </label>
              <Select value={selectedStatus} name="Status" options={options} onChange={(e)=>handleSelectChange(e,setFieldValue)} />
              <span className="text-danger">
                {errors.Status && touched.Status && errors.Status}
              </span>
            </div>
            <div className="form-group">
              <label className="font-weight-bold form-text">
                Customer Service Email
              </label>
              <input
                type="text"
                name="CustomerServiceEmail"
                onChange={handleChange}
                className="form-control"
                value={values.CustomerServiceEmail}
              />
              <span className="text-danger">
                {errors.CustomerServiceEmail && touched.CustomerServiceEmail && errors.CustomerServiceEmail}
              </span>
            </div>
            <div className="form-group">
              <label className="font-weight-bold form-text">
                Contact Name
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="ContactName"
                className="form-control"
                value={values.ContactName}
              />
              <span className="text-danger">
                {errors.ContactName && touched.ContactName && errors.ContactName}
              </span>
            </div>
            <div className="form-group">
              <label className="font-weight-bold form-text">
                Contact Phone
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="ContactPhone"
                className="form-control"
                value={values.ContactPhone}
              />
              <span className="text-danger">
                {errors.ContactPhone && touched.ContactPhone && errors.ContactPhone}
              </span>
            </div>
            <div className="form-group">
              <label className="font-weight-bold form-text">
                Contact Email
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="ContactEmail"
                className="form-control"
                value={values.ContactEmail}
              />
              <span className="text-danger">
                {errors.ContactEmail && touched.ContactEmail && errors.ContactEmail}
              </span>
            </div>
            <div className="form-group">
              <label className="font-weight-bold form-text">
                Sales Email
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="SalesEmail"
                className="form-control"
                value={values.SalesEmail}
              />
              <span className="text-danger">
                {errors.SalesEmail && touched.SalesEmail && errors.SalesEmail}
              </span>
            </div>
            <div className="form-group">
              <label className="font-weight-bold form-text">
                Tellmemore Email
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="TellmemoreEmail"
                className="form-control"
                value={values.TellmemoreEmail}
              />
              <span className="text-danger">
                {errors.TellmemoreEmail && touched.TellmemoreEmail && errors.TellmemoreEmail}
              </span>
            </div>
            <div className="form-group">
              <label className="font-weight-bold form-text">
                Website
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="Website"
                className="form-control"
                value={values.Website}
              />
              <span className="text-danger">
                {errors.Website && touched.Website && errors.Website}
              </span>
            </div>
            <div className="text-center py-2">
              <CustomButton isSubmitting={isSubmitting} btnText='Submit' /> 
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default CreateBrokerForm;
