import React from "react";
import { Formik } from "formik";
import CustomButton from './CustomButton';
import { Row, Col } from "reactstrap";
import { hasKeys } from "../../utitility";

const SearchUserForm = ({setUrl,reloadTableData,user}) => {

		const  cleanObj = (obj) => {
			for (var propName in obj) { 
				if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
					delete obj[propName];
				}
			}
			return obj;
		}

    const clean =(array)=>{
      return array.filter(function (el) {
        return el != null;
      });
    }
		
    const buildQuery=(params)=>{
      var esc = encodeURIComponent;
      var query = Object.keys(params)
                  .map(function(k) {
                    if(esc(params[k])){
                      return esc(k) + '=' + esc(params[k].trim())
                    }
                  });
      if(query){
        query= clean(query).join('&')
      }
      return query;
    }

    return (
      <Formik
      initialValues={{
        username: "",
        firstname: "",
		lastname: "",
        brokercode: "",
        with_all: "",
        with_atleast_one: ""
      }}
      validate={(values) => {
				const errors = {};
				if(!hasKeys(cleanObj(values))){
					errors.username = 'please enter at least one value to search';
				}
        return errors;
      }}
      onSubmit={(values,{ setSubmitting }) => {
        setSubmitting(false)
        let query = buildQuery(values);
        query = `searchUser?${query}&`;
				setUrl(query);
				reloadTableData();
      }}
    >
      {({
        values,
        handleChange,
				handleBlur,
				errors,
        touched,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
					<Row className="ml-2">
						<Col className="form-group col-md-2 mt-3">
							<input
								type="text"
								name="username"
								className="form-control"
								onChange={handleChange}
								value={values.username}
								placeholder="Username"
							/>
							<span className="text-danger">
              	{errors.username && touched.username && errors.username}
              </span>
						</Col>
						<Col className="form-group col-md-2 mt-3">
							<input
								type="text"
								name="firstname"
								className="form-control"
								onChange={handleChange}
								value={values.firstname}
								placeholder="First Name"
							/>
							<span className="text-danger">
              	{errors.firstname && touched.firstname && errors.firstname}
              </span>
						</Col>
						<Col className="form-group col-md-2 mt-3">
							<input
								type="text"
								name="lastname"
								className="form-control"
								onChange={handleChange}
								value={values.lastname}
								placeholder="Last Name"
							/>
							<span className="text-danger">
              	{errors.lastname && touched.lastname && errors.lastname}
              </span>
						</Col>
						<Col className="form-group col-md-2 mt-3">
							<input
								type="text"
								name="brokercode"
								className="form-control"
								onChange={handleChange}
								value={values.brokercode}
								placeholder="Broker Code"
							/>
							<span className="text-danger">
              	{errors.brokercode && touched.brokercode && errors.brokercode}
              </span>
						</Col>
						<Col className="form-group md-2 mt-3">
							<input
								type="text"
								name="with_all"
								className="form-control"
								onChange={handleChange}
								value={values.with_all}
								placeholder="all these words"
							/>
						</Col>
			            <Col className="form-group md-2 mt-3">
							<input
								type="text"
								name="with_atleast_one"
								className="form-control"
								onChange={handleChange}
								value={values.with_atleast_one}
								placeholder="at least one of these words"
							/>
						</Col>
						<Col className="form-group col-md-2 mt-3">
							<CustomButton isSubmitting={isSubmitting} btnText='Search' />
						</Col>
						
			</Row>
        </form>
      )}
    </Formik>
    );
}

export default SearchUserForm;
