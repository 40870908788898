import * as Yup from 'yup';
const charactersRegExp = /^[a-zA-Z ]*$/
const numbersRegExp = /^[0-9]/
const industryRegExp = /^[a-zA-Z ,()0-9.]*$/
const OrganizatioinSchema = Yup.object().shape({
    organizationDetails: Yup.object().shape({
        OrgName: Yup.string().required('Organization name is required'),
        CompanyNickname: Yup.string().max(30,'Please enter nick name within 30 characters').matches(charactersRegExp,'Plaese enter characters only'),
        City: Yup.string().max(20,'Please enter city within 20 characters').matches(charactersRegExp, 'Invalid city'),
        State: Yup.string().max(20,'Please enter state within 20 characters').matches(charactersRegExp, 'Invalid state'),
        Email: Yup.string().email(),
        Industry: Yup.string().max(50,'Please enter nick name within 50 characters').matches(industryRegExp,'Invalid industry name'),
        Size: Yup.string().matches(industryRegExp,'Invalid size'),
        Referrer: Yup.string().matches(numbersRegExp,'Invalid referrer')
    })
})
export default OrganizatioinSchema;
