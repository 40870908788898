import React, { Component } from "react";


class HomePage extends Component {
  constructor() {
		super();
    this.state = {};
  }
  render() {
    return (
      <div id="content">
				<div className="bck_img">
					<div className="content_box_center">
						<div className="content_transperent_box">
							<center><h1>INNOVATIVE ROUTES TO PATENT PROTECTION AND MONETIZATION</h1></center>
							<center><h1>562,602 Patents Available</h1></center>
							<p>
										For more than 15 years, Tynax has been a leading patent broker. We've developed unique and creative ways to help companies of all types secure patent protection and monetize their patent assets.Feel free to browse our exchange, free books and resources, or jump to the most appropriate pages from here:		
							</p>
							<ul className="smallgoldbulletimage">
									<li><a href="services-monetize-large-portfolio">Click here</a> if you're looking to monetize a large patent portfolio.</li>
									<li><a href="services-infringed-portfolio">Click here</a> if you're an inventor or small/medium business with a heavily infringed portfolio.</li>
									<li><a href="services-patent-protection">Click here</a> if you're a startup or a growing business looking for patent protection.</li>
									<li><a href="services-defendant">Click here</a> if you're a defendant looking to minimize your costs and exposure from a patent infringement suit.</li>
									<li><a href="how-to-sell-your-own-patent">Click here</a> if you're looking to sell/commercialize patents that are not yet being infringed.</li>
							</ul>	
						</div>
					</div>
				</div>	
      </div>
    );
  }
}
export default HomePage;
