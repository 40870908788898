import React, { useState } from "react";
import { notify, http, hasKeys, apiBaseUrl, getStandardDateForm, getUserType } from "../../utitility";
import { Formik } from "formik";
import CustomButton from './CustomButton';
import CustomModal from "../Modal/CustomModal";
import ChangePassword from "./ChangePassword";
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import Select from 'react-select'
import AsyncSelect from "react-select/async";
import { useLocation } from 'react-router-dom';

const Profile = ({user, setUser,asAdmin,brokerData}) => {

  const  location = useLocation();
  const params = new URLSearchParams(location.search);
  let user_type = params.get('user_type');
   
  const [isModalOpen,setModalOpen] = useState(false);

  const toggle = () => {
		setModalOpen(!isModalOpen);
  }

  useEffect(() => {
    if(hasKeys(user) && hasKeys(user.tbl_broker) && asAdmin){
       let selectedBrokerData = {};
       let selectedUserData = {};
       selectedUserData.value = user.UserType;
       selectedUserData.label = getUserType(user.UserType);
       selectedBrokerData.value = user.tbl_broker.BrokerID;
       selectedBrokerData.label = user.tbl_broker.BrokerID + ' - ' +user.tbl_broker.BrokerName;
       setselectedBroker(selectedBrokerData);
       setselectedUserRole(selectedUserData);
    }
  },[user]);

  const [selectedUserRole,setselectedUserRole] = useState({ value: '', label: '' });
  const [selectedBroker,setselectedBroker] = useState({value:'',label:''});

	const options = [
		{ value: 1, label: 'Seller' },
		{ value: 15, label: 'Buyer' },
		{ value: 30, label: 'Tynax Broker' },
		{ value: 40, label: 'Admin' }
  ]
  
  const handleSelectChange=(option, setFieldValue)=>{
    setselectedUserRole(option);
    setFieldValue('UserType',option.value)
  }

  const handleBrokerChange=(option, setFieldValue)=>{
    setselectedBroker(option);
    setFieldValue('BrokerID',option.value)
  }

  const loadOptions=(input,callback)=> {
		http.get(apiBaseUrl('/api/broker'))
				.then((response) => {
					const items = response.data.data;
					let options = items.map(function(item) {
					return {
					value: item.BrokerID,
					label: item.BrokerID + ' - ' + item.BrokerName,
					};
					});
					callback(options);
				}).catch((error) => {
					notify.error(error.message);
				}); 

	}

  const getRedirectLink = () => {
    let redirectLink = ''
    if (user_type == 40)
    {
      redirectLink = '/admin/users';
    } else if (user_type == 15 || user_type == 30) {
    redirectLink = '/myaccount/myaccount_ta_all';
    } else {
      redirectLink = '/myaccount/my_listing';
    }
    return redirectLink;
    
 }

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          Email: hasKeys(user) ? user.Email : "",
          Firstname: hasKeys(user) ? user.Firstname : '',
          Lastname: hasKeys(user) ? user.Lastname: '',
          Phone: hasKeys(user) ? user.Phone: '',
          Company: hasKeys(user) ? user.Company: '',
          UserType: hasKeys(user) ? user.UserType: '',
          BrokerID: hasKeys(user) ? user.BrokerID: '',
          selectedBroker,
          selectedUserRole,
          LastUpdate: getStandardDateForm()
        }}
        validate={(values) => {
          if (asAdmin) {
            return true;
          } else {
          const errors = {};
          // var phoneno = /^\d{11}$/;
          if (!values.Email) {
            errors.Email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
          ) {
            errors.Email = "Invalid email address";
          }
          // if((values.Phone && (values.Phone.length <= 8)))
          // {
          //   errors.Phone = "Enter valid phone number";
          // }
          if (typeof values.Phone !== "undefined" && values.Phone.length !== 0) {
            // var pattern = new RegExp(/^[0-9\b]+$/);
            var pattern = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);
            if (!pattern.test(values.Phone)) {
              errors.Phone = "Please enter valid phone number.";
            }else if(values.Phone.length < 8){
              errors.Phone = "Please enter valid phone number.";
            }
          }
          if (!values.Firstname) {
            errors.Firstname = "Required";
          }
          if (!values.Lastname) {
            errors.Lastname = "Required";
          }
          if(asAdmin){
            if (!values.BrokerID) {
              errors.BrokerID = "Please select a broker";
            }
            if (!values.UserType) {
              errors.UserType = "Please select a user type";
            }
          }
          return errors;
        }
        }}
        onSubmit={(values, { setSubmitting }) => {
          let data = {...values};

          if(asAdmin){
            let broker_id = hasKeys(selectedBroker) ? selectedBroker.value : user.BrokerID;
            let user_type = hasKeys(selectedUserRole) ? selectedUserRole.value : user.UserType;
            data.UserType = user_type; 
            data.BrokerID = broker_id;
          }

          setSubmitting(true);
          http.post(apiBaseUrl(`/api/user/${user.UserID}`),data)
          .then((res) => {
            if(!asAdmin){
              http.get(apiBaseUrl(`/api/user/${user.UserID}`))
              .then((res) => {
                setUser(res.data)
                notify.success("Details updated successfully.");
                window.location.href = getRedirectLink();
              }).catch((error)=>{
                setSubmitting(false);
                notify.error("User name / email id  is already attached to an account.");
              }); 
            }else{
              notify.success("Details updated successfully.");
              setSubmitting(false);
              window.location.href = getRedirectLink();
            }   
          }).catch((error)=>{
            setSubmitting(false);
            notify.error("User name / email id  is already attached to an account.");
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            {hasKeys(user) &&
              <div>
                {asAdmin && 
                  <React.Fragment>
                    <div className="form-group">
                      <label
                        className="font-weight-bold form-text"
                        htmlFor="exampleInputEmail1"
                      >
                        User ID
                      </label>
                      {user.UserID}
                    </div>
                    <div className="form-group">
                      <label
                        className="font-weight-bold form-text"
                        htmlFor="exampleInputEmail1"
                      >
                        Broker
                      </label>
                      <AsyncSelect
                          cacheOptions
													classNamePrefix="rct-select"
													name="BrokerID"
													loadOptions={loadOptions}
													onChange={(option) => handleBrokerChange(option,setFieldValue)}
													value={ selectedBroker }
                          defaultOptions
                          
													/>
                        <span className="text-danger">
                          {errors.BrokerID && touched.BrokerID && errors.BrokerID}
                        </span>
                      {/* <Select value={selectedUserRole} options={brokerData} onChange={(e)=>handleBrokerChange(e,setFieldValue)} /> */}
                    </div>
                    <div className="form-group">
                      <label
                        className="font-weight-bold form-text"
                        htmlFor="exampleInputEmail1"
                      >
                        User Type
                      </label>
                      <Select value={selectedUserRole} options={options} onChange={(e)=>handleSelectChange(e,setFieldValue)} />
                      <span className="text-danger">
                        {errors.Email && touched.Email && errors.Email}
                      </span>
                    </div>
                  </React.Fragment>
                  }
                  {user.Username &&
                    <div className="form-group">
                      <label
                        className="font-weight-bold form-text"
                        htmlFor="exampleInputEmail1"
                      >
                        User Name
                      </label>
                      {user.Username}
                    </div>
                  }
                  <div className="form-group">
                    <label
                      className="font-weight-bold form-text"
                      htmlFor="exampleInputEmail1"
                    >
                      Email Address
                    </label>
                      {user.Email}
                    {/* <label
                      className="font-weight-bold form-text"
                      htmlFor="exampleInputEmail1"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      name="Email"
                      onChange={handleChange}
                      className="form-control"
                      value={values.Email}
                    />
                    <span className="text-danger">
                      {errors.Email && touched.Email && errors.Email}
                    </span> */}
                  </div>
                  <div className="form-group">
                    <button type="button" onClick={()=>toggle()} className="btn btn-sm btn-secondary">Change Account Password</button>
                  </div>
                  <div className="form-group">
                    <label
                      className="font-weight-bold form-text"
                      htmlFor="exampleInputEmail1"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="Firstname"
                      maxLength="50"
                      className="form-control"
                      value={values.Firstname}
                    />
                    <span className="text-danger">
                      {errors.Firstname && touched.Firstname && errors.Firstname}
                    </span>
                  </div>
                  <div className="form-group">
                    <label
                      className="font-weight-bold form-text"
                      htmlFor="exampleInputEmail1"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="Lastname"
                      maxLength="50"
                      onChange={handleChange}
                      className="form-control"
                      value={values.Lastname}
                    />
                    <span className="text-danger">
                      {errors.Lastname && touched.Lastname && errors.Lastname}
                    </span>
                  </div>
                  <div className="form-group">
                    <label
                      className="font-weight-bold form-text"
                      htmlFor="exampleInputEmail1"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      name="Phone"
                      onChange={handleChange}
                      className="form-control"
                      value={values.Phone}
                    />
                    <span className="text-danger">
                      {errors.Phone && touched.Phone && errors.Phone}
                    </span>
                  </div>
                  <div className="form-group">
                    <label
                      className="font-weight-bold form-text"
                      htmlFor="exampleInputEmail1"
                    >
                      Company
                    </label>
                    <input
                      type="text"
                      name="Company"
                      maxLength="70"
                      onChange={handleChange}
                      className="form-control"
                      value={values.Company}
                    />
                  </div>
                  <div className="text-center py-2">
                    <CustomButton isSubmitting={isSubmitting} btnText='Save' /> 
                    <Link to={getRedirectLink()} className="ml-3 btn btn-sm btn-secondary">Cancel</Link>
                     {/* {(user.UserType== 15|| user.UserType == 30) ?
                      <Link to='/myaccount/myaccount_ta_all' className="ml-3 btn btn-sm btn-secondary">Cancel</Link> :
                      <Link to='/myaccount/my_listing' className="ml-3 btn btn-sm btn-secondary">Cancel</Link>
                    } */}
                  </div>
              </div>
            }
          </form>
        )}
      </Formik>
      {isModalOpen &&
        <CustomModal notice={true} modal={isModalOpen}  toggle={toggle} closeAllModal={toggle} heading={"Change Account Password"}  render={() => (  
          <React.Fragment>
            <p>Please enter your current password and then choose your new password. Click Save when you're done.</p>
            <ChangePassword user_id={user.UserID}/>
          </React.Fragment>
        )}/>
      }
    </React.Fragment>
  );
};
export default Profile;
