import React, { useEffect, useState, useRef } from "react";
import { notify, http, apiBaseUrl } from "../../utitility";
import { Formik, Field, ErrorMessage } from "formik";
import CustomButton from './CustomButton';
import Radio from "../Fields/Radio";
import ListingSchema from "../validation/ListingSchema";
import classnames from "classnames";

const CreateDetailedListingForm5 = ({ user, listingId,initialState, setInitialState, history }) => {
	const [isDisabled,setIsDisabled] = useState(false);
  
  useEffect(() => {
    if(listingId){
      http.get(apiBaseUrl(`/api/getListingDetailById?listing_id=${listingId}`))
      .then((res) => {
        let initialStateNew = {...initialState};
        initialStateNew.listing = res.data.listing;
        initialStateNew.listing_details = res.data.listing_details;
        initialStateNew.salesInfo =res.data.salesInfo;
        setInitialState(initialStateNew);
      }).catch((err)=>{
        console.log(err);
      })
    }
	},[listingId]);

  const clearFormFields=(resetForm)=>{
    resetForm({});
  }

  const redirectToPreviousPage=()=>{
    history.goBack();
  }
  
  const redirectToNextPage=()=>{
    history.push('detailed_listing2');
  }

  const PreviewListing=()=>{
    // history.push('detailed_listing2');
  }

  const changeType = (e, setFieldValue, field) => {
    setFieldValue(field, e.target.value);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={ListingSchema}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          // // setSubmitting(true);
          // setIsDisabled(true);
          // let data = {...values};
          // if(!listingId){
          //   http.post(apiBaseUrl(`/api/createListing`),data)
          //   .then(() => {
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     notify.success("Listing created successfully");
          //     clearFormFields(resetForm,setFieldValue);
          //     if(user.UserType==40){
          //       history.push('/myaccount/myaccount_ta_all');
          //     }
          //     else{
          //       history.push('/myaccount/my_listing');
          //     }
          //   }).catch((error)=>{
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     notify.error(error);
          //   });
          // }else{
          //   http.post(apiBaseUrl(`/api/editListing?listing_id=${listingId}`),data)
          //   .then(() => {
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     if(user.UserType==40){
          //       history.push('/myaccount/myaccount_ta_all');
          //     }
          //     else{
          //       history.push('/myaccount/my_listing');
          //     }
          //     notify.success("Listing updated successfully");
          //   }).catch((error)=>{
          //     // setSubmitting(false);  
          //     setIsDisabled(false);
          //     notify.error(error);
          //   });
          // }
          if(user.UserType==40){
            history.push('/myaccount/myaccount_ta_all');
          }
          else{
            history.push('/myaccount/my_listing');
          }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
          <h5 className="mb-3">
              <b className="text-primary">5. Broker Information</b>
            </h5>
            <span>
              Please complete the questions in this section, all information provided on this page is private and will NOT be 
              published on the Tynax web site, it is used by Tynax to aid in the sales process.
            </span>
            <label className="font-weight-bold form-text">
              Is there a contract in place between the broker and the seller?
            </label>
            <Radio
              id="PatentLitigation_yes"
              name="listing_details.PatentLitigation"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />            
            <Radio
              id="PatentLitigation_no"
              name="listing_details.PatentLitigation"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <Radio
              id="PatentLitigation_unknown"
              name="listing_details.PatentLitigation"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <ErrorMessage
              name="listing.ListingName"
              component="div"
              className="text-danger"
            />
            <Field type="hidden" name="listing.AvailableStatus" className="form-control" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Is this broker arrangement exclusive?
            </label>
            <Radio
              id="PatentLitigation_yes"
              name="listing_details.PatentLitigation"
              value={2}
              custom="false"
              label="Exclusive  "
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />            
            <Radio
              id="PatentLitigation_no"
              name="listing_details.PatentLitigation"
              value={1}
              custom="false"
              label="Non-exclusive"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <Radio
              id="PatentLitigation_unknown"
              name="listing_details.PatentLitigation"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <ErrorMessage
              name="listing.ListingDescription"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              What commission is payable to the broker by the lister (percentage)?
            </label>
            <Field type="text" name="listing_details.Overview" component="textarea" className="form-control"  />
            <span>
              %
            </span>
            <ErrorMessage
              name="listing_details.Overview"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Please describe the brokering arrangement:
            </label>
            <Field type="text" name="salesInfo.Keywords" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.Keywords"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Is the listing arrangement directly between the seller and Tynax? Select "No" if the seller's contract 
              is with another broker:
            </label>
            <Radio
              id="PatentLitigation_yes"
              name="listing_details.PatentLitigation"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />            
            <Radio
              id="PatentLitigation_no"
              name="listing_details.PatentLitigation"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <Radio
              id="PatentLitigation_unknown"
              name="listing_details.PatentLitigation"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <ErrorMessage
              name="salesInfo.DecisionMakingProcess"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Is a commission required from the buyer? Select "No" if the seller is paying the Tynax commission:
            </label>
            <Radio
              id="PatentLitigation_yes"
              name="listing_details.PatentLitigation"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />            
            <Radio
              id="PatentLitigation_no"
              name="listing_details.PatentLitigation"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <Radio
              id="PatentLitigation_unknown"
              name="listing_details.PatentLitigation"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <ErrorMessage
              name="salesInfo.DecisionMakingProcess"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Motivated seller - does this listing contain bargain-priced patents?
            </label>
            <Radio
              id="PatentLitigation_yes"
              name="listing_details.PatentLitigation"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />            
            <Radio
              id="PatentLitigation_no"
              name="listing_details.PatentLitigation"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <Radio
              id="PatentLitigation_unknown"
              name="listing_details.PatentLitigation"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <ErrorMessage
              name="salesInfo.DecisionMakingProcess"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="text-center py-2">
            <button type="button" onClick={(e) => redirectToPreviousPage()} className="ml-2 btn btn-primary">Back</button>
            <button 
                  disabled={isDisabled} 
                  type="submit" 
                  className={classnames({
                    "ml-2 buttonload btn btn-primary btn-sm": isDisabled === true,
                    "ml-2 btn btn-primary btn-sm": isDisabled === false,
                  })}>
                    Save & Return Later
            </button>
            <button type="button" onClick={(e) => PreviewListing()} className="ml-2 btn btn-primary">Preview Listing</button>
            <button type="button" onClick={(e) => redirectToNextPage()} className="ml-2 btn btn-primary">Finish</button>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default CreateDetailedListingForm5;
