import React, { useEffect, useState, useContext } from "react";
import { CreateContactForm } from "../Components";
import { http, apiBaseUrl } from "../utitility";
import { useLocation,Link } from 'react-router-dom';
import { UserContext } from "../Context/UserContext";
const ContactEditPage = ({history}) => {

	const {user} = useContext(UserContext);
	const  location = useLocation();
	// const [contactDetails,setContactDetails] = useState({});
	// const [tynaxPeople,setTynaxPeople] = useState({});
	// const [contact_link,setContactLink] = useState({});
	// const [userSummary,setUserSummary] = useState({});
	// const [organization_link,setOrganizationLink] = useState([]);
	const [contactId,setContactId] = useState();
	const [contactDetails] =useState({
		Firstname:"",
		Lastname:"",
		Nickname:"",
		Title:"",
		WorkPhone:"",
		MobilePhone:"",
		OtherPhone:"",
		PrimaryEmail:"",
		EmailVerify:0,
		EmailVerifyStatus:"",
		EmailVerifyDate:"",
		SecondaryEmail:"",
		DealsmithWebsiteCode:"",
		SourceWebsite:"",
		Website:"",
		Skype:"",
		Linkedin:"",
		Address:"",
		City:"",
		State:"",
		ZipCode:"",
		Country:"",
		CreatorName:user.Firstname + ' ' + user.Lastname,
		Description:"",
		Keywords:"",
		mailing_notes:"",
		CreateDate:"",
		UpdateDate:"",
		USPatentAttorney:0,
		MemberOfAUTM:0,
		OkSendPatentNo:2,
		Responsible:[],
	});

	const [initialState,setInitialState] = useState({
		contactDetails:contactDetails,
	});
	useEffect(() => {
			const params = new URLSearchParams(location.search);
			let contact_id = params.get('contact_id');
			setContactId(contact_id);
			// http.get(apiBaseUrl(`/api/getContactInfo?contact_id=${contact_id}`))
			// .then((res) => {
			// 	console.log(res.data)
			// 	setContactDetails(res.data.contact_details[0]);
			// 	setTynaxPeople(res.data.tynax_people);
			// 	setContactLink(res.data.contact_link[0]);
			// 	setUserSummary(res.data.user_summary);
			// 	setOrganizationLink(res.data.organization_link);
			// }).catch((error=>{
			// 	console.log(error,'error')
			// }))
	},[]);

	return (
		<div className="container-fluid bg-white">
			<div className="px-2 mb-3">
						<u><Link  className="px-2 text-primary" to={'/contacts/mylisting'}>My Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_contact'}>Create Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_contacts'}>Search Contact</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/create_organizations'}>Create Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/organizations'}>Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/search_organizations'}>Search Organization</Link></u>
						<u><Link className="px-2 text-primary" to={'/contacts/mailing_lists'}>Mailing lists</Link></u>
					</div>
			<div className="border-bg">
				<section className=" mb-5 ml-5 row">
					<div className="col-9">
						<CreateContactForm user={user}  history={history} contactId={contactId} initialState={initialState} setInitialState={setInitialState} />
					</div>
				</section>
			</div>
		</div>
	);
}

export default ContactEditPage;
