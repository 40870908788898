import React,{useState, useEffect} from "react";
import { MyContacts, Contacts, Organizations, MailListing, SearchContact, SearchOrganizations } from "../Components";
import { useLocation, Link } from 'react-router-dom';
import { hasKeys, getSubMenu } from '../utitility';

const ContactPage = ({user}) => {

	const location = useLocation();
  const pathName = location.pathname;
  const [subMenu,setSubMenu] = useState();

  useEffect(() => {
    let path = location.pathname;
    let splittedPath = path.split("/");
    let subMenu = getSubMenu(splittedPath[1],user.UserType);
    setSubMenu(subMenu);
	},[]);
	
  return (
				<div className="tab-content" id="myTabContent">
					<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						{pathName==="/contacts/mylisting"&&<MyContacts user={user}/>} 
						{pathName==="/contacts/all_contacts"&&<Contacts user={user}/>} 
						{pathName==="/contacts/organizations"&&<Organizations user={user}/>}
						{pathName==="/contacts/search_contacts"&&<SearchContact user={user}/>}
						{pathName==="/contacts/search_organizations"&&<SearchOrganizations user={user}/>}
						{pathName==="/contacts/mailing_lists"&&<MailListing user={user} />}
					</div>
				</div>
  );
}

export default ContactPage;
