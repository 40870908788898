import React from "react";
import {  CreatePatentForm } from "../Forms";
import { hasKeys } from '../../utitility'

const CreatePatent = ({user, history}) => {
	
	return (
		<React.Fragment>
			{hasKeys(user) &&
				<section className=" mb-5 ml-5 row">
					<div className="col-12">
						<h3 className="font-weight-bold" style={{ color: "#2d78a0" }}>
							Create Patent
						</h3>
					</div>
					<div className="col-6">
						<CreatePatentForm user={user} history={history} />
					</div>
				</section>
			}
		</React.Fragment>
  	);
}
export default CreatePatent;
