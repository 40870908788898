import React from "react";
import tynax_logo from "../assets/images/tynax_logo.png";
import HeaderTab from "./HeaderTab";
import { getUser, http, apiBaseUrl } from '../utitility'
import { UserContext } from "../Context/UserContext";

const Header = (props) => {
  let user = getUser();
  const logout = () =>{
    http.get(apiBaseUrl(`/api/logout`))
    .then((res) => {
      localStorage.clear();
      props.history.push('/login');
    });
  }

  let currentPath = props.history.location.pathname;

  return (
      <React.Fragment>
        <section className="header">
          <div className="container-fluid py-3 bg-white">
            <nav className="navbar navbar-expand-lg navbar-light">
              <a className="navbar-brand text-primary" href={process.env.REACT_APP_TYNAX_BASE_URL}>
                <img className="" src={tynax_logo} style={{ width: "150px" }} />
              </a>
            </nav>
            <HeaderTab history={props.history} user={user} logout={logout} currentPath={currentPath}/>
          </div>
      </section>
      </React.Fragment>
    );
  }

export default Header;
