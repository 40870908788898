import React from 'react';
import { Button, Modal } from 'react-bootstrap';
const ConfirmationDialogComponent = (props) => {
  return (
    <Modal
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header>
        <h5 className='font-weight-bold form-text'>Confirmation</h5>
      </Modal.Header>
      <Modal.Body className='font-weight-bold form-text'>Are you sure do you want to remove?</Modal.Body>
      <Modal.Footer>
        <Button className="ml-2 btn btn-secondary" variant="basic" onClick={props.close}>
          No
        </Button>
        <Button className='btn btn-primary btn-sm' onClick={props.confirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmationDialogComponent;
