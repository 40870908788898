import React from "react";
import { Modal, ModalBody } from "reactstrap";

const CustomModal = props => {

  const { heading, notice, closeAllModal } = props;

  return (
    <React.Fragment>
      <Modal size="md" isOpen={props.modal} className={props.className}>
        <div className="modal-header d-block pt-5 border-bottom-0">
            <div className="row">
              <div className="col col-10">
                {notice === true ?
                  <h5 className="font-weight-bold text-secondary text-center text-uppercase">
                    {heading}
                  </h5>:
                  <h4 className="font-weight-bold text-secondary text-center text-uppercase">
                  {heading}
                </h4>
                }
              </div>
              <div className="col col-2">
                <button type="button" onClick={closeAllModal} className="close"><span aria-hidden="true">×</span></button>
              </div>
            </div>
        </div>
        <ModalBody>
          <div>
            {props.render()}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CustomModal;
