import React,{useRef,useMemo,useState} from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { useLocation,Link } from 'react-router-dom';
import { http, apiBaseUrl, notify, mergeString,formatDate} from '../../utitility';
import { confirmAlert } from 'react-confirm-alert'; 
const ListingContactLink = () =>{
  const childRef = useRef();
	const  location = useLocation();
    const [contactId,setContactId] = useState(new URLSearchParams(location.search).get('contact_id'))
    const reloadTableData = () => {
		childRef.current.reloadTableData()
	}
  const getActionField = (ListingID) => {
    return( 
        <div>
            <span onClick={()=>ConfirmAlert(ListingID)} className="mr-2 table_font_size btn-link link-pointer">Remove</span>
        </div>
    )
}

const ConfirmAlert = (value) => {
    confirmAlert({
        message: 'Do you really want to remove this list?',
        buttons: [
            {
                label: 'Yes',
                onClick: () => deleteContact(value)
            },
            {
                label: 'No',
                onClick: () => {}
            }
        ]
    });
}

const deleteContact = (ListingID) => {
    http.delete(apiBaseUrl(`/api/removeListingByContactId?listing_id=${ListingID}&contact_id=${contactId}`))
    .then(() => {
        reloadTableData();
        notify.success("Removed successfully");
    }).catch((error)=>{
        let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
                                        error.response.data.message :
                                        'whoops...something went wrong';
        notify.error(errorMsg);
    });
}
    const columns = useMemo(
		() => [
			{
				Header: "Listing Name",
				accessor: "ListingName",
				width: 130,
				sort:true,
				Cell: data => {return(<Link to={`/listing_full_details?listing_id=${data.row.original.ListingID}`}>{data.row.original.ListingName}</Link>)},
			},
			{
				Header: "Status",
				accessor: "AvailableStatus",
				width: 130,
				sort:true,
				Cell: data => {return(data.row.original.AvailableStatus ? 'Active' : 'Inactive')},
			},
			{
				Header: "Tag",
				accessor: "Tag",
				width: 130,
				sort:true
			},
			{
				Header: "Created Date",
				accessor: "CreateDate",
				width: 130,
				sort:true,
				Cell: data => {return formatDate(data.cell.value)}
			},
			{
				Header: "Action",
				accessor: "act",
				Cell: data => (getActionField(data.row.original.ListingID)),
				width: 130
			}
		],
		[]
	)
    return(
        <React.Fragment>
            <div className="container-fluid bg-white">
                <div className="justify-content-center py-1">
                    { contactId && <ReactGenericTable  pagination={true} url={`getListingByContactId?contact_id=${contactId}&`} ref={childRef} defaultSortIndex="ListingId" defaultSortOrder="DESC" columns={columns} />}
                </div>
            </div>
        </React.Fragment>
    )
}
export default ListingContactLink;