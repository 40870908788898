export default {
  app: {
    url: {
      base: process.env.REACT_APP_BASE_URL,
    },
  },
  local: {
    url: {
      base: process.env.REACT_APP_BASE_URL,
    },
  },

  api: {
    url: {
      base: process.env.REACT_APP_API_BASE_URL,
      path: process.env.REACT_APP_API_PATH,
    },
  },
  stripe: {
    publish_key: process.env.REACT_APP_STRIPE_PUBLISH_KEY,
    client_id: process.env.REACT_APP_STRIPE_CONNECT_CLIENT_ID,
    connect_url: process.env.REACT_APP_STRIPE_CONNECT_URL,
  },
  google_client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  facebook_app_id: process.env.REACT_APP_FACEBOOK_APP_Id,
};
