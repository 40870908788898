import React, { useState,useEffect } from "react";
import { hasKeys, formatDate } from '../../utitility';

const ContactInfo = ({contactDetails,tynaxPeople,contact_link,userSummary,responsible}) => {
	const [tynaxPeopleData,setTynaxPeopleData] = useState([]);
	const [responsibleValue,setResponsibleValue] = useState();
  useEffect(() => {
	if(contact_link){
		contact_link.map(val => {
			const matchedObj = tynaxPeople.find(data => data.PersonID === val.TynaxContactId);
			if(matchedObj){
				tynaxPeopleData.push({contactId: val.TynaxContactId, tynaxContactType: val.TynaxContactType, label: `${matchedObj.Firstname} ${matchedObj.Lastname}`})
			}})
	}
  },[contact_link]);
  useEffect(()=> {viewResponsible(responsible);},[responsible]);
	const getTynaxActivity = () => {
		if(userSummary.length < 0){
			return <span class="text-muted ml-3">Tynax User: No</span>
		}else{
			return(
				<React.Fragment>
					<span class="text-muted ml-3">Tynax User: Yes</span>
					<div>
					{hasKeys(userSummary) && userSummary.map((user)=>{
					return (	
									<div>
										<span class="text-muted ml-3">{user.Username}</span>
										<span class="text-muted ml-3">Receives Email Alerts: {user.EmailAlertCount >0 ? "Yes" : "no"}</span>
										<span class="text-muted ml-3">Saved Searches: {user.SavedSearchCount}</span>
									</div>
								)
					})}
					</div>
				</React.Fragment>
			)
		}
	}

  const getContactType = (data) => {
		if(hasKeys(data)){
			let type = data.TynaxContactType;
			if(!type){
				return "Preferred";
			}else if(type==2){
				return "Exclusive";
			}else{
				return "";
			}
		}else{
			return "";
		}
	}

  const getSendPatentNumbers = () =>{
		if(contactDetails.OkSendPatentNo == '1'){
      return "Yes"
		}else if(contactDetails.OkSendPatentNo == "2"){
    	return "Unknown"
		}else{
      return "No"
		}
	}
	const viewResponsible = (responsible) =>{
		const result = [];
		if(responsible.includes(1)){
			result.push("Buying Patent");
		}
		if(responsible.includes(2)){
			result.push("Selling Patent");
		}
		if(responsible.includes(3)){
			result.push("M & A");
		}
		setResponsibleValue(result);
	}
	return (
			<div class="col-12 col-lg-6">
				<div class="d-flex flex-wrap align-items-center justify-content-between">
					<h4 class="text-primary mb-4 text-center">Contact Information</h4>
					<a href={`/contact_edit?contact_id=${contactDetails.ContactId}`} class="text-primary">Edit</a>
				</div>
				<div class="grey-bg p-3">
					<div class="my-3">
							<h6 class="text-primary mx-2">ID:<span class="text-muted ml-3">{contactDetails.ContactId}</span></h6>
							<h6 class="text-primary mx-2">Name:
								<span class="text-muted ml-3 mr-1">{contactDetails.Firstname}</span>
								<span class="text-muted">{contactDetails.Lastname}</span>
							</h6>
							<h6 class="text-primary mx-2">Nickname:<span class="text-muted ml-3">{contactDetails.Nickname ? contactDetails.Nickname:"N/A"}</span></h6>
							<h6 class="text-primary mx-2">Job title:<span class="text-muted ml-3">{contactDetails.Title ? contactDetails.Title:"N/A"}</span></h6>
							<h6 class="text-primary mx-2">Address:<span class="text-muted ml-3">{contactDetails.Address ? contactDetails.Address:"N/A"}</span></h6>
							<h6 class="text-primary mx-2">City:<span class="text-muted ml-3">{contactDetails.City ? contactDetails.City:"N/A"}</span></h6>
							<h6 class="text-primary mx-2">State:<span class="text-muted ml-3">{contactDetails.State ? contactDetails.State:"N/A"}</span></h6>
							<h6 class="text-primary mx-2">ZipCode:<span class="text-muted ml-3">{contactDetails.ZipCode ? contactDetails.ZipCode:"N/A"}</span></h6>
							<h6 class="text-primary mx-2">Country:<span class="text-muted ml-3">{contactDetails.Country ? contactDetails.Country:"N/A"}</span></h6>
							<h6 class="text-primary mx-2">Phone: Work:<span class="text-muted ml-3">{contactDetails.WorkPhone ? contactDetails.WorkPhone : 'N/A'}</span></h6>
							<h6 class="text-primary mx-2">Mobile:<span class="text-muted ml-3">{contactDetails.MobilePhone ? contactDetails.MobilePhone : 'N/A'}</span></h6>
							<h6 class="text-primary mx-2">Fax:<span class="text-muted ml-3">{contactDetails.OtherPhone ? contactDetails.OtherPhone : 'N/A'}</span></h6>
							<h6 class="text-primary mx-2">Primary Email<span class="text-muted ml-3">{contactDetails.PrimaryEmail ? contactDetails.PrimaryEmail : "N/A"}</span></h6>
							<div class="contact-card p-3 mb-3">
								<h6 class="text-dark">Email Verified :<span class="text-muted ml-3">{contactDetails.EmailVerify ? "Yes" : "No"}</span></h6>
								<h6 class="text-dark">Verified Status :<span class="text-muted ml-3">{contactDetails.EmailVerifyStatus ? contactDetails.EmailVerifyStatus : 'N/A'}</span></h6>
								<h6 class="text-dark">Verified Date :<span class="text-muted ml-3">{contactDetails.EmailVerify ? formatDate(contactDetails.EmailVerifyDate) : "Not verified"}</span></h6>
							</div>
							<h6 class="text-primary mx-2">Secondary Email:<span class="text-muted ml-3">{contactDetails.SecondaryEmail?contactDetails.SecondaryEmail:"N/A"}</span></h6>
							<h6 class="text-primary mx-2 text-muted-break">Website:<span class="text-muted ml-3">{contactDetails.Website?contactDetails.Website:"N/A"}</span></h6>
							<h6 class="text-primary mx-2 text-muted-break">Skype<span class="text-muted ml-3">{contactDetails.Skype?contactDetails.Skype:"N/A"}</span></h6>
							<h6 class="text-primary mx-2 text-muted-break">Linkedin<span class="text-muted ml-3">{contactDetails.Linkedin?contactDetails.Linkedin:"N/A"}</span></h6>
							<h6 class="text-primary mx-2 text-muted-break">Dealsmiths Website Code:<span class="text-muted ml-3">{contactDetails.DealsmithWebsiteCode?contactDetails.DealsmithWebsiteCode:"N/A"}</span></h6>
							<h6 class="text-primary mx-2 text-muted-break">Source Website Code:<span class="text-muted ml-3">{contactDetails.SourceWebsite?contactDetails.SourceWebsite:"N/A"}</span></h6>
							<h6 class="text-primary mx-2">Description:</h6>
							<span class="text-muted ml-3 text-muted-break">{contactDetails.Description?contactDetails.Description:"N/A"}</span>
							<h6 class="text-primary mx-2">Keywords:</h6>
							<div class="grey-bg">
								<span class="text-muted ml-3">{contactDetails.Keywords?contactDetails.Keywords:'N/A'}</span>
							</div>
							<h6 class="text-primary mx-2">Mailing Notes:</h6>
							<span class="text-muted ml-3 text-muted-break">{contactDetails.mailing_notes?contactDetails.mailing_notes:"N/A"}</span>
							<h6 class="text-primary mx-2">Tynax Person:</h6>
							{ tynaxPeopleData && tynaxPeopleData.map(val =>
							<div class="grey-bg">
								<span class="text-muted ml-3">{val.label} {val.tynaxContactType ? '(Preferred)' : '(Exclusive)'}</span>
							</div>
								)}
							<h6 class="text-primary mx-2">Tynax Activity:{getTynaxActivity()}</h6>
							<h6 class="text-primary mx-2">Newsletter:<span class="text-muted ml-3">{contactDetails.Newsletter>0?"Yes":"No"}</span></h6>
							<h6 class="text-primary mx-2">US Patent Attorney:<span class="text-muted ml-3">{contactDetails.USPatentAttorney === 0 ? 'No' : 'Yes'}</span></h6>
							<h6 class="text-primary mx-2">Member Of AUTM:<span class="text-muted ml-3">{contactDetails.MemberOfAUTM === 0 ? 'No' : 'Yes'}</span></h6>
							<h6 class="text-primary mx-2">Responsible for:</h6>
							<div class="grey-bg mb-3">
								{responsibleValue&&responsibleValue.map(val =>(<span class="text-muted ml-3">{val}<br/></span>))}
							</div>
							<h6 class="text-primary mx-2">OK to Send Patent Numbers:<span class="text-muted ml-3">{getSendPatentNumbers()}</span></h6>
							<h6 class="text-primary mx-2">Created By:<span class="text-muted ml-3">{contactDetails.CreatorName}</span></h6>
							<h6 class="text-primary mx-2">Created Date:<span class="text-muted ml-3">{formatDate(contactDetails.CreateDate) }</span></h6>
							<h6 class="text-primary mx-2">Updated Date:<span class="text-muted ml-3">{formatDate(contactDetails.UpdateDate)}</span></h6>
						</div>
					</div>
			</div>
    );
}

export default ContactInfo;
