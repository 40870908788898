import React, { Component } from "react";
import Account_img from "../assets/images/tynax-anim.gif";

class MyAccountDetailSection extends Component {
  render() {
    return (
      <section>
        <div className="container-fluid py-3 bg-white">
            <div className="row">
                <div className="col-12 col-lg-4">
                    <img className="account_img" src={Account_img} alt="tech"/>
                </div>
                <div className="col-12 col-lg-8">
                    <h3 className="text-primary mb-2">My Account</h3>
                    <p>Welcome to your account console. This is the hub for your trading activity. This is where you
                        create, edit and post listings, manage and track listings of interest.</p>

                    <p>To manage your account effectively you can sort any of the columns in each tab area. Click on the
                        column name to sort it alphabetically or numerically - sort by name, number, date, priority.</p>
                </div>
            </div>
        </div>
    </section>
    );
  }
}

export default MyAccountDetailSection;