import React, { useMemo, useRef }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import ActionField from '../Listing/ActionField';
import { formatDate, getStatus } from '../../utitility';
import { Link } from 'react-router-dom';

const BrokerListingForm = ({user,  brokerData }) => {

	const childRef = useRef();

	const getPromotionCode = (value) => {
		let code="Promotion Code (" + value + ")";
		return value;
	}

	const getActionField = (value) =>{
		return <ActionField user={user} listing={value} reloadTableData={()=>reloadTableData()}/>
	}

	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

	const columns = useMemo(
		() => [
			{
				Header: "ID",
				accessor: "ID",
				width: 70,
				sort:true,
			},{
				Header: "Broker ID",
				accessor: "BrokerID",
				width: 100,
			},{
				Header: "Broker Name",
				accessor: "BrokerName",
				width: 250,
				sort:true
			},{
				Header: "Status",
				accessor: "Status",
				width: 150,
				Cell: data => {return getStatus(data.cell.value)}
			},{
				Header: "Contact Name",
				accessor: "ContactName",
				width: 250,
				sort:true
			},
			// {
			// 	Header: "Promotion Code",
			// 	accessor: "BrokerID",
			// 	width: 200,
			// 	Cell: data => {return getPromotionCode(data.cell.value)},
			// },
			{
				Header: "Created Date",
				accessor: "CreateDate",
				width: 200,
				Cell: data => {return formatDate(data.cell.value)}

			}
		],
		[]
	)


  return (
				<React.Fragment>
					<div>
						<ReactGenericTable ref={childRef} pagination={true} url="broker?" defaultSortIndex="ID" defaultSortOrder="DESC" columns={columns} />
					</div>
				</React.Fragment>
  );
};
export default BrokerListingForm;