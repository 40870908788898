import React, { useState } from "react";
import classnames from "classnames";
import { mainMenuTab } from "../utitility/constants";
import {hasKeys} from '../utitility';
import { Link } from 'react-router-dom';

const HeaderTab = ({history,user,logout,currentPath}) => {

    const [isBurgerMenuOpen,SetBurgerMenuOpen] = useState(false);
    const handleBurgerMenu = () =>{
      SetBurgerMenuOpen(!isBurgerMenuOpen);
    }

    const getMainMenu = (tab, index) => {
      let currentUserRole = hasKeys(user) && user.UserType && user.UserType.toString();
      let allowedRoles = tab["allowedUserIds"];
      let allowed = allowedRoles.includes(currentUserRole);
      let menuUrl = ''
      if(tab.url == '/profile') {
        menuUrl = `/profile?user_type=${user && user.UserType}`;
      } 
      else
      {
        menuUrl = tab.url;
      }
      if (allowed) {
        return (
          <li className="nav-item mr-2 text-light" key={index}>
            <a className="nav-link text-light" href={menuUrl}>
              {tab.label}
            </a>
          </li>
        );
      } else {
        return "";
      }
    }; 
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg py-2">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={(e) => handleBurgerMenu(e)}
          >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div 
          className={classnames({
            "collapse navbar-collapse": isBurgerMenuOpen === false,
            "collapse navbar-collapse show": isBurgerMenuOpen === true,
          })}
          id="navbarSupportedContent">
            <ul className="navbar-nav">
              {mainMenuTab &&
                mainMenuTab.map((tab, index) => (
                  getMainMenu(tab, index)
                ))}
            </ul>
          {user ?
            <button className="login_btn ml-auto  btn btn-primary" onClick={()=>logout()}>Logout</button>
            : <span className="ml-auto">{currentPath=='/login' || currentPath=='/' ? 
                    <Link type="button" className=" login_btn btn btn-primary" to="/register">Create new account</Link> 
                    :<Link type="button" className=" login_btn btn btn-primary" to="/login">Log in</Link>}</span>
          }
        </div>
      </nav>
    );
  }

export default HeaderTab;
