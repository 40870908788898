import React from "react";
import classnames from "classnames";

const CustomButton = ({ isSubmitting, btnText }) => {
  return (
			<button 
				disabled={isSubmitting} 
				type="submit" 
				className={classnames({
					"buttonload btn btn-primary btn-sm": isSubmitting === true,
					"btn btn-primary btn-sm": isSubmitting === false,
				})}
			>
			{isSubmitting &&
				<i className="fa fa-spinner fa-spin"></i>
			}
			{btnText}
			</button>
    );
  }

export default CustomButton;
