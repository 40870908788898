import React, { useEffect, useState, useRef } from "react";
import { notify, http, apiBaseUrl } from "../../utitility";
import { Formik, Field, ErrorMessage } from "formik";
import CustomButton from './CustomButton';
import ListingSchema from "../validation/ListingSchema";
import classnames from "classnames";

const CreateDetailedListingForm1 = ({ user, listingId,initialState, setInitialState, history }) => {
	const [isDisabled,setIsDisabled] = useState(false);
  
  useEffect(() => {
    if(listingId){
      http.get(apiBaseUrl(`/api/getListingDetailById?listing_id=${listingId}`))
      .then((res) => {
        let initialStateNew = {...initialState};
        initialStateNew.listing = res.data.listing;
        initialStateNew.listing_details = res.data.listing_details;
        initialStateNew.salesInfo =res.data.salesInfo;
        setInitialState(initialStateNew);
      }).catch((err)=>{
        console.log(err);
      })
    }
	},[listingId]);

  const clearFormFields=(resetForm)=>{
    resetForm({});
  }

  const redirectToListingPage=()=>{
    history.goBack();
  }
  
  const redirectToNextPage=()=>{
    history.push('detailed_listing2');
  }

  const PreviewListing=()=>{
    // history.push('detailed_listing2');
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={ListingSchema}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          // // setSubmitting(true);
          // setIsDisabled(true);
          // let data = {...values};
          // if(!listingId){
          //   http.post(apiBaseUrl(`/api/createListing`),data)
          //   .then(() => {
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     notify.success("Listing created successfully");
          //     clearFormFields(resetForm,setFieldValue);
          //     if(user.UserType==40){
          //       history.push('/myaccount/myaccount_ta_all');
          //     }
          //     else{
          //       history.push('/myaccount/my_listing');
          //     }
          //   }).catch((error)=>{
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     notify.error(error);
          //   });
          // }else{
          //   http.post(apiBaseUrl(`/api/editListing?listing_id=${listingId}`),data)
          //   .then(() => {
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     if(user.UserType==40){
          //       history.push('/myaccount/myaccount_ta_all');
          //     }
          //     else{
          //       history.push('/myaccount/my_listing');
          //     }
          //     notify.success("Listing updated successfully");
          //   }).catch((error)=>{
          //     // setSubmitting(false);  
          //     setIsDisabled(false);
          //     notify.error(error);
          //   });
          // }
          if(user.UserType==40){
            history.push('/myaccount/myaccount_ta_all');
          }
          else{
            history.push('/myaccount/my_listing');
          }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Listing Name:
            </label>
            <span>Use less than 10 words.</span>
            <Field type="text" name="listing.ListingName" className="form-control"  />
            <ErrorMessage
              name="listing.ListingName"
              component="div"
              className="text-danger"
            />
            <Field type="hidden" name="listing.AvailableStatus" className="form-control" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Summary Description:
            </label>
            <span>Use 20 to 30 words.</span>
            <Field type="text" name="listing.ListingDescription" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing.ListingDescription"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Overview:
            </label>
            <span>
              Provide an overview of the technology, including its key benefits.
            </span>
            <Field type="text" name="listing_details.Overview" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing_details.Overview"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Keywords Associated with the Technology Available:
            </label>
            <span>
              Identify keywords and phrases related to this listing. Keywords are important, they are used for search 
              indexing, matching buyer requirements & promotional purposes. Please separate keywords and phrases with 
              a comma (,); use quotation marks ("") to indicate a phrase e.g. "fuel cells", "data security". Use capital 
              letters for acronyms only e.g. RFID, in all other instances use lower case text.
            </span>
            <Field type="text" name="salesInfo.Keywords" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.Keywords"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Primary applications for this technology - identify the primary target market:
            </label>
            <Field type="text" name="salesInfo.PricingNotes" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.PricingNotes"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="text-center py-2">
            <button 
                  disabled={isDisabled} 
                  type="submit" 
                  className={classnames({
                    "ml-2 buttonload btn btn-primary btn-sm": isDisabled === true,
                    "ml-2 btn btn-primary btn-sm": isDisabled === false,
                  })}>
                    Save & Return Later
            </button>
            <button type="button" onClick={(e) => PreviewListing()} className="ml-2 btn btn-primary">Preview Listing</button>
            <button type="button" onClick={(e) => redirectToNextPage()} className="ml-2 btn btn-primary">Next</button>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default CreateDetailedListingForm1;
