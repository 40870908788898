import React, { useEffect, useState, useRef } from "react";
import { notify, http, apiBaseUrl } from "../../utitility";
import { Formik, Field, ErrorMessage } from "formik";
import CustomButton from './CustomButton';
import ListingSchema from "../validation/ListingSchema";
import classnames from "classnames";

const CreateDetailedListingForm2 = ({ user, listingId,initialState, setInitialState, history }) => {
	const [isDisabled,setIsDisabled] = useState(false);
  
  useEffect(() => {
    if(listingId){
      http.get(apiBaseUrl(`/api/getListingDetailById?listing_id=${listingId}`))
      .then((res) => {
        let initialStateNew = {...initialState};
        initialStateNew.listing = res.data.listing;
        initialStateNew.listing_details = res.data.listing_details;
        initialStateNew.salesInfo =res.data.salesInfo;
        setInitialState(initialStateNew);
      }).catch((err)=>{
        console.log(err);
      })
    }
	},[listingId]);

  const clearFormFields=(resetForm)=>{
    resetForm({});
  }

  const redirectToPreviousPage=()=>{
    history.goBack();
  }

  const UploadImage=()=>{

  }
  
  const redirectToNextPage=()=>{
    history.push('detailed_listing3');
  }

  const PreviewListing=()=>{
    // history.push('detailed_listing2');
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={ListingSchema}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          // // setSubmitting(true);
          // setIsDisabled(true);
          // let data = {...values};
          // if(!listingId){
          //   http.post(apiBaseUrl(`/api/createListing`),data)
          //   .then(() => {
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     notify.success("Listing created successfully");
          //     clearFormFields(resetForm,setFieldValue);
          //     if(user.UserType==40){
          //       history.push('/myaccount/myaccount_ta_all');
          //     }
          //     else{
          //       history.push('/myaccount/my_listing');
          //     }
          //   }).catch((error)=>{
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     notify.error(error);
          //   });
          // }else{
          //   http.post(apiBaseUrl(`/api/editListing?listing_id=${listingId}`),data)
          //   .then(() => {
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     if(user.UserType==40){
          //       history.push('/myaccount/myaccount_ta_all');
          //     }
          //     else{
          //       history.push('/myaccount/my_listing');
          //     }
          //     notify.success("Listing updated successfully");
          //   }).catch((error)=>{
          //     // setSubmitting(false);  
          //     setIsDisabled(false);
          //     notify.error(error);
          //   });
          // }
          if(user.UserType==40){
            history.push('/myaccount/myaccount_ta_all');
          }
          else{
            history.push('/myaccount/my_listing');
          }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <h5 className="mb-3">
              <b className="text-primary">2. Additional Listing Information</b>
            </h5>
            <span>
              These questions are optional and can be used to elaborate the benefits of your technology, information provided 
              will be published in the listing.
            </span>
              <label className="font-weight-bold form-text">
              Listing Image:
            </label>
            <span>
              Add a small image to this listing, create a link from your web site. For example, http://www.yourdomain.com/images/image.gif.
            </span>
            <Field type="text" name="listing.ListingImg" className="form-control"  />
            <span>
              OR, upload a suitable image(s) to be used in this listing: 
            </span>
            <button type="button" onClick={(e) => UploadImage()} className="ml-2 btn btn-secondary">Upload Image</button>
            <Field type="hidden" name="listing.AvailableStatus" className="form-control" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Competitive Advantage:
            </label>
            <span>
              Describe why the technology is superior to the competition:
            </span>
            <Field type="text" name="listing.CompetitiveAdvantage" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing.CompetitiveAdvantage"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Problem Solved by the Technology
            </label>
            <span>
              What's the problem that the technology is designed to solve? Or what does it do that cannot be achieved by other means?
            </span>
            <Field type="text" name="listing_details.ProblemSolved" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing_details.Overview"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              How the Technology Solves the Problem:
            </label>
            <span>
              Explain why the technology provides a superior solution:
            </span>
            <Field type="text" name="salesInfo.Keywords" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.Keywords"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Frequently Asked Questions And Answers:
            </label>
            <Field type="text" name="salesInfo.PricingNotes" component="textarea" className="form-control"  />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              If applicable, please note any comments on the transaction sought - potential terms & restrictions etc.:
            </label>
            <Field type="text" name="salesInfo.PricingNotes" component="textarea" className="form-control"  />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Additional Information
            </label>
            <Field type="text" name="salesInfo.PricingNotes" component="textarea" className="form-control"  />
          </div>
          <div className="text-center py-2">
            <button type="button" onClick={(e) => redirectToPreviousPage()} className="ml-2 btn btn-primary">Back</button>
            <button 
                  disabled={isDisabled} 
                  type="submit" 
                  className={classnames({
                    "ml-2 buttonload btn btn-primary btn-sm": isDisabled === true,
                    "ml-2 btn btn-primary btn-sm": isDisabled === false,
                  })}>
                    Save & Return Later
            </button>
            <button type="button" onClick={(e) => PreviewListing()} className="ml-2 btn btn-primary">Preview Listing</button>
            <button type="button" onClick={(e) => redirectToNextPage()} className="ml-2 btn btn-primary">Next</button>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default CreateDetailedListingForm2;
