import React, {  useState } from "react";
import {  http, apiBaseUrl, getStandardDateForm, notify } from "../../utitility";
import { Formik, ErrorMessage, Field } from "formik";
import CustomButton from './CustomButton';
import Radio from "../Fields/Radio";

const PublishListingForm = ({ listingId, history, listing}) => {

  const [initialState] = useState({
    listing_status:listing.ListingStatus,
    available_status:listing.AvailableStatus,
    new_available_status:-1,
    updatedDate:getStandardDateForm(),
    publish:0,
    activation_code:""
  });

  const clearFormFields=(resetForm)=>{
    resetForm({});
    history.goBack();
  }
  
  const changeType = (e, setFieldValue, field) => {
    setFieldValue(field, e.target.value);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          http.post(apiBaseUrl(`/api/publishListing?listing_id=${listingId}`),values)
          .then(() => {
            resetForm({})
            setSubmitting(false);
            history.push('/myaccount/my_listing');
          }).catch((error=>{
            setSubmitting(false);
            notify.error(error.message)
            console.log(error,'error')
          }))
      }}
    >
      {({
        values,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>          
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Activation Code:
            </label>
            <Field type="text" name="activation_code" className="form-control"  />
            <ErrorMessage
              name="activation_code"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="text-center py-2">
            <CustomButton isSubmitting={isSubmitting} btnText='Submit' /> 
            <button type="button" onClick={(e) => clearFormFields(resetForm,setFieldValue)} className="ml-2 btn btn-secondary">Cancel</button>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default PublishListingForm;
