const headerTab = [
  { id: 1, label: "Home", url: "/" },
  { id: 3, label: "Patents Wanted", url: "/prioritywanted" },
  { id: 4, label: "Services", url: "/services" },
  { id: 5, label: "About Tynax", url: "/about" },
  { id: 6, label: "Free Resources", url: "/resources" },
  { id: 7, label: "My Account", url: "/myaccount/my_listing" },
];

const mainMenuTab = [
  {
    id: 1,
    label: "Create Listing",
    url: "/listings",
    allowedUserIds: ["1","40"]
  },
  {
    id: 2,
    label: "All listings",
    url: "/myaccount/myaccount_ta_all",
    allowedUserIds: ["40","30"]
  },
  {
    id: 3,
    label: "All Active Listings",
    url: "/myaccount/myaccount_ta_all",
    allowedUserIds: ["15"]
  },
  {
    id: 4,
    label: "My Listings",
    url: "/myaccount/my_listing",
    allowedUserIds: ["1","30","40"]
  },
  {
    id: 5,
    label: "Pursue Requests",
    url: "/pursue/listings",
    allowedUserIds: ["40", "15", "30"]
  },
  {
    id: 6,
    label: "Search",
    url: "/myaccount/search",
    allowedUserIds: ["15", "30", "40"]
  },
  {
    id: 7,
    label: "Patents",
    url: "/myaccount/patent",
    allowedUserIds: ["15", "30", "40"]
  },
  {
    id: 8,
    label: "Contacts",
    url: "/contacts/all_contacts",
    allowedUserIds: ["30","40"]
  },
  {
    id: 9,
    label: "Admin",
    url: "/admin/users",
    allowedUserIds: ["40"]
  },
  {
    id: 10,
    label: "Account",
    url: "/profile",
    allowedUserIds: ["40", "1", "15", "30"],
  }
  // {
  //   id: 10,
  //   label: "Un Read Email",
  //   url: "/myaccount/unreadEmail",
  //   allowedUserIds: ["40", "1", "15", "30"],
  // }
];

const mainMenuTabs = [
  {
    id: 1,
    label: "Listings",
    mainTab:"myaccount",
    url: "/myaccount/my_listing",
    allowedUserIds: ["1","30","40"],
    subMenus:[
      {id: 1,label: "Create Listing",url: "/listings",allowedUserIds: ["1","40","30"]},
      {id: 3,label: "My Listings",url: "/myaccount/my_listing",allowedUserIds: ["1","40","30"]},
      {id: 8,label: "Team Listing",url: "/myaccount/team_listing",allowedUserIds: ["30"]},
      {id: 7,label: "Browse Listings",url: "/myaccount/browse_listing",allowedUserIds: ["15"]},
      {id: 4,label: "Search Listings",url: "/myaccount/search",allowedUserIds: ["1","40","15","30"]},
      {id: 5,label: "Bookmarked Listings",url: "/myaccount/bookmarked_listing",allowedUserIds: ["1","15","30","40"]},
      {id: 6,label: "All For Sale Listings",url: "/myaccount/myaccount_ta_all",allowedUserIds: ["40","30"]}
    ]
  },
  {
    id: 8,
    label: "Listings",
    mainTab:"myaccount",
    url: "/myaccount/browse_listing",
    allowedUserIds: ["15"],
    subMenus:[
      {id: 1,label: "Create Listing",url: "#",allowedUserIds: ["1","40","30"]},
      {id: 3,label: "My Listings",url: "/myaccount/my_listing",allowedUserIds: ["1","40","30"]},
      {id: 8,label: "Team Listing",url: "/myaccount/team_listing",allowedUserIds: ["30"]},
      {id: 7,label: "Browse Listings",url: "/myaccount/browse_listing",allowedUserIds: ["15"]},
      {id: 4,label: "Search Listings",url: "/myaccount/search",allowedUserIds: ["1","40","15","30"]},
      {id: 5,label: "Bookmarked Listings",url: "/myaccount/bookmarked_listing",allowedUserIds: ["1","15","30","40"]},
      {id: 6,label: "All For Sale Listings",url: "/myaccount/myaccount_ta_all",allowedUserIds: ["40","30"]}
    ]
  },
  {
    id: 2,
    label: "Patents",
    mainTab:"patent",
    url: "/patent/browse",
    allowedUserIds: ["40", "15", "30"],
    subMenus:[
      {id: 1,label: "Browse Patent",url: "/patent/browse",allowedUserIds: ["15","40","30"]},
      {id: 2,label: "Search Patent",url: "/patent/search",allowedUserIds: ["15","40","30"]},
      {id: 3,label: "Bookmarked Patent",url: "#",allowedUserIds: ["15","40","30"]}
    ]
  },
  // {
  //   id: 5,
  //   label: "Applications",
  //   mainTab:"listing",
  //   url: "#",
  //   allowedUserIds: ["15", "40", "30"],
  // },
  {
    id: 6,
    label: "Wanted Listings",
    mainTab:"wanted",
    url: "/wanted/listing",
    allowedUserIds: ["15", "30", "40"],
    subMenus:[
      {id: 1,label: "Create Listing",url: "#",allowedUserIds: ["15","40","30"]},
      {id: 2,label: "My Wanted",url: "/wanted/listing",allowedUserIds: ["15","40","30"]},
      {id: 3,label: "Team wanted",url: "/wanted/team_listing",allowedUserIds: ["15","30"]},
      {id: 4,label: "Search tech wanted",url: "#",allowedUserIds: ["40","30"]},
      {id: 5,label: "All wanted listings",url: "/wanted/all_listing",allowedUserIds: ["40","30"]}

    ]
  },
  {
    id: 6,
    label: "Pursue Requests",
    mainTab:"pursue",
    url: "/pursue/listings",
    allowedUserIds: ["15","30","40"],
    subMenus:[
      {id: 1,label: "Listings",url: "/pursue/listings",allowedUserIds: ["15","40","30"]},
      {id: 2,label: "Patents",url: "/pursue/patents",allowedUserIds: ["15","40","30"]},
     // {id: 3,label: "Applications",url: "/myaccount/my_listing",allowedUserIds: ["15","40","30"]}
    ]
  },
  {
    id: 7,
    label: "EOU",
    mainTab:"eou",
    url: "/eou/listings",
    allowedUserIds: ["40"],
    subMenus:[
      {id: 1,label: "Create EOU",url: "#",allowedUserIds: ["40"]},
      {id: 2,label: "Import EOU",url: "#",allowedUserIds: ["40"]},
      {id: 3,label: "All EOU",url: "/eou/listings",allowedUserIds: ["40"]},
      {id: 3,label: "Seacrch EOU",url: "#",allowedUserIds: ["40"]}
    ]
  },
  {
    id: 8,
    label: "Analysis",
    mainTab:"analysis",
    url: "/analysis/listings",
    allowedUserIds: ["40", "13", "25", "20"],
    subMenus:[
      {id: 1,label: "Create Analysis",url: "#",allowedUserIds: ["40"]},
      {id: 2,label: "Import Analysis",url: "#",allowedUserIds: ["40"]},
      {id: 3,label: "All Analysis",url: "/analysis/listings",allowedUserIds: ["40"]},
      {id: 3,label: "Seacrch Analysis",url: "#",allowedUserIds: ["40"]}
    ]
  },
  {
    id: 9,
    label: "Search",
    mainTab:"listing",
    url: "",
    allowedUserIds: ["40", "13", "25", "20"],
  },
  {
    id: 10,
    label: "Account",
    mainTab:"profile",
    url: "/profile",
    allowedUserIds: ["40", "13", "1", "15", "25", "20", "30"],
  },
  {
    id: 11,
    label: "Contacts",
    mainTab:"contacts",
    url: "/contacts/mylisting",
    allowedUserIds: ["40"],
    subMenus:[
      {id: 1,label: "My Contact",url: "/contacts/mylisting",allowedUserIds: ["40"]},
      {id: 2,label: "Contact",url: "/contacts/all_contacts",allowedUserIds: ["40"]},
      {id: 3,label: "Organization",url: "/contacts/organizations",allowedUserIds: ["40"]},
      {id: 4,label: "Create Contact",url: "#",allowedUserIds: ["40"]},
      {id: 5,label: "Create Organization",url: "#",allowedUserIds: ["40"]},
      {id: 6,label: "Search Contact",url: "#",allowedUserIds: ["40"]},
      {id: 7,label: "Search Organization",url: "#",allowedUserIds: ["40"]},
      {id: 8,label: "Mailing Lists",url: "/contacts/mailing_lists",allowedUserIds: ["40"]}


    ]
  },
  {
    id: 12,
    label: "Admin",
    mainTab:"admin",
    url: "/admin/users",
    allowedUserIds: ["40"],
    subMenus:[
      {id: 1,label: "Users",url: "/admin/users",allowedUserIds: ["40"]}
    ]
  },
];

const listingStatus =  [
  { value: '1', label: 'Active' },
  { value: '2', label: 'Under Offer' },
  { value: '3', label: 'Under Option' },
  { value: '4', label: 'Unavailable' },
  { value: '5', label: 'Withdrawn' },
  { value: '6', label: 'Sold' },
  { value:'7', label: 'Expired' }
]

export { headerTab, mainMenuTab, listingStatus };
