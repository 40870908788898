import React, {useRef} from "react";
import { notify, http, apiBaseUrl, hasKeys, getStandardDateForm} from "../../utitility";
import { Formik } from "formik";
import CustomButton from './CustomButton';
import { useState } from "react";
import { Field } from 'formik'

const AttachmentForm = ({user, listings, reloadTableData, setForceReload}) => {
  const fileInput = useRef(null);
  const [attachedImg,setAttachedImg] = useState({});
  const handleImageClick = (e) => {
    e.stopPropagation();
    fileInput.current.click();
  };  

  const handleFileChange = (event,setFieldValue) =>{
    setFieldValue("file", event.currentTarget.files[0]);
    let reader = new FileReader();

    reader.onloadend = () => {
      setAttachedImg(reader.result);
    };

    reader.readAsDataURL(event.currentTarget.files[0]);
  }

  const clearFormFields=(resetForm)=>{
    setAttachedImg("");
    resetForm({});
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
				file:{},
				description:'',
        Internal:0,
        listingId:listings ? listings.ListingID : '',
        CreateDate:''
      }}
      validate={(values) => {
        const errors = {};
        if (!(values.file.size)) {
          errors.file = "Please select a file to upload";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
				var fd = new FormData();
        fd.append("file", values.file);
				setSubmitting(true);
        http.post(apiBaseUrl(`/api/uploadImg?listing_id=${listings.ListingID}`),fd)
        .then((res) => {
          resetForm({});
          let data = {...values};
					data.fileName = res.data.data.filename;
					data.originalname = res.data.data.originalname;
          data.size =  res.data.data.size;
          data.mimetype = res.data.data.mimetype;
          data.UserID = user.UserID;
          data.Internal = values.Internal ? 1 : 0;
          http.post(apiBaseUrl(`/api/uploadImgData`),data)
          .then((res) => {
            setSubmitting(false);          
            setForceReload(new Date());
            setFieldValue("file",{});
            setAttachedImg("");
            reloadTableData();
						notify.success("Attachment uploaded Successfully");
					}).catch((error)=>{
            setSubmitting(false);
						notify.error("Error while uploading");
					});
        }).catch((error)=>{
          setSubmitting(false);
          notify.error("Error while uploading");
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputPassword1"
            >
              File Upload
            </label>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={(e) => handleImageClick(e)}
             >
              Choose file
             </button>
             {hasKeys(values.file) && values.file.fileName}
            <input
              type="file"
              name="file"
              ref={fileInput}
              className="form-control d-none"
              onChange={(event) => {
                handleFileChange(event,setFieldValue)
							}}
            />
            {hasKeys(attachedImg) &&
              <img src={attachedImg} width="100px" height="100px"/>
            }
            <span className="text-danger">
              {errors.file && touched.file && errors.file}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Description
            </label>
            <input
              type="text"
              name="description"
              className="form-control"
              onChange={handleChange}
              value={values.description}
            />
            <span className="text-danger">
              {errors.description &&
                touched.description &&
                errors.description}
            </span>
          </div>
          <div className="form-check form-check-inline">
            <Field type="checkbox" name="Internal" className="form-check-input" value='checked' />
            <label className="form-check-label pl-3" htmlFor="inlineCheckbox1">
								This attachment cannot be included in the invitation.
            </label>
          </div>
          <span className="text-danger">
              {errors.Internal && touched.Internal && errors.Internal}
            </span>
          <div className="text-center py-2">
            <CustomButton isSubmitting={isSubmitting} btnText='Attach' /> 
            <button type="button" onClick={(e) => clearFormFields(resetForm)} className="ml-2 btn btn-secondary">Clear</button>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default AttachmentForm;
