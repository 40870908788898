import React, { Component } from "react";
import tynax_anim from '../assets/images/tynax-anim.gif';

class PublicLeftMenu extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div className="box-left">
					<div style={{ textAlign: "center" }}>
						<ul className="sidemenu">
							<img src={tynax_anim} />
							<br />
							<br />

							<li>
								Services
								<ul className="goldbulletimage">
									<li>
										<a href="services.php">Tynax Services</a>
									</li>
									<li>
										<a href="transactions_sourcing_acquisition.php">
											Buy-Side Brokerage
										</a>
									</li>
									<li>
										<a href="transactions_patent_sale.php">
											Sell-Side Brokerage
										</a>
									</li>
									<li>
										<a href="strategy.php">Strategy Consulting</a>
									</li>
									<li>
										<a href="analysis.php">Patent Analysis</a>
									</li>
									<li>
										<a href="eou.php">Evidence of Use</a>
									</li>
									<li>
										<a href="sep.php">Standards-Essentials</a>
									</li>
									<li>
										<a href="valuation_services.php">Valuation Services</a>
									</li>
									<li>
										<a href="education.php">I.P. Education</a>
									</li>
								</ul>
							</li>
							<li></li>
							<li>
								Free Resources
								<ul className="goldbulletimage">
									<li>
										<a href="transactions_patent_sale_guide.php">
											Patent Sale Guide
										</a>
									</li>
									<li>
										<a href="https://trendsv.com/category/patents/">
											Patent Podcast
										</a>
									</li>
									<li>
										<a href="daggers.php">Patents, Cloaks &amp; Daggers Book</a>
									</li>
									<li>
										<a href="patent_brokers.php">Guide to Patent Brokers</a>
									</li>
									<li>
										<a href="how-to-sell-your-own-patent.php">
											How to Sell Your Own Patent
										</a>
									</li>
									<li>
										<a href="dollarvalue.php">Dollar Value Book</a>
									</li>
									<li>
										<a href="videos.php">Videos</a>
									</li>
								</ul>
								Company
								<ul className="goldbulletimage">
									<li>
										<a href="about.php">About Tynax</a>
									</li>
									<li>
										<a href="closedcases.php">Brokered Transactions</a>
									</li>
									<li>
										<a href="management.php">Management</a>
									</li>
									<li>
										<a href="contact.php">Contact</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
    );
  }
}

export default PublicLeftMenu;
