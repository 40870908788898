
import React, { useMemo, useState, useRef } from "react";
import ReactGenericTable from '../Tables/ReactGenericTable';
import { formatDate, http, apiBaseUrl, notify, mergeString } from '../../utitility';
import Switch from '../Fields/Switch';
import { Row, Col } from "reactstrap";
import { PatentAttachmentForm } from "../Forms";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const PatentAttachment = ({ user, patentDetails }) => {

	const [editValue, setEditValue] = useState({});
	const [count, setCount] = useState(0);
	const title = `ATTACHMENTS - ${count}`;
	const EditNotes = (value) => {
		setEditValue(value);
	}
	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

	const deleteAttachment = (data) => {
		if (data.DocID) {
			http.delete(apiBaseUrl(`/api/deletePatentAttachment?DocID=${data.DocID}`))
				.then((res) => {
					notify.success("success");
					reloadTableData();
				}).catch((error) => {
					let errorMsg = (error.response && error.response.data && error.response.data.message) ?
						error.response.data.message :
						'whoops...something went wrong';
					notify.error(errorMsg);
				});
		}
	}

	const childRef = useRef();

	const downloadAttachment = (data) =>{
		if(data.DocID){
				http.get(apiBaseUrl(`/api/downloadPatentAttachmentFile?docId=${data.DocID}&patent_id=${data.PatentID}`))
			.then((res) => {
				reloadTableData();
				notify.success("success");
				const base64 =res && res.data && res.data.baseimage;
				const downloadLink = document.createElement("a");
				const fileName = data.doc_dispplay_name;
	
				downloadLink.href = base64;
				downloadLink.download = fileName;
				downloadLink.click();
			}).catch((error)=>{
			  let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
							  error.response.data.message :
							  'whoops...something went wrong';
			  notify.error(errorMsg);
			});
		}
	}

	const getActionField = (data) => {
		let href=`./patent/${data.PatentID}/${data.FileName}`;
		return (
			<div>
				{/* {data.downloadFile ? 
				<a className="btn btn-secondary btn-sm mr-1" href={href} download >
					<i className="fa fa-download" />
				</a>
				:
				<a className="btn btn-secondary btn-sm mr-1" href="#" >
					<i className="fa fa-download" />
				</a>} */}
				<button  onClick={e => downloadAttachment(data)} className="btn btn-secondary btn-sm">
					<i className="fa fa-download" />
				</button>
				<button onClick={e => deleteAttachment(data)} className="btn btn-secondary btn-sm">
					<i className="fa fa-trash" />
				</button>
			</div>
		)
	}

	const columns = useMemo(
		() => [
			{
				Header: "File Name",
				accessor: "doc_dispplay_name",
				width: 250,
				sort: true
			},
			{
				Header: "File Size",
				accessor: "FileSize",
				width: 150,
				sort: true
			}, {
				Header: "Description",
				accessor: "Description",
				width: 320
			}, {
				Header: "User",
				accessor: "ConvertedMessage",
				width: 150,
				sort: true,
				Cell: data => { return mergeString(data.row.original.FirstName, data.row.original.LastName) },
			}, {
				Header: "Date",
				accessor: "CreateDate",
				width: 150,
				sort: true,
				Cell: data => { return formatDate(data.cell.value) }
			}, {
				Header: "Action",
				accessor: "ListingId",
				Cell: data => { return getActionField(data.cell.row.original) },
				width: 180,
				sort: true
			}
		],
		[]
	)


	const isActive = (count > 0 ? true : false);
	return (
		<Switch
			classNames="icon icon-uploadAgreement-icon"
			title={title}
			className="grouped-body"
			id="attachment_info"
			collapsible={true}
			activeStatus={isActive}
		>
			<section className="mb-3">
				<React.Fragment>
					{patentDetails &&
						<ul className="pl-0" style={{ listStyleType: 'none' }}>
							<li><b className="text-primary mr-2">Patent Id:</b>{patentDetails.PatentID}</li>
							<li><b className="text-primary mr-2">Patent Name:</b>{patentDetails.PatentName}</li>
						</ul>}
					<Row>
						<Col className="col-sm-12 col-md-8">
							<PatentAttachmentForm
								reloadTableData={() => reloadTableData()}
								patentDetails={patentDetails}
								editValue={editValue}
								user={user}
							/>
						</Col>
					</Row>
					{patentDetails && patentDetails.PatentID &&
						<ReactGenericTable
							ref={childRef}
							setCount={setCount}
							url={`getPatentAttachment?patent_id=${patentDetails.PatentID}&`}
							defaultSortIndex="a.ObjID"
							defaultSortOrder="DESC"
							columns={columns}
						/>
					}
				</React.Fragment>
			</section>
		</Switch>
	);
};
export default PatentAttachment;