import React, { useMemo, useRef }  from "react";
import  ReactGenericTable from '../Tables/ReactGenericTable';
import { formatDate, getStar } from '../../utitility';
import { useState } from "react";
import { SearchPatentForm } from "../Forms";
import { Link } from 'react-router-dom';

const SearchPatent = ({user}) => {
	
	const [url,setUrl] = useState("");
	const childRef = useRef();
	
	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

	const getViewLink = (patentId,patentNumber,country) => {
		return <Link className="nav-link" to={`/patent_details?patentid=${patentId}&plist=pid&pat_num=${patentNumber}&pcont=${country}`}>{patentNumber}</Link>
	}

	const columns = useMemo(
		() => [
			{
				Header: "Patent Number",
				accessor: "PatentNumber",
				width: 90,
				sort:true,
				Cell: data => {return getViewLink(data.row.original.PatentID,data.row.original.PatentNumber,data.row.original.Country)},
			},{
				Header: "Country",
				accessor: "Country",
				width: 50
			},{
				Header: "Listing",
				accessor: "ListingId",
				width: 188,
				sort:true
			},{
				Header: "Rating",
				accessor: "rating",
				width: 100,
				Cell: data => {return getStar(data.row.values.rating)},
				sort:true
			},{
				Header: "Status",
				accessor: "PatentStatus",
				width: 70,
				sort:true
			},{
				Header: "Title",
				accessor: "Title",
				width: 350,
				sort:true
			},{
				Header: "Company",
				accessor: "CompanyName",
				width: 150,
			},{
				Header: "Assignee",
				accessor: "Assignee",
				width: 203,
			},{
				Header: "Inventor",
				accessor: "Inventor",
				width: 203,
			},{
				Header: "Created",
				accessor: "CreateDate",
				width: 110,
				Cell: row => (formatDate(row.cell.value)),
			}
		],
		[]
	)

  return (
				<React.Fragment>
					<section>
						<SearchPatentForm user={user} reloadTableData={() => reloadTableData()}  setUrl={setUrl}/>
					</section>
					<div>
						<ReactGenericTable ref={childRef} pagination={false} url={url} defaultSortIndex="PatentID" defaultSortOrder="DESC" columns={columns} filter={true} exportCSV={true} />
					</div>
				</React.Fragment>
  );
};
export default SearchPatent;
