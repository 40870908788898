import React,{useContext, useState, useEffect} from "react";
import {  CreateUserForm } from "../Components/Forms";
import { hasKeys, getStandardDateForm } from '../utitility'
import { UserContext } from "../Context/UserContext";

const CreateUserpage = ({history}) => {
	
	// const {user} = useContext(UserContext);
  	const {user,setUser} = useContext(UserContext);
	return (
		<React.Fragment>
			{hasKeys(user) &&
						<div className="container-fluid bg-white">
							<div className="border-bg">
								<h3
                  					className="font-weight-bold text mb-3 mt-3 ml-3" 
                  					style={{ color: "#2d78a0" }}
                				>
                  				Create User
                				</h3>
								<section className=" mb-5 ml-5 row">
									<div className="col-6">
										<CreateUserForm history={history} />
									</div>
								</section>
							</div>
						</div>
				}
		</React.Fragment>
  	);
}
export default CreateUserpage;
