import React, { useEffect, useState, useContext } from "react";
import { useLocation } from 'react-router-dom';
import {  http, apiBaseUrl, hasKeys } from '../utitility';
import { PatentNotes, PatentAttachment, PatentListings, PatentAnalysis, PatentEOU } from '../Components';
import { UserContext } from "../Context/UserContext";

const PatentDetailsPage = () => {
	
	const [patentDetails,setPatentDetails] = useState({});
	const [patentId,setPatentID] = useState('');
	const [PageURL,setPageURL] = useState("");
	const [googleURL,setGoogleURL] = useState("");
	const [lenseURL,setLenseURL] = useState("");
	const [patentBuddy,setPatentBuddy] = useState("");
	const  location = useLocation();
	const {user,setUser} = useContext(UserContext);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		let patent_id = params.get('patentid');
		let patent_number = params.get('pat_num');
		let patent_country = params.get('pcont');
		getPatentDetails(patent_id,patent_number,patent_country);
		setPatentID(patent_id);
	},[location.search]);
	
	const dataArray = [
		{label:"ORIGINAL ASSIGNEE",value:"Assignee"},
		{label:"INVENTOR",value:"Inventor"},
		{label:"CLASSIFICATION",value:"Classification"},
		{label:"INTERNATIONAL CLASS",value:"InternationalClass"},
		{label:"FILED DATE",value:"FiledDate"},
		{label:"ISSUED DATE",value:"IssuedDate"}
	]

	const getPatentDetails = (patent_id,patent_number,patent_country) =>{ 

		setPageURL(`http://patft.uspto.gov/netacgi/nph-Parser?Sect2=PTO1&Sect2=HITOFF&p=1&u=%2Fnetahtml%2FPTO%2Fsearch-bool.html&r=1&f=G&l=50&d=PALL&RefSrch=yes&Query=PN%2F${patent_number}`);
		setGoogleURL(`http://patents.google.com/patent/${patent_country.toUpperCase()}${patent_number}`);
		setLenseURL(`https://www.lens.org/lens/search?q=${patent_country}${patent_number}`);
		setPatentBuddy(`http://www.patentbuddy.com/Patent/${patent_number}?ft=true&sr=true`);
		
		http.get(apiBaseUrl(`/api/patentDetailById?patent_id=${patent_id}&pat_num=${patent_number}&pcont=${patent_country}`))
		.then((res) => {
			if (res.data.data[0]) {
				setPatentDetails(res.data.data[0]);
				// let pageUrl = res.data.data[0].PageURL;
				// let country = res.data.data[0].Country;
				// if (!pageUrl  && country.toLowerCase() == 'us') {
				// 	setPageURL(`http://patft.uspto.gov/netacgi/nph-Parser?Sect2=PTO1&Sect2=HITOFF&p=1&u=%2Fnetahtml%2FPTO%2Fsearch-bool.html&r=1&f=G&l=50&d=PALL&RefSrch=yes&Query=PN%2F${res.data.data[0].PatentNumber}`);
				// 	setGoogleURL(`http://patents.google.com/patent/${res.data.data[0].Country}${res.data.data[0].PatentNumber}`);
				// 	setLenseURL(`https://www.lens.org/lens/search?q=${res.data.data[0].Country}${res.data.data[0].PatentNumber}`);
				// 	setPatentBuddy(`http://www.patentbuddy.com/Patent/${res.data.data[0].PatentNumber}?ft=true&sr=true`)
				// }else{
				// 	setPageURL('#');
				// }
			} 
		}).catch((error)=>{
			console.log(error)
		});
	}

    return (
			<React.Fragment>
				<section>
					<div className="container-fluid py-3 bg-white">
						<div className="row ml-3">
							<div className="col-12 col-lg-12">
								{hasKeys(patentDetails) &&
									<div>
										<h4 className="text-primary mb-2">{patentDetails.Country} {patentDetails.PatentStatus} Patent {patentDetails.PatentNumber}</h4>
										<h5 className="text-primary mb-2">{patentDetails.Title}</h5>
									</div>
								}
								<h6 className="text-secondary mb-2">
									View this patent on one of the following websites:&nbsp;
								  <u><a className="mr-2 text-secondary" target="_blank" href={PageURL}>USPTO</a></u>
									<u><a className="mr-2 text-secondary" target="_blank" href={googleURL}>GOOGLE</a></u>
								  <u><a className="mr-2 text-secondary" target="_blank" href={lenseURL}>Lens</a></u>
								  <u><a className="mr-2 text-secondary" target="_blank" href={patentBuddy}>Patent Buddy</a></u>
								</h6>
								<ul  style={{listStyleType:'none'}}>
									{dataArray && patentDetails && 
										dataArray.map((data, index) => (
												patentDetails[data.value] ?
												<li className="mb-2" key={index}><div className="text-primary mr-2">{data.label}</div><div>{patentDetails[data.value]}</div></li>:
												<li></li>
										)
									)
									}
								</ul>	
							</div>
						</div>
						<div className="row ml-3">
							<div className="col-12 col-lg-12">
								<PatentNotes patentDetails={patentDetails} user={user}/>
							</div>
						</div>
						<div className="row ml-3">
							<div className="col-12 col-lg-12">
								<PatentAttachment patentDetails={patentDetails} user={user}/>
							</div>
						</div>
						<div className="row ml-3">
							<div className="col-12 col-lg-12">
								<PatentListings patentDetails={patentDetails} user={user}/>
							</div>
						</div>
						<div className="row ml-3">
							<div className="col-12 col-lg-12">
								<PatentEOU patentId={patentId} patentDetails={patentDetails} user={user}/>
							</div>
						</div>
						<div className="row ml-3">
							<div className="col-12 col-lg-12">
								<PatentAnalysis patentId={patentId} patentDetails={patentDetails} user={user}/>
								<p>View <a className="text-secondary" target="_blank" href={PageURL}>this patent</a> on the original website.</p>
							</div>
						</div>

					</div>
				</section>
			</React.Fragment>
    );
}
export default PatentDetailsPage;
