import React, {useEffect, useRef} from "react";
import { Row, Col } from "reactstrap";
import AsyncSelect from "react-select/async";
import { useState } from "react";
import { filter , isEmpty} from "lodash";
import { http, apiBaseUrl,notify, getStandardDateForm, hasKeys } from "../utitility"
import classnames from "classnames";
import CustomModal from "./Modal/CustomModal";

const RecommendListing = ({ listing, listing_id, reloadTableData, classNameValue }) => {

	useEffect(() => {
    if(listing.recommendedByTynax){
			setSelectedValue(JSON.parse(listing.recommendedByTynax));
		}
	},[listing]);
	
	const [IsOpenRecommendModal,OpenRecommendModal] = useState(false);
	const [selectedValue,setSelectedValue] = useState(null);
	const elRef = useRef(null);

	const [isSubmitting,setIsSubmitting] = useState(false);

	const handleItemSelectChange=(option)=>{
		setSelectedValue(option);
 	}

	const loadOptions = (inputValue, callback) => {
		let url = `/api/user_role?UserType=15`;

		if(inputValue){
			url = `/api/user_role?UserType=15&SearchString=${inputValue}`
		}

		http.get(apiBaseUrl(url))
			.then((res) => {
				callback(res.data.data)
			}).catch((error=>{
				console.log(error,'error')
			}))
	}

	const toggleLRecommendModal = () => {
		if(listing.recommendedByTynax){
			setSelectedValue(JSON.parse(listing.recommendedByTynax));
		}
		OpenRecommendModal(!IsOpenRecommendModal);
	}

	const recommendListing = () => {
		if(selectedValue !== null) {
			let data = {};
			let option_string = JSON.stringify(selectedValue)
			data.listing_id = listing_id;
			data.updatedDate = getStandardDateForm();
			data.recommendedByTynax = option_string;
			http.post(apiBaseUrl(`/api/setRecommendation`),data)
			.then(() => {
				reloadTableData();
				toggleLRecommendModal();
				notify.success("Recommendation updated successfully");
			}).catch((error)=>{
				console.log(error)
				let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
												error.response.data.message :
												'whoops...something went wrong';
				notify.error(errorMsg);
			});
		} else {
			notify.error('Please select any buyer');
			setIsSubmitting(false);
		} 
	}

	const getOptionLabel = (firstName,LastName) => {
		let name = firstName ? firstName : "";
		name += LastName ? " " +LastName : "";
		return name;
	}

  return (
    <React.Fragment>
      <span className={classNameValue? classNameValue :"btn-link link-pointer" }onClick={()=>{toggleLRecommendModal()}}>Recommend</span>
			{IsOpenRecommendModal &&
				<CustomModal notice={true} modal={IsOpenRecommendModal}  toggle={toggleLRecommendModal} closeAllModal={toggleLRecommendModal} heading={"Recommend Listing"}  render={() => (  
					<React.Fragment>
						<Row className="justify-content-center">
							<Col className="mb-3 col-6">
								Buyers:
								<AsyncSelect
										cacheOptions
										defaultOptions
										isMulti
										value={selectedValue}
										getOptionLabel={e => getOptionLabel(e.Firstname,e.Lastname)}
										getOptionValue={e => e.UserID}
										loadOptions={loadOptions}
										ref={elRef}
										isClearable={true}
										onChange={handleItemSelectChange}
										placeholder="Search buyers"
									/>
							</Col>
						</Row>
						<div className="text-center">
							<button 
							disabled={isSubmitting} 
							type="button"
							className={classnames({
							"buttonload btn btn-primary btn-sm": isSubmitting === true,
							"btn btn-primary btn-sm": isSubmitting === false,
							})}
							onClick={()=>recommendListing()}
							>
								{isSubmitting &&
								<i className="fa fa-spinner fa-spin"></i>
								}
								Save
							</button>
						</div>
					</React.Fragment>
				)}/>
			}
		</React.Fragment>
  );
};

export default RecommendListing;
