import React, { Component } from "react";
import widget1_header_left1 from "../assets/images/widget1-header-left1.png";
import widget1_header_right1 from "../assets/images/widget1-header-right1.png";

class NewUserBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="formbox" id="regbox">
        <div className="content">
          <p>
            Register for a free Tynax account to post listings of patents,
            portfolios and evidence-of-use materials that you are looking to
            monetize. Your account will provide you with the ability to manage
            your listings, review and bookmark patents and candidates you want
            to track and acts as a console for all your patent monetization and
            licensing activities.
          </p>
          <p>
            <a href="registration.php">Create an Account</a>
          </p>
        </div>
        <div className="footer">
          <span></span>
        </div>
      </div>
    );
  }
}

export default NewUserBox;
