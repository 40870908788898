import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactGenericTable from '../Tables/ReactGenericTable'
import AttachmentForm from "../Forms/AttachmentForm";
import { Row, Col } from "reactstrap";
import { notify, apiBaseUrl, http, formatDate } from "../../utitility";

const CreateAttachment = ({listings,user, forceReload, setForceReload, history}) => {
	const [count,setCount] = useState(0);

	const childRef = useRef();

	const reloadTableData = () => {
		childRef.current.reloadTableData()
	}

  const deleteAttachment = (data) =>{
    if(data.DocID){
			http.delete(apiBaseUrl(`/api/delete_attachment?DocID=`+data.DocID))
        .then((res) => {
					reloadTableData();
					notify.success("success");
					setForceReload(new Date());
        }).catch((error)=>{
          let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
                          error.response.data.message :
                          'whoops...something went wrong';
          notify.error(errorMsg);
        });
		}
	}

	const downloadAttachment = (data) =>{
		if(data.DocID){
				http.get(apiBaseUrl(`/api/downloadFile?docId=${data.DocID}&listing_id=${listings.ListingID}`))
			.then((res) => {
				reloadTableData();
				notify.success("success");
				setForceReload(new Date());
				const base64 =res && res.data && res.data.baseimage;
				const downloadLink = document.createElement("a");
				const fileName = data.doc_dispplay_name;
	
				downloadLink.href = base64;
				downloadLink.download = fileName;
				downloadLink.click();
			}).catch((error)=>{
			  let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
							  error.response.data.message :
							  'whoops...something went wrong';
			  notify.error(errorMsg);
			});
		}
	}

  const getActionField = (data) => {
		let href =`/downloadFile?docId=${data.DocID}&listing_id=${listings.ListingID}`;
		return( 
			<div>
				{/* <a
					className="btn btn-secondary btn-sm mr-1"
				  href={href}
					>
					<i className="fa fa-download" />
				</a> */}
				<button  onClick={e => downloadAttachment(data)} className="btn btn-secondary btn-sm">
					<i className="fa fa-download" />
				</button>
				<button  onClick={e => deleteAttachment(data)} className="btn btn-secondary btn-sm">
					<i className="fa fa-trash" />
				</button>
			</div>
		)
	}

	const getFileSize = (size) =>{
		if(size){
			return size + " kb";
		}else {
			return "";
		}
	}
	
	const redirectToNextPage=()=>{
		notify.success("Attachment Uploaded successfully");
		if(user.UserType==40){
		  history.push('/myaccount/myaccount_ta_all');
		}
		else{
		  history.push('/myaccount/my_listing');
		}
	}
	
	const redirectToPrevPage=()=>{
		if(user.UserType==40){
		  history.push('/myaccount/myaccount_ta_all');
		}
		else{
		  history.push('/myaccount/my_listing');
		}
	}

	const columns_attachment_for_admin = useMemo(
		() => [
			{
				Header: "File Name",
				accessor: "doc_dispplay_name",
				width: 250,
				sort:true
			},
			{
				Header: "Size",
				accessor: "FileSize",
				Cell: data => {return getFileSize(data.cell.value)},
				width: 100,
				sort:true
			},
			{
				Header: "Description",
				accessor: "Description",
				width: 250,
				sort:false
			},
			{
				Header: "Action",
				accessor: "act",
				Cell: data => {return getActionField(data.row.original)},
				width: 200,
				sort:false
			}
		]
	)

	const columns_attachment = useMemo(
		() => [
			{
				Header: "File Name",
				accessor: "doc_dispplay_name",
				width: 250,
				sort:true
			},
			{
				Header: "Size",
				accessor: "FileSize",
				Cell: data => {return getFileSize(data.cell.value)},
				width: 100,
				sort:true
			},
			{
				Header: "Description",
				accessor: "Description",
				width: 150,
				sort:false
			},
			{
				Header: "User",
				accessor: "tbl_user.Username",
				Cell: data => {return user.Firstname+' '+user.Lastname},
				width: 100,
				sort:false
			},
			{
				Header: "Date",
				accessor: "CreateDate",
				Cell: data => {return formatDate(data.cell.value)},
				width: 100,
				sort:true
			},
			{
				Header: "Action",
				accessor: "act",
				Cell: data => {return getActionField(data.row.original)},
				width: 200,
				sort:false
			}
		]
	)

  const isActive = (count > 0 ? true : false);
  return (
			<section className="mb-3">
				<React.Fragment>
					{(user.UserType === 1 || user.UserType === 30 || user.UserType === 40) &&
						<div>
							<p>
								If you have claims charts or other pertinent documents that will 
								help Tynax and our clients appreciate the potential of your patents 
								please upload them here.
							</p>
							<ul className="pl-0" style={{listStyleType:'none'}}>
								<li><b className="text-primary mr-2">Listing Id:</b>{listings.ListingID}</li>
								<li><b className="text-primary mr-2">Listing Name:</b>{listings.ListingName}</li>
							</ul>
							<Row>
								<Col className="col-sm-12 col-md-8">
									<AttachmentForm 
										setForceReload={setForceReload}
										reloadTableData={() => reloadTableData()} 
										listings={listings} user={user}
									/>
								</Col>
							</Row>
						</div>
					}
					{listings && listings.ListingID	&&
						<ReactGenericTable  
						  ref={childRef}
							setCount={setCount} 
							url={`listing_attachment?listing_id=${listings.ListingID}&`} 
							defaultSortIndex="doc_dispplay_name" 
							defaultSortOrder="DESC" 
							columns={user.UserType>=30 ?  columns_attachment :  columns_attachment_for_admin} 
						/>
					}	
				</React.Fragment>
				<div className="text-center py-2">
					<button type="button" onClick={(e) => redirectToNextPage()} className="ml-2 btn btn-primary">Submit</button>
					<button type="button" onClick={(e) => redirectToPrevPage()} className="ml-2 btn btn-primary">Cancel</button>
				</div>
			</section>
    );
  }

export default CreateAttachment;
