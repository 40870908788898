
import * as Yup from 'yup'

const ListingSchema = Yup.object().shape({
	listing: Yup.object().shape({
		ListingName: Yup.string().required("Listing Name is required"),
	}),
	listing_details: Yup.object().shape({
		// PatentNotes:Yup.string().required("Patent Number is required"),
		TechnologyOverview:Yup.string().when('PatentIPOwnership', {
			is: 2,
			then: Yup.string().required('please describe these encumbrances'),
			otherwise: Yup.string().notRequired(),
		}),
		PatentLicensingArrangement:Yup.string().when('PatentLicensingStatus', {
			is: 2,
			then: Yup.string().required('please describe these encumbrances'),
			otherwise: Yup.string().notRequired(),
		}),
		PatentPotentialInfringers:Yup.string().when('PatentInfringement', {
			is: 2,
			then: Yup.string().required('please provide information on potential infringers'),
			otherwise: Yup.string().notRequired(),
		}),
		PatentLitigationNotes:Yup.string().when('PatentLitigation', {
			is: 2,
			then: Yup.string().required('please describe the cases and the outcome'),
			otherwise: Yup.string().notRequired(),
		})
	}),
	salesInfo: Yup.object().shape({
		Seller:Yup.string().required("Patent Seller is required"),
	})
});

export default ListingSchema;

// please describe existing licensing arrangements: