import React, {useEffect, useRef} from "react";
import { filter , isEmpty} from "lodash";


const RecommendListing = ({ value, user }) => {

	const isRecommended=(id,value)=> {
		if (!isEmpty(value)) {
			return filter(value, function(o) {
					if (o.UserID === id) return o;
			});
		} else {
			return {};
		}
	}

	const getRecommentation = (value) => {
		if(value){
			value = JSON.parse(value)
			let recommendedArray = isRecommended(user.UserID,value)
      if(recommendedArray.length >0){
			return (
			        <div className="text-center">
								<i style={{"fontSize": "2em", "color": "#FFD700"}} class="fa fa-thumbs-up"></i>
							</div>
						 )
			}else{
				return "";
			}
		}else{
			return "";
		}
	}

  return (
    <React.Fragment>
       {getRecommentation(value)}
		</React.Fragment>
  );
};

export default RecommendListing;
