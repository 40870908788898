import React,{useContext, useState, useEffect} from "react";
import {  BrokerListingForm } from "../Components/Forms";
import { hasKeys, getStandardDateForm } from '../utitility'
import { UserContext } from "../Context/UserContext";
import { UserListing } from "../Components";

const BrokerListingPage = ({brokerData}) => {
	
  	const {user,setUser} = useContext(UserContext);
	return (
		<React.Fragment>
			{hasKeys(user) &&
						<div className="container-fluid bg-white">
										<div className="border-bg">
											<section className=" mb-5 ml-3 row">
													<BrokerListingForm user={user} brokerData={brokerData} />
											</section>
										</div>
									</div>
				}
		</React.Fragment>
  	);
}
export default BrokerListingPage;
