import { toast } from "react-toastify";

const config = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: true,
  hideProgressBar: true,
  className: "notify",
};

export default {
  success: (message) => toast.success(message, config),
  error: (message) => toast.error(message, config),
  info: (message) => toast.info(message,config),
};
