import React, { useContext } from "react";
import google_signin from "../../assets/images/btn_google_signin_dark_normal_web@2x.png";
import GoogleLogin from 'react-google-login';
import { notify, http, apiBaseUrl, getStandardDateForm, hasKeys } from "../../utitility";
import { UserContext } from "../../Context/UserContext";

const LoginUsingGoogle = (props) => {
	const {user,setUser} = useContext(UserContext);

	const responseGoogle = (response) => {
		let values = {};
		values.UserName = response.profileObj.name;
		values.Email = response.profileObj.email;
		values.GoogleLogin = true;
		values.Firstname =  response.profileObj.name;
		values.CreateDate= getStandardDateForm();
		values.LastUpdate= getStandardDateForm();
		http.post(apiBaseUrl(`/api/login`),values)
			.then((res) => {
				notify.success("success");
				setUser(res.data.data);
				window.localStorage.setItem('user', JSON.stringify(res.data.data));
				let userData = res.data.data;
				if(hasKeys(userData) && (userData.UserType == 15 || userData.UserType == 30 || userData.UserType == 40)){
					props.history.push('/myaccount/myaccount_ta_all');
				}else{
					props.history.push('/myaccount/my_listing');
				}
			}).catch((error)=>{
				console.log(error);
				console.log(error.message)
				notify.error('whoops...something went wrong')
			});
	};
	const loginFailed = (response) => {
		console.log(response.error);
		// notify.error('whoops...something went wrong')
	}
  return (
			<GoogleLogin
				clientId="928487203956-k1bs7dr735prjsm1ift3i8jdo0hra3ir.apps.googleusercontent.com"
				render={renderProps => (
					<div onClick={renderProps.onClick} className="google-img text-center py-3">
						<img className="google-btn" src={google_signin} />
					</div>
				)}
				buttonText="Login"
				onSuccess={responseGoogle}
				onFailure={loginFailed}
				cookiePolicy={'single_host_origin'}
			/>
  );
};
export default LoginUsingGoogle;
