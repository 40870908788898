import React, { useEffect, useState, useRef } from "react";
import { notify, http, apiBaseUrl } from "../../utitility";
import { Formik, Field, ErrorMessage } from "formik";
import CustomButton from './CustomButton';
import Radio from "../Fields/Radio";
import ListingSchema from "../validation/ListingSchema";
import classnames from "classnames";

const CreateDetailedListingForm4 = ({ user, listingId,initialState, setInitialState, history }) => {
	const [isDisabled,setIsDisabled] = useState(false);
  
  useEffect(() => {
    if(listingId){
      http.get(apiBaseUrl(`/api/getListingDetailById?listing_id=${listingId}`))
      .then((res) => {
        let initialStateNew = {...initialState};
        initialStateNew.listing = res.data.listing;
        initialStateNew.listing_details = res.data.listing_details;
        initialStateNew.salesInfo =res.data.salesInfo;
        setInitialState(initialStateNew);
      }).catch((err)=>{
        console.log(err);
      })
    }
	},[listingId]);

  const clearFormFields=(resetForm)=>{
    resetForm({});
  }

  const redirectToPreviousPage=()=>{
    history.goBack();
  }
  
  const redirectToNextPage=()=>{
    history.push('detailed_listing5');
  }

  const PreviewListing=()=>{
    // history.push('detailed_listing2');
  }

  const changeType = (e, setFieldValue, field) => {
    setFieldValue(field, e.target.value);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={ListingSchema}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          // // setSubmitting(true);
          // setIsDisabled(true);
          // let data = {...values};
          // if(!listingId){
          //   http.post(apiBaseUrl(`/api/createListing`),data)
          //   .then(() => {
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     notify.success("Listing created successfully");
          //     clearFormFields(resetForm,setFieldValue);
          //     if(user.UserType==40){
          //       history.push('/myaccount/myaccount_ta_all');
          //     }
          //     else{
          //       history.push('/myaccount/my_listing');
          //     }
          //   }).catch((error)=>{
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     notify.error(error);
          //   });
          // }else{
          //   http.post(apiBaseUrl(`/api/editListing?listing_id=${listingId}`),data)
          //   .then(() => {
          //     // setSubmitting(false);
          //     setIsDisabled(false);
          //     if(user.UserType==40){
          //       history.push('/myaccount/myaccount_ta_all');
          //     }
          //     else{
          //       history.push('/myaccount/my_listing');
          //     }
          //     notify.success("Listing updated successfully");
          //   }).catch((error)=>{
          //     // setSubmitting(false);  
          //     setIsDisabled(false);
          //     notify.error(error);
          //   });
          // }
          if(user.UserType==40){
            history.push('/myaccount/myaccount_ta_all');
          }
          else{
            history.push('/myaccount/my_listing');
          }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <h5 className="mb-3">
              <b className="text-primary">4. Sales Information</b>
            </h5>
            <span>
              Please complete the questions in this section, all information provided on this page is private and will NOT be 
              published on the Tynax web site, it is used by Tynax to aid in the sales process.
            </span>
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Target Price (US dollars):
            </label>
            <Field type="number" name="salesInfo.TargetPrice" className="form-control"  />
            <ErrorMessage
              name="salesInfo.TargetPrice"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Minimum Acceptable Price (US dollars):
            </label>
            <Field type="number" name="salesInfo.MinimumAcceptablePrice" className="form-control"  />
            <ErrorMessage
              name="salesInfo.MinimumAcceptablePrice"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Pricing Notes:
            </label>
            <span>
            Please provide further information on pricing that may assist us in our discussions with interested buyers.
            If available, please provide valuation metrics or other figures supporting your price expectation:
            </span>
            <Field type="text" name="salesInfo.PricingNotes" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.PricingNotes"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Reasons For Listing the Technology:
            </label>
            <span>Please indicate why you have posted this listing -- is it due to a sale of company assets, change in corporate strategy, etc?</span>
            <Field type="text" name="salesInfo.ReasonsForListing" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.ReasonsForListing"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Decision-Making Process:
            </label>
            <span>
              Describe who makes the decisions regarding accepting offers or bids for this listing, and how such decisions are made:
            </span>
            <Field type="text" name="salesInfo.DecisionMakingProcess" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.DecisionMakingProcess"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Target Market
            </label>
            <span>
              Are there any companies/organization that you would specifically like us to approach?
            </span>
            <Field type="text" name="salesInfo.DecisionMakingProcess" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.DecisionMakingProcess"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Are there any restrictions on who you would sell this technology to?
            </label>
            <span>
              Please include any organization that you do NOT want us to approach?
            </span>
            <Field type="text" name="salesInfo.DecisionMakingProcess" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.DecisionMakingProcess"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Please provide information on any confidentiality requirements and other restrictions regarding this listing:
            </label>
            <Field type="text" name="salesInfo.DecisionMakingProcess" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.DecisionMakingProcess"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Would you consider selling your patent to a buyer with an enforcement-based business model?
            </label>
            <span>
              Some buyers acquire patents in order to assert them against possible infringers and 
              generate revenues from licensing to infringers or litigation.
            </span>
            <Radio
              id="PatentLitigation_yes"
              name="listing_details.PatentLitigation"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />            
            <Radio
              id="PatentLitigation_no"
              name="listing_details.PatentLitigation"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <Radio
              id="PatentLitigation_unknown"
              name="listing_details.PatentLitigation"
              value={0}
              custom="false"
              label="No Preference"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <ErrorMessage
              name="salesInfo.DecisionMakingProcess"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Is this technology appropriate for commercialization through a start-up company?
            </label>
            <Radio
              id="PatentLitigation_yes"
              name="listing_details.PatentLitigation"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />            
            <Radio
              id="PatentLitigation_no"
              name="listing_details.PatentLitigation"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <Radio
              id="PatentLitigation_unknown"
              name="listing_details.PatentLitigation"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,"listing_details.PatentLitigation")}}
            />
            <ErrorMessage
              name="salesInfo.DecisionMakingProcess"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Marketing Materials:
            </label>
            <span>
            Are there any additional marketing material that could be made available to an inquirer. 
            If so, please provide descriptions of documents available, URL's or other references:
            </span>
            <Field type="text" name="salesInfo.MarketingMaterials" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.MarketingMaterials"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Prior Promotional Efforts:
            </label>
            <span>
              Has this technology been promoted before, by whom, to who, and with what results?
            </span>
            <Field type="text" name="salesInfo.PriorPromotionalEfforts" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.PriorPromotionalEfforts"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Other Sales Notes:
            </label>
            <span>
            Please provide us with any other relevant information for this listing:
            </span>
            <Field type="text" name="salesInfo.OtherSalesNotes" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.OtherSalesNotes"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="text-center py-2">
            <button type="button" onClick={(e) => redirectToPreviousPage()} className="ml-2 btn btn-primary">Back</button>
            <button 
                  disabled={isDisabled} 
                  type="submit" 
                  className={classnames({
                    "ml-2 buttonload btn btn-primary btn-sm": isDisabled === true,
                    "ml-2 btn btn-primary btn-sm": isDisabled === false,
                  })}>
                    Save & Return Later
            </button>
            <button type="button" onClick={(e) => PreviewListing()} className="ml-2 btn btn-primary">Preview Listing</button>
            <button type="button" onClick={(e) => redirectToNextPage()} className="ml-2 btn btn-primary">Next</button>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default CreateDetailedListingForm4;
