import React, {useEffect, useRef} from "react";
import { Row, Col } from "reactstrap";
import Select from 'react-select'
import { useState } from "react";
import { filter , isEmpty} from "lodash";
import { http, apiBaseUrl,notify, getStandardDateForm } from "../utitility"
import classnames from "classnames";
import AsyncSelect from "react-select/async";

const ChangeDecisionAsAdmin = ({ user, patent, listing, listings, CurrentDetail, reloadTableData, closeAllModal, asAdmin }) => {

	useEffect(() => {
    if(listings){
			loadSelectedBroker(3);
		}
	},[listings]);

	const [selectedOption,setSelectedOption] = useState({ value: 3, label: 'Pass' });
	const [isSubmitting,setIsSubmitting] = useState(false);
	const [selectedValue,setSelectedValue] = useState(null);
	const elRef = useRef(null);

	const options = [
		{ value: '3', label: 'Pass' },
		{ value: '4', label: 'Pursue' },
		{ value: '5', label: 'Postpone' }
	];

	const loadSelectedBroker = (status) => {
		http.get(apiBaseUrl(`/api/getBrokerBasedOnDecision?ListingID=${listings.ListingID}&DecisionStatus=${status}`))
		.then((res) => {
			setSelectedValue(res.data.data);
		}).catch((error)=>{

		});
	}

	const handleChange=(data)=>{
		setSelectedOption(data);
		loadSelectedBroker(data.value);
	}
	
	const changePatentDecision=()=>{
		let data ={};

		setIsSubmitting(true);
		data.PatentNumber = CurrentDetail.PatentNumber;
		data.BrokerCode = user.BrokerID;
		data.DecisionStatus = selectedOption.value;
    data.UserID =user.UserID;
		http.post(apiBaseUrl(`/api/setPatentDecision`),data)
		.then(() => {
			reloadTableData();
			closeAllModal();
			setIsSubmitting(false);
			notify.success("Decision Status changed successfully");
		}).catch((error)=>{
			setIsSubmitting(false);
			notify.error(error);
		});
		
	}

  const changeListingDecision=(value)=>{
		let data ={};
		setIsSubmitting(true);
		data.ListingID = listing.ListingID;
		data.ListingName = listing.ListingName;
		data.BrokerCode = user.BrokerID;
		data.OwnerID= listing.OwnerID
		data.DecisionStatus = value;
		data.LastUpdate = getStandardDateForm();
		http.post(apiBaseUrl(`/api/decision`),data)
		.then(() => {
			reloadTableData();
			closeAllModal();
			setIsSubmitting(false);
			notify.success("Decision Status changed successfully");
		}).catch((error)=>{
			setIsSubmitting(false);
			notify.error(error);
		});
	}

	const getBrokerCode = (selectedValue) => {
		let brokerCode = [];
		for(var i=0;i<selectedValue.length;i++){
			brokerCode.push(selectedValue[i].BrokerID)
		}
		return brokerCode;
	}

	const changeDecision=(selectedOption)=>{
		let data ={};
			data.ListingID = listings.ListingID;
  		data.ListingName = listings.ListingName;
			data.BrokerCode = getBrokerCode(selectedValue);
			data.OwnerID= listings.OwnerID
			data.DecisionStatus = selectedOption.value;
			data.LastUpdate = getStandardDateForm();
			http.post(apiBaseUrl(`/api/decisionAsAdmin`),data)
			.then(() => {
			//	loadDecisionStatus();
				closeAllModal();
				setIsSubmitting(false);
				notify.success("Decision Status changed successfully");
				window.location.reload();
			}).catch((error)=>{
				setIsSubmitting(false);
				notify.error(error);
			});
	}

  const getDecisionStatus=(status)=> {
		if (!isEmpty(options)) {
		return filter(options, function(o) {
				if (o.value === status) return o;
		});
		} else {
			return {};
		}
	}

	const getOptionLabel=(value)=>{
		return value.BrokerID
	}

	const handleItemSelectChange=(option)=>{
		setSelectedValue(option);
	}

	const loadOptions = (inputValue, callback) => {
		let url = "/api/broker"
		http.get(apiBaseUrl(url))
			.then((res) => {
				callback(res.data.data)
			}).catch((error=>{
				console.log(error,'error')
			}))
	}

  return (
    <div>
			<Row className="justify-content-center">
				<Col className="mb-3 col-12">
					<Select value={selectedOption} options={options} onChange={handleChange} />
				</Col>
			</Row>
			<Row className="justify-content-center">
				<Col className="mb-3 col-12">
					Broker Code
				<AsyncSelect
							cacheOptions
							defaultOptions
							isMulti
							value={selectedValue}
							getOptionLabel={e => getOptionLabel(e)}
							getOptionValue={e => e.BrokerID}
							loadOptions={loadOptions}
							ref={elRef}
							isClearable={true}
							onChange={handleItemSelectChange}
							placeholder="Search Broker Code"
						/>
				</Col>
			</Row>
			<div className="text-center">
				<button 
					disabled={isSubmitting} 
					type="button"
					className={classnames({
						"buttonload btn btn-primary btn-sm": isSubmitting === true,
						"btn btn-primary btn-sm": isSubmitting === false,
					})}
					onClick={()=>changeDecision(selectedOption)}
				>
				{isSubmitting &&
					<i className="fa fa-spinner fa-spin"></i>
				}
				Save
				</button>
			</div>
		</div>
  );
};

export default ChangeDecisionAsAdmin;
