import React, { useEffect } from "react";
import { notify, http, apiBaseUrl } from "../../utitility";
import Switch from '../Fields/Switch';

const ShowListingForm = ({ user, listingId, listings, confidential_details, salesDetails, initialState, setInitialState }) => {
	const arrYesNoUnknown = ['Not Entered','No','Yes'];
  const getYesNoStatus=(status)=>{
		status = status ? status : 0;
		return arrYesNoUnknown[status];
	}

  useEffect(() => {
    if(listingId){
      http.get(apiBaseUrl(`/api/getListingDetailById?listing_id=${listingId}`))
      .then((res) => {
        let initialStateNew = {...initialState};
        initialStateNew.listing = res.data.listing;
        initialStateNew.listing_details = res.data.listing_details;
        initialStateNew.salesInfo =res.data.salesInfo;
        setInitialState(initialStateNew);
      }).catch((err)=>{
        console.log(err);
      })
    }
	},[listingId]);
  
	const title = `LISTING DETAILS`;
  return (
    <Switch
			classNames="icon icon-uploadAgreement-icon"
			title={title}
			className="grouped-body"
			id="listing_info"
			collapsible={true}
			activeStatus={true}
		>
			<section className="mb-3">
        <form>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Listing Name: 
            </label>
            {listings.ListingName}
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Summary Description:
            </label>
            {listings.ListingDescription}
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Patent Information Submitted:
            </label>
            {confidential_details.PatentNotes}
          </div>
          {(user.UserType==30||user.UserType == 40) &&
            <div className="form-group">
              <label className="font-weight-bold form-text">
              Patent Seller:
              </label>
              {salesDetails.Seller}
            </div>
          }
          <div className="form-group">
            <label className="font-weight-bold form-text">
                Back License:
            </label>
            <label className="form-group">
            {confidential_details && getYesNoStatus(confidential_details.PatentBackLicense)}
            </label>
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
              Intellectual Property Ownership & Encumbrances:
            </label>
            <label className="form-group">
            {confidential_details && getYesNoStatus(confidential_details.PatentIPOwnership)}
            </label>
          </div>
          <div className="form-group">
          {confidential_details.TechnologyOverview}
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
             Licensing Status
            </label>
            <label className="form-group">
            {confidential_details && getYesNoStatus(confidential_details.PatentLicensingStatus)}
            </label>
          </div>
          <div className="form-group">
            {confidential_details.PatentLicensingArrangement}
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
            Infringement:
            </label>
            <label className="form-group">
            {confidential_details && getYesNoStatus(confidential_details.PatentInfringement)}
            </label>
          </div>
          <div className="form-group">
            {confidential_details.PatentPotentialInfringers}
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
            Litigation
            </label>
            <label className="form-group">
            {confidential_details && getYesNoStatus(confidential_details.PatentLitigation)}
            </label>
          </div>
          <div className="form-group"> 
            {confidential_details.PatentLitigationNotes}
          </div>
          <label className="font-weight-bold form-text">
            Sales Information
          </label>
          {(user.UserType==30||user.UserType == 40) &&
            <div className="form-group">
              <label className="font-weight-bold form-text">
              Target Price (US dollars):
              </label> {salesDetails.TargetPrice}
            </div>
            }
          {(user.UserType==30||user.UserType == 40) &&
            <div className="form-group">
              <label className="font-weight-bold form-text">
              Minimum Acceptable Price (US dollars):
              </label>{salesDetails.MinimumAcceptablePrice}
            </div>
            }
          {(user.UserType==30||user.UserType == 40) &&
              <div className="form-group">
              <label className="font-weight-bold form-text">
              Pricing Notes:
              </label>
              <span>
              {salesDetails.PricingNotes}
              </span> 
            </div>
          }
          <div className="form-group">
            <label className="font-weight-bold form-text">
            Reasons For Listing the Technology:
            </label>
            <span>{salesDetails.ReasonsForListing}</span>
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
            Decision-Making Process:
            </label>
            <span>
              {salesDetails.DecisionMakingProcess}
            </span>
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
            Marketing Materials:
            </label>
            <span>
            {salesDetails.MarketingMaterials}
            </span>
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
            Prior Promotional Efforts:
            </label>
            <span>
            {salesDetails.PriorPromotionalEfforts}
            </span>
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text">
            Other Sales Notes:
            </label>
            <span>
            {salesDetails.OtherSalesNotes}
            </span>
          </div>
        </form>
        </section>
		</Switch>	
    );
  };
export default ShowListingForm;