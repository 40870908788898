import React from "react";
import ContactInfo from "./ContactInfo";
import OrganizationInfo from "./OrganizationInfo";

const ContactDetail = ({contactDetails,tynaxPeople,contact_link,userSummary,organization_link,responsible}) => {
	return (
		<section class="contact-section">
				<div class="container-fluid">
						<div class="row justify-content-center pt-3 my-5">
						    <ContactInfo contactDetails={contactDetails} tynaxPeople={tynaxPeople} contact_link={contact_link} userSummary={userSummary} responsible={responsible}/>
								<OrganizationInfo organization_link={organization_link}/>
						</div>
				</div>
		</section>
    );
}

export default ContactDetail;
