import React, { useEffect, useState, useContext } from "react";
import { useLocation } from 'react-router-dom';
import { UserContext } from "../Context/UserContext";
import  { Attachment, PatentInfo, ListingDetail, ConfidentialTab,ListingStatus,Invitation, ListingContact, ActionBox, ListingDecisionStatus, ListingNotes } from '../Components';
import {  hasKeys, notify, http, apiBaseUrl } from '../utitility';
import { listingStatus } from '../utitility/constants';
import {  ShowListingForm } from "../Components/Forms";
import Account_img from "../assets/images/tynax-anim.gif";

const ListingDetailsPage = ({history}) => {
	
		const [notAllowed,setNotallowed] = useState(false);
		const [listingcontact,setListingContact] = useState([]);
    	const [forceReload,setForceReload] = useState(false);
		const  location = useLocation();
		const [listingId,setListingId] = useState(0);

		useEffect(() => {
			const params = new URLSearchParams(location.search);
			let listingId = params.get('listing_id');
			getContent(listingId);
			getListingContact(listingId);
		},[location.search]);

		const {user,setUser} = useContext(UserContext);
		const [listings, setListingStatus] = useState({});
		const [category,setCategory] = useState([]);
		const [patentDetail,setPatentDetail] = useState([]);
		const [confidential_details,setConfidentialDetails] = useState([]);
		const [salesDetails,setSalesDetails] = useState([]);
		const [ownerDetails,setOwnerDetails] = useState([]);
		const [listing_details,setlisting_details] = useState([]);

		
		const [initialState,setInitialState] = useState({
			user_id:user.UserID,
			listings:listings,
			listing_details:listing_details,
			salesDetails:salesDetails
		});	

		const getListingContact = (listingId) =>{
			http.get(apiBaseUrl(`/api/listing_contact?listing_id=${listingId}`))
			.then((res) => {
				setListingContact(res.data.data);				
			}).catch((error)=>{
			});
  	} 

		const getContent = (listingId) =>{ 
			http.get(apiBaseUrl(`/api/listing_details?UserID=${user.UserID}&listingId=${listingId}`))
			.then((res) => {
				if (res.data) {
					setListingStatus(res.data.listings);
					setCategory(res.data.category);
					setPatentDetail(res.data.patentListing);
					setOwnerDetails(res.data.ownerDetails);
					http.get(apiBaseUrl(`/api/confidential_details?listingId=${listingId}`))
					.then((res1) => {
						if (res1.data) {
							setConfidentialDetails(res1.data.listings);
							setSalesDetails(res1.data.sales);
						} 
					}).catch((error)=>{
						 console.log(error,'error')
					});
				} 
			}).catch((error)=>{
				setNotallowed(true);
				if(error && error.response){
					notify.error(error.response.data.message);
				}
	 		});

		}
		const arrDealStructurePatentObj = { '1':'Sale of business unit',
																				'2':'Sale of patent',
																				'3':'Exclusive license with right to enforce',
																				'4':'Exclusive license',
																				'5':'Non-exclusive license',
																				'6':'Region/country license',
																				'7':'Field-of-use license'
																			}
		const arrDealStructure = {
			'1':'Sale of business unit',
			'2':'Sale of technology',
			'4' :'Exclusive license',
			'5':'Non-exclusive license',
			'6':'Region/country license',
			'7' :'Field-of-use license',
			'8' :'Open to ideas',
			'9' :'Distribution deal',
			'99':'Other'
		}	
		
    return (
			<React.Fragment>
				{hasKeys(listings) &&
				<section>
					<div className="container-fluid py-3 bg-white">
						<div className="row">
							<div className="col-12 col-lg-2">
								<img className="img-fluid" src={Account_img} alt="tech"/>
								<ListingStatus 
									patentDetail={patentDetail} 
									confidential_details={confidential_details} 
									listings={listings}
									category={category} 
									listingStatusConstant={listingStatus}
									arrDealStructurePatentObj={listings.ListingType > 0 ? arrDealStructurePatentObj : arrDealStructure } 
								/> 
								<ConfidentialTab 
									confidential_details={confidential_details} 
									listings={listings} 
									salesDetails={salesDetails}
									ownerDetails={ownerDetails} 
									user={user}
								/>
							</div>
							<div className="col-12 col-lg-8 my-3">
								<div className="">
									<div className="">
										<ListingDetail confidential_details={confidential_details} listings={listings} arrDealStructurePatentObj={listings.ListingType > 0 ? arrDealStructurePatentObj : arrDealStructure} /> <br />
										<PatentInfo listings={listings} user={user} salesDetails={salesDetails}/>
										<Attachment forceReload={forceReload} setForceReload={setForceReload} listings={listings} user={user}/>
										<ListingNotes listings={listings} user={user} />
										{user.UserType !== 15 &&
											<React.Fragment>
												<Invitation forceReload={forceReload} user={user} listings={listings} ownerDetails={ownerDetails} salesDetails={salesDetails}/>
												<ListingContact listings={listings} listingcontact={listingcontact}/>
											</React.Fragment>
										}
										{(user.UserType === 30 || user.UserType === 40) &&
											<ListingDecisionStatus user={user} listing={true} listings={listings}/>
										}
										<ShowListingForm user={user} listingId={listingId} listings={listings} 
												initialState={initialState} setInitialState={setInitialState}
												confidential_details={confidential_details} 
												salesDetails={salesDetails}
									
										/>
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-2">
								{(user.UserType === 15 || user.UserType === 40) &&
											<ActionBox history={history} user={user} listing={true} listings={listings} getContent={()=>{getContent()}} getListingContact={getListingContact}/>
								}										
							</div>
						</div>
					</div>
				</section>}
			</React.Fragment>
    );
}
export default ListingDetailsPage;