import React from "react";
import { getUserType, formatDate } from '../../utitility'
const ConfidentialTab = ({ listings, confidential_details, salesDetails, ownerDetails, user }) => {
	const arrYesNoUnknown = ['Not Entered','No','Yes'];
	const arrYesNoNoPreference = ['No preference','No','Yes'];

	const getYesNoStatus=(status,type)=>{
		status = status ? status : 0;
		if(type == 'pre'){
      return arrYesNoNoPreference[status];
		}
		return arrYesNoUnknown[status];
	}
  return (
	<div className="card mt-3">
		<div className="card-title text-center p-2">Confidential</div>
		<div className="card-list">
				<div className="card-item">
					<div className="card-text mb-2">Rating</div>
					<div>{listings.Rating ? listings.Rating : 0}</div>
				</div>
				{listings.ListingType > 0 &&				
          <div className="card-item">
						<div className="card-text mb-2">Patent Info:</div>
						<div><b>Encumbrances:</b> {confidential_details && getYesNoStatus(confidential_details.PatentIPOwnership,'unk')}</div>
						<div><b>Licensed:</b> {confidential_details && getYesNoStatus(confidential_details.PatentLicensingStatus,'unk')}</div>
						<div><b>Infringed:</b> {confidential_details && getYesNoStatus(confidential_details.PatentInfringement,'unk')}</div>
						<div><b>Litigation:</b> {confidential_details && getYesNoStatus(confidential_details.PatentLitigation,'unk')}</div>
			 		</div>
				}
				{user.UserType >=30 &&
				<div className="card-item">
					<div className="card-text mb-2">Sales Info:</div>
					<div><b>Target Price:</b>{salesDetails && salesDetails.TargetPrice}</div>
					<div><b>Minimum Price:</b>{salesDetails && salesDetails.MinimumAcceptablePrice}</div>
					{/* <div>{salesDetails && getYesNoStatus(salesDetails.PatentEnforceBuyer,'pre')}</div>
					<div>{salesDetails && getYesNoStatus(salesDetails.CommercializeStartup,'unk')}</div> */}
				</div>}
			{user.UserType >=30 &&
					<div>
				  <div className="card-item">
					  <div className="card-text mb-2">Broker Info:</div>
						<div><b>Tynax Direct:</b> {getYesNoStatus(salesDetails.TynaxDirectDeal,'unk')}</div>
						<div><b>Buyside Required:</b> {getYesNoStatus(salesDetails.BuySideCommission,'unk')}</div>
						<div><b>Motivated Seller:</b>  {getYesNoStatus(salesDetails.BarginPrice,'unk')}</div>
						<div><b>Broker Commission:</b>{salesDetails.BrokerCommission} %</div>
						<div><b>Broker Contract:</b> {getYesNoStatus(salesDetails.BrokerContract,'unk')}</div>
						<div><b>Broker Exclusivity:</b> {getYesNoStatus(salesDetails.BrokerArrangementExclusive,'unk')}</div>
					</div>
					<div className="card-item">
							<div className="card-text mb-2">Listing Details:</div>
							<div><b>Created Date:</b> {formatDate(listings.createdate)}</div>
							<div><b>Created By:</b> {ownerDetails && <span>{getUserType(ownerDetails.UserType,'unk')} - {ownerDetails.Firstname}{ownerDetails.Lastname}</span>}</div>
							<div><b>Company:</b> {ownerDetails && ownerDetails.Company}</div>
							<div><b>Source:</b> {listings.Brokercode}</div>
							{salesDetails.Seller &&
								<div><b>Patent Seller:</b> {salesDetails.Seller}</div>
							}
					</div>
					<div className="card-item">
						<ul className="list-unstyled sidebar-list">
							<li className=""><a href="#">Details</a></li>
							<li className=""><a href="#">Patents</a></li>
						</ul>
						<ul className="list-unstyled sidebar-list">
							<li className=""><a href="#">Notes</a></li>
							<li className=""><a href="#">Attach</a></li>
						</ul>
					</div>
				</div>}
				</div>
	</div>
    );
}

export default ConfidentialTab;


