import React, { useEffect, useState, useRef } from "react";
import { notify, http, apiBaseUrl } from "../../utitility";
import { Formik, Field, ErrorMessage } from "formik";
import Radio from "../Fields/Radio";
import ListingSchema from "../validation/ListingSchema";
import classnames from "classnames";
import Attachment from '../ListingDetails/Attachment';
import { ConnectedFocusError } from 'focus-formik-error'
import Select from 'react-select'

const CreateListingForm = ({ user, listingId,initialState, setInitialState, history }) => {
	const [isDisabled,setIsDisabled] = useState(false);
  const [forceReload,setForceReload] = useState(false);
  const [selectedCountry,setselectedCountry] = useState({ value: 'US', label: 'United States' });
  const [selectedStatus,setselectedStatus] = useState({ value: 'Issued', label: 'Issued' });
  const [countryOptions,setcountryOptions] = useState([]);
  const [patentData,setPatentData] = useState({});

  const options = [
		{ value: 'Issued', label: 'Issued' },
		{ value: 'Application', label: 'Application' }
  ]
  
  useEffect(() => {
    if(listingId){
      http.get(apiBaseUrl(`/api/getListingDetailById?listing_id=${listingId}`))
      .then((res) => {
        let initialStateNew = {...initialState};
        initialStateNew.listing = res.data.listing;
        initialStateNew.listing_details = res.data.listing_details;
        initialStateNew.salesInfo =res.data.salesInfo;
        setInitialState(initialStateNew);
        setPatentData(res.data.listingPatentDetails);
      }).catch((err)=>{
        console.log(err);
      })
    }
    if(countryOptions.length === 0){
      http.get(apiBaseUrl(`/api/getCountry`))
      .then((res) => {
        setcountryOptions(res.data.data);
      }).catch((err)=>{
        console.log(err);
      })
    }
	},[listingId]);

		
  const refPatentIPOwnership_Notes = useRef();
  const refPatentLicensingStatus_Notes = useRef();
  const refPatentInfringement_Notes = useRef();
  const refPatentLitigation_Notes = useRef();

  const clearFormFields=(resetForm)=>{
    resetForm({});
  }

  const redirectToListingPage=()=>{
    history.goBack();
  }
  
  const changeType = (e, setFieldValue, field) => {
    setFieldValue(field, e.target.value);
    if (e.target.name==="listing_details.PatentIPOwnership"){
      if (e.target.value==2)
        refPatentIPOwnership_Notes.current.hidden=false;
      else
        refPatentIPOwnership_Notes.current.hidden=true;
    }
    if (e.target.name==="listing_details.PatentLicensingStatus"){
      if (e.target.value==2)
        refPatentLicensingStatus_Notes.current.hidden=false;
      else
        refPatentLicensingStatus_Notes.current.hidden=true;
    }
    if (e.target.name==="listing_details.PatentInfringement"){
      if (e.target.value==2)
        refPatentInfringement_Notes.current.hidden=false;
      else
        refPatentInfringement_Notes.current.hidden=true;
    }
    if (e.target.name==="listing_details.PatentLitigation"){
      if (e.target.value==2)
        refPatentLitigation_Notes.current.hidden=false;
      else
        refPatentLitigation_Notes.current.hidden=true;
    }
  };

  const findPatentFrom = (patentNumbers) => {
    let allPatents = [];
    let getPatentNumbers = [];
    let patentNotes = patentNumbers && patentNumbers.listing_details && patentNumbers.listing_details.PatentNotes;
    
    if(patentNotes !== null)
    {
      /* The Below piece of code to get normal patent from patent notes */
      getPatentNumbers = patentNotes.match(/\d+/g);
      if(getPatentNumbers != null)
      {
        getPatentNumbers.forEach((patentNum) => {
          const updatePatent = {
            patentNumber: patentNum,
            patentCountry: 'US',
            patentStatus : 'ISSUED',
        }
          let patentNumlength = patentNum.toString().length;
          if(patentNumlength == 7 || patentNumlength == 8){
            allPatents.push(updatePatent);
          }
        });
      }

      /* The Below piece of code to get patent added with commas from patent notes */
      let commaSeperatedPatent  = patentNotes.match(/[0-9]+(,[0-9]+)+/g);
      if(commaSeperatedPatent != null)
      {
        commaSeperatedPatent.forEach((patentNum) => {
          let patentReplaced = patentNum.replace(/,/g, '');
          const updatePatent = {
            patentNumber: patentReplaced,
            patentCountry: 'US',
            patentStatus : 'ISSUED',
          }
          let patentNumlength = patentReplaced.toString().length;
          if(patentNumlength == 7 || patentNumlength == 8){
            allPatents.push(updatePatent);
          }
        });
      }
    }

    return allPatents;
  }

  const handleStatusChange=(option, setFieldValue)=>{
    setselectedStatus(option);
  }

  const handleCountryChange=(option, setFieldValue)=>{
    setselectedCountry(option);
  }

  const onAddPatent = (value) => {
    let replacedPatentNumber = value.PatentNumber.replace(/,/g, '');
    let result = patentData.length > 0 ? patentData.filter(t=>t.patentNumber === replacedPatentNumber) : [];
    if(value.PatentNumber && result.length === 0) 
    {
      http.get(apiBaseUrl(`/api/getPatentDetailFromBulk?patentNumber=${replacedPatentNumber}&country=${selectedCountry.value}&status=${selectedStatus.value}`))
      .then((res) => {
        if(patentData.length > 0) 
        {
          setPatentData(patentData.concat(res.data.data));
        } else
        {
          setPatentData(res.data.data);
        }
      }).catch((error)=>{
        console.log(error);
      });
    } else if(!replacedPatentNumber) {
      notify.error("Patent Number is Required");
    } else {
      notify.error("Patent number already added to this listing");
    }
  }

  const deleteListingPatent = (index,data) =>{
      patentData.splice(index, 1);
      data["removePatent"] = true;
      setPatentData(patentData.concat(data));
	}

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }


  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={ListingSchema}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          // setSubmitting(true);
          setIsDisabled(true);
          const listingPatentNumber = findPatentFrom(values);
          let listing_Patent = [];
          if(patentData.length > 0)
          {
            listing_Patent = listingPatentNumber.concat(patentData);
          } else {
            listing_Patent = listingPatentNumber;
          }
          const uniqueListing_Patent = getUniqueListBy(listing_Patent, 'patentNumber');
          let data = {...values, listingPatent:uniqueListing_Patent};
          if(!listingId){
            http.post(apiBaseUrl(`/api/createListing`),data)
            .then((res) => {
              // setSubmitting(false);
              setIsDisabled(false);
              notify.success("Listing created successfully");
              clearFormFields(resetForm,setFieldValue); 
              history.push(`/listings/UploadAttachment?listing_id=${res.data.listing.ListingID}`);
            }).catch((error)=>{
              // setSubmitting(false);
              setIsDisabled(false);
              notify.error(error);
            });
          }else{
            http.post(apiBaseUrl(`/api/editListing?listing_id=${listingId}`),data)
            .then(() => {
              // setSubmitting(false);
              setIsDisabled(false);
              if(user.UserType==40){
                history.push('/myaccount/myaccount_ta_all');
              }
              else{
                history.push('/myaccount/my_listing');
              }
              notify.success("Listing updated successfully");
            }).catch((error)=>{
              // setSubmitting(false);  
              setIsDisabled(false);
              notify.error(error);
            });
          }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <ConnectedFocusError />
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Listing Name:
            </label>
            <span>Use less than 10 words.</span>
            <Field type="text" name="listing.ListingName" className="form-control"  />
            <ErrorMessage
              name="listing.ListingName"
              component="div"
              className="text-danger"
            />
            <Field type="hidden" name="listing.AvailableStatus" className="form-control" />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Summary Description:
            </label>
            <Field type="text" name="listing.ListingDescription" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing.ListingDescription"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Please Enter the Patent Numbers Here:
            </label>
            <span>
              Our system will indentify US patent numbers in various formats including 7777777, US7777777,
               7,777,777, 7777777B1, etc. Ideally place one patent number on each line, but the system can usually 
               identify patent numbers on the same line, separated by spaces.
            </span>
            <Field type="text" name="listing_details.PatentNotes" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing_details.PatentNotes"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Add Patent
            </label>
          </div>
          <div className="form-group" style={{ border: "1px solid #ced4da", borderRadius: "5px"}}>
            <div className="form-group col-sm-12 col-md-4 col" style={{ float: "left"}}>
              <label className="font-weight-bold form-text" >
                Patent Number
              </label>
              <input
                type="text"
                name="PatentNumber"
                className="form-control"
                onChange={handleChange}
                value={values.PatentNumber}
                placeholder="Patent Number"
              />
            </div>
            <div className="form-group col-sm-12 col-md-4 col" style={{ float: "left"}}>
              <label className="font-weight-bold form-text">
                Country
              </label>
              <Select value={selectedCountry} name="Country" options={countryOptions} onChange={(e)=>handleCountryChange(e,setFieldValue)} />
            </div>
            <div className="form-group col-sm-12 col-md-4 col" style={{ float: "left"}}>
              <label className="font-weight-bold form-text">
              Status
              </label>
              <Select value={selectedStatus} name="PatentStatus" options={options} onChange={(e)=>handleStatusChange(e,setFieldValue)} />
            </div>
            <div className="text-center py-2">
              <span className="btn btn-primary btn-sm" onClick={()=>onAddPatent(values)}> Add Patent </span>
            </div>
          </div>
          {patentData.length > 0 &&
            <div className="form-group">
              <table style={{ border: '1px solid #ccc', padding: '5px'}}>
                <thead style={{ border: '1px solid #ccc', padding: '5px'}}>
                  <th style={{ border: '1px solid #ccc', padding: '5px'}}>Patent Number</th>
                  <th style={{ border: '1px solid #ccc', padding: '5px'}}>Country</th>
                  <th style={{ border: '1px solid #ccc', padding: '5px'}}>Title</th>
                  <th style={{ border: '1px solid #ccc', padding: '5px'}}>Assignee</th>
                  <th style={{ border: '1px solid #ccc', padding: '5px'}}>Status</th>
                  <th style={{ border: '1px solid #ccc', padding: '5px'}}>Filed Date</th>
                  <th style={{ border: '1px solid #ccc', padding: '5px'}}>Action</th>
                </thead>
                <tbody style={{ border: '1px solid #ccc', padding: '5px'}}>
                {patentData.map((patentData,index) => (
                  patentData.removePatent == false || patentData.removePatent == undefined ? 
                  <tr key={index}>
                    <td style={{ border: '1px solid #ccc', padding: '5px'}}>{patentData.patentNumber}</td>
                    <td style={{ border: '1px solid #ccc', padding: '5px'}}>{patentData.patentCountry}</td>
                    <td style={{ border: '1px solid #ccc', width: '30%', padding: '5px'}}>{patentData.Title}</td>
                    <td style={{ border: '1px solid #ccc', padding: '5px'}}>{patentData.Assignee}</td>
                    <td style={{ border: '1px solid #ccc', padding: '5px'}}>{patentData.patentStatus}</td>
                    <td style={{ border: '1px solid #ccc', padding: '5px'}}>{patentData.FiledDate}</td>
                    <td style={{ border: '1px solid #ccc', padding: '5px'}}>
                    <div>
                      <span  onClick={e => deleteListingPatent(index,patentData)} className="btn btn-secondary btn-sm">
                        <i className="fa fa-trash" />
                      </span>
                    </div>
                    </td>
                  </tr>
                : ''))}
                </tbody>
              </table>
            </div>
          }
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Patent Seller:
            </label>
            <Field type="text" name="salesInfo.Seller" className="form-control"  />
            <ErrorMessage
              name="salesInfo.Seller"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
                Back License:
            </label>
            <span>
              When selling this patent(s) would you like to retain a license back for your own ongoing use?
            </span>
            <Radio
              id="listing_details_no"
              name="listing_details.PatentBackLicense"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
            <Radio
              id="listing_details_yes"
              name="listing_details.PatentBackLicense"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
            <Radio
              id="listing_details_unknown"
              name="listing_details.PatentBackLicense"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              Intellectual Property Ownership & Encumbrances:
            </label>
            <span>
              Are there any liens, secured debts or other claims encumbering the patent rights that would restrict the buyer from gaining clean title?
            </span>
            <Radio
              id="PatentIPOwnership_no"
              name="listing_details.PatentIPOwnership"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
            <Radio
              id="PatentIPOwnership__yes"
              name="listing_details.PatentIPOwnership"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
            <Radio
              id="PatentIPOwnership_unknown"
              name="listing_details.PatentIPOwnership"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
          </div>
          <div className="form-group" ref={refPatentIPOwnership_Notes} hidden="true">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              If yes, please describe these encumbrances:
            </label>
            <Field type="text" name="listing_details.TechnologyOverview" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing_details.TechnologyOverview"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
             Licensing Status
            </label>
            <span>
            Are there any existing licensees?
            </span>
            <Radio
              id="PatentLicensingStatus_no"
              name="listing_details.PatentLicensingStatus"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
            <Radio
              id="PatentLicensingStatus_yes"
              name="listing_details.PatentLicensingStatus"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
            <Radio
              id="PatentLicensingStatus_unknown"
              name="listing_details.PatentLicensingStatus"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
          </div>
          <div className="form-group" ref={refPatentLicensingStatus_Notes} hidden="true">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
              If yes, without naming licensees, please describe existing licensing arrangements:
            </label>
            <Field type="text" name="listing_details.PatentLicensingArrangement" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing_details.PatentLicensingArrangement"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Infringement:
            </label>
            <span>
            Do you suspect anyone of practicing the patented inventions without a license?
            </span>
            <Radio
              id="PatentInfringement_no"
              name="listing_details.PatentInfringement"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
            <Radio
              id="PatentInfringement_yes"
              name="listing_details.PatentInfringement"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
            <Radio
              id="PatentInfringement_unknown"
              name="listing_details.PatentInfringement"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
          </div>
          <div className="form-group" ref={refPatentInfringement_Notes} hidden="true">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            If yes, please provide information on potential infringers:
            </label>
            <Field type="text" name="listing_details.PatentPotentialInfringers" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing_details.PatentPotentialInfringers"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Litigation
            </label>
            <span>
            Has this listing been subject to infringement or other forms of litigation?
            </span>
            <Radio
              id="PatentLitigation_no"
              name="listing_details.PatentLitigation"
              value={1}
              custom="false"
              label="No"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />
            <Radio
              id="PatentLitigation_yes"
              name="listing_details.PatentLitigation"
              value={2}
              custom="false"
              label="Yes"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />            
            <Radio
              id="PatentLitigation_unknown"
              name="listing_details.PatentLitigation"
              value={0}
              custom="false"
              label="UnKnown"
              onClick = { e => {changeType(e,setFieldValue,values)}}
            />  
          </div>
          <div className="form-group" ref={refPatentLitigation_Notes} hidden="true">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            If yes, please describe the cases and the outcome:
            </label>
            <Field type="text" name="listing_details.PatentLitigationNotes" component="textarea" className="form-control"  />
            <ErrorMessage
              name="listing_details.PatentLitigationNotes"
              component="div"
              className="text-danger"
            />
          </div>
          <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Sales Information — If you are looking to sell the patents outright.
          </label>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Target Price (US dollars):
            </label>
            <Field type="number" name="salesInfo.TargetPrice" className="form-control"  />
            <ErrorMessage
              name="salesInfo.TargetPrice"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Minimum Acceptable Price (US dollars):
            </label>
            <Field type="number" name="salesInfo.MinimumAcceptablePrice" className="form-control"  />
            <ErrorMessage
              name="salesInfo.MinimumAcceptablePrice"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Pricing Notes:
            </label>
            <span>
            Please provide further information on pricing that may assist us in our discussions with interested buyers.
            If available, please provide valuation metrics or other figures supporting your price expectation:
            </span>
            <Field type="text" name="salesInfo.PricingNotes" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.PricingNotes"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Reasons For Listing the Technology:
            </label>
            <span>Please indicate why you have posted this listing -- is it due to a sale of company assets, change in corporate strategy, etc?</span>
            <Field type="text" name="salesInfo.ReasonsForListing" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.ReasonsForListing"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Decision-Making Process:
            </label>
            <span>
              Describe who makes the decisions regarding accepting offers or bids for this listing, and how such decisions are made:
            </span>
            <Field type="text" name="salesInfo.DecisionMakingProcess" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.DecisionMakingProcess"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Marketing Materials:
            </label>
            <span>
            Are there any additional marketing material that could be made available to an inquirer. 
            If so, please provide descriptions of documents available, URL's or other references:
            </span>
            <Field type="text" name="salesInfo.MarketingMaterials" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.MarketingMaterials"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Prior Promotional Efforts:
            </label>
            <span>
              Has this technology been promoted before, by whom, to who, and with what results?
            </span>
            <Field type="text" name="salesInfo.PriorPromotionalEfforts" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.PriorPromotionalEfforts"
              component="div"
              className="text-danger"
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold form-text" htmlFor="exampleInputEmail1">
            Other Sales Notes:
            </label>
            <span>
            Please provide us with any other relevant information for this listing:
            </span>
            <Field type="text" name="salesInfo.OtherSalesNotes" component="textarea" className="form-control"  />
            <ErrorMessage
              name="salesInfo.OtherSalesNotes"
              component="div"
              className="text-danger"
            />
          </div>
					{(listingId) &&
            <div>
              <Attachment forceReload={forceReload} setForceReload={setForceReload} listings={initialState.listing} user={user}/>
            </div>
          }
          <div className="text-center py-2">
            {/* <CustomButton isSubmitting={false} btnText='Submit' />  */}
            <button 
                  disabled={isDisabled} 
                  type="submit" 
                  className={classnames({
                    "buttonload btn btn-primary btn-sm": isDisabled === true,
                    "btn btn-primary btn-sm": isDisabled === false,
                  })}>
                    Submit
            </button>
            <button type="button" onClick={(e) => redirectToListingPage()} className="ml-2 btn btn-secondary">Cancel</button>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default CreateListingForm;
