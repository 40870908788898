import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactGenericTable from '../Tables/ReactGenericTable';
import Switch from '../Fields/Switch';
import { notify, apiBaseUrl, formatDate, getDecisionStatus } from "../../utitility";

const ListingDecisionStatus = ({listings,user}) => {
	const [count,setCount] = useState(0);
  const [url,setUrl] = useState('');
	const childRef = useRef();

	useEffect(() => {
		if(user.UserType === 30){ 
			// setUrl(`getDecisionstatusForBroker?ListingID=${listings.ListingID}&BrokerCode=${user.BrokerID}&`);
			setUrl(`getDecisionstatusForAdmin?ListingID=${listings.ListingID}&`);	
		}else if(user.UserType === 40){
			setUrl(`getDecisionstatusForAdmin?ListingID=${listings.ListingID}&`);	
		}
	},[user]);



	const columns = useMemo(
		() => [
			{
				Header: "Decision Status",
				accessor: "DecisionStatus",
				width: 150,
				Cell: data => {return getDecisionStatus(data.cell.value)},
				sort:true
			},
			{
				Header: "Decision Made By",
				accessor: "UserName",
				width: 200,
				sort:true
			},
			{
				Header: "BrokerCode",
				accessor: "BrokerCode",
				width: 150,
				sort:true
			},
			{
				Header: "Updated",
				accessor: "LastUpdate",
				Cell: row => (formatDate(row.cell.value)),
				width: 150,
				sort:true
			}
		]
	)
   const title = `DECISIONS - ${count}`;
   const isActive = (count > 0 ? true : false);
	return (
		<Switch
			classNames="icon icon-uploadAgreement-icon"
			title={title}
			className="grouped-body"
			id="decision_info"
			collapsible={true}
			activeStatus={isActive}
		>
			<section className="mb-3">
			{url&&
			<ReactGenericTable 
				ref={childRef} 
				setCount={setCount}  
				pagination={true} 
				url={url} 
				defaultSortIndex="ListingID" 
				defaultSortOrder="DESC" 
				columns={columns} 
			/>}
			</section>
		</Switch>
    );
  }

export default ListingDecisionStatus;
